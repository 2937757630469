// IMPORTS
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// ACTIONS
import {
  doGetSearchKey,
  doDownloadStudentPDF,
  doGetStudentCount,
  doGetStudentResults,
  doDownloadStudentCSV,
  doGetSchoolCount,
  doGetSchools,
  doDownloadSchoolCSV,
  doDeleteSchool,
  doGetStudents
} from './actions';

// PRESENTATION
import Student from '../../components/Students';

// REDUX
const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doGetSearchKey,
      doDownloadStudentPDF,
      doGetStudentCount,
      doGetStudentResults,
      doDownloadStudentCSV,
      doGetSchoolCount,
      doGetSchools,
      doDownloadSchoolCSV,
      doDeleteSchool,
      doGetStudents
    },
    dispatch
  );
const StudentsContainer = connect(mapStateToProps, mapDispatchToProps)(Student);

// EXPORTS
export default StudentsContainer;
