// IMPORTS
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

// PRESENTATION COMPONENTS
import Dashboard from '../../components/Dashboard';

// ACTIONS
import { doModalHide } from '../ModalBackgroundContainer/actions';

// REDUCER
import { AppState } from '../../redux/reducer';

// TYPES
interface IProps {
  doModalHide: Function;
}

// MAIN COMPONENT
const DashboardContainer: React.FC<IProps> = props => {
  // Vars
  const { doModalHide: modalHide } = props;

  // Hooks
  useEffect(() => {
    modalHide();
  }, [modalHide]);

  return <Dashboard {...props}>{props.children}</Dashboard>;
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doModalHide
    },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
