// IMPORTS
import { Dispatch } from 'redux';

// TYPES
import {
  USER_SEARCHKEY_REQUEST,
  STUDENT_PDF_DOWNLOAD_REQUEST,
  STUDENT_COUNT_REQUEST,
  STUDENT_RESULTS_REQUEST,
  STUDENT_CSV_EXPORT_REQUEST,
  SCHOOL_COUNT_REQUEST,
  SCHOOL_REQUEST,
  SCHOOL_CSV_EXPORT_REQUEST,
  SCHOOL_DELETE_REQUEST,
  STUDENT_DATA_REQUEST
} from './types';

// ACTIONS
/**
 *
 */
const doGetSearchKey = (schoolId: string) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: USER_SEARCHKEY_REQUEST,
    payload: {
      schoolId
    }
  });
};

/**
 *
 * @param id
 */
const doDownloadStudentPDF = (id: string, language: String) => (
  dispatch: Dispatch<ReduxAction>
) => {
  dispatch({
    type: STUDENT_PDF_DOWNLOAD_REQUEST,
    payload: {
      id,
      language
    }
  });
};

const doGetStudentCount = (schoolId: string) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: STUDENT_COUNT_REQUEST,
    payload: {
      schoolId
    }
  });
};

const doGetSchoolCount = () => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: SCHOOL_COUNT_REQUEST
  });
};

const doGetSchools = (page?: number, sort?: any, schoolId?: string) => (
  dispatch: Dispatch<ReduxAction>
) => {
  dispatch({
    type: SCHOOL_REQUEST,
    payload: {
      page,
      sort,
      schoolId
    }
  });
};

const doGetStudentResults = (schoolId: string) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: STUDENT_RESULTS_REQUEST,
    payload: {
      schoolId
    }
  });
};

const doGetStudents = ({
  schoolId,
  page,
  limit,
  lastSorted,
  search,
  showDisabled
}: {
  schoolId: string;
  page: number;
  limit: number;
  lastSorted: string;
  search: string;
  showDisabled: boolean;
}) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: STUDENT_DATA_REQUEST,
    payload: {
      schoolId,
      page,
      limit: limit ?? 200,
      lastSorted,
      search,
      showDisabled
    }
  });
};

const doDownloadStudentCSV = (
  query?: string,
  locale?: string,
  showDisabled?: boolean,
  schoolId?: string
) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: STUDENT_CSV_EXPORT_REQUEST,
    payload: {
      query,
      locale,
      showDisabled,
      schoolId
    }
  });
};

const doDownloadSchoolCSV = (locale?: string, sort?: any) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: SCHOOL_CSV_EXPORT_REQUEST,
    payload: {
      locale,
      sort
    }
  });
};

const doDeleteSchool = (schoolId?: string) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: SCHOOL_DELETE_REQUEST,
    payload: {
      schoolId
    }
  });
};

// EXPORTS
export {
  doGetSearchKey,
  doDownloadStudentPDF,
  doGetStudentCount,
  doGetStudentResults,
  doDownloadStudentCSV,
  doGetSchoolCount,
  doGetSchools,
  doDownloadSchoolCSV,
  doDeleteSchool,
  doGetStudents
};
