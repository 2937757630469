import DashboardStyles from '../Dashboard/styles';
import styled, { keyframes } from 'styled-components';
import BaseCard from '../Card';
import Colors from '../../styles/Colors';

const CardSortStyle = styled(DashboardStyles)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 50px;
`;

export const width = 220;
export const aspectRatio = 270 / 432;

const ShowHide = (from: string, to: string) => keyframes`
  from {
    opacity: ${from};
  }
  to {
    opacity: ${to};
  }
`;
export const Card = styled(BaseCard)`
  display: ${props => (props.visible ? 'block' : 'none')};
  opacity: ${props => (props.visible ? '1' : '0')};
  user-select: none;
  width: ${width}px;
  height: ${width / aspectRatio}px;
  margin-bottom: 14px;
  transition: all 250ms ease-in-out 1s;
  animation-duration: 400ms;
  animation-iteration-count: once;
  animation-timing-function: linear;
  animation-name: ${ShowHide('0', '1')};

  @media (max-width: 360px) and (min-height: 569px) {
    width: 200px;
    min-height: 300px;
    height: auto;
    > img {
      width: 100%;
    }
  }
  @media (max-width: 360px) and (max-height: 568px) {
    width: 190px;
    min-height: 265px;
    max-height: 280px;
    > img {
      width: ${width - 25}px;
    }
  }
  @media (max-height: 480px) and (max-width: 320px) {
    width: 100%;
    min-height: 200px;
    height: auto;
    > img {
      width: calc(100% - 25px);
    }
  }
`;

export const CardsWrapper = styled.div`
  margin: 8px 0 14px 0;
  text-align: center;
  cursor: pointer;
  position: relative;
  & span {
    position: absolute;
    bottom: 15%;
    background-color: ${Colors.white}
    color: ${Colors.black};
    font-size: 0.9em !important;
    text-transform: uppercase;
    right: 0;
    left: 0;
    text-align: center;
    padding: 0.5rem;
    line-height: 1.5rem;
  }
  @media screen and (max-width: 320px) {
    margin: 8px 0;
    & span {
      top: calc(95% - 75px);
    }
  }
`;

export const InfoWrapperContainer = styled.div`
  background-color: ${Colors.white};
  border: 2px solid ${Colors.purple72}
  position: absolute;
  top: 12px;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 12px;
  border-radius: 100%;

  button:focus {
    border: 2px solid ${Colors.black};
    box-shadow: none;
  }
`;

export const InfoWrapper = styled.button`
  height: 1.75em;
  width: 1.75em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  transition: all 250ms ease-in-out 0s;
  padding: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
  }
`;

export const ProgressWrapper = styled.div`
  width: 285px;
  margin-left: 66px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 360px) {
    width: 250px;
    margin-left: 46px;
  }
  @media screen and (max-width: 320px) {
    width: 210px;
    margin-left: 66px;
  }
`;

export const UndoWrapper = styled.button<{ hidden: boolean }>`
  visibility: ${props => (props.hidden ? 'hidden' : 'unset')};
  user-select: none;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 58px;
  height: 58px;
  border-radius: 100%;
  line-height: unset;
  padding: 0;
  cursor: pointer;
  color: ${Colors.white};
  & svg {
    font-size: 20px;
    color: ${Colors.white};
  }
  &:hover {
    color: ${Colors.purple72};
    background-color: ${Colors.white};
    & svg {
      color: ${Colors.purple72};
    }
  }
  transition: all 0.2s ease-in-out;
`;

export default CardSortStyle;
