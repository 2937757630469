// IMPORTS
import React, { useContext } from 'react';
import { Push } from 'connected-react-router';
import LanguageContext from '../../locale/LanguageContext';
import { AppState } from '../../redux/reducer';
import { FaInfo } from 'react-icons/fa';
import gaUtil from '../../util/ga'

// STYLE COMPONENTS
import BackOfCardReminderStyles, { BackWrapper, BackOfCardReminderDemoCard } from './styles';
import { Back } from '../../styles/assets';
import Button from '../../styles/Button';
import { InfoWrapper } from '../CardSort/styles'

// TYPES
interface IProps {}
type StateProps = AppState;
interface DispatchProps {
  push: Push;
}
type Props = IProps & StateProps & DispatchProps;

// MAIN COMPONENT
const BackOfCardReminder: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const role = props.accountReducer.get('role')
  const cardsResults = props.resultReducer.get('cards');
  const hasResults = cardsResults && cardsResults.toJS().length > 0;
  // Functions
  const onClickBack = () => {
    props.push('/dashboard/cards/instructions');
  };
  const onClickSort = () => {
    const isFirstSort = !hasResults
    gaUtil.events.cardSort.startCardSort(role, {isFirstSort})
    props.push('/dashboard/cards/1');
  };

  // Render
  return (
    <BackOfCardReminderStyles>
      <h1>{translate('BackOfCardReminder.Heading')}</h1>
      <div>
        <p>{translate('BackOfCardReminder.Description')}</p>
        <BackOfCardReminderDemoCard>
          <InfoWrapper disabled>
            <FaInfo />
          </InfoWrapper>
        </BackOfCardReminderDemoCard>
      </div>
      <Button styleType="primary-white" onClick={onClickSort}>
        {translate('BackOfCardReminder.Button')}
      </Button>
      <BackWrapper onClick={onClickBack}>
        <Back /> {translate('BackOfCardReminder.Back')}
      </BackWrapper>
    </BackOfCardReminderStyles>
  );
};

// EXPORTS
export default BackOfCardReminder;
