import React from "react";
import { ChangeEvent } from "react"
import { InputStyles, CustomCheckbox, CustomLoaderStyles } from './styles';
import Loader from '../Loader';

interface ICheckboxProps {
  checked: boolean;
  loading?: boolean;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
}

const Checkbox = (props: ICheckboxProps) => {
  const {checked, onChange, loading} = props
  
  return (
    <>
      <InputStyles type="checkbox" checked={checked} onChange={onChange} disabled={loading}/>
      {loading ? (
        <CustomLoaderStyles>
          <Loader/>
        </CustomLoaderStyles>
      ) : (
        <CustomCheckbox/>
      )}
    </>
  )
}

export default Checkbox