import styled from 'styled-components';
import Colors from '../../styles/Colors';
interface IProps {
  value: number;
}

const ProgressBarStyle = styled('div') <IProps>`
  flex: 1;
  height: 12px;
  border-radius: 8px;
  border: 1px solid ${Colors.white};

  & div {
    transition: width 0.3s ease-in-out;
    border-radius: 8px;
    height: 100%;
    width: ${props => props.value * 100}%;
    background-color: ${Colors.white};
  }
`;

export default ProgressBarStyle;
