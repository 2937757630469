import { Dispatch } from 'redux';
import { RESULT_DOWNLOAD_PDF, RESULT_DO_LOAD, RESULT_SET_START_TIME, RESULT_SAVE_PROGRESS, RESULT_CREATE, RESULT_DELETE } from './types';

export const doDownloadPdf = (payload: any) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_DOWNLOAD_PDF,
    payload
  });
};

export const doLoadResults = () => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_DO_LOAD
  });
};

export const doSetStartTime = (payload: number | null) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_SET_START_TIME,
    payload
  });
};

export const doSaveProgress = (payload: any) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_SAVE_PROGRESS,
    payload
  });
};

export const doCreateResult = (payload: any) => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_CREATE,
    payload
  });
};

export const doDeleteResult = () => (dispatch: Dispatch<ReduxAction>) => {
  dispatch({
    type: RESULT_DELETE
  });
};
