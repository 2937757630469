// IMPORTS
import { put, takeLeading } from 'redux-saga/effects';
import firebase from '../../firebase';
import fetch from '../../fetch';
import gaUtil from '../../util/ga';
import { isEmailValid } from '../../util/validation';

// REDUX TYPES
import { MODAL_HIDE } from '../ModalBackgroundContainer/types';
import { USER_SIGNED_UP, USER_SIGNUP_ERROR, SIGNUP_REQUEST, CONTACT_SUBMISSION_REQUEST, CONTACT_SUBMISSION_ERROR, CONTACT_SUBMISSION_SUCCESS } from './types';
import { GET_USER_DATA_SUCCESS } from '../AccountContainer/types';

// TYPES
import { AuthPayload } from '../Auth/reducer';
interface ErrorObject {
  [field: string]: string;
}

// SAGES
function* workerSignup({ payload }: ReduxAction<AuthPayload>) {
  try {
    // 1. Get payload
    const {
      email,
      password,
      confirmPassword,
      firstname,
      lastname,
      type,
      address,
      city,
      country,
      phone,
      postalzip,
      state,
      code,
      schoolname
    } = payload;

    if (!firstname) { throw new Error('error.firstNameRequired') }
    if (!lastname) { throw new Error('error.lastNameRequired') }
    if (!email) { throw new Error('error.emailRequired') }
    if (!isEmailValid(email)) { throw new Error('auth/invalid-email') }

    if (type === 'school') {
      if (!address) { throw new Error('error.addressRequired') }
      if (!city) { throw new Error('error.cityRequired') }
      if (!state) { throw new Error('error.stateRequired') }
      if (!country) { throw new Error('error.countryRequired') }
      if (!postalzip) { throw new Error('error.validPostalCodeRequired') }
      if (!schoolname) { throw new Error('error.schoolNameRequired') }
      if (!phone) { throw new Error('error.phoneNumberRequired') }
    } else {
      if (!code) { throw new Error('error.codeRequired') }
    }

    if (!password) { throw new Error('error.passwordRequired') }
    if (password.length < 8) { throw new Error('error.passwordMinLength') }
    if (password !== confirmPassword) { throw new Error('error.passwordsDoNotMatch') }

    // 2. Register user
    yield fetch.post('/auth/register', {
      email,
      password,
      firstname,
      lastname,
      code,
      type,
      ...(type === 'student'
        ? {}
        : {
            address,
            city,
            country,
            phone,
            postalzip,
            state,
            schoolname
          })
    });

    // 3. Firebase Signin
    const user = (yield firebase
      .auth()
      .signInWithEmailAndPassword(email, password)) as firebase.auth.UserCredential;

    let token = '';
    if (user.user) {
      fetch.defaults.headers.common.Authorization = token = (yield user.user.getIdToken(
        true
      )) as string;
    }

    // 5. Get user data
    yield put({
      type: GET_USER_DATA_SUCCESS,
      payload: {
        firstName: firstname,
        lastName: lastname,
        email,
        role: type
      }
    });

    // 8. Add to payload
    yield put({
      type: USER_SIGNED_UP,
      payload: { ...user.user, token }
    });

    // 9. Hide modal
    yield put({
      type: MODAL_HIDE
    });

    gaUtil.dimensions.setDimensions(type, {role: type, code})
    gaUtil.events.authentication.signup(type)

  } catch (e) {
    let message = e.message;
    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      message = e.response.data.errors.map((item: ErrorObject) => item.msg).join('\n');
    } else if (e.response && e.response.data && e.response.data.error) {
      const error = e.response.data.error;

      switch (error.code) {
        case 'auth/email-already-exists':
          message = "auth/email-already-exists";
          break;
        case 'auth/email-domain-blacklisted':
          message = 'auth/email-domain-blacklisted';
          break;
        case 'auth/email-domain-not-whitelisted':
          message = 'auth/email-domain-not-whitelisted';
          break;
        default:
          message = error;
          break;
      }
    }

    message =
      message.message ||
      (typeof message === 'string' && message) || 'error.defaultMessage';

    yield put({
      type: USER_SIGNUP_ERROR,
      payload: message
    });
  }
}

function* workerContactSubmission({ payload }: ReduxAction) {
  try {
    const {
      email,
      firstname,
      lastname,
      phone,
      schoolname
    } = payload;

    if (!firstname) { throw new Error('error.firstNameRequired') }
    if (!lastname) { throw new Error('error.lastNameRequired') }
    if (!email) { throw new Error('error.emailRequired') }
    if (!schoolname) { throw new Error('error.schoolNameRequired') }
    if (!phone) { throw new Error('error.phoneNumberRequired') }

    yield fetch.post('/contact', {
      email,
      firstname,
      lastname,
      phone,
      schoolname
    });

    yield put({type: CONTACT_SUBMISSION_SUCCESS});

  } catch (e) {
    const message = (e && (e.message || (e.response && e.response.data && e.response.data.message))) || 'error.defaultMessage'
      
    yield put({
      type: CONTACT_SUBMISSION_ERROR,
      payload: message
    });
  }
}

function* watcherSignup() {
  yield takeLeading(SIGNUP_REQUEST, workerSignup);
}

export function* watcherContactSubmission() {
  yield takeLeading(CONTACT_SUBMISSION_REQUEST, workerContactSubmission);
}

// EXPORTS
export default watcherSignup;
