// IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';

// CONTAINER COMPONENTS
import DashboardContainer from '../DashboardContainer';

// PRESENTATION COMPONENTS
import Account from '../../components/Account';

// ACTIONS
import {
  doSaveDetails,
  doSaveAddress,
  doSavePassword,
  doUpdateReset,
  doUpdateSchoolCode
} from './actions';

// TYPES
interface IProps {}

interface StateProps {
  authReducer: AppState['authReducer'];
  accountReducer: AppState['accountReducer'];
}

interface DispatchProps {
  doSaveDetails: Function;
  doSaveAddress: Function;
  doSavePassword: Function;
  doUpdateReset: Function;
  doUpdateSchoolCode: Function;
}

type Props = IProps & StateProps & DispatchProps;

// MAIN COMPONENT
const AccountContainer: React.FC<Props> = props => {
  // Vars
  return (
    <DashboardContainer>
      <Account
        {...props}
        authReducer={props.authReducer}
        accountReducer={props.accountReducer}
      />
    </DashboardContainer>
  );
};

// REDUX
/**
 *
 * @param state
 */
const mapStateToProps = (state: AppState) => ({ ...state });

/**
 *
 * @param dispatch
 */
const mapDispatch = (dispatch: Dispatch) =>
  bindActionCreators(
    { doSaveDetails, doSaveAddress, doSavePassword, doUpdateReset, doUpdateSchoolCode },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatch
)(AccountContainer);
