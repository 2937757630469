// IMPORTS
import React, { useContext, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom'
import {withRouter, RouteComponentProps} from 'react-router-dom'
import LanguageContext from '../../locale/LanguageContext';
import SkipToContent from '../SkipToContent';

// STYLE COMPONENTS
import {
  HeaderContainer,
  HeaderStyles,
  NavInternalContainer,
  NavInternalStyles,
  NavMobileButtonStyles,
  NavInternalListLeftStyles,
  NavInternalListRightStyles,
  NavInternalLinkStyles,
  Nav,
  NavLink as CustomNavLink
} from './styles';
import { Logo } from '../../styles/Icons';
import Button from '../../styles/Button';
import MockButton from '../../styles/MockButton';
import NavLink from '../../styles/NavLink';
import { AppState } from '../../redux/reducer';
import { doLogout } from '../../containers/LoginContainer/actions';
import { FaSignOutAlt, FaUserCircle } from 'react-icons/fa';
import LanguageButton from '../LanguageButton';

// TYPES
interface IProps {
  onClickMenu: Function;
  onClickLogin: Function;
  onClickRegister: Function;
  menuOpen: boolean;
}

interface StateProps {
  auth: AppState['authReducer'];
  userData: AppState['accountReducer'];
}

interface DispatchProps {
  doLogout: typeof doLogout;
}
const headerRoot = document.getElementById("header-root");
const el = document.createElement("div");
headerRoot?.appendChild(el);

type Props = IProps & StateProps & DispatchProps;
// MAIN COMPONENT
const Header: React.FC<Props & RouteComponentProps> = props => {
  const { translate, language } = useContext(LanguageContext);
  const loginButtonRef = useRef<HTMLButtonElement>(null)
  const signupButtonRef = useRef<HTMLButtonElement>(null)
  const prevPathName = useRef<string | null>(null)
  const role = props.userData.get('role')
  const isUserConsumerGroup = props.userData.get('isConsumerGroup') || false;
  const isStudentConsumerGroup = role && role === 'student' && isUserConsumerGroup;
  const shouldHideHeader = isStudentConsumerGroup && props.location.pathname === '/dashboard/cards/results';

  useEffect(() => {
    const {pathname} = props.location || {}

    if (pathname === '/') {
      if (prevPathName.current === '/login' || prevPathName.current === '/forgot') {
        loginButtonRef?.current?.focus()
      }

      if (prevPathName.current === '/signup') {
        signupButtonRef?.current?.focus()
      }
    }

    prevPathName.current = pathname
  }, [props.location])

  const content = (
    props.auth.get('user') && props.userData.get('email') ? (
    <NavInternalStyles>
      <SkipToContent href="#content-container" />
      {shouldHideHeader ? null : (
        <NavInternalContainer>
          <NavInternalListLeftStyles>
            <NavInternalLinkStyles desktop>
              <NavLink
                to={
                  (role === 'school' || role === 'admin') ? '/dashboard/students' : '/dashboard/cards'
                }
                activeClassName="nav-link--active"
              >
                <MockButton styleType={'primary-white'} outline tabIndex={-1}>
                  {(role === 'school' || role === 'admin')
                    ? translate('Header.StudentResults')
                    : translate('NavigationContainer.LinkName.StudentResults')}
                </MockButton>
              </NavLink>
            </NavInternalLinkStyles>
            <NavInternalLinkStyles desktop>
              <NavLink 
                to={(role === 'school' || role === 'admin') ? "/dashboard/help" : "/dashboard/support"}
                activeClassName="nav-link--active">
                <MockButton styleType={'primary-white'} outline tabIndex={-1}>
                  {translate('Header.Button.MsgUs')}
                </MockButton>
              </NavLink>
            </NavInternalLinkStyles>
          </NavInternalListLeftStyles>
          <NavInternalListRightStyles>
            <NavInternalLinkStyles desktop mobile>
              <LanguageButton isDashboard={true} />
            </NavInternalLinkStyles>
            <NavInternalLinkStyles mobile>
              <NavMobileButtonStyles
                onClick={() => props.onClickMenu()}
                expanded={props.menuOpen}
                purple
                aria-label={translate('AriaLabel.Navigation')}
                aria-expanded={props.menuOpen}
              >
                <span></span>
                <span></span>
                <span></span>
              </NavMobileButtonStyles>
            </NavInternalLinkStyles>
            {role !== 'admin' && (
              <NavInternalLinkStyles desktop>
                <NavLink
                  to="/dashboard/account"
                  title={`${props.userData.get('firstname')} ${props.userData.get('lastname')}`}
                  activeClassName="nav-link--active"
                >
                  <MockButton styleType={'primary-white'} outline tabIndex={-1}>
                    <FaUserCircle size={20} />
                    <span id="user-name">
                      {props.userData.get('firstname') || ''}
                      {props.userData.get('lastname') ? ` ${props.userData.get('lastname')}` : ''}
                    </span>
                  </MockButton>
                </NavLink>
              </NavInternalLinkStyles>
            )}
            <NavInternalLinkStyles desktop>
              <Button styleType={'primary-white'} outline onClick={() => props.doLogout()}>
                <FaSignOutAlt /> {translate('Header.LogOut')}
              </Button>
            </NavInternalLinkStyles>
          </NavInternalListRightStyles>
        </NavInternalContainer>
      )}
    </NavInternalStyles>
  ) : (
      <HeaderContainer>
        {shouldHideHeader ? null : (
          <HeaderStyles>
            <a href={'https://mysparkpath.com'} target="_blank" rel="noopener noreferrer" aria-label={translate('AriaLabel.Logo')}>
              <div id="logo">
                <Logo language={language} />
              </div>
            </a>
            <Nav>
              <>
                <CustomNavLink desktop mobile>
                  <LanguageButton isDashboard={false} />
                </CustomNavLink>
                <CustomNavLink mobile>
                  <NavMobileButtonStyles
                    onClick={() => props.onClickMenu()}
                    expanded={props.menuOpen}
                    aria-label={translate('AriaLabel.Navigation')}
                    aria-expanded={props.menuOpen}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </NavMobileButtonStyles>
                </CustomNavLink>
                <CustomNavLink desktop>
                  <Button ref={loginButtonRef} styleType={'secondary'} onClick={() => props.onClickLogin()} outline>
                    {translate('Header.Login')}
                  </Button>
                </CustomNavLink>
                <CustomNavLink desktop>
                  <Button ref={signupButtonRef} styleType={'primary'} onClick={() => props.onClickRegister()}>
                    {translate('Header.SignUp')}
                  </Button>
                </CustomNavLink>
              </>
            </Nav>
          </HeaderStyles>
        )}
    </HeaderContainer>
  ));

  return createPortal(content, el)
};

// EXPORTS
export default withRouter(Header);
