// IMPORTS
import React, { useState, useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import FaqStyles, { FaqToggle, Question, FaqItem, QuestionsWrapper } from './styles';
import Button from '../../styles/Button';
import Link from '../../styles/Link';
import gaUtil from '../../util/ga'

// TYPES
interface Props {
  user: string;
  loading: boolean;
  code: string;
}

// MAIN COMPONENT
const Faq: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const [open, setOpen] = useState('');
  const onSetOpen = (id: string, label: string) => () => {
    gaUtil.events.help.faq.viewFAQ(props.user, {label})
    if (open === id) return setOpen('');
    return setOpen(id);
  };
  return (
    <FaqStyles>
      <h2>{translate('Faq.Heading')}</h2>
      <QuestionsWrapper>
        <FaqItem>
          <Question onClick={onSetOpen('a', translate('Faq.Question.One'))}>{translate('Faq.Question.One')}</Question>
          <FaqToggle open={open === 'a'}>
            <div>
              {translate('Faq.AnswerOne.PartOne')}
              <br />
              <br />
              {translate('Faq.AnswerOne.PartTwo')}
              <br />
              {translate('Faq.AnswerOne.PartThree')}
              <br />
              {translate('Faq.AnswerOne.PartFour')}
            </div>
          </FaqToggle>
        </FaqItem>
        <FaqItem>
          <Question onClick={onSetOpen('b', translate('Faq.Question.Two'))}>{translate('Faq.Question.Two')}</Question>
          <FaqToggle open={open === 'b'}>
            <div>
              {translate('Faq.AnswerTwo.PartOne')}
              <br />
              <br />
              {translate('Faq.AnswerTwo.PartTwo')}
              <br />
              {translate('Faq.AnswerTwo.PartThree')}
              <br />
              {translate('Faq.AnswerTwo.PartFour')}
              <br />
              {translate('Faq.AnswerTwo.PartFive')}
              <br />
              {translate('Faq.AnswerTwo.PartSix')}
              <br />
              {translate('Faq.AnswerTwo.PartSeven')}
              <br />
              <br />
              {translate('Faq.AnswerTwo.PartEight')}
            </div>
          </FaqToggle>
        </FaqItem>
        <FaqItem>
          <Question onClick={onSetOpen('c', translate('Faq.Question.Three'))}>{translate('Faq.Question.Three')}</Question>
          <FaqToggle open={open === 'c'}>
            <div>
              {translate('Faq.AnswerThree.PartOne')}{' '}
              <a href={'https://mysparkpath.com'} target="_blank" rel="noopener noreferrer">
                mysparkpath.com
              </a>
              .
            </div>
          </FaqToggle>
        </FaqItem>
        <FaqItem>
          <Question onClick={onSetOpen('d', translate('Faq.Question.Four'))}>{translate('Faq.Question.Four')}</Question>
          <FaqToggle open={open === 'd'}>
            <div>{translate('Faq.AnswerFour.PartOne')}</div>
          </FaqToggle>
        </FaqItem>
      </QuestionsWrapper>
      <Link to={'/dashboard/support'}>
        <Button styleType="primary-white">
          {translate('Faq.Button.Support')}
        </Button>
      </Link>
    </FaqStyles>
  );
};

// EXPORTS
export default Faq;
