import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function changeLanguage({
  language
} : any) {
  ReactGA.event({
    category: 'Navigation',
    action: 'Change language',
    label: language
  })
}

export default {
  changeLanguage: validateRole(changeLanguage)
}