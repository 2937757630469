import { AuthPayload } from '../Auth/reducer';
import { Dispatch } from 'redux';
import { SIGNUP_RESET_ERRORS, SIGNUP_REQUEST, CONTACT_SUBMISSION_REQUEST, CONTACT_SUBMISSION_RESET } from './types';

/**
 *
 */
const doResetErrors = () => (dispatch: Dispatch) => {
  dispatch({
    type: SIGNUP_RESET_ERRORS
  });
};

/**
 *
 * @param formState
 */
const doSignup = (formState: AuthPayload) => (dispatch: Dispatch<ReduxAction<AuthPayload>>) => {
  dispatch({
    type: SIGNUP_REQUEST,
    payload: formState
  });
};

/**
 *
 * @param formState
 */
const doSubmitContactRequest = (formState: any) => (dispatch: Dispatch) => {
  dispatch({
    type: CONTACT_SUBMISSION_REQUEST,
    payload: formState
  });
};

const doResetContactState = () => (dispatch: Dispatch) => {
  dispatch({
    type: CONTACT_SUBMISSION_RESET
  });
};

// EXPORTS
export { doSignup, doResetErrors, doSubmitContactRequest, doResetContactState };
