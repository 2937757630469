// IMPORTS
import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';

// STYLE COMPONENT
import NotFoundStyles from './styles';
import Button from '../../styles/Button';
import Link from '../../styles/Link';

// TYPES
interface INotFoundProps {
  state: any;
}

// MAIN COMPONENT
const NotFound: React.FC<INotFoundProps> = props => {
  const { translate } = useContext(LanguageContext);
  return (
    <NotFoundStyles>
      <h1>{translate('NotFound.Heading')}</h1>
      {!props.state.authReducer.get('loading') && (
        <Link to={props.state.authReducer.get('user') ? '/dashboard' : '/'}>
          <Button tabIndex={-1} styleType="primary-white">{translate('NotFound.Button.Back')}</Button>
        </Link>
      )}
    </NotFoundStyles>
  );
};

// EXPORTS
export default NotFound;
