// COLORS - lighter to darker
const Colors = {
  blue00: '#00aaff',
  blue1D: '#1da2dc',
  blue1DRGB: '29, 162, 220',
  white: '#ffffff',
  greyCC: '#cccccc',
  greyDD: '#dddddd',
  greyE6: '#e6e6e6',
  greyF8: '#f8f8f8',
  greyF4: '#f4f4f4',
  grey66: '#666666',
  grey99: '#999999',
  grey75: '#757575',
  grey6B: '#6b6b6b',
  grey4E: '#4E4E4E',
  grey33: '#333333',
  purpleDF: '#dfcaff',
  purple8e: '#8e56b6',
  purple72: '#72449b',
  purple78: '#78419f',
  purple62: '#623682',
  purple90: '#906EB0',
  purple77: '#770098',
  redC6: '#c63434',
  teal17: '#17a8ae',
  error: '#b91212',
  errorFF: '#ffe8e8',
  errorF5: '#f5c0c0',
  success: '#e0fbe0',
  success1E: '#1e681b',
  successB8: '#b8dab8',
  cardBigMachines: '#6D6F71',
  cardBuildBig: '#8D3881',
  cardCareCars: '#B45DA4',
  cardControlWater: '#414142',
  cardFixBrokenStuff: '#21586B',
  cardGivePower: '#59595B',
  cardGreatProducts: '#6D205E',
  cardGrowFood: '#00564C',
  cardHelpOthersSolve: '#1B75BC',
  cardHelpPeople: '#00A79D',
  cardHelpTeamsAchieve: '#2B3990',
  cardMakeBuildingsWork: '#939597',
  cardMakeItBeautiful: '#3EBEB3',
  cardMakeMatters: '#63A7BF',
  cardMasterTool: '#113944',
  cardPeopleBetter: '#C981B6',
  cardPrepareFood: '#069183',
  cardSetThingsUp: '#367996',
  cardSpecialEquipment: '#0B2228',
  cardSpecialMaterials: '#000000',
  cardUseComputers: '#4C0D3F',
  cardWeatherElements: '#262626',
  cardWorkOnHomes: '#B2B3B3',
  cardYourOwnBusiness: '#262262',
  cardHealthcare: '#68cce9',
  cardHealthyLiving: '#5bc9ee',
  cardHealthInformatics: '#53b8e9',
  cardMentalHealth: '#4c6db3',
  cardWealth: '#ffd106',
  cardSustainableDevelopment: '#fdbd10',
  cardFairTrade: '#f7971d',
  cardEntrepreneurship: '#f48020',
  cardFutureLeaders: '#f16522',
  cardAI: '#966fb1',
  cardBioTech: '#7a3f95',
  cardBigData: '#5f2772',
  cardMedicine: '#568bc8',
  cardVR: '#331d5a',
  cardBrain: '#390d3b',
  cardSchoolFuture: '#e772ab',
  cardProtectSociety: '#ef6ca8',
  cardReachPotential: '#f06b99',
  cardPoverty: '#f06b86',
  cardRespect: '#f16b79',
  cardSustainableEnergy: '#0dad4b',
  cardBiodiversity: '#00ad53',
  cardTransportation: '#04ad82',
  cardWaste: '#06ac9a',
  cardFoodSystems: '#00a8ac',
  cardIdeasEmotions: '#cc3d27',
  cardMasterTools: '#ca2645',
  cardMeaningfulExperiences: '#c92735',
  cardInspireArt: '#da2827',
  cardSportsPerformance: '#3c5690',
  cardBlockChain: "#1a121c",
  cardLearnFromAnimal: '#0b7f80',
  black: '#000000',
  blackRGB: '0, 0, 0'
};

// EXPORTS
export default Colors;
