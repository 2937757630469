import CardBigMachines from '../../styles/assets/cards/card-bigmachines.svg';
import CardBuildBig from '../../styles/assets/cards/card-buildbig.svg';
import CardCareCars from '../../styles/assets/cards/card-carecars.svg';
import CardControlWater from '../../styles/assets/cards/card-controlwater.svg';
import CardFixBrokenStuff from '../../styles/assets/cards/card-fixbrokenstuff.svg';
import CardGivePower from '../../styles/assets/cards/card-givepower.svg';
import CardGreatProducts from '../../styles/assets/cards/card-greatproducts.svg';
import CardGrowFood from '../../styles/assets/cards/card-growfood.svg';
import CardHelpOthersSolve from '../../styles/assets/cards/card-helpotherssolve.svg';
import CardHelpPeople from '../../styles/assets/cards/card-helppeople.svg';
import CardHelpTeamsAchieve from '../../styles/assets/cards/card-helpteamsachieve.svg';
import CardMakeBuildingsWork from '../../styles/assets/cards/card-makebuildingswork.svg';
import CardMakeItBeautiful from '../../styles/assets/cards/card-makeitbeautiful.svg';
import CardMakeMatters from '../../styles/assets/cards/card-makematters.svg';
import CardMasterTool from '../../styles/assets/cards/card-mastertool.svg';
import CardPeopleBetter from '../../styles/assets/cards/card-peoplebetter.svg';
import CardPrepareFood from '../../styles/assets/cards/card-preparefood.svg';
import CardSetThingsUp from '../../styles/assets/cards/card-setthingsup.svg';
import CardSpecialEquipment from '../../styles/assets/cards/card-specialequipment.svg';
import CardSpecialMaterials from '../../styles/assets/cards/card-specialmaterials.svg';
import CardUseComputers from '../../styles/assets/cards/card-usecomputers.svg';
import CardWeatherElements from '../../styles/assets/cards/card-weatherelements.svg';
import CardWorkOnHomes from '../../styles/assets/cards/card-workonhomes.svg';
import CardYourOwnBusiness from '../../styles/assets/cards/card-yourownbusiness.svg';

import CardHealthcare from '../../styles/assets/cards/card-healthcare.svg';
import CardHealthyLiving from '../../styles/assets/cards/card-healthyliving.svg';
import CardHealthInformatics from '../../styles/assets/cards/card-healthinformatics.svg';
import CardMentalHealth from '../../styles/assets/cards/card-mentalhealth.svg';
import CardWealth from '../../styles/assets/cards/card-wealth.svg';
import CardSustainableDevelopment from '../../styles/assets/cards/card-sustainabledevelopment.svg';
import CardFairTrade from '../../styles/assets/cards/card-fairtrade.svg';
import CardEntrepreneurship from '../../styles/assets/cards/card-entrepreneurship.svg';
import CardFutureLeaders from '../../styles/assets/cards/card-futureleader.svg';
import CardAI from '../../styles/assets/cards/card-ai.svg';
import CardBioTech from '../../styles/assets/cards/card-biotech.svg';
import CardBigData from '../../styles/assets/cards/card-bigdata.svg';
import CardVR from '../../styles/assets/cards/card-vr.svg';
import CardBrain from '../../styles/assets/cards/card-brain.svg';
import CardSchoolFuture from '../../styles/assets/cards/card-schoolfuture.svg';
import CardProtectSociety from '../../styles/assets/cards/card-protectsociety.svg';
import CardReachPotential from '../../styles/assets/cards/card-reachpotential.svg';
import CardPoverty from '../../styles/assets/cards/card-poverty.svg';
import CardRespect from '../../styles/assets/cards/card-respect.svg';
import CardSustainableEnergy from '../../styles/assets/cards/card-sustainableenergy.svg';
import CardBiodiversity from '../../styles/assets/cards/card-biodiversity.svg';
import CardTransportation from '../../styles/assets/cards/card-transportation.svg';
import CardWaste from '../../styles/assets/cards/card-waste.svg';
import CardFoodSystems from '../../styles/assets/cards/card-foodsystems.svg';
import CardIdeasEmotions from '../../styles/assets/cards/card-ideasemotions.svg';
import CardMasterTools from '../../styles/assets/cards/card-mastertools.svg';
import CardInspireArt from '../../styles/assets/cards/card-inspireart.svg';
import CardMedicine from '../../styles/assets/cards/card-medicine.svg';
import CardMeaningfulExperiences from '../../styles/assets/cards/card-meaningfulexperiences.svg';
import CardSportsPerformance from '../../styles/assets/cards/card-sportsperformance.svg';
import CardBlockChain from '../../styles/assets/cards/card-blockchain.svg';
import CardLearnFromAnimal from '../../styles/assets/cards/card-learnfromanimal.svg';

export const bulletNumberKeys = [
  'One',
  'Two',
  'Three',
  'Four',
  'Five',
  'Six',
  'Seven',
  'Eight',
  'Nine',
  'Ten',
  'Eleven',
  'Twelve',
  'Thirteen',
  'Fourteen',
  'Fifteen'
];

const getBulletKeys = num => {
  const array = [];
  for (let i = 0; i < num; i++) {
    array.push(`bullet${bulletNumberKeys[i]}`);
  }

  return array;
};

const cards = {
  cardBigMachines: {
    src: CardBigMachines,
    key: 'cardBigMachines',
    bullets: getBulletKeys(5)
  },
  cardBuildBig: {
    src: CardBuildBig,
    key: 'cardBuildBig',
    bullets: getBulletKeys(6)
  },
  cardCareCars: {
    src: CardCareCars,
    key: 'cardCareCars',
    bullets: getBulletKeys(5)
  },
  cardControlWater: {
    src: CardControlWater,
    key: 'cardControlWater',
    bullets: getBulletKeys(6)
  },
  cardFixBrokenStuff: {
    src: CardFixBrokenStuff,
    key: 'cardFixBrokenStuff',
    bullets: getBulletKeys(5)
  },
  cardGivePower: {
    src: CardGivePower,
    key: 'cardGivePower',
    bullets: getBulletKeys(6)
  },
  cardGreatProducts: {
    src: CardGreatProducts,
    key: 'cardGreatProducts',
    bullets: getBulletKeys(7)
  },
  cardGrowFood: {
    src: CardGrowFood,
    key: 'cardGrowFood',
    bullets: getBulletKeys(5)
  },
  cardHelpOthersSolve: {
    src: CardHelpOthersSolve,
    key: 'cardHelpOthersSolve',
    bullets: getBulletKeys(6)
  },
  cardHelpPeople: {
    src: CardHelpPeople,
    key: 'cardHelpPeople',
    bullets: getBulletKeys(5)
  },
  cardHelpTeamsAchieve: {
    src: CardHelpTeamsAchieve,
    key: 'cardHelpTeamsAchieve',
    bullets: getBulletKeys(5)
  },
  cardMakeBuildingsWork: {
    src: CardMakeBuildingsWork,
    key: 'cardMakeBuildingsWork',
    bullets: getBulletKeys(7)
  },
  cardMakeItBeautiful: {
    src: CardMakeItBeautiful,
    key: 'cardMakeItBeautiful',
    bullets: getBulletKeys(6)
  },
  cardMakeMatters: {
    src: CardMakeMatters,
    key: 'cardMakeMatters',
    bullets: getBulletKeys(7)
  },
  cardMasterTool: {
    src: CardMasterTool,
    key: 'cardMasterTool',
    bullets: getBulletKeys(7)
  },
  cardPeopleBetter: {
    src: CardPeopleBetter,
    key: 'cardPeopleBetter',
    bullets: getBulletKeys(6)
  },
  cardPrepareFood: {
    src: CardPrepareFood,
    key: 'cardPrepareFood',
    bullets: getBulletKeys(6)
  },
  cardSetThingsUp: {
    src: CardSetThingsUp,
    key: 'cardSetThingsUp',
    bullets: getBulletKeys(5)
  },
  cardSpecialEquipment: {
    src: CardSpecialEquipment,
    key: 'cardSpecialEquipment',
    bullets: getBulletKeys(5)
  },
  cardSpecialMaterials: {
    src: CardSpecialMaterials,
    key: 'cardSpecialMaterials',
    bullets: getBulletKeys(6)
  },
  cardSportsPerformance: {
    src: CardSportsPerformance,
    key: 'cardSportsPerformance',
    bullets: getBulletKeys(14)
  },
  cardBlockChain: {
    src: CardBlockChain,
    key: 'cardBlockChain',
    bullets: getBulletKeys(9)
  },
  cardLearnFromAnimal: {
    src: CardLearnFromAnimal,
    key: 'cardLearnFromAnimal',
    bullets: getBulletKeys(9)
  },
  cardUseComputers: {
    src: CardUseComputers,
    key: 'cardUseComputers',
    bullets: getBulletKeys(6)
  },
  cardWeatherElements: {
    src: CardWeatherElements,
    key: 'cardWeatherElements',
    bullets: getBulletKeys(6)
  },
  cardWorkOnHomes: {
    src: CardWorkOnHomes,
    key: 'cardWorkOnHomes',
    bullets: getBulletKeys(6)
  },
  cardYourOwnBusiness: {
    src: CardYourOwnBusiness,
    key: 'cardYourOwnBusiness',
    bullets: getBulletKeys(5)
  },
  cardHealthcare: {
    src: CardHealthcare,
    key: 'cardHealthcare',
    bullets: getBulletKeys(10)
  },
  cardHealthyLiving: {
    src: CardHealthyLiving,
    key: 'cardHealthyLiving',
    bullets: getBulletKeys(9)
  },
  cardHealthInformatics: {
    src: CardHealthInformatics,
    key: 'cardHealthInformatics',
    bullets: getBulletKeys(8)
  },
  cardMedicine: {
    src: CardMedicine,
    key: 'cardMedicine',
    bullets: getBulletKeys(9)
  },
  cardMentalHealth: {
    src: CardMentalHealth,
    key: 'cardMentalHealth',
    bullets: getBulletKeys(10)
  },
  cardWealth: {
    src: CardWealth,
    key: 'cardWealth',
    bullets: getBulletKeys(13)
  },
  cardSustainableDevelopment: {
    src: CardSustainableDevelopment,
    key: 'cardSustainableDevelopment',
    bullets: getBulletKeys(9)
  },
  cardFairTrade: {
    src: CardFairTrade,
    key: 'cardFairTrade',
    bullets: getBulletKeys(9)
  },
  cardEntrepreneurship: {
    src: CardEntrepreneurship,
    key: 'cardEntrepreneurship',
    bullets: []
  },
  cardFutureLeaders: {
    src: CardFutureLeaders,
    key: 'cardFutureLeaders',
    bullets: getBulletKeys(6)
  },
  cardAI: {
    src: CardAI,
    key: 'cardAI',
    bullets: getBulletKeys(8)
  },
  cardBioTech: {
    src: CardBioTech,
    key: 'cardBioTech',
    bullets: getBulletKeys(11)
  },
  cardBigData: {
    src: CardBigData,
    key: 'cardBigData',
    bullets: getBulletKeys(9)
  },
  cardVR: {
    src: CardVR,
    key: 'cardVR',
    bullets: getBulletKeys(9)
  },
  cardBrain: {
    src: CardBrain,
    key: 'cardBrain',
    bullets: getBulletKeys(6)
  },
  cardSchoolFuture: {
    src: CardSchoolFuture,
    key: 'cardSchoolFuture',
    bullets: getBulletKeys(10)
  },
  cardProtectSociety: {
    src: CardProtectSociety,
    key: 'cardProtectSociety',
    bullets: getBulletKeys(11)
  },
  cardReachPotential: {
    src: CardReachPotential,
    key: 'cardReachPotential',
    bullets: getBulletKeys(12)
  },
  cardPoverty: {
    src: CardPoverty,
    key: 'cardPoverty',
    bullets: getBulletKeys(8)
  },
  cardRespect: {
    src: CardRespect,
    key: 'cardRespect',
    bullets: getBulletKeys(9)
  },
  cardSustainableEnergy: {
    src: CardSustainableEnergy,
    key: 'cardSustainableEnergy',
    bullets: getBulletKeys(7)
  },
  cardBiodiversity: {
    src: CardBiodiversity,
    key: 'cardBiodiversity',
    bullets: getBulletKeys(11)
  },
  cardTransportation: {
    src: CardTransportation,
    key: 'cardTransportation',
    bullets: getBulletKeys(8)
  },
  cardWaste: {
    src: CardWaste,
    key: 'cardWaste',
    bullets: getBulletKeys(8)
  },
  cardFoodSystems: {
    src: CardFoodSystems,
    key: 'cardFoodSystems',
    bullets: getBulletKeys(9)
  },
  cardIdeasEmotions: {
    src: CardIdeasEmotions,
    key: 'cardIdeasEmotions',
    bullets: getBulletKeys(12)
  },
  cardMasterTools: {
    src: CardMasterTools,
    key: 'cardMasterTools',
    bullets: getBulletKeys(13)
  },
  cardMeaningfulExperiences: {
    src: CardMeaningfulExperiences,
    key: 'cardMeaningfulExperiences',
    bullets: getBulletKeys(8)
  },
  cardInspireArt: {
    src: CardInspireArt,
    key: 'cardInspireArt',
    bullets: getBulletKeys(13)
  }
};

export default cards;
