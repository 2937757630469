// IMPORTS
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';

// PRESENTATION COMPONENT
import Support from '../../components/Support';
import { doSupportSubmit, doSupportReset } from './actions';

// TYPES
interface IProps extends AppState, ReturnType<typeof mapDispatchToProps> {}

// MAIN COMPONENT
const SupportContainer: React.FC<IProps> = props => {
  // Vars
  const [loading, setLoading] = useState(false);
  const role = props.accountReducer.get('role');
  const { doSupportReset: supportReset } = props;

  // Hooks
  useEffect(() => {
    if (role !== null) {
      supportReset();
      setLoading(true);
    }
  }, [role, supportReset]);

  // Functions
  const onSubmit = (message: string) => {
    props.doSupportSubmit(message);
  };

  return <Support {...props} loading={loading} onSubmit={onSubmit} />;
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  ...state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doSupportSubmit, doSupportReset }, dispatch);

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportContainer);
