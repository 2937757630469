export function getLastNMonths(firstDate: Date, n: number) {
  const dates = []

  for (let i = 0; i < n; i++) {
    const date = new Date(firstDate)
    date.setUTCDate(1)
    date.setUTCHours(0,0,0,0)
    date.setUTCMonth(date.getUTCMonth() - i)
    dates.push(date.toISOString())
  }

  return dates
}

export function getDatesObject(dates: string[]) {
  const datesObject: {
    [field: string]: number
  } = {}

  dates.reduce((acc, curr) => {
    const dateString = getYearAndMonthString(curr)
    acc[dateString] = 0

    return acc
  }, datesObject)

  return datesObject
}

export function getYearAndMonthString(ISOString: string) {
  const matches = ISOString.match(/\d+-\d+/g)

  if (matches && matches.length > 0) {
    return matches[0]
  } else {
    return ''
  }
}