import React from 'react';

const defaultContext = () => ({
  language: 'en',
  toggleLanguage: () => {},
  translate: () => {}
});

const LanguageContext = React.createContext(defaultContext.language);

export default LanguageContext;
