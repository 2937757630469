import { useEffect, useRef } from 'react';

const useCancelPromise = () => {
  const cancelPromise = useRef(false);

  useEffect(() => {
    return () => {
      cancelPromise.current = true;
    };
  }, []);

  return cancelPromise;
};

export default useCancelPromise;
