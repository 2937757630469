// IMPORTS
import React, { useState, useEffect, useContext, Fragment, useRef } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { useParams } from 'react-router';
import { ChartData } from 'chart.js';
import Colors from '../../styles/Colors';
import Checkbox from '../../styles/Checkbox';
import { disableUser } from '../../webApi/user';
import { millisecondsToMinutes } from '../../util/time';
import { getDatesObject, getLastNMonths } from '../../util/date';
import { getNextSortDirection, formatSort } from '../../util/table';
import gaUtil from '../../util/ga';
import SignUpsChart from './components/SignUpsChart';
import CustomPagination from './components/CustomPagination';
import DeleteSchoolModal from './components/DeleteSchoolModal';
import { ReactComponent as XIcon } from '../../styles/assets/x-icon.svg';

// STYLES
import {
  Container,
  SearchBoxContainer,
  StudentsStyles,
  SearchPlaceholderStyles,
  StudentTopCardsStyles,
  StudentTopChallengesContainerStyles,
  StudentTopChallengesStyles,
  StudentResultsErrorMessageStyles,
  ExportCSVStyles,
  StudentExportErrorStyles,
  ChartContainer,
  AdditionalInfo,
  AdditionalInfoContainer,
  SignUpsAndInfoContainer,
  StudentTopChallengesSection,
  SchoolTableContainer,
  SchoolsTableSection,
  SchoolDetailsHeader
} from './styles';
import Loader from '../../styles/Loader';
import { AppState } from '../../redux/reducer';
import Button from '../../styles/Button';
import Link from '../../styles/Link';
import { FaDownload } from 'react-icons/fa';
import Card, { CardEnum } from '../Card';
import cards from '../Card/cards';
import StudentTable from './components/StudentTable/StudentTable';
import SearchBar from './components/SearchBar/SearchBar';
import StudentTablePagination from './components/StudentTablePagination';
import { getExtraCardContent } from '../../util/card';

// TYPES
interface IPropsHit {
  hit: any;
  downloadPdf: Function;
  loading: boolean;
  formatTime: Function;
  onDisable: Function;
  showDisabled: boolean;
  role: string;
}

interface IProps extends AppState {}
interface DispatchProps {
  doDownloadStudentPDF: Function;
  doGetStudentCount: Function;
  doGetSchoolCount: Function;
  doGetStudentResults: Function;
  doDownloadStudentCSV: Function;
  doGetSchools: Function;
  doDownloadSchoolCSV: Function;
  doDeleteSchool: Function;
  doGetStudents: Function;
}
interface DisplayOnSearchResultsProps {
  searchState?: any;
  searchResults: any;
  children?: any;
}
type StudentsProps = IProps & DispatchProps;
interface IStudentResults {
  data: Object;
  totalStudents: number;
  totalSortAttempts: number;
  totalTimeSpent: number;
  totalStudentsWithTrackedTime: number;
  totalSortAttemptsWithTrackedTime: number;
  averageTimeSpentPerSortWithTrackedTime: number;
  averageTimeSpentPerStudentWithTrackedTime: number;
  students: Object[];
}

interface ParamTypes {
  schoolId: string;
}

// MAIN COMPONENT

const Students: React.FC<StudentsProps> = props => {
  const { translate, language } = useContext(LanguageContext);
  const [showDisabled, setShowDisabled] = useState(false);
  const [schoolTablePage, setSchoolTablePage] = useState(1);
  const [showDeleteSchoolModal, setShowDeleteSchoolModal] = useState(false);
  const [refreshAdminDashboard, setRefreshAdminDashboard] = useState(false);
  const [schoolToBeDeleted, setSchoolToBeDeleted] = useState(null);
  const [schoolTableSort, setSchoolTableSort] = useState({
    created: null,
    studentcount: null
  });
  const { schoolId } = useParams<ParamTypes>();
  const studentCountLoading = props.studentsReducer.get('studentCountLoading');
  const schoolCountLoading = props.studentsReducer.get('schoolCountLoading');
  const schoolLoading = props.studentsReducer.get('schoolLoading');
  const studentResultsLoading = props.studentsReducer.get('studentResultsLoading');
  const studentExportLoading = props.studentsReducer.get('studentExportLoading');
  const schoolExportLoading = props.studentsReducer.get('schoolExportLoading');
  const schoolDeleteLoading = props.studentsReducer.get('schoolDeleteLoading');
  const studentCount = props.studentsReducer.get('studentCount');
  const studentCountError = props.studentsReducer.get('studentCountError');
  const schoolCount = props.studentsReducer.get('schoolCount');
  const schoolsObject = props.studentsReducer.get('schools') as any | null;
  const schoolCountError = props.studentsReducer.get('schoolCountError');
  const schoolError = props.studentsReducer.get('schoolError');
  const studentResults = props.studentsReducer.get('studentResults') as any;
  const studentResultsError = props.studentsReducer.get('studentResultsError');
  const studentDataLoading = props.studentsReducer.get('studentDataLoading');
  const studentData = props.studentsReducer.get('studentData') as any;
  const studentExportError = props.studentsReducer.get('studentExportError');
  const schoolExportError = props.studentsReducer.get('schoolExportError');
  const schoolDeleteError = props.studentsReducer.get('schoolDeleteError');
  const schoolDeleteSuccess = props.studentsReducer.get('schoolDeleteSuccess');
  const role = props.accountReducer.get('role');
  const {
    data: topCards = {},
    totalSortAttempts,
    totalStudents,
    totalTimeSpent,
    totalSortAttemptsWithTrackedTime,
    totalStudentsWithTrackedTime,
    averageTimeSpentPerStudentWithTrackedTime,
    averageTimeSpentPerSortWithTrackedTime
  } = (studentResults as IStudentResults) || {};

  const { students, hasNextPage, hasPrevPage, total: totalStudent } = studentData ?? {};

  const top3Cards = topCards ? (Object.keys(topCards).slice(0, 3) as string[] | CardEnum[]) : [];
  const queryRef = useRef('');
  const tableHeadingRef = useRef(null);
  const deleteSchoolButtonRef = useRef(null);
  const cardsAsAny = cards as any;
  const {
    doGetStudentCount: getStudentCount,
    doGetSchoolCount: getSchoolCount,
    doGetStudentResults: getStudentResults,
    doGetStudents: getStudentData,
    doDownloadStudentCSV: downloadStudentCSV,
    doDownloadSchoolCSV: downloadSchoolCSV,
    doGetSchools: getSchools,
    doDeleteSchool: deleteSchool
  } = props;
  const { total = 0, schools = [], school } = schoolsObject || {};
  const defaultDatesObject = getDatesObject(getLastNMonths(new Date(), 6));
  const [page, setPage] = useState(1);
  const [sortStudent, setSortStudent] = useState('');
  const [search, setSearch] = useState('');
  const limit = 20;
  const lastStudentPage = Math.floor(totalStudent / limit) + 1;

  useEffect(() => {
    if (
      !studentCountLoading &&
      !studentResultsLoading &&
      (role === 'school' || (role === 'admin' && schoolId))
    ) {
      getStudentData({ schoolId, page, limit, lastSorted: sortStudent, search, showDisabled });
    }
  }, [
    search,
    page,
    sortStudent,
    studentCountLoading,
    studentResultsLoading,
    role,
    schoolId,
    showDisabled,
    getStudentData
  ]);

  useEffect(() => {
    getStudentCount(schoolId);
  }, [getStudentCount, schoolId]);

  useEffect(() => {
    getStudentResults(schoolId);
  }, [getStudentResults, schoolId]);

  useEffect(() => {
    if (role === 'admin' && !schoolId) {
      getSchoolCount();
    }
  }, [getSchoolCount, role, schoolId]);

  useEffect(() => {
    const sortArray = formatSort(schoolTableSort);

    if (role === 'admin') {
      getSchools(schoolTablePage, sortArray, schoolId);
    }
  }, [getSchools, role, schoolTablePage, schoolTableSort, schoolId]);

  useEffect(() => {
    if (schoolDeleteSuccess) {
      setShowDeleteSchoolModal(false);
      setSchoolToBeDeleted(null);
      setRefreshAdminDashboard(true);
    }
  }, [schoolDeleteSuccess]);

  useEffect(() => {
    if (refreshAdminDashboard) {
      setRefreshAdminDashboard(false);

      const sortArray = formatSort(schoolTableSort);

      if (role === 'admin') {
        getSchools(schoolTablePage, sortArray, schoolId);
      }

      if (role === 'admin' && !schoolId) {
        getSchoolCount();
      }

      getStudentCount(schoolId);
      getStudentResults(schoolId);
    }
  }, [
    refreshAdminDashboard,
    getSchools,
    role,
    schoolId,
    schoolTablePage,
    schoolTableSort,
    getSchoolCount,
    getStudentCount,
    getStudentResults
  ]);

  function handleExportSchoolCSVClick() {
    const sortArray = formatSort(schoolTableSort);

    downloadSchoolCSV(language, sortArray);
  }

  const formatTime = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.round(minutes % 60);
    if (remainingMinutes === 0) {
      return translate('Students.Results.LessThanAMinute');
    }

    const minuteString = `${remainingMinutes} ${
      remainingMinutes !== 1
        ? translate('Students.Results.Minutes')
        : translate('Students.Results.Minute')
    }`;
    const hourString = `${hours} ${
      hours !== 1 ? translate('Students.Results.Hours') : translate('Students.Results.Hour')
    }`;
    return `${hours > 0 ? hourString : ''} ${minuteString}`;
  };

  function getAdditionalStudentInfo(isDesktop: boolean) {
    return (
      <AdditionalInfo isDesktop={isDesktop}>
        {studentResultsLoading ? (
          <Loader />
        ) : (
          <ul>
            <li>
              <div>{translate('Students.AdditionalInfo.TotalStudents')}</div>
              <div>
                {studentResultsError || totalStudents === undefined || totalStudents === null
                  ? '-'
                  : totalStudents}
              </div>
            </li>
            <li>
              <div>{translate('Students.AdditionalInfo.TotalSorts')}</div>
              <div>
                {studentResultsError ||
                totalSortAttempts === undefined ||
                totalSortAttempts === null
                  ? '-'
                  : totalSortAttempts}
              </div>
            </li>
            <li>
              <div>{translate('Students.AdditionalInfo.TotalTimeSpent')}</div>
              <div>
                {studentResultsError || totalTimeSpent === undefined || totalTimeSpent === null
                  ? '-'
                  : formatTime(millisecondsToMinutes(totalTimeSpent))}
              </div>
            </li>
            <li>
              <div>{translate('Students.AdditionalInfo.AverageTimeSpentPerStudent')}</div>
              <div>
                {studentResultsError ||
                averageTimeSpentPerStudentWithTrackedTime === undefined ||
                averageTimeSpentPerStudentWithTrackedTime === null ||
                !totalStudentsWithTrackedTime
                  ? '-'
                  : formatTime(millisecondsToMinutes(averageTimeSpentPerStudentWithTrackedTime))}
              </div>
            </li>
            <li>
              <div>{translate('Students.AdditionalInfo.AverageTimeSpentPerSort')}</div>
              <div>
                {studentResultsError ||
                averageTimeSpentPerSortWithTrackedTime === undefined ||
                averageTimeSpentPerSortWithTrackedTime === null ||
                !totalSortAttemptsWithTrackedTime
                  ? '-'
                  : formatTime(millisecondsToMinutes(averageTimeSpentPerSortWithTrackedTime))}
              </div>
            </li>
          </ul>
        )}
      </AdditionalInfo>
    );
  }

  function getAdditionalSchoolInfo(isDesktop: boolean) {
    return (
      <AdditionalInfo isDesktop={isDesktop}>
        {schoolLoading ? (
          <Loader />
        ) : (
          <ul>
            <li>
              <div>{translate('Students.AdditionalInfo.TotalSchools')}</div>
              <div>{schoolError || !total ? '-' : total}</div>
            </li>
          </ul>
        )}
      </AdditionalInfo>
    );
  }

  function toggleSchoolTableSort(key: any) {
    const currentSortDirection = (schoolTableSort as any)[key];
    setSchoolTableSort({
      ...schoolTableSort,
      [key]: getNextSortDirection(currentSortDirection)
    });
    setSchoolTablePage(1);
  }

  function scrollToTopOfTable() {
    if (tableHeadingRef && tableHeadingRef.current) {
      const element = (tableHeadingRef.current as any) as HTMLElement;
      window.scrollTo(0, element.offsetTop - 100);
    }
  }

  function resetSearch() {
    setSearch('');
  }

  function handleCancelDeleteSchoolModal() {
    setShowDeleteSchoolModal(false);
    setSchoolToBeDeleted(null);
    setTimeout(() => {
      if (deleteSchoolButtonRef.current) {
        (deleteSchoolButtonRef.current as any).focus();
      }
    }, 0);
  }

  function handleDeleteSchool(schoolId: string) {
    deleteSchool(schoolId);
  }

  function handleShowDisabledCheckboxChange() {
    if (!showDisabled) {
      gaUtil.events.studentTable.showDisabledStudentAccounts(role);
    } else {
      gaUtil.events.studentTable.hideDisabledStudentAccounts(role);
    }
    setShowDisabled(!showDisabled);
  }

  function handlePageChange(action: string) {
    if (action === 'next') {
      return setPage(prev => prev + 1);
    }
    if (action === 'prev') {
      return setPage(prev => prev - 1);
    }
    if (action === 'first') {
      return setPage(1);
    }
    if (action === 'last') {
      return setPage(lastStudentPage);
    }
  }

  function toggleStudentTableSort() {
    if (sortStudent === '') {
      return setSortStudent('asc');
    }
    if (sortStudent === 'asc') {
      return setSortStudent('desc');
    }
    if (sortStudent === 'desc') {
      return setSortStudent('');
    }
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  function getCardContent(type: string) {
    const keyLowercase = type.charAt(0).toLowerCase() + type.slice(1);
    const baseCard = cardsAsAny[keyLowercase];
    const extraCard = getExtraCardContent(keyLowercase, props.resultReducer.get('extraCards'));
    const cardContent = !baseCard && extraCard._id ? extraCard : baseCard;
    const localizedContent = baseCard ? null : cardContent?.[language] || {};
    return {
      baseCard,
      extraCard,
      localizedContent,
      cardContent
    };
  }

  async function disableUserClick(userId: string, disabled: boolean) {
    await disableUser(userId, !disabled);
    if (!disabled) {
      gaUtil.events.studentTable.disableStudentAccount(role);
    } else {
      gaUtil.events.studentTable.enableStudentAccount(role);
    }
    getStudentData({ schoolId, page, limit, lastSorted: sortStudent, search, showDisabled });
  }

  return (
    <Container>
      <StudentsStyles>
        {!role ||
        ((role === 'school' || (role === 'admin' && schoolId)) &&
          (studentCountLoading || studentResultsLoading)) ? (
          <Loader white />
        ) : (
          <>
            {schoolId && (
              <SchoolDetailsHeader>
                <h1>{(school && school.schoolname) || translate('placeholder.NotProvided')}</h1>
                <Link to="/dashboard/students" onClick={resetSearch}>
                  {translate('Students.SchoolDetailsHeader.BackLink')}
                </Link>
              </SchoolDetailsHeader>
            )}
            <SignUpsAndInfoContainer>
              {(role === 'school' ||
                (role === 'admin' && schoolId) ||
                (role === 'admin' && !schoolId) ||
                !!totalStudents) && (
                <ChartContainer>
                  <SignUpsChart
                    data={getChartDataFromCount(
                      studentCount || defaultDatesObject,
                      translate('Students.Chart.Label')
                    )}
                    heading={translate('Students.Chart.Heading')}
                    isLoading={studentCountLoading}
                    error={studentCountError}
                  />
                  {role === 'admin' && !schoolId && getAdditionalStudentInfo(true)}
                </ChartContainer>
              )}
              {role === 'admin' && !schoolId && (
                <ChartContainer>
                  <SignUpsChart
                    data={getChartDataFromCount(
                      schoolCount || defaultDatesObject,
                      translate('Students.SchoolChart.Label')
                    )}
                    heading={translate('Students.SchoolChart.Heading')}
                    isLoading={schoolCountLoading}
                    error={schoolCountError}
                  />
                  {getAdditionalSchoolInfo(true)}
                </ChartContainer>
              )}
            </SignUpsAndInfoContainer>
            {role === 'admin' && !schoolId && (
              <AdditionalInfoContainer>
                {getAdditionalStudentInfo(false)}
                {getAdditionalSchoolInfo(false)}
              </AdditionalInfoContainer>
            )}
            {(role === 'school' ||
              (role === 'admin' && schoolId) ||
              (role === 'admin' && !schoolId) ||
              totalStudents > 0 ||
              top3Cards?.length > 0) && (
              <StudentTopChallengesSection>
                <h2>{translate('Students.TopChallenges.Heading')}</h2>
                <StudentTopChallengesContainerStyles>
                  {studentResultsLoading ? (
                    <Loader white />
                  ) : studentResultsError ? (
                    <StudentResultsErrorMessageStyles>
                      {translate('Students.TopChallenges.Error')}
                    </StudentResultsErrorMessageStyles>
                  ) : (
                    <>
                      <StudentTopCardsStyles>
                        {top3Cards.map((type, index) => {
                          const {
                            baseCard,
                            extraCard,
                            localizedContent,
                            cardContent
                          } = getCardContent(type);
                          if (cardContent) {
                            return (
                              <Fragment key={baseCard ? `top-cards-${index}` : extraCard._id}>
                                <Card
                                  type={type}
                                  cardObj={baseCard ? null : cardContent}
                                  watermarkImageUrl={
                                    (cardContent && cardContent.watermarkImageUrl) || ''
                                  }
                                  logoImageUrl={(cardContent && cardContent.logoImageUrl) || ''}
                                >
                                  <span>
                                    {baseCard
                                      ? translate(`${cardContent.key}.Title`)
                                      : localizedContent.title}
                                  </span>
                                </Card>
                              </Fragment>
                            );
                          }

                          return null;
                        })}
                      </StudentTopCardsStyles>
                      <StudentTopChallengesStyles>
                        <ol>
                          {Object.keys(topCards).map((type, index) => {
                            const {
                              baseCard,
                              extraCard,
                              localizedContent,
                              cardContent
                            } = getCardContent(type);
                            if (cardContent) {
                              return (
                                <li key={baseCard ? `all-top-challenges-${index}` : extraCard._id}>
                                  <span>
                                    {baseCard
                                      ? translate(`${cardContent.key}.Title`)
                                      : localizedContent.title}
                                  </span>
                                  <span>{(topCards as any)[type]}</span>
                                </li>
                              );
                            }

                            return null;
                          })}
                        </ol>
                      </StudentTopChallengesStyles>
                    </>
                  )}
                </StudentTopChallengesContainerStyles>
              </StudentTopChallengesSection>
            )}
            {role === 'admin' && !schoolId && (
              <SchoolsTableSection>
                <h2 ref={tableHeadingRef}>{translate('Students.SchoolTable.Schools')}</h2>
                <SchoolTableContainer>
                  <div className="table-overflow-container">
                    <ExportCSVStyles>
                      {schoolExportError && !schoolExportLoading && (
                        <StudentExportErrorStyles>
                          {translate('Students.CSVExport.Error')}
                        </StudentExportErrorStyles>
                      )}
                      <Button
                        styleType="primary"
                        outline
                        isLoading={schoolExportLoading}
                        disabled={schoolExportLoading}
                        onClick={() => handleExportSchoolCSVClick()}
                      >
                        <Loader />
                        <FaDownload />
                        <span>{translate('Students.Results.ExportCSV')}</span>
                      </Button>
                    </ExportCSVStyles>
                    <div className="table-overflow schools-table-overflow">
                      <table>
                        <thead>
                          <tr>
                            <th>{translate('Students.SchoolTable.SchoolName')}</th>
                            <th>{translate('Students.SchoolTable.ContactName')}</th>
                            <th>{translate('Students.SchoolTable.ContactEmail')}</th>
                            <th>
                              <button
                                disabled={schoolLoading}
                                onClick={() => toggleSchoolTableSort('created')}
                                data-sort-direction={schoolTableSort.created}
                              >
                                {translate('Students.SchoolTable.SignUpDate')}
                              </button>
                            </th>
                            <th>
                              <button
                                disabled={schoolLoading}
                                onClick={() => toggleSchoolTableSort('studentcount')}
                                data-sort-direction={schoolTableSort.studentcount}
                              >
                                {translate('Students.SchoolTable.StudentCount')}
                              </button>
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!schoolLoading &&
                            !schoolError &&
                            schools.map((school: any, index: number) => {
                              return (
                                <tr key={`school-table-row-${school.id}-${index}`}>
                                  <td>
                                    {school.studentCount > 0 ? (
                                      <Link to={`/dashboard/students/${school.id}`}>
                                        {school.schoolname || translate('placeholder.NotProvided')}
                                      </Link>
                                    ) : (
                                      school.schoolname || translate('placeholder.NotProvided')
                                    )}
                                  </td>
                                  <td>{`${school.firstname} ${school.lastname}`}</td>
                                  <td>{school.email}</td>
                                  <td>
                                    {school.created
                                      ? new Date(school.created).toLocaleDateString(language)
                                      : null}
                                  </td>
                                  <td>{school.studentCount}</td>
                                  <td>
                                    <Button
                                      styleType="primary"
                                      outline
                                      onClick={e => {
                                        setSchoolToBeDeleted(school);
                                        setShowDeleteSchoolModal(true);
                                        deleteSchoolButtonRef.current = e.target as any;
                                      }}
                                    >
                                      <XIcon />
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                      <div
                        className={`school-table-placeholder ${
                          schoolLoading ? 'school-table-placeholder--loading' : ''
                        }`}
                      >
                        {schoolLoading ? (
                          <Loader />
                        ) : schoolError ? (
                          <p className="school-table-error">
                            {translate('Students.SchoolTable.Error')}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {!schoolError && (
                    <CustomPagination
                      isDisabled={schoolLoading}
                      refine={(page: number) => setSchoolTablePage(page)}
                      currentRefinement={schoolTablePage}
                      nbPages={Math.ceil(total / 20)}
                      onClick={scrollToTopOfTable}
                    />
                  )}
                </SchoolTableContainer>
              </SchoolsTableSection>
            )}
            {(role === 'school' || (role === 'admin' && schoolId)) && !totalStudents && (
              <SearchPlaceholderStyles>
                <h2>{translate('Students.Results.Placeholder.Heading')}</h2>
                <p>{`${translate('Students.Results.Placeholder.PartOne')} `}</p>
                <p>
                  <Link to={'/dashboard/account'}>
                    {translate('Students.Results.Placeholder.PartTwoBtn')}
                  </Link>
                  {` ${translate('Students.Results.Placeholder.PartTwo')}`}
                </p>
              </SearchPlaceholderStyles>
            )}
            {(role === 'school' || (role === 'admin' && schoolId)) && !!totalStudents && (
              <SchoolTableContainer>
                <h2 ref={tableHeadingRef}>{translate('Students.Heading')}</h2>
                <SearchBoxContainer>
                  <SearchBar search={search} handleSearchChange={handleSearchChange} />
                  <label>
                    <Checkbox checked={showDisabled} onChange={handleShowDisabledCheckboxChange} />
                    {translate('Students.Search.ShowDisabledAccounts')}
                  </label>
                </SearchBoxContainer>
                <ExportCSVStyles>
                  {studentExportError && !studentExportLoading && (
                    <StudentExportErrorStyles>
                      {translate('Students.CSVExport.Error')}
                    </StudentExportErrorStyles>
                  )}
                  <Button
                    styleType="primary"
                    outline
                    isLoading={studentExportLoading}
                    disabled={studentExportLoading}
                    onClick={() =>
                      downloadStudentCSV(queryRef.current, language, showDisabled, schoolId)
                    }
                  >
                    <Loader />
                    <FaDownload />
                    <span>{translate('Students.Results.ExportCSV')}</span>
                  </Button>
                </ExportCSVStyles>
                <StudentTable
                  extraCards={props.resultReducer.get('extraCards')}
                  students={students}
                  formatTime={formatTime}
                  downloadPDF={props.doDownloadStudentPDF}
                  sortStudent={sortStudent}
                  toggleStudentTableSort={toggleStudentTableSort}
                  disableUserClick={disableUserClick}
                />
                <StudentTablePagination
                  hasNextPage={hasNextPage}
                  hasPrevPage={hasPrevPage}
                  page={page}
                  handlePageChange={handlePageChange}
                  isDisabled={studentDataLoading}
                />
              </SchoolTableContainer>
            )}
          </>
        )}
      </StudentsStyles>
      <DeleteSchoolModal
        show={showDeleteSchoolModal}
        school={schoolToBeDeleted}
        onCancel={handleCancelDeleteSchoolModal}
        deleteSchool={handleDeleteSchool}
        isLoading={schoolDeleteLoading}
        error={schoolDeleteError}
      />
    </Container>
  );
};

function getChartDataFromCount(count: any, label: string) {
  const chartData: ChartData = {
    labels: count
      ? Object.keys(count)
          .slice(0, 12)
          .reverse()
      : [],
    datasets: [
      {
        label,
        data: count
          ? (Object.values(count)
              .slice(0, 12)
              .reverse() as number[])
          : [],
        lineTension: 0.1,
        borderColor: Colors.blue1D,
        pointBackgroundColor: Colors.blue1D,
        backgroundColor: `rgba(${Colors.blue1DRGB}, 0.1)`
      }
    ]
  };

  return chartData;
}

// EXPORTS
export default Students;
