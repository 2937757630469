// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';

// TYPES
interface IFooterLinkProps {
  key: string;
  active: boolean;
  light?: boolean;
}

interface IFooterStylesProps {
  light?: boolean;
}

// MAIN COMPONENTS
const FooterList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;

  @media (min-width: ${Screens.medium}) {
    display: block;
    text-align: center;
  }
`;

const FooterLink = styled.li<IFooterLinkProps>`
  display: block;

  a {
    display: block;
    font-size: 14px;
    padding: 10px 0px;
  }

  @media (max-width: ${Screens.small}) {
    a {
      &:hover {
        color: ${Colors.grey33};
        background: ${Colors.greyDD};
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    display: inline-block;
    margin: 0 10px;

    a {
      &:hover {
        color: ${props => props.light ? Colors.white : Colors.purple78};
      }
    }
  }
`;

const FooterColophon = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: currentColor;
  margin-bottom: 15px;
`;

const FooterStyles = styled.div<IFooterStylesProps>`
  text-align: center;
  padding-top: 10px;
  color: ${props => props.light ? Colors.white : Colors.grey4E}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    text-align: center;

    li {
      display: inline-block;
      margin: 0 15px 20px 15px;

      a {
        display: block;
        height: 42px;
        border: 1px solid ${Colors.greyDD};
        border-radius: 30px;
        line-height: 40px;
        padding: 0 30px;
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    ul {
      li {
        a {
          display: inline;
          border: none;
          line-height: 1;
          padding: 0;
        }
      }
    }
  }
`;

// EXPORTS
export default FooterStyles;

export { FooterList, FooterLink, FooterColophon };
