import styled from 'styled-components';

const SearchBarContainer = styled.div`
  flex-grow: 1;
  flex-basis: 300px;

  input {
    width: 100%;
    height: 40px;
    border-radius: 18px;
    border: 1px solid rgb(230, 230, 230);
    transition: all 250ms ease-in-out 0s;
    padding: 0px 12px;
  }
`;

export { SearchBarContainer };
