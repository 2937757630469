// IMPORTS
import { fromJS } from 'immutable';

// TYPES
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_RESET_ERRORS,
  DO_RESET_ERROR,
  USER_LOGGED_IN,
  USER_LOGIN_ERROR,
  USER_LOGOUT_STARTED,
  USER_LOGOUT_ERROR,
  USER_LOGGED_OUT
} from '../LoginContainer/types';
import {
  SIGNUP_RESET_ERRORS,
  SIGNUP_STARTED,
  USER_SIGNED_UP,
  USER_SIGNUP_ERROR,
  SIGNUP_REQUEST,
  CONTACT_SUBMISSION_REQUEST,
  CONTACT_SUBMISSION_SUCCESS,
  CONTACT_SUBMISSION_ERROR,
  CONTACT_SUBMISSION_RESET
} from '../SignupContainer/types';
import { User } from 'firebase';
import { AUTH_STOP_LOADING } from './types';

// TYPES
export type AUTH_STATE = {
  user: User | null;
  loading: boolean;
  isSignUpLoading: boolean;
  isLoginLoading: boolean;
  isContactLoading: boolean;
  isContactSuccess: boolean;
  error: string | null;
};

export type AUTH_REDUCER = ImmutableMap<AUTH_STATE>;

// INITIAL STATE
const STATE: AUTH_STATE = {
  user: null,
  loading: true,
  isSignUpLoading: false,
  isLoginLoading: false,
  isContactLoading: false,
  isContactSuccess: false,
  error: null
};

const INITAL_STATE = fromJS(STATE) as ImmutableMap<AUTH_STATE>;

export type AuthPayload = {
  email: string;
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
  code: string;
  type: string;
  address: string;
  city: string;
  state: string;
  postalzip: string;
  phone: string;
  country: string;
  schoolname: string;
};

// MAIN REDUCER
export const authReducer = (state = INITAL_STATE, { payload, type }: ReduxAction<AuthPayload>) => {
  switch (type) {
    case SIGNUP_REQUEST:
      return state.set('error', null).set('isSignUpLoading', true);
    case LOGIN_REQUEST:
      return state.set('error', null).set('isLoginLoading', true);
    case LOGIN_SUCCESS:
      return state
        .set('user', fromJS(payload))
        .set('error', null)
        .set('isLoginLoading', false);
    case LOGIN_FAIL:
      return state.set('isLoginLoading', false).set('error', (payload as unknown) as string);
    case SIGNUP_RESET_ERRORS:
    case LOGIN_RESET_ERRORS:
      return state.set('error', null).set('loading', false);
    case USER_LOGGED_IN:
      return state
        .set('user', fromJS(payload))
        .set('error', null)
        .set('isLoginLoading', false)
        .set('loading', false);
    case USER_SIGNED_UP:
      return state
        .set('user', fromJS(payload))
        .set('error', null)
        .set('isSignUpLoading', false);
    case DO_RESET_ERROR:
      return state.set('error', null).set('loading', false);
    case SIGNUP_STARTED:
    case USER_LOGOUT_STARTED:
      return state.set('error', null).set('loading', true);
    case USER_SIGNUP_ERROR:
      return state.set('isSignUpLoading', false).set('error', (payload as unknown) as string);
    case USER_LOGIN_ERROR:
    case USER_LOGOUT_ERROR:
      return state.set('loading', false).set('error', (payload as unknown) as string);
    case AUTH_STOP_LOADING:
      return state
        .set('loading', false)   
        .set('user', null);
    case CONTACT_SUBMISSION_REQUEST:
      return state
        .set('isContactLoading', true)
        .set('isContactSuccess', false)      
        .set('error', null);
    case CONTACT_SUBMISSION_SUCCESS:
      return state
        .set('isContactLoading', false)   
        .set('isContactSuccess', true)   
        .set('error', null);
    case CONTACT_SUBMISSION_RESET:
      return state
        .set('isContactLoading', false)   
        .set('isContactSuccess', false)   
        .set('error', null);
    case CONTACT_SUBMISSION_ERROR:
      return state
        .set('isContactLoading', false)   
        .set('isContactSuccess', false)   
        .set('error', fromJS(payload));
    case USER_LOGGED_OUT:
      return state
        .set('error', null)
        .set('loading', false)
        .set('user', null);
    default:
      return state;
  }
};
