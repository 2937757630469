// IMPORTS
import React from 'react';

// CONTAINERS
import FaqContainer from '../../containers/FaqContainer';

// MAIN COMPONENT
const FaqPage = () => <FaqContainer />;

// EXPORTS
export default FaqPage;
