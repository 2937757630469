import styled from 'styled-components';
import { aspectRatio } from '../../../CardSort/styles';

export const CardDescription = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: 200px;
  width: ${aspectRatio * 200}px;
  border-radius: 1.125rem;
  padding: 0.5rem;
  padding-bottom: 1rem;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background: white;
  color: #000;
  transition: transform 0.15s ease-in-out;

  &:hover {
    transform: scale(1.02);
    color: #000;
  }

  &:active &:visited {
    color: #000;
  }

  @media (max-width: 360px) and (min-height: 569px) {
    width: 200px;
    min-height: 300px;
    height: auto;
  }

  @media (max-width: 360px) and (max-height: 568px) {
    width: 190px;
    min-height: 265px;
    max-height: 280px;
  }

  @media (max-height: 480px) and (max-width: 320px) {
    width: 100%;
    min-height: 200px;
    height: auto;
  }
`;

export const AddIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 2.25rem;
`;
