// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import LoaderStyles from '../../styles/Loader/styles';
import { CardsDisplay } from '../../styles/assets';

// STYLE COMPONENT
import { InputStyles } from '../Input/styles';
import Error from '../../styles/Error';
import Colors from '../../styles/Colors';
import Success from '../../styles/Success';

// MAIN COMPONENTS
const SignupLeftStyles = styled.div`
  display: none;
  overflow: hidden;

  @media (min-width: ${Screens.medium}) {
    background: url(${CardsDisplay});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const SignupRightStyles = styled.div`
  position: relative;
  display: block;
  padding: 30px;
  height: 100%;
  overflow-y: auto;

  h2,
  p,
  hr {
    margin-bottom: 20px;
  }

  ${Error.toString} {
    margin-bottom: 15px;
  }

  ${Success.toString} {
    margin-bottom: 15px;
  }

  ${InputStyles.toString} {
    input {
      width: 100%;
      color: ${Colors.grey33};
    }
  }

  form {
    p {
      text-align: center;
      margin-bottom: 0;
      small {
        line-height: 42px;
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    width: 50%;
    margin-left: 50%;
  }
`;

const SignupStyles = styled.div`
  ${LoaderStyles} {
    position: sticky;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9999;
  }

  height: 100%

  @media (max-width: ${Screens.medium}) {
    display: flex;
    flex-direction: column;
  }
`;

// EXPORTS
export { SignupLeftStyles, SignupRightStyles, SignupStyles };
