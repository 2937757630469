import React, { useContext } from 'react';
import LanguageContext from '../../../../locale/LanguageContext';
import { CardHolder } from '../CardSelectionSection/styles';
import addIconBlack from '../../../../styles/assets/icons/add/add_black.svg';
import { CardDescription, AddIcon } from './styles';

interface IProps {
  onClick: () => void;
}

type Props = IProps;

const AddCustomCardButton: React.FC<Props> = props => {
  const { onClick } = props;
  const { translate } = useContext(LanguageContext);
  return (
    <CardHolder>
      <CardDescription
        onClick={event => {
          event.preventDefault();
          onClick();
        }}
      >
        <AddIcon src={addIconBlack} />
        {translate('customCard.addMessage')}
      </CardDescription>
    </CardHolder>
  );
};

export default AddCustomCardButton;
