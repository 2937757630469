// IMPORTS
import React from 'react';
import PageLoaderStyles from './styles';

// STYLE COMPONENT
import Loader from '../../styles/Loader';

// TYPES
interface IPageLoaderProps {}

// MAIN COMPONENT
const PageLoader: React.FC<IPageLoaderProps> = props => (
  <PageLoaderStyles>
    <Loader />
  </PageLoaderStyles>
);

// EXPORTS
export default PageLoader;
