// IMPORTS
import styled from 'styled-components';
import Color from 'color';
import Colors from '../Colors';
import LoaderStyles from '../Loader/styles';

// MAIN COMPONENTS
export const CustomCheckbox = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  pointer-events: none;
  
  &::before {
    border-radius: 4px;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    border: 1px solid ${Colors.greyE6}
  }

  &::after {
    position: absolute;
    display: none;
    content: "";
    left: 8px;
    top: 2px;
    width: 8px;
    height: 16px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    transform-origin: center;
  }
`

export const InputStyles = styled.input`
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: -24px;
  position: relative;
  opacity: 0;
  cursor: pointer;

  :disabled {
    cursor: initial;
  }

  :checked {
    & ~ ${CustomCheckbox}::before {
      border: none;
      background: ${Colors.purple72}
    }

    & ~ ${CustomCheckbox}::after {
      display: block;
    }
  }

  :focus {
    & ~ ${CustomCheckbox}::before {
      outline: none;
      border-color: ${Colors.black};
      box-shadow: 0px 0px 0px 2px
      ${Color(Colors.black)
        .rgb()
        .toString()};
    }
  }
`;

export const CustomLoaderStyles = styled.div`
  ${LoaderStyles} {
    width: 24px;
    height: 24px;

    svg {
      height: 24px;
      width: 24px;
    }
  }
`
