// IMPORTS
import React, { useContext, useEffect, useState } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { Push } from 'connected-react-router';
import { useLocation } from 'react-router'

// PRESENTATION COMPONENTS
import Card from '../Card';

// STYLE COMPONENTS
import Button from '../../styles/Button';
import LandingStyles, { Content, Deck, Banner } from '../../components/Landing/styles';

// TYPES
interface IProps {
  onClickGetStarted: Function;
}

interface DispatchProps {
  push: Push;
}

type Props = IProps & DispatchProps;

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */

const Landing: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const {search} = useLocation()
  const [showTimeoutBanner, setShowTimeoutBanner] = useState(false)
  const searchParams = new URLSearchParams(search)
  const hasTimeout = !!searchParams.get('timeout')

  useEffect(() => {
    if (hasTimeout) {
      setShowTimeoutBanner(true)
    }
  }, [hasTimeout])

  let hideCookieBannerValue = null;
  try {
    hideCookieBannerValue = localStorage.getItem('hideCookieBanner')
  } catch {
    hideCookieBannerValue = null
  }
  
  const [hideCookieBanner, setHideCookieBanner] = useState(hideCookieBannerValue);

  function dismissTimeoutBanner() {
    setShowTimeoutBanner(false)
  }

  function dismissCookieBanner() {
    try {
      localStorage.setItem('hideCookieBanner', 'true')
      setHideCookieBanner('true')
    } catch {
      setHideCookieBanner('true')
    }
  }
  
  return (
    <LandingStyles>
      <Content>
        <h1>{translate('Landing.Heading')}</h1>
        <p>{translate('Landing.Paragraph')}</p>
        <Button styleType={'primary'} onClick={() => props.onClickGetStarted()}>
          {translate('Landing.GetStarted')}
        </Button>
        <Deck style={{ animationPlayState: 'paused' }}>
          <Card type={'CardHealthcare'} />
          <Card type={'CardEntrepreneurship'} />
          <Card type={'CardBigData'} />
          <Card type={'CardSustainableEnergy'} />
          <Card type={'CardInspireArt'} />
          <Card type={'CardProtectSociety'} />
        </Deck>
        <Deck style={{ animationPlayState: 'paused' }}>
          <Card type={'CardHealthcare'} />
          <Card type={'CardEntrepreneurship'} />
          <Card type={'CardBigData'} />
          <Card type={'CardSustainableEnergy'} />
          <Card type={'CardInspireArt'} />
          <Card type={'CardProtectSociety'} />
        </Deck>
      </Content>
      {((!hideCookieBanner || hideCookieBanner !== 'true') && !showTimeoutBanner) && (
        <Banner>
          <button onClick={dismissCookieBanner} aria-label={translate('AriaLabel.Close')}>
            <div></div>
            <div></div>
          </button>
          <div>{translate('cookieBanner.Part1')}<a href="https://mysparkpath.com/pages/sparkpath-terms-of-use" target="_blank" rel="noopener noreferrer">{translate('termsAndConditions')}</a>{translate('cookieBanner.Part2')}<a href="https://mysparkpath.com/pages/sparkpath-privacy-policy" target="_blank" rel="noopener noreferrer">{translate('privacyPolicy')}</a></div>
        </Banner>
      )}
      {showTimeoutBanner && (
        <Banner>
         <button onClick={dismissTimeoutBanner} aria-label={translate('AriaLabel.Close')}>
           <div></div>
           <div></div>
         </button>
         <div>{translate('timeoutBanner.Message')}</div>
       </Banner>
      )}
    </LandingStyles>
  );
};

export default Landing;
