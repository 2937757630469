// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Toggle from '../Toggle';

const ShadowColor = '#eee';
const ToggleBG = '#f3f3f3';
// MAIN COMPONENTS
const FaqStyles = styled.div`
  margin: 20px;
  padding: 20px 15px;
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  h2 {
    color: ${Colors.white};
  }

  > a {
    margin-top: 30px;
  }
`;

export const Question = styled.button`
  color: ${Colors.white};
  padding: 12px 20px;
  cursor: pointer;
  border-top: 1px solid ${ShadowColor};
  transition: color 0.15s ease-in-out;
  border-radius: inherit;
  width: 100%;
  text-align: left;
  line-height: initial;
  height: auto;
  
  &:hover {
    background: ${Colors.white};
    color: ${Colors.purple62};
    transform: none;
  }
`;

export const QuestionsWrapper = styled.div`
  border-left: 1px solid ${ShadowColor};
  border-right: 1px solid ${ShadowColor};
  border-radius: 5px;
  overflow: hidden;
  /* box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2); */
`;

export const FaqToggle = styled(Toggle)`
  & div {
    box-shadow: inset 0px 1px 0px 0px ${ShadowColor};
    padding: 12px 20px;
    background-color: ${ToggleBG};
    color: #333;
    a,
    span {
      color: ${Colors.purple8e};
    }
    a:hover {
      color: ${Colors.purple62};
      text-decoration: underline;
    }
  }
`;

export const FaqItem = styled.div`
  &:nth-last-child(1) {
    & ${Question} {
      border-bottom: 1px solid ${ShadowColor};
    }
    & ${FaqToggle} {
      & div {
        box-shadow: inset 0px -1px 0px 0px ${ShadowColor};
      }
    }
  }
`;

// EXPORTS
export default FaqStyles;
