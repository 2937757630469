import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import CardResultStyles, { MainContainer, BottomContainer } from './styles';
import { AppState } from '../../redux/reducer';
import { StateItem } from '../CardSort';
import { cardTypes } from '../Card';
import ButtonStyles from '../../styles/Button';
import { Push } from 'connected-react-router';
import Error from '../../styles/Error';
import NativeLink from '../../styles/NativeLink';
import gaUtil from '../../util/ga';
import LanguageContext from '../../locale/LanguageContext';
import * as env from '../../config/env.json';
import { getExtraCardContent } from '../../util/card';
import ChallengeProfileCoverImageEN from '../../styles/assets/challenge-profile-cover-en.jpg';
import ChallengeProfileCoverImageFR from '../../styles/assets/challenge-profile-cover-fr.jpg';
import { CustomCard } from '../../types/customCard';

export type NavigationState = {
  cards: StateItem[];
  selected: string[] & CustomCard[];
};

interface IProps {}
type StateProps = AppState;
interface DispatchProps {
  push: Push;
  doDownloadPdf: Function;
  doSaveDetails: Function;
}
type Props = IProps & StateProps & DispatchProps;
const CardsResult: React.FC<Props> = props => {
  const history = useHistory();
  const { translate, language } = useContext(LanguageContext);
  const error = props.resultReducer.get('error');
  const loading = props.resultReducer.get('loading');
  const resultCardsArray = props.resultReducer.get('cards').toJS();
  const userFirstName = props.accountReducer.get('firstname');
  const userLastName = props.accountReducer.get('lastname');
  const userEmail = props.accountReducer.get('email');
  const role = props.accountReducer.get('role');
  const isUserConsumerGroup = props.accountReducer.get('isConsumerGroup');
  const [showIframe, setShowIframe] = useState(role && role === 'student' && isUserConsumerGroup);
  const resultCardTypeArray =
    resultCardsArray &&
    resultCardsArray.map(card => {
      if (typeof card === 'object') {
        return cardTypes('', false, '', language, card);
      } else {
        return cardTypes(
          card,
          false,
          '',
          language,
          getExtraCardContent(card, props.resultReducer.get('extraCards'))
        );
      }
    });
  let resultCardsString = '';
  resultCardTypeArray &&
    resultCardTypeArray.forEach((resultCardType, index) => {
      resultCardsString += `Challenge ${index + 1} - ${resultCardType.name || ''}%0A`;
    });

  useEffect(() => {
    const toggleIframeVisibility = (event: MessageEvent) => {
      if (event && event.data && event.data.action === 'submission-completed') {
        setShowIframe(false);
        const hasCompletedConsumerEndSurvey = true;
        props.doSaveDetails({ hasCompletedConsumerEndSurvey });
        props.push('/dashboard/cards', { hasCompletedConsumerEndSurvey });
      }
    };
    showIframe && window.addEventListener('message', toggleIframeVisibility);

    return () => {
      window.removeEventListener('message', toggleIframeVisibility);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showIframe]);

  return (
    <CardResultStyles>
      {showIframe ? (
        <div className="card-result__iframe">
          <iframe
            title="JotForm"
            src={`${env.CHALLENGE_AFTER_SORT_JOTFORM_URL}?whatWere=${resultCardsString}&name[first]=${userFirstName}&name[last]=${userLastName}&email=${userEmail}`}
            width="100%"
            height="100%"
            frameBorder="0"
          />
        </div>
      ) : (
        <React.Fragment>
          <MainContainer>
            <h1>{translate('CardResult.Heading')}</h1>
            <p>
              {translate('CardResult.Paragraph.PartOne')}{' '}
              <strong>{translate('CardResult.Paragraph.PartTwo')}</strong>{' '}
              {translate('CardResult.Paragraph.PartThree')}
            </p>
            {language === 'fr' ? (
              <img src={ChallengeProfileCoverImageFR} alt="" />
            ) : (
              <img src={ChallengeProfileCoverImageEN} alt="" />
            )}
          </MainContainer>
          <BottomContainer>
            {error && !loading && <Error>{translate('error.defaultMessage')}</Error>}
            {loading ? (
              <>
                <p>{translate('CardResult.Bottom.GeneratingPDF')}</p>
              </>
            ) : (
              <>
                <ButtonStyles
                  styleType="primary-white"
                  onClick={() => {
                    gaUtil.events.results.downloadResults(role);
                    props.doDownloadPdf({
                      language,
                      email: false,
                      redirect: '/dashboard/cards/conclusion'
                    });
                  }}
                >
                  {translate('CardResult.Bottom.GetProfile')}
                </ButtonStyles>
                <ButtonStyles
                  style={{ color: '#fff', marginBottom: '0.5rem' }}
                  onClick={e => {
                    e.preventDefault();
                    gaUtil.events.results.emailResults(role);
                    props.doDownloadPdf({
                      language,
                      email: true,
                      redirect: '/dashboard/cards/conclusion?downloadtype=email'
                    });
                  }}
                >
                  {translate('CardResult.EmailTheProfileInstead')}
                </ButtonStyles>
                <NativeLink
                  styleType="button"
                  href={
                    language === 'fr'
                      ? '/French_Challenge_Profile_Accessible.pdf'
                      : '/English_Challenge_Profile_Accessible.pdf'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                  onClick={() => {
                    history.push('/dashboard/cards/conclusion');
                  }}
                >
                  {translate('CardResult.AccessiblePdf')}
                </NativeLink>
              </>
            )}
          </BottomContainer>
        </React.Fragment>
      )}
    </CardResultStyles>
  );
};
export default CardsResult;
