import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Success from '../../styles/Success';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 14px;

  h1, h2 {
    margin: 12px;
    color: ${Colors.white};
    font-weight: 600;
  }

  img {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 200px;
  }

  a, a:hover, a:visited {
    color: ${Colors.white};
    text-decoration: underline;
    margin-bottom: 64px
  }

  ${Success} {
    margin: 0 0 16px 0;
    text-align: left;

    @media (min-width: 992px) {
      width: 910px;
    }
  
    @media (min-width: 1200px) {
      width: 1110px;
    }
  }
`;
