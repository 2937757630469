// IMPORTS
import { takeLeading, put, select } from 'redux-saga/effects';
import fetch from '../../fetch';
import gaUtil from '../../util/ga'

// TYPES
import { SUPPORT_FORM_REQUEST, SUPPORT_FORM_FAIL, SUPPORT_FORM_SUCCESS } from './types';

function* workerSupportSubmitForm({ payload }: ReduxAction<{ message: string }>) {
  try {
    const result = yield fetch.post('/supports', {
      message: payload
    });

    if (!result || !result.data || !result.data.success) {
      throw new Error('Something went wrong.');
    }

    const role = yield select(state => state.accountReducer.get('role'))
    gaUtil.events.help.support.submitSupportRequest(role)

    yield put({
      type: SUPPORT_FORM_SUCCESS
    });
  } catch (e) {
    const message =
      (e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors[0] &&
        e.response.data.errors[0].msg &&
        e.response.data.errors[0].msg) ||
      'Something went wrong.';
    yield put({
      type: SUPPORT_FORM_FAIL,
      payload: message
    });
  }
}
export function* watcherSupportSubmitForm() {
  yield takeLeading(SUPPORT_FORM_REQUEST, workerSupportSubmitForm);
}
