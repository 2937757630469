// IMPORTS
import React, { useContext } from 'react';
import { Push } from 'connected-react-router';
import LanguageContext from '../../locale/LanguageContext';
import { AppState } from '../../redux/reducer';

// STYLE COMPONENTS
import InstructionsStyles, { BackWrapper, InstructionButtons } from './styles';
import { Back } from '../../styles/assets';
import Button from '../../styles/Button';
import LikeButton from '../LikeButton';

// TYPES
interface IProps {}
type StateProps = AppState;
interface DispatchProps {
  push: Push;
}
type Props = IProps & StateProps & DispatchProps;

// MAIN COMPONENT
const Instructions: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  // Functions
  const onClickBack = () => {
    props.push('/dashboard/cards/intro');
  };
  const onClickNext = () => {
    props.push('/dashboard/cards/reminder');
  };

  // Render
  return (
    <InstructionsStyles>
      <h1>{translate('Instructions.Heading')}</h1>
      <div>
        <p>{translate('Instructions.Description1')}</p>
        <p>{translate('Instructions.Description2')}</p>
        <InstructionButtons>
          <LikeButton
            variant="dislike"
            onClick={() => {}}
            label={translate('Instructions.ButtonDislike')}
          />
          <LikeButton
            variant="like"
            onClick={() => {}}
            label={translate('Instructions.ButtonLike')}
          />
          <LikeButton
            variant="superlike"
            onClick={() => {}}
            label={translate('Instructions.ButtonSuperlike')}
          />
        </InstructionButtons>
        <p>{translate('Instructions.Description3')}</p>
      </div>
      <Button styleType="primary-white" onClick={onClickNext}>
        {translate('Instructions.Button')}
      </Button>
      <BackWrapper onClick={onClickBack}>
        <Back /> {translate('Instructions.Back')}
      </BackWrapper>
    </InstructionsStyles>
  );
};

// EXPORTS
export default Instructions;
