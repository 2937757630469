export function provideCSVDownload(data: BlobPart, fileName: string) {
  const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
  const filename = fileName || 'exports.csv';
  // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   // IE 11
  //   const success = window.navigator.msSaveOrOpenBlob(blob, filename); // bool for debugging
  //   return;
  // }
  const file = URL.createObjectURL(blob);
  const a = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
        // there isn't a browser compatible way to do this without dom manipulation
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.setAttribute('href', file);
  a.setAttribute('download', filename);
  a.click();
  // need to delay removing from dom to fix ios download issue
  setTimeout(() => {
    window.URL.revokeObjectURL(file);
    document.body.removeChild(a);
  }, 1000);
};