// IMPORTS
import React from 'react';

// CONTAINERS
import SupportContainer from '../../containers/SupportContainer';

// MAIN COMPONENT
const SupportPage = () => <SupportContainer />;

// EXPORTS
export default SupportPage;
