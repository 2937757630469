// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';

// TYPES
interface ModalBackgroundStyles {
  show: boolean;
  isDashboard: boolean;
  backgroundColor?: String;
}

const getModalBackgroundColor = (props: ModalBackgroundStyles) => {
  if (props.isDashboard) return 'rgba(114,68,155, 1);';
  return props.backgroundColor ? `${props.backgroundColor};` : 'rgba(255, 255, 255, 0.8);';
};
// MAIN COMPONENT
const ModalBackgroundStyles = styled.div<ModalBackgroundStyles>`
  background: ${props => getModalBackgroundColor(props)}
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  transition: all 250ms ease-in-out 0s;
  opacity: ${props => (props.show ? '1' : '0')};
  display: ${props => (props.show ? 'block' : 'none')};

  @media (min-width: ${Screens.medium}) {
    justify-content: center;
    align-items: center;
  }
`;

// EXPORTS
export default ModalBackgroundStyles;
