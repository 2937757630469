// IMPORTS
import React from 'react';

// CONTAINERS
import GetCardsContainer from '../../containers/GetCardsContainer';

// COMPONENT
const GetCards = () => <GetCardsContainer />;

// EXPORTS
export default GetCards;
