// IMPORTS
import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import Link from '../../styles/Link';

// STYLE COMPONENTS
import FooterStyles, { FooterList, FooterLink, FooterColophon } from './styles';

// TYPES
interface ILink {
  icon: JSX.Element | null;
  name: string;
  link: string;
  active: boolean;
  onClick: () => void;
}

interface IPropsFooter {
  links?: ILink[];
  light?: boolean;
}

// MAIN COMPONENT
/**
 *
 * @param props
 */
const Footer: React.FC<IPropsFooter> = props => {
  const { translate } = useContext(LanguageContext);
  const hideCookieBanner = localStorage.getItem('hideCookieBanner');
  return (
    <FooterStyles light={props.light}>
      {props.links && props.links.length > 0 && (
        <FooterList>
          {props.links.map((value, key) => (
            <FooterLink
              key={`Footer-Nav-${key}`}
              active={value.active}
              light={props.light}
              ref={node => node && (hideCookieBanner
                ? node.removeAttribute('inert')
                : node.setAttribute('inert', '')
              )}
            >
              <Link doInherit={true} to={value.link} onClick={() => value.onClick()}>
                {value.name}
              </Link>
            </FooterLink>
          ))}
        </FooterList>
      )}
      <FooterColophon>&copy; 2021. {translate('Footer.AllRightsReserved')} </FooterColophon>
    </FooterStyles>
  );
};

// EXPORTS
export default Footer;
