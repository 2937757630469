import styled from 'styled-components';
import ButtonStyle from '../../styles/Button';
import { TextArea as BaseTextArea, TextInput } from '../Inputs';
import { Card as CardSort } from '../CardSort/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Reminder as BaseReminder } from '../PickThree/components/CardSelectionSection/styles';
import Colors from '../../styles/Colors';
import Error from '../../styles/Error';

interface CloseButtonProps extends React.HTMLProps<HTMLButtonElement> { }
interface BoxedLabelProps extends React.HTMLProps<HTMLLabelElement> {
  showBorder?: boolean;
}

interface UploadTextProps extends React.HTMLProps<HTMLDivElement> {
  hasError: boolean;
}

export const EditCustomCardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const CloseButtonContainer = styled.div``;

export const CloseButton = styled.button<CloseButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem 0 auto;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
`;

export const CloseButtonIcon = styled.img`
  display: block;
  width: 1.5rem;
`;

export const MainContentContainerForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton = styled(ButtonStyle)`
  max-width: 10rem;
  text-transform: uppercase;
`;

export const Card = styled(CardSort)`
  background: ${Colors.purple77}; // TODO: custom card color
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const CardUploadContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  height: 220px;
  max-height: 220px;
`;

export const Reminder = styled(BaseReminder)`
  z-index: 999;
`;

export const BoxedLabel = styled.label<BoxedLabelProps>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${props => (props.showBorder ? '1rem' : '0')};
  border: ${props => (props.showBorder ? `1.5px dashed ${Colors.white}` : 'none')};
  border-radius: 1px;
  cursor: pointer;
`;

export const UploadText = styled.div<UploadTextProps>`
  padding: 0 1rem;
  font-size: 0.8rem;
  text-align: center;
  color: ${props => (props.hasError ? Colors.errorF5 : Colors.white)};
`;

export const ImageFile = styled.img`
  width: 100%;
  max-height: 220px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
`;

export const PhotoIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: ${Colors.white};
`;

export const HiddenInput = styled.input`
  opacity: 0;
  position: absolute;
  z-index: -1;
`;

export const TextArea = styled(BaseTextArea)`
  border-radius: 0;
  width: 100%;
  margin-bottom: 50px;
  font-size: 0.9rem;
  resize: none;
  text-align: center;
`;

export const ErrorContainer = styled(Error)`
  margin-bottom: 1rem;
`;

export const ModalContainerForm = styled.form`
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const TitleInput = styled(TextInput)`
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 24px;
`;

export const ModalBoxedLabel = styled(BoxedLabel)`
  margin-left: 0;
  margin-right: 0;
  background-color: ${Colors.purple77}; // TODO: custom card color
`;

export const ModalImageFile = styled(ImageFile)`
  max-width: 100%;
  width: unset;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

export const DescriptionTextArea = styled(TextArea)`
  flex: 1;
  min-height: 6rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
`;

export const ModalButtonSubmitContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
