// IMPORTS
import { fromJS } from 'immutable';

// TYPES
import { MODAL_SHOW, MODAL_HIDE } from './types';

// TYPES
type MODAL_STATE = {
  show: boolean;
};

export type MODAL_REDUCER = ImmutableMap<MODAL_STATE>;

// INITIAL STATE
const STATE: MODAL_STATE = {
  show: false
};

const INITAL_STATE = fromJS(STATE) as ImmutableMap<typeof STATE>;

// MAIN REDUCER
const modalReducer = (state = INITAL_STATE, { type }: ReduxAction) => {
  switch (type) {
    case MODAL_SHOW:
      return state.set('show', true);
    case MODAL_HIDE:
      return state.set('show', false);
    default:
      return state;
  }
};

// EXPORTS
export { modalReducer };
