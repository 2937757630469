import React, { useState, useContext, useEffect } from 'react';
import CardIntroStyles from './styles';
import Button from '../../styles/Button';
import { Back } from '../../styles/assets';
import { Push } from 'connected-react-router';
import { BackWrapper } from '../Instructions/styles';
import * as env from '../../config/env.json'
import LanguageContext from '../../locale/LanguageContext';
import { AppState } from '../../redux/reducer';

interface IProps {}
type StateProps = AppState;
interface DispatchProps {
  push: Push;
}
type Props = IProps & StateProps & DispatchProps;
const CardIntro: React.FC<Props> = props => {
  const userRole = props.accountReducer.get('role');
  const userEmail = props.accountReducer.get('email');
  const isUserConsumerGroup = props.accountReducer.get('isConsumerGroup');
  const [showIframe, setShowIframe] = useState(userRole && userRole === 'student' && isUserConsumerGroup);
  const { translate } = useContext(LanguageContext);

  const onClickBack = () => {
    props.push('/dashboard/cards');
  };
  const onClickNext = () => {
    props.push('/dashboard/cards/instructions');
  };

  useEffect(() => {
    const toggleIframeVisibility = (event: MessageEvent) => {
    if (event && event.data && event.data.action === 'submission-completed') {
      setShowIframe(false);
      }
    };

    showIframe && window.addEventListener('message', toggleIframeVisibility);


    return () => {
      window.removeEventListener('message', toggleIframeVisibility);
    };
  }, [showIframe]);

  return (
    <CardIntroStyles>
      {showIframe ? 
        <div className="card-intro__iframe">
          <iframe
            title="JotForm"
            src={`${env.CHALLENGE_PROFILE_INTRO_JOTFORM_URL}?email=${userEmail}`}
            height="100%"
            frameBorder="0"
            />
        </div>
        :
        <React.Fragment>
          <h1>{translate('CardIntro.Heading')}</h1>
          <div>
            <p>{translate('CardIntro.Paragraph.First')}</p>
            <p>{translate('CardIntro.Paragraph.Second')}</p>
          </div>
          <Button styleType="primary-white" onClick={onClickNext}>
            {translate('CardIntro.Button.Next')}
          </Button>
          <BackWrapper onClick={onClickBack}>
            <Back /> {translate('CardIntro.Button.Back')}
          </BackWrapper>
        </React.Fragment>
      }
      
    </CardIntroStyles>
  )
};
export default CardIntro;
