// IMPORTS
import React, { Ref } from 'react';

// STYLES
import { InputStyles, InputLabelStyles, InputFieldStyles, InputAlertStyles } from './styles';

// TYPES
interface InputProps {
  forwardRef?: Ref<any>;
  type?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  tip?: string | null;
  error?: boolean;
  value?: string;
  name?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
  required?: boolean;
  autoComplete?: string;
}

// MAIN COMPONENT
const Input: React.FC<InputProps> = props => (
  <InputStyles>
    {props.label && props.label.length > 0 &&
      <InputLabelStyles htmlFor={props.name} required={props.required || false}>{props.label}</InputLabelStyles>
    }
    <InputFieldStyles
      id={props.name}
      ref={props.forwardRef && props.forwardRef}
      type={props.type && props.type}
      error={(props.error && true) || false}
      disabled={(props.disabled && true) || false}
      placeholder={(props.placeholder && props.placeholder) || ''}
      value={props.value}
      name={(props.name && props.name) || ''}
      onChange={props.onChange && props.onChange}
      required={props.required && props.required}
      autoComplete={props.autoComplete}
    />
    {props.tip && props.tip.length > 0 && (
      <InputAlertStyles error={(props.error && true) || false}>{props.tip}</InputAlertStyles>
    )}
  </InputStyles>
);

export default Input;
