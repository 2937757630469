import styled from 'styled-components';
import Colors from '../../styles/Colors';
interface IProps {
  error?: string | null;
}
export const TextInput = styled.input<IProps>`
  padding: 8px 12px;
  background: white;
  border: 1px solid;
  border-color: ${props => (props.error ? Colors.error : '#eee')};
  border-radius: 5px;
  background: #f2f2f2;
  ::placeholder {
    color: ${Colors.grey75};
  }
  :focus,
  :active {
    border-color: ${Colors.purple62};
    outline: none;
  }
`;

export const TextArea = TextInput.withComponent('textarea');
