// IMPORTS
import React from 'react';
import DashboardStyles from './styles';

// TYPES
interface Props {}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const Dashboard: React.FC<Props> = props => {
  return props.children ? <DashboardStyles>{props.children}</DashboardStyles> : null;
};

// EXPORTS
export default Dashboard;
