import { useState, useEffect } from 'react';
import { History, Location } from 'history'

export const useForm = <T extends {}, K extends keyof T>(
  formState: T
): [T, (key: K) => (value: T[K]) => void] => {
  const [form, setForm] = useState(formState);
  const setChange = <Z extends keyof T>(field: Z) => (value: T[Z]) => {
    setForm({
      ...form,
      [field]: value
    });
  };
  return [form, setChange];
};


export const useSortStartTime = (currentSortStartTime: number | null, setSortStartTime: Function, history?: History) => {

  useEffect(() => {
    if (!currentSortStartTime) {
      setSortStartTime(Date.now())
    }

    function resetSortStartTime(location: Location) {
      const {pathname} = location
      if (!/\/dashboard\/cards\/\w+$/.test(pathname)) {
        setSortStartTime(null)
      }
    }

    if (history) {
      return history.listen(resetSortStartTime)
    }

  }, [setSortStartTime, currentSortStartTime, history])
}

export const useAriaLiveMessage = (successMessage?: string, errorMessage?: string) => {
  const [ariaLiveMessage, setAriaLiveMessage] = useState('');

  useEffect(() => {
    if (successMessage) {
      setAriaLiveMessage(successMessage);
    }
    if (errorMessage) {
      setAriaLiveMessage(errorMessage);
    }
  }, [successMessage, errorMessage]);

  return ariaLiveMessage;
}