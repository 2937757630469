// IMPORTS
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
// import { history } from '../../redux/store';

// PRESENTATION COMPONENT
import Faq from '../../components/Faq';
// import { push, Push } from 'connected-react-router';

// ACTIONS
// import { doModalHide } from '../ModalBackgroundContainer/actions';
// import { authReducer } from '../Auth/reducer';

// TYPES
interface IProps {}

type StateProps = AppState;

type Props = IProps & StateProps;
// MAIN COMPONENT
const FaqContainer: React.FC<Props> = props => {
  // Var
  const [loading, setLoading] = useState(false);
  const role = props.accountReducer.get('role');

  //   // Functions
  //   const onClickGetStarted = () => {
  //     history.push('/signup');
  //   };

  // Hooks
  useEffect(() => {
    if (role !== null) {
      setLoading(true);
    }
  }, [role]);

  return (
    <Faq
      user={props.accountReducer.get('role')}
      code={props.accountReducer.get('code')}
      loading={loading}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  ...state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqContainer);
