// Fetch PDF results
const RESULT_START_LOADING = 'Cards/Result/RESULT_START_LOADING';
const RESULT_END_LOADING = 'Cards/Result/RESULT_END_LOADING';
const RESULT_GET_ERROR = 'Cards/Result/RESULT_GET_ERROR';
const RESULT_DOWNLOAD_PDF = 'Cards/Result/RESULT_DOWNLOAD_PDF';
const RESULT_RESET_EMAIL = 'Cards/Result/RESULT_RESET_EMAIL';
const RESULT_RESET = 'Cards/Result/RESULT_RESET';
// Fetch JSON results
const RESULT_DO_LOAD = 'Cards/Result/RESULT_DO_LOAD';
const RESULT_DO_LOAD_ALL_CARDS = 'Cards/Result/RESULT_DO_LOAD_ALL_CARDS'; // TODO: transition hardcoded cards to the backend, as of now these are extra cards
const RESULT_LOAD_ALL_CARDS_END = 'Cards/Result/RESULT_LOAD_ALL_CARDS_END';
const RESULT_START_GET = 'Cards/Result/RESULT_START_GET';
const RESULT_END_GET = 'Cards/Result/RESULT_END_GET';
const RESULT_ERROR_GET = 'Cards/Result/RESULT_ERROR_GET';
// Time spent sorting
const RESULT_SET_START_TIME = 'Cards/Result/RESULT_SET_START_TIME';
// Save card sort progress
const RESULT_SAVE_PROGRESS_END = 'Cards/Result/RESULT_SAVE_PROGRESS_END';
const RESULT_SAVE_PROGRESS_ERROR = 'Cards/Result/RESULT_SAVE_PROGRESS_ERROR';
const RESULT_SAVE_PROGRESS_START = 'Cards/Result/RESULT_SAVE_PROGRESS_START';
const RESULT_SAVE_PROGRESS = 'Cards/Result/RESULT_SAVE_PROGRESS';
const RESULT_CREATE = 'Cards/Result/RESULT_CREATE';
const RESULT_DELETE = 'Cards/Result/RESULT_DELETE';

export {
  RESULT_START_LOADING,
  RESULT_END_LOADING,
  RESULT_GET_ERROR,
  RESULT_DOWNLOAD_PDF,
  RESULT_DO_LOAD,
  RESULT_DO_LOAD_ALL_CARDS,
  RESULT_LOAD_ALL_CARDS_END,
  RESULT_RESET_EMAIL,
  RESULT_RESET,
  RESULT_START_GET,
  RESULT_END_GET,
  RESULT_ERROR_GET,
  RESULT_SET_START_TIME,
  RESULT_SAVE_PROGRESS,
  RESULT_SAVE_PROGRESS_END,
  RESULT_SAVE_PROGRESS_ERROR,
  RESULT_SAVE_PROGRESS_START,
  RESULT_CREATE,
  RESULT_DELETE
};
