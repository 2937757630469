import { cards as allCards } from '../containers/CardSortContainer';
import { StateItem } from '../components/CardSort';

export interface CardAttributesResult {
  _id?: string;
  name: string;
  color: string;
  watermarkImageUrl: string;
  logoImageUrl: string;
  result: null | string;
  en?: {
    title: string;
    imageUrl: string;
    pdfUrl: string;
    bulletHeading: string;
    content: any;
    jobLinks: any;
    bulletDescription: any;
  };
  fr?: {
    title: string;
    imageUrl: string;
    pdfUrl: string;
    bulletHeading: string;
    content: any;
    jobLinks: any;
    bulletDescription: any;
  };
}

export function findFirstUnsortedCardIndex(resultsCards: any[]) {
  const allCardNames = resultsCards.map(card => card.name);
  const sortedCards = resultsCards.filter(c => c.result).map(c => c.name);
  let index = -1

  for (let i = 0; i < allCardNames.length; i++) {
    const cardName = allCardNames[i];

    if (!sortedCards.includes(cardName)) {
      index = i
      break;
    }
  }

  return index
}

export function prepareExtraCards(extraCards: CardAttributesResult[]) {
  return extraCards.map(extraCard => ({
    ...extraCard,
    result: null
  }));
}

export function getAllCardsWithContent(allCards: any[], extraCards: CardAttributesResult[]) {
  // allCards with { result: null | 'like' | 'superlike' | 'dislike' }
  const allCardsNames = allCards.map(card => card.name);
  const unsavedExtraCards = extraCards.filter(extraCard => {
    return !allCardsNames.includes(extraCard.name);
  });
  const savedCards = allCards.map(card => {
    const extraCard = extraCards.filter(extraCard => card.name === extraCard.name)[0];
    if (extraCard) {
      return { ...extraCard, ...card };
    } else {
      return card;
    }
  });
  return [...savedCards, ...prepareExtraCards(unsavedExtraCards)];
}

export function getExtraCardContent(
  card: any,
  extraCards: CardAttributesResult[] | any
): CardAttributesResult | { name: string; _id?: string | undefined } {
  const cardName = typeof card === 'object' ? card.name : card;
  const foundExtraCard = extraCards.filter((extraCard: any) => {
    return extraCard.name === cardName || '';
  });
  if (foundExtraCard.length > 0) {
    return foundExtraCard[0];
  } else {
    return { name: cardName };
  }
}

export function getBaseCardsWithExclusion(excludedCards: string[]) {
  const lowerCaseExcludedCards = excludedCards.map(cardName => cardName.toLowerCase());
  const filteredCards = allCards.filter(cardName => {
    if (excludedCards.length <= 0) {
      return true;
    }
    const lowerCaseCardName = cardName.toLowerCase();
    return !lowerCaseExcludedCards.includes(lowerCaseCardName);
  });
  return filteredCards.map(i => ({ name: i, result: null })) as StateItem[];
}

export function getCardsResultsWithExclusions(
  extraCards: CardAttributesResult[],
  excludedCards: string[],
  resultCards: any[]
) {
  const lowerCaseExcludedCards = excludedCards.map(cardName => cardName.toLowerCase());
  const lowerCaseBaseCardsWithExclusions = allCards.reduce((acc: string[], cardName) => {
    const lowerCaseCardName = cardName.toLowerCase();
    if (lowerCaseExcludedCards.includes(lowerCaseCardName)) {
      return acc;
    }
    return [...acc, lowerCaseCardName];
  }, []);

  const lowerCaseExtraCards = extraCards.map(extraCard => extraCard.name.toLowerCase());

  return resultCards.filter(cardResult => {
    const cardName = (cardResult && cardResult.name && cardResult.name.toLowerCase()) || '';
    return (
      lowerCaseBaseCardsWithExclusions.includes(cardName) || lowerCaseExtraCards.includes(cardName)
    );
  });
}

export function isCustomCard(item: any) {
  return !!(
    typeof item === 'object' &&
    item.title &&
    typeof item.description === 'string' &&
    typeof item.imageUrl === 'string'
  );
}

export function isExtraCard(item: any) {
  return !!(typeof item === 'object' && item._id && item.name && item.en && item.fr);
}

export function getStudentTopChallenge(
  translate: any,
  language: any,
  selectedCard: any,
  extraCards: any[]
): string {
  if (typeof selectedCard === 'string') {
    // standard and extra cards
    const keyLowercase = selectedCard.charAt(0).toLowerCase() + selectedCard.slice(1);
    const standardCardName = translate(`${keyLowercase}.Title`);
    const extraCardObj: any = getExtraCardContent(selectedCard, extraCards);
    const selectedCardName =
      (extraCardObj[language] && extraCardObj[language].title) || standardCardName;
    return selectedCardName || '';
  }

  // custom cards
  return (selectedCard && selectedCard.title) || '';
}
