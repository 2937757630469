import authentication from './authentication'
import help from './help'
import navigation from './navigation'
import account from './account'
import studentTable from './studentTable'
import cardSort from './cardSort'
import results from './results'

export default {
  authentication,
  help,
  navigation,
  account,
  studentTable,
  cardSort,
  results
}