import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function startCardSort({
  isFirstSort
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'Start card sort',
    label: isFirstSort ? 'First sort' : 'Sort again'
  })
}

export function abandonCardSort({
  isFirstSort
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'Abandon card sort',
    label: isFirstSort ? 'First sort' : 'Sort again'
  })
}

export function completeCardSort({
  isFirstSort
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'Complete card sort',
    label: isFirstSort ? 'First sort' : 'Sort again'
  })
}

export function viewBackOfCard({
  card
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'View back of card',
    ...(card && {label: card})
  })
}

export function viewSampleCareers({
  card
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'View sample careers',
    ...(card && {label: card})
  })
}

export function clickOnCareer({
  career
} : any) {
  ReactGA.event({
    category: 'Card sort',
    action: 'Click on career',
    ...(career && {label: career})
  })
}

export default {
  startCardSort: validateRole(startCardSort),
  abandonCardSort: validateRole(abandonCardSort),
  completeCardSort: validateRole(completeCardSort),
  viewBackOfCard: validateRole(viewBackOfCard),
  viewSampleCareers: validateRole(viewSampleCareers),
  clickOnCareer: validateRole(clickOnCareer)
}