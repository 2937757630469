// IMPORTS
import styled from 'styled-components';

// MAIN COMPONENT
const NotFoundStyles = styled.div`
  margin: 20px;
  width: 100%;
`;

// EXPORTS
export default NotFoundStyles;
