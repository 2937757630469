// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// TYPES
interface SkipToContentProps {
  href?: string;
}

// MAIN COMPONENTS
const SkipToContentStyles: React.FC<SkipToContentProps> = styled.a`
  position: absolute;
  left: -100rem;

  &:focus {
    position: static;
    outline: none;
    padding: 2px;
    margin-left: 0.5rem;
    color: ${Colors.white};
    border: 2px solid ${Colors.black};
  }
`;

// EXPORTS
export {
  SkipToContentStyles
};
