// IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { history } from '../../redux/store';

import { AppState } from '../../redux/reducer';

// PRESENTATION
import Header from '../../components/Header';

// ACTIONS
import { doNavOpen, doNavClose } from '../NavigationContainer/actions';
import { doLogout } from '../LoginContainer/actions';

// REDUCERS

// SAGAS

// TYPES
interface Props {
  state: AppState;
  doNavClose: typeof doNavClose;
  doNavOpen: typeof doNavOpen;
  doLogout: typeof doLogout;
}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const HeaderContainer: React.FC<Props> = props => {
  // Variables / States
  //   const {} = props;

  // Functions
  /**
   *
   */
  const onClickMenu = () => {
    if (props.state.navReducer.get('open')) {
      props.doNavClose();
    } else {
      props.doNavOpen();
    }
  };

  /**
   *
   */
  const onClickLogin = () => {
    history.push('/login');
  };

  /**
   *
   */
  const onClickRegister = () => {
    history.push('/signup');
  };
  //   const onLoad = () => {
  //     // Load script
  //   };

  //   // Effects
  //   // Component Load
  //   useEffect(() => {
  //     onLoad();
  //   }, [onLoad]);

  //   // Component Unload
  //   useEffect(() => {
  //     return () => {};
  //   }, []);

  // Return
  // return <p {...props} />;
  return (
    <Header
      onClickMenu={onClickMenu}
      menuOpen={props.state.navReducer.get('open')}
      onClickLogin={onClickLogin}
      onClickRegister={onClickRegister}
      doLogout={props.doLogout}
      userData={props.state.accountReducer}
      auth={props.state.authReducer}
    />
  );
};

// PROPTYPES
HeaderContainer.propTypes = {
  state: PropTypes.any
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // actions here
      doNavOpen,
      doNavClose,
      doLogout
    },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
