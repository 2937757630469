// IMPORTS
import styled from 'styled-components';
import Colors from '../../../../styles/Colors';

const List = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  background: ${Colors.greyF4};
  border-radius: 18px;
  padding: 0;
  overflow: hidden;

  li {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:first-child) {
      border-left: 1px solid ${Colors.greyDD}
    }

    button {
      padding: 15px 10px;
      line-height: 1;
      width: 100%;
      height: calc(100% + 3px);
      text-align: center;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0;
      margin-top: -3px;

      &:not(:disabled) {
        color: ${Colors.purple72}

        &:hover {
          background: ${Colors.greyDD};
          transform: none
        }
      }

      &:disabled {
        color: ${Colors.greyCC};
        cursor: default;
      }
    }
  }
`;

// EXPORTS
export { List };
