// IMPORTS
import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';

// STYLE COMPONENTS
import {
  SkipToContentStyles
} from './styles';

// TYPES
interface Props {
  href: string;
}

// MAIN COMPONENT
const SkipToContent: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  return (
    <SkipToContentStyles href={props.href}>
      {translate('SkipToContent.LinkText')}
    </SkipToContentStyles>
  );
};

// EXPORTS
export default SkipToContent;
