import React from 'react';
import * as Sentry from '@sentry/browser';
import LanguageContext from './locale/LanguageContext';

class ErrorBoundary extends React.Component {
  state = {
    error: null
  };

  static contextType = LanguageContext;

  componentDidCatch(error: Error) {
    Sentry.captureException(error);
    this.setState({
      error: error
    });
  }

  render() {
    const { translate } = this.context;

    if (this.state.error) {
      return (
        <div>
          <h2>{translate('ErrorBoundary.Title')}</h2>
          <p>{translate('ErrorBoundary.Message')}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
