// IMPORTS
import { fromJS } from 'immutable';
import { LOCATION_CHANGE, LocationChangePayload } from 'connected-react-router';
// TYPES
import { NAV_OPEN, NAV_CLOSE } from './types';
import { matchPath } from 'react-router';

// TYPES
export type NAV_STATE = {
  open: boolean;
};

export type NAV_REDUCER = ImmutableMap<NAV_STATE>;

// INITIAL STATE
const STATE: NAV_STATE = {
  open: false
};

const INITAL_STATE = fromJS(STATE) as ImmutableMap<typeof STATE>;

const shouldBeOpen = (payload: LocationChangePayload) => {
  let path = matchPath<{ sub: string }>(payload.location.pathname, {
    path: '/dashboard/cards/:sub'
  });
  if (path) {
    if (path.params.sub === 'get') {
      return true;
    }
    return false;
  }
  switch (payload.location.pathname) {
    case '/':
      return false;
    default:
      return false;
  }
};
// MAIN REDUCER
const navReducer = (state = INITAL_STATE, { type, payload }: ReduxAction) => {
  switch (type) {
    case NAV_OPEN:
      return state.set('open', true);
    case NAV_CLOSE:
      return state.set('open', false);
    case LOCATION_CHANGE:
      return state.set('open', shouldBeOpen(payload as LocationChangePayload));
    default:
      return state;
  }
};

// EXPORTS
export { navReducer };
