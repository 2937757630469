import React, { useEffect, useContext } from 'react';
import { AppState } from '../../redux/reducer';
import LanguageContext from '../../locale/LanguageContext';
import Loader from '../../styles/Loader';
import { ClasslinkStyles, ErrorStyles } from './styles';

interface IProps {
  location: any;
}

interface StateProps {
  classlinkReducer: AppState['classlinkReducer'];
  authReducer: AppState['authReducer'];
}

interface DispatchProps {
  doSSO: Function;
}

type Props = IProps & StateProps & DispatchProps;

function Classlink(props: Props) {
  const { translate } = useContext(LanguageContext);
  const { doSSO } = props;
  const queryString: string = props.location?.search || '';
  const getClasslinkCode: Function = (queryString: string): string => {
    if (!queryString) return '';
    const splittedQuery = queryString.split('&');
    const classlinkCodeQuery = splittedQuery.find(text => text.includes('code='));
    if (!classlinkCodeQuery) return '';
    const splittedclasslinkCodeQuery = classlinkCodeQuery.split('=');
    if (splittedclasslinkCodeQuery.length <= 0) return '';
    return splittedclasslinkCodeQuery[splittedclasslinkCodeQuery.length - 1];
  };
  useEffect(() => {
    const classlinkCode = getClasslinkCode(queryString);
    const isUserLoggedIn = !!props.authReducer.get('user');
    const isAuthLoadingDone = props.authReducer.get('loading') === false;
    if (classlinkCode && isAuthLoadingDone && !isUserLoggedIn) {
      doSSO(classlinkCode);
    }
    // eslint-disable-next-line
  }, [props.authReducer.get('loading')]);

  return (
    <ClasslinkStyles isLoading={props.classlinkReducer.get('isSSOLoading')}>
      {props.classlinkReducer.get('isSSOLoading') ? <Loader /> : null}
      {!props.classlinkReducer.get('isSSOLoading') && props.classlinkReducer.get('error') ? (
        <ErrorStyles>{translate(`${props.classlinkReducer.get('error')}`)}</ErrorStyles>
      ) : null}
    </ClasslinkStyles>
  );
}

export default Classlink;
