// IMPORTS
import React from 'react';

// CONTAINERS
import StudentsContainer from '../../containers/StudentsContainer';

// MAIN COMPONENT
const StudentsPage = () => <StudentsContainer />;

// EXPORTS
export default StudentsPage;
