export function getNextSortDirection(currentSortDirection: string | null) {
  if (currentSortDirection === 'asc') {
    return 'desc'
  } else if (currentSortDirection === 'desc') {
    return null
  } else {
    return 'asc'
  }
}

export function formatSort(sort: any) {
  return Object.keys(sort)
  .map(sortKey => {
    const sortedDirection = (sort as any)[sortKey]
    if (sortedDirection) {
      return `${sortKey}:${sortedDirection}`
    } else {
      return null
    }
  })
  .filter(s => s)
}