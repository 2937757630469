// IMPORTS
import styled from 'styled-components';
import Color from 'color';
import Colors from '../../styles/Colors';
import ButtonStyles from '../../styles/Button/styles';
import MockButtonStyles from '../../styles/MockButton/styles';
import Screens from '../../styles/Screens';
import { ButtonHTMLAttributes } from 'react';

// TYPES
interface NavLinkProps {
  mobile?: boolean;
  desktop?: boolean;
  uppercase?: boolean;
}

interface NavMobileButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  expanded: boolean;
  purple?: boolean;
}

interface MenuProps {
  onClick: Function;
  menuOpen: boolean;
}

// MAIN COMPONENTS
const NavInternalLinkStyles: React.SFC<NavLinkProps> = styled.li<NavLinkProps>`
  display: ${props => (props.mobile ? 'block' : 'none')};

  ${ButtonStyles.toString},
  ${MockButtonStyles.toString} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }

  ${props =>
    props.uppercase &&
    `
  > button,
    ${MockButtonStyles.toString} {
    text-transform: uppercase;
    width: 64px;
    text-align: center; 
  }`}

  #user-name {
    width: 100%;
    height: 42px;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
    margin-left: 5px;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  @media (min-width: ${Screens.medium}) {
    display: ${props => (props.desktop ? 'block' : 'none')};
  }
`;

const NavInternalListLeftStyles = styled.ul`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;

  ${NavInternalLinkStyles.toString} {
    margin-right: 20px;
    margin-bottom: 10px;

    button,
    ${MockButtonStyles.toString} {
      white-space: nowrap;
    }

    @media (max-width: 1199px) {
      margin-right: 0;
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      button,
      ${MockButtonStyles.toString} {
        padding: 0 15px;
      }
    }
  }
`;

const NavInternalListRightStyles = styled.ul`
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: end;

  ${NavInternalLinkStyles.toString} {
    margin-left: 20px;

    button,
    ${MockButtonStyles.toString} {
      white-space: nowrap;

      @media (max-width: 1199px) {
        padding: 0 15px;
      }
    }

    &:nth-child(1) {
      button,
      ${MockButtonStyles.toString} {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    &:nth-child(3) {
      button,
      ${MockButtonStyles.toString} {
        max-width: 180px;

        > span {
          display: block !important;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: -10px;
          margin-left: 5px;
        }
      }
    }

    @media (max-width: 1199px) {
      &:nth-child(3) {
        button,
        ${MockButtonStyles.toString} {
          max-width: 150px;
        }
      }
    }
  }
`;

const NavMobileButtonStyles: React.SFC<NavMobileButtonProps> = styled.button<NavMobileButtonProps>`
  background: ${props =>
    props.expanded
      ? Colors.white
      : props.purple
        ? Colors.purple72
        : Colors.white};
  border: 1px solid
    ${props =>
      props.expanded
        ? props.purple
          ? Colors.white
          : Colors.purple72
        : props.purple
        ? Colors.white
        : Colors.greyDD};
  position: relative;
  padding: 0;
  width: 62px;

  @media screen and (max-width: 420px) {
    max-width: 55px;
  }

  span {
    background: ${props =>
      props.expanded
        ? Colors.purple62
        : props.purple
          ? Colors.white
          : Colors.grey66};
    display: block;
    width: 20px;
    height: 2px;
    border-radius: 0.5px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transition: all 250ms ease-in-out 0s;

    &:nth-child(1) {
      top: ${props => (props.expanded ? '19px' : '12px')};
      transform: ${props => (props.expanded ? 'rotate(45deg)' : 'rotate(0deg)')};
    }

    &:nth-child(2) {
      top: 0;
      bottom: 0;
      display: ${props => (props.expanded ? 'none' : 'block')};
    }

    &:nth-child(3) {
      top: ${props => (props.expanded ? '19px' : '26px')};
      transform: ${props => (props.expanded ? 'rotate(-45deg)' : 'rotate(0deg)')};
    }
  }
`;

const NavInternalStyles = styled.div`
  background: ${Color(Colors.purple72)
    .alpha(0.9)
    .rgb()
    .toString()};
  overflow: hidden;
  padding: 25px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100px;
  z-index: 11;

  @media screen and (max-width: 767px) {
    height: 70px;
    padding: 10px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-top: 4px;
    }
  }
`;

const NavInternalContainer = styled.div`
  width: calc(100% - 30px);
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }
`;

const Nav = styled.ul`
  align-items: center;
`;

const NavLink: React.SFC<NavLinkProps> = styled.li<NavLinkProps>`
  display: ${props => (props.mobile ? 'flex' : 'none')};
  align-items: center;
  text-align: center;

  @media (min-width: ${Screens.medium}) {
    display: ${props => (props.desktop ? 'flex' : 'none')};
  }
`;

const HeaderContainer = styled.div`
  background: ${Colors.white};
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 11;

  @media screen and (max-width: 767px) {
    height: 70px;
  }
`;

const HeaderStyles = styled.div`
  display: flex;
  background: ${Colors.white};
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  height: 100px;

  width: calc(100% - 30px);
  padding: 0 15px;
  margin: 0 auto;

  a {
    text-decoration: none;
    border: 1px solid transparent;
    border-radius: 25px;
    transition: all 250ms ease-in-out 0s;

    &:focus {
      outline: none;
      border-color: ${Colors.black};
      box-shadow: 0px 0px 0px 2px
      ${Color(Colors.black)
        .rgb()
        .toString()};
    }
  }

  #logo {
    height: 50px;
    width: 100%;

    svg {
      height: 100%;
    }
  }

  @media screen and (max-width: 420px) {
    svg {
      max-width: 130px;
    }
  }

  @media screen and (max-width: 490px) and (min-width: 421px) {
    svg {
      max-width: 215px;
    }
  }

  @media screen and (max-width: 767px) {
    height: 70px;
  }

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  ${ButtonStyles},
  ${MockButtonStyles} {
    width: 100%;
  }

  ${Nav} {
    margin: 0;
    padding: 0;
    list-style: none;
    float: right;
    height: 42px;

    li {
      float: left;
      margin-left: 20px;

      a {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        position: relative;
        line-height: 34px;
        min-height: 34px;
        padding-left: 40px;
        min-width: 30x;

        svg {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
        }

        &:hover {
          text-decoration: none;
        }
      }

      button,
      ${MockButtonStyles.toString} {
        line-height: 40px;
      }

      @media screen and (max-width: 420px) {
        margin-left: 15px;
      }
    }
  }
`;

// EXPORTS
export {
  HeaderContainer,
  HeaderStyles,
  NavInternalContainer,
  NavInternalStyles,
  NavMobileButtonStyles,
  NavInternalLinkStyles,
  NavInternalListLeftStyles,
  NavInternalListRightStyles,
  Nav,
  NavLink
};
