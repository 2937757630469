// TYPES
const USER_LOGGED_IN = 'Auth/USER_LOGGED_IN';
const USER_LOGIN_STARTED = 'Auth/USER_LOGIN_STARTED';
const USER_LOGIN_ERROR = 'Auth/USER_LOGIN_ERROR';
const DO_RESET_ERROR = 'Auth/DO_RESET_ERROR';
const DO_USER_LOGIN = 'Auth/DO_USER_LOGIN';
const DO_USER_LOGOUT = 'Auth/DO_USER_LOGOUT';
const USER_LOGOUT_STARTED = 'Auth/USER_LOGOUT_STARTED';
const USER_LOGGED_OUT = 'Auth/USER_LOGGED_OUT';
const USER_LOGOUT_ERROR = 'Auth/USER_LOGOUT_ERROR';

const LOGIN_REQUEST = 'Auth/LOGIN_REQUEST';
const LOGIN_SUCCESS = 'Auth/LOGIN_SUCCESS';
const LOGIN_FAIL = 'Auth/LOGIN_FAIL';
const LOGIN_RESET_ERRORS = 'Auth/LOGIN_RESET_ERRORS';

// EXPORTS
export {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGIN_RESET_ERRORS,
  DO_RESET_ERROR,
  USER_LOGGED_IN,
  DO_USER_LOGIN,
  USER_LOGIN_STARTED,
  USER_LOGIN_ERROR,
  DO_USER_LOGOUT,
  USER_LOGOUT_STARTED,
  USER_LOGGED_OUT,
  USER_LOGOUT_ERROR
};
