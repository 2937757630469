import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

// STYLED COMPONENTS
import DashboardStyles from '../Dashboard/styles';
import ButtonStyles from '../../styles/Button/styles';
import { InfoWrapper } from '../CardSort/styles'

// MAIN COMPONENTS
const BackWrapper = styled.button`
  background: ${Colors.greyF8};
  color: ${Colors.grey99};
  margin-top: 40px;
  bottom: 24px;
  line-height: 42px;
  left: 20px;
  right: 20px;
  display: block;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  transition: all 250ms ease-in-out 0s;

  & svg {
    margin-right: 6px;
  }

  @media (min-width: ${Screens.medium}) {
    backgorund: none;
    max-width: 300px;
    margin: 0 auto;
    &:hover {
      color: ${Colors.grey33};
    }
  }
`;

const BackOfCardReminderStyle = styled(DashboardStyles)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  
  & h1 {
    color: ${Colors.white};
  }

  & > div {
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    & p {
      text-align: center;
      color: ${Colors.purpleDF}
      margin: 0 0 30px 0;
      max-width: 450px;
    }
  }

  & ${ButtonStyles} {
    margin-bottom: 2rem;
  }

  @media screen and (min-width: ${Screens.medium}) {
    padding-bottom: 30px;
  }
`;

const BackOfCardReminderDemoCard = styled.div`
  position: relative;
  width: 220px;
  height: 150px;
  margin: 0 auto 40px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  background: linear-gradient(${Colors.cardHealthcare}, ${Colors.purple72});

  ${InfoWrapper} {
    pointer-events: none;
    margin: 1rem;
  }
`

// EXPORTS
export default BackOfCardReminderStyle;

export { BackWrapper, BackOfCardReminderDemoCard };
