// IMPORTS
import styled from 'styled-components';
import Colors from '../../../../styles/Colors';
import LoaderStyles from '../../../../styles/Loader/styles';

const Heading = styled.h2`
  margin-bottom: 0;
`

const ChartContainer = styled.div`
  padding: 20px;
  background: ${Colors.white};
  border-radius: 18px;
  text-align: center;

  @media (max-width: 420px) {
    margin-left: -40px;
    margin-right: -40px;
    border-radius: 0;
    padding: 20px 0;
  }

  ${Heading} {
    color: ${Colors.grey33}
  }

  ${LoaderStyles} {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
`

const Chart = styled.div`
  height: 200px
`

// EXPORTS
export {
  ChartContainer,
  Chart,
  Heading
};
