import { Dispatch } from 'redux';
import { SUPPORT_FORM_RESET, SUPPORT_FORM_REQUEST } from './types';

export const doSupportSubmit = (message: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SUPPORT_FORM_REQUEST,
    payload: message
  });
};

export const doSupportReset = () => (dispatch: Dispatch) => {
  dispatch({
    type: SUPPORT_FORM_RESET
  });
};
