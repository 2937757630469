// IMPORTS
import { Dispatch } from 'redux';
import { NAV_OPEN, NAV_CLOSE } from './types';

// MAIN ACTIONS
/**
 *
 */
const doNavOpen = () => (dispatch: Dispatch) => {
  dispatch({
    type: NAV_OPEN
  });
};

/**
 *
 */
const doNavClose = () => (dispatch: Dispatch) => {
  dispatch({
    type: NAV_CLOSE
  });
};

// EXPORTS
export { doNavOpen, doNavClose };
