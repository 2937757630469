import { connect } from 'react-redux';
import CardSortConclusion from '../../components/CardSortConclusion';
import { AppState } from '../../redux/reducer';

const mapStateToProps = (state: AppState) => ({
  ...state
});

const CardSortConlusionContainer = connect(mapStateToProps)(CardSortConclusion);

export default CardSortConlusionContainer;
