import React, { useState, useEffect } from 'react';
import ProgressBarStyles from './styles';

interface IProps {
  progress: number;
  label: string;
  min?: number;
  max?: number;
  value?: number;
}

interface StateProps {}

interface DispatchProps {}

type Props = IProps & StateProps & DispatchProps;

const ProgressBar: React.FC<Props> = props => {
  const [value, setValue] = useState(0);
  useEffect(() => {
    setValue(props.progress);
  }, [props.progress]);
  return (
    <ProgressBarStyles
      value={value}
      aria-label={props.label}
      role="progressbar"
      aria-valuemin={props.min || 0}
      aria-valuenow={props.value}
      aria-valuemax={props.max || 100}
    >
      <div />
    </ProgressBarStyles>
  );
};
export default ProgressBar;
