import React, { useState } from 'react';
import axios from 'axios';
import fetch from '../../../fetch';
import { CustomCard } from '../../../types/customCard';
import useCancelPromise from '../../../hooks/useCancelPromise';
import Colors from '../../../styles/Colors';

export function limitAndTrimText(
  event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  callback: React.Dispatch<React.SetStateAction<string>>,
  maxCharacters: number = 40
) {
  const trimmedText = event.target.value.replace(/(\r\n|\n|\r)/gm, '');
  callback(trimmedText.slice(0, maxCharacters));
}

const useCustomCardForm = ({
  onUpdateCustomCard,
  accountId,
  customCardLength,
  showCardInfoModal,
  doModalHide
}: {
  onUpdateCustomCard: (value: CustomCard) => void;
  customCardLength: number;
  accountId: string;
  showCardInfoModal: boolean;
  doModalHide: Function;
}) => {
  const cancelPromise = useCancelPromise();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [hasFileValidationError, setHasFileValidationError] = useState(false);
  const maxFileSizeInBytes = 1024 * 1024 * 1.5;

  async function updateCustomCard() {
    try {
      if (!cancelPromise.current) {
        setIsLoading(true);
        setError(null);
      }
      let imageUrl = '';
      const fileName = `${accountId}-${customCardLength + 1}`;
      const fileExtension = imageFile && imageFile.type.split('/')[1];
      const getUploadUrlResults = imageFile
        ? await fetch.get('/cards/upload', {
            params: {
              fileName,
              fileExtension
            }
          })
        : null;
      if (getUploadUrlResults && imageFile) {
        const { uploadUrl, downloadUrl } =
          getUploadUrlResults.data && getUploadUrlResults.data.data;
        await axios.put(uploadUrl, imageFile, { headers: { 'Content-Type': imageFile.type } });
        imageUrl = downloadUrl;
      }
      if (!cancelPromise.current) setIsLoading(false);
      if (showCardInfoModal) doModalHide();
      onUpdateCustomCard({
        id: `${Date.now()}${title}`,
        title,
        description,
        imageUrl,
        color: Colors.purple77 // TODO: custom card color
      });
    } catch (error) {
      if (!cancelPromise.current) {
        setIsLoading(false);
        setError(error);
      }
    }
  }

  return {
    isLoading,
    setIsLoading,
    error,
    setError,
    title,
    setTitle,
    description,
    setDescription,
    updateCustomCard,
    imageFile,
    setImageFile,
    hasFileValidationError,
    setHasFileValidationError,
    maxFileSizeInBytes
  };
};

export default useCustomCardForm;
