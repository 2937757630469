// IMPORTS
import { fromJS } from 'immutable';

// TYPES
import {
  FORGOT_RESET_ERRORS,
  FORGOT_REQUEST,
  FORGOT_REQUEST_SUCCESS,
  FORGOT_REQUEST_FAILURE
} from './types';

// TYPES
export type FORGOT_STATE = {
  fetching: boolean;
  email: string;
  errors: object | null;
  success: boolean;
};

export type FORGOT_REDUCER = ImmutableMap<FORGOT_STATE>;

// INITIAL STATE
const STATE: FORGOT_STATE = {
  fetching: false,
  email: '',
  errors: null,
  success: false
};

const INITAL_STATE = fromJS(STATE) as ImmutableMap<typeof STATE>;

// MAIN REDUCER
const forgotReducer = (state = INITAL_STATE, { type, payload }: ReduxAction) => {
  switch (type) {
    case FORGOT_RESET_ERRORS:
      return state
        .set('errors', null)
        .set('success', false)
        .set('fetching', false);
    case FORGOT_REQUEST:
      return state
        .set('email', payload.email)
        .set('errors', null)
        .set('success', false)
        .set('fetching', true);
    case FORGOT_REQUEST_SUCCESS:
      return state
        .set('email', '')
        .set('errors', null)
        .set('success', true)
        .set('fetching', false);
    case FORGOT_REQUEST_FAILURE:
      return state
        .set('email', '')
        .set('success', false)
        .set('errors', payload.errors)
        .set('fetching', false);
    default:
      return state;
  }
};

// EXPORTS
export { forgotReducer };
