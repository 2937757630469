import ReactGA from 'react-ga';
import {validateRole} from './middleware'

export function recordPageView({
  path
}: any) {
  ReactGA.pageview(path)
}

export default {
  recordPageView: validateRole(recordPageView)
}