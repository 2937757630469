import DashboardStyles from '../Dashboard/styles';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';
import Error from '../../styles/Error';
import Button from '../../styles/Button';

const CardResultStyle = styled(DashboardStyles)`
  display: flex;
  flex-direction: column;

  .card-result__iframe {
    position: relative;
    height: 100%;
    width: 60%;
    margin: 0 auto;

    @media (max-width: ${Screens.large}) {
      width: 80%;
    }
  }


`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  & p {
    color: ${Colors.purpleDF};
  }
  & h1 {
    margin: 12px;
    color: ${Colors.white};
    font-weight: 600;
  }
  & + div {
    margin-top: 8px;
  }

  img {
    height: 400px;
    border: 10px solid white;
    border-radius: 18px;
  }
  padding: 14px;
`;

export const BottomContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 16px;

  & p {
    color: ${Colors.purpleDF};
    font-weight: 300;
    max-width: 300px;
    line-height: 1.8em;
    font-size: 1em;
    text-align: center;
  }

  ${Button} {
    margin-bottom: 8px;
    line-height: 1;
  }

  ${Error} {
    margin-bottom: 16px;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  & p {
    color: ${Colors.purpleDF};
    text-align: left;
  }
  & + & {
    margin-top: 14px;
  }
`;

export const CardImage = styled.div<{ color: string; src: string }>`
  height: 82px;
  width: 82px;
  background-image: ${props => `url(${props.src})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 5px;
  background-color: ${props => props.color};
  margin-right: 18px;
  flex-shrink: 0;
`;

export default CardResultStyle;
