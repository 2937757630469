// IMPORTS
import styled from 'styled-components';
import Colors from '../Colors';
import Screens from '../Screens';

// TYPES
interface Props {
  type?: string;
  placeholder?: string;
  autoComplete?: string;
}

interface InputLabelProps {
  required?: boolean;
}

// MAIN COMPONENTS
const InputStyles = styled.input.attrs((props: Props) => ({
  type: props.type || 'text',
  placeholder: props.placeholder || '',
  autoComplete: props.autoComplete
}))`
  background: ${props => (props.disabled ? Colors.greyF8 : Colors.white)};
  display: block;
  line-height: 40px;
  height: 40px;
  padding: 0 12px;
  outline: none;
  border-radius: 3px;
  border: 1px solid ${Colors.greyE6};
  font-size: 14px;
  transition: all 250ms ease-in-out 0s;
  width: 100%;

  &:focus {
    border: 1px solid rgba(70, 142, 250, 0.5);
    box-shadow: 0px 0px 2px 0px rgba(70, 142, 250, 1);
  }

  @media screen and (min-width: ${Screens.medium}) {
  }
`;

export const InputLabel = styled.label<InputLabelProps>`
  ${props => props.required &&
    `&::after {
      color: ${Colors.black};
      content: '*';
      display: inline-block;
      margin-left: 0.1em;
    }`
  }
`;

export const SelectInput = styled(InputStyles.withComponent('select'))`
  border: 1px solid ${Colors.grey66};
  margin-bottom: 20px;
  height: 40px;
`;

// EXPORTS
export default InputStyles;
