import { takeEvery, put } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  RESULT_RESET_EMAIL,
} from '../CardResultContainer/types';

function* goToCardsWorker({ payload }: any) {
  const location = payload.location as any;
  if (location.pathname === '/dashboard/cards') {
    yield put({
      type: RESULT_RESET_EMAIL
    });
  }
}

export function* goToCardsListener() {
  yield takeEvery(LOCATION_CHANGE, goToCardsWorker);
}
