import React, { useContext } from 'react';
import { LiveMessage } from 'react-aria-live';
import { MainContainer } from './styles';
import LanguageContext from '../../locale/LanguageContext';
import { BackWrapper } from '../Instructions/styles';
import { Back } from '../../styles/assets';
import Link from '../../styles/Link';
import Success from '../../styles/Success';
import RocketImage from '../../styles/assets/rocket.svg';
import DashboardStyles from '../Dashboard/styles';
import { useHistory, useLocation } from 'react-router';
import { useAriaLiveMessage } from '../../hooks';
import { AppState } from '../../redux/reducer';

interface IProps {}
const CardSortConclusion: React.FC<IProps & AppState> = props => {
  const { translate } = useContext(LanguageContext);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const downloadType = searchParams.get('downloadtype');
  const successMessage = downloadType === 'email' ? translate('Cards.EmailSent') : '';
  const ariaLiveMessage = useAriaLiveMessage(successMessage);
  const isConsumerGroup = props.accountReducer.get('isConsumerGroup');
  const isFreemiumGroup = props.accountReducer.get('isFreemiumGroup');

  return (
    <DashboardStyles>
      <MainContainer>
        <LiveMessage message={ariaLiveMessage} aria-live="polite" />
        {downloadType === 'email' && <Success>{translate('Cards.EmailSent')}</Success>}
        <h1>
          {isConsumerGroup && isFreemiumGroup
            ? translate('CardSortConclusion.HeaderFreemiumConsumer')
            : translate('CardSortConclusion.Header')}
        </h1>
        <img src={RocketImage} alt="" />
        <h2>{translate('CardSortConclusion.FeedbackHeader')}</h2>
        <Link doInherit={true} to={'/dashboard/support'}>
          {translate('CardResult.Bottom.Feedback')}
        </Link>
        <BackWrapper onClick={() => history.push('/dashboard/cards')}>
          <Back /> {translate('CardIntro.Button.Back')}
        </BackWrapper>
      </MainContainer>
    </DashboardStyles>
  );
};
export default CardSortConclusion;
