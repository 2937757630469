// IMPORTS
import { Ref, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import color from 'color';

// STYLES
import Colors from '../../styles/Colors';

// TYPES
interface InputLabelStylesProps {
  htmlFor?: string;
  required?: boolean;
}

interface InputFieldStylesProps extends InputHTMLAttributes<HTMLInputElement> {
  error: boolean;
  ref?: Ref<any>;
  autoComplete?: string;
}

interface InputAlertStylesProps {
  error: boolean;
}

interface InputStylesProps {}

// MAIN COMPONENTS
/**
 *
 */
export const InputLabelStyles: React.SFC<InputLabelStylesProps> = styled.label<InputLabelStylesProps>`
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${Colors.grey4E};

  ${props => props.required &&
    `&::after {
      color: #82204a;
      content: '*';
      display: inline-block;
      margin-left: 0.1em;
    }`
  }
`;

/**
 *
 */
export const InputFieldStyles: React.SFC<InputFieldStylesProps> = styled.input<InputFieldStylesProps>`
  background: ${props => (props.disabled ? Colors.greyF4 : Colors.white)};
  cursor: ${props => (props.disabled ? 'no-drop' : 'auto')};
  display: block;
  border: 1px solid ${props => (props.error ? Colors.redC6 : Colors.grey66)};
  border-radius: 4px;
  padding: 0 14px;
  height: 42px;
  line-height: 40px;
  transition: all 250ms ease-in-out 0s;
  color: ${props => (props.error ? Colors.redC6 : Colors.grey99)};
  margin-bottom: 20px;
  opacity: ${props => (props.disabled ? '0.6' : '1.0')};

  &:focus {
    outline: none;
    border-color: ${props => (props.error ? Colors.redC6 : Colors.black)};
    box-shadow: 0px 0px 0px 2px
      ${props =>
        color(props.error ? Colors.redC6 : Colors.black)
          .rgb()
          .toString()};
  }

  &::placeholder {
    color: ${Colors.grey75};
  }
`;

/**
 *
 */
export const InputAlertStyles: React.SFC<InputAlertStylesProps> = styled.div<InputAlertStylesProps>`
  background: ${Colors.redC6};
  display: inline-block;
  height: 30px;
  color: ${Colors.white};
  border-radius: 30px;
  padding: 0 20px;
  line-height: 30px;
  font-size: 14px;
  margin-bottom: 20px;
`;

/**
 *
 */
export const InputStyles: React.SFC<InputStylesProps> = styled.div<InputStylesProps>`
  overflow: visible !important;
`;
