import {
  ACCOUNT_GET_USER_REQUEST,
  ACCOUNT_DETAILS_UPDATE_REQUEST,
  ACCOUNT_ADDRESS_UPDATE_REQUEST,
  ACCOUNT_PASSWORD_UPDATE_REQUEST,
  ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST,
  ACCOUNT_UPDATE_RESET
} from './types';
import { Dispatch } from 'redux';

export type EditPayload = {
  [field: string]: any;
};

/**
 *
 */
const doGetUserData = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACCOUNT_GET_USER_REQUEST
  });
};

/**
 *
 */
const doUpdateReset = () => (dispatch: Dispatch) => {
  dispatch({
    type: ACCOUNT_UPDATE_RESET
  });
};

/**
 *
 * @param data
 */
const doSaveDetails = (data: EditPayload) => (dispatch: Dispatch<ReduxAction<EditPayload>>) => {
  dispatch({
    type: ACCOUNT_DETAILS_UPDATE_REQUEST,
    payload: data
  });
};

/**
 *
 * @param data
 */
const doSaveAddress = (data: EditPayload) => (dispatch: Dispatch<ReduxAction<EditPayload>>) => {
  dispatch({
    type: ACCOUNT_ADDRESS_UPDATE_REQUEST,
    payload: data
  });
};

/**
 *
 * @param data
 */
const doSavePassword = (data: EditPayload) => (dispatch: Dispatch<ReduxAction<EditPayload>>) => {
  dispatch({
    type: ACCOUNT_PASSWORD_UPDATE_REQUEST,
    payload: data
  });
};

const doUpdateSchoolCode = (data: EditPayload) => (dispatch: Dispatch<ReduxAction<EditPayload>>) => {
  dispatch({
    type: ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST,
    payload: data
  });
};

// EXPORTS
export { doUpdateReset, doGetUserData, doSaveDetails, doSaveAddress, doSavePassword, doUpdateSchoolCode };
