import styled, { keyframes } from 'styled-components';
import { Card as BaseCard } from '../../../CardSort/styles';
import { CardLogoImage } from '../../../Card/styles';
import Colors from '../../../../styles/Colors';
import Screens from '../../../../styles/Screens';

interface HeartWrapperProps {
  active: boolean;
}

interface ListboxProps {
  ref: any;
  isMarginRequired: boolean;
}

interface OptionProps {
  type: string;
  onClick: any;
}

const aspectRatio = 270 / 432;
export const Card = styled(BaseCard)`
  position: relative;
  transform: unset;
  right: unset;
  margin: unset;
  bottom: unset;
  height: 200px;
  width: ${aspectRatio * 200}px;
  transition: transform 0.15s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }

  ${CardLogoImage} {
    bottom: 5px;
    max-width: 2rem;

    @media screen and (max-width: 360px) {
      bottom: 8px;
      max-width: 2.5rem;
    }
  }
`;

export const NameContainer = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
  background-color: ${Colors.white};
  color: ${Colors.black};
  position: absolute;
  text-transform: uppercase;
  bottom: 12.5%
  right: 50%;
  transform: translateX(50%);
  font-size: 11px !important;
  width: 100%;
  z-index: 1;
  overflow: hidden;
`;

export const CardHolder = styled.div`
  display: flex;
  margin: 6px 6px;
  flex-basis: 125px;
  flex-shrink: 1;
  /* First */
  @media screen and (min-width: 361px) and (max-width: ${Screens.smallAbsolute - 1 + 'px'}) {
    /* First */
    &:nth-child(2n + 1) {
      flex-basis: 34%;
      flex-grow: 1;
      justify-content: flex-end;
      &:nth-last-child(1) {
        justify-content: center;
      }
    }
    /* Second */
    &:nth-child(2n + 2) {
      flex-basis: 34%;
      flex-grow: 1;
    }
  }
  @media screen and (min-width: ${Screens.small}) and (max-width: ${Screens.mediumAbsolute -
      1 +
      'px'}) {
    /* First */
    &:nth-child(3n + 1) {
      flex-basis: 34%;
      flex-grow: 1;
      justify-content: flex-end;
      /* When first is last child */
      &:nth-last-child(1) {
        justify-content: center;
      }
    }
    /* When Second is last child */
    &:nth-last-child(1),
    &:nth-child(3n + 3) {
      flex-basis: 34%;
      flex-grow: 1;
    }
  }
  @media screen and (min-width: ${Screens.medium}) {
    /* First */
    &:nth-child(5n + 1) {
      flex-basis: calc(50% - 250px);
      flex-grow: 1;
      justify-content: flex-end;
      /* When first is last child */
      &:nth-last-child(1) {
        justify-content: center;
      }
    }
    /* When element is last element */
    &:nth-child(5n + 5),
    &:nth-last-child(1) {
      flex-basis: calc(50% - 250px);
      flex-grow: 1;
    }
  }
`;

export const CardContent = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: none;
  line-height: initial;
  display: flex;
  flex-direction: row;
  border-radius: inherit;
  overflow: hidden;
  padding: 4px;

  &:hover {
    transform: none;
  }

  & > div {
    padding: 4px;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;

    & > div {
      color: white;
      margin-right: auto;
      margin-left: 0;
      z-index: 2;

      &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 2px ${Colors.white};
      }
    }
  }
`;

const CardSelectionSectionStyle = styled.div`
  h2 {
    color: ${Colors.white}
    text-align: center;
    margin-top: 30px;
  }
`;

export const HeartWrapper = styled.div<HeartWrapperProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: inherit;
  padding: 8px;
  background-color: ${props => (props.active ? 'rgba(0, 0, 0, 0.3)' : 'transparent')};
  z-index: 1 !important;
  display: flex;
  font-size: 32px;
  font-weight: 600;
  & svg {
    height: 100%;
    width: calc(100% - 16px);
    right: 8px;
    left: 8px;
    position: absolute;
    display: ${props => (props.active ? 'block' : 'none')};
  }
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  padding: 18px 24px;
  background-color: white;
  color: ${Colors.purple62};
  border-radius: 30px;
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.6);
`;

const HoverUpDown = (from: string, to: string) => keyframes`
  0% {
    transform: translateY(${from});
  }
  50% {
    transform: translateY(${to});
  }
  100% {
    transform: translateY(${from});
  }
`;

export const Reminder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  width: 100%;
  height: 95px;
  padding: 14px;
  border-radius: 18px;
  border: 1px solid ${Colors.white};
  position: absolute;
  top: 0;
  margin-top: -105px;
  left: 0;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: ${HoverUpDown('0px', '-3px')};

  &:before,
  &:after {
    top: 100%;
    left: 11%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(213, 0, 0, 0);
    border-top-color: ${Colors.white};
    border-width: 6px;
  }

  span {
    display: block;
    width: 100%;
    height: 5px;
    background: ${Colors.grey33};
    position: static !important;
    opacity: 0.075;
    margin-bottom: 7px;

    &:nth-child(2) {
      width: 75%;
    }

    &:nth-child(3) {
      opacity: 0.15;
      width: 90%;
    }

    &:nth-child(4) {
      opacity: 0.05;
      width: 80%;
    }
  }

  p {
    text-align: left;
    font-size: 12px;
    margin: 0;
  }
  @media screen and (max-width: 320px) and (max-height: 567px) {
    width: 180px;
  }
`;

export const Listbox = styled.fieldset<ListboxProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin-top: ${props => (props.isMarginRequired ? '125px' : '0')};
  border: none;
  &:focus {
    outline: 3px solid red;
    outline-offset: 2px;
  }
`;

export const Option = styled.input<OptionProps>`
  width: 100%;
  height: 80%;
  display: block;
  position: absolute;
  z-index: 3;
  top: 2rem;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 18px;
  opacity: 0;
  cursor: pointer;
`;

export const OptionWrapper = styled.div`
  position: relative;

  input[type='checkbox']:focus + div > div {
    outline: 3px solid red;
    outline-offset: 2px;
  }
`;

export const InfoWrapperContainer = styled.div`
  font-size: 0.9rem;
  background-color: ${Colors.white};
  border: 2px solid ${Colors.purple72}
  position: absolute;
  height: 2em;
  width: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 8px;
  left: 8px;
  border-radius: 100%;
  z-index: 3;
  cursor: help;

  button:focus {
    border: 2px solid ${Colors.black};
    box-shadow: none;
  }

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
`;

export const InfoWrapper = styled.button`
  height: 1.7em;
  width: 1.7em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  border: 1px solid white;
  border-radius: 100%;
  transition: all 250ms ease-in-out 0s;
  padding: 0;

  &:hover {
    background: rgba(0, 0, 0, 0.4);
    -webkit-transform: none;
    transform: none;
  }
`;

export default CardSelectionSectionStyle;
