// IMPORTS
import styled, { keyframes } from 'styled-components';
import ButtonStyles from '../../styles/Button/styles';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';
import CardStyles from '../Card/styles';
import Color from 'color';

// COMPONENTS
const Content = styled.div`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: calc(100vh - 90px - 100px);
  padding: 0 20px;
  position: relative;
`;

const Deck = styled.div`
  display: flex;
  width: 100%;
  height: 430px;
  justify-content: space-between;
`;

const RightToLeft = (from: string, to: string) => keyframes`
  from {
    transform: translateX(${from});
  }
  to {
    transform: translateX(${to});
  }
`;

// MAIN COMPONENT
const LandingStyles = styled.div`
  height: calc(100vh - 90px - 100px);
  flex: 1;
  min-height: 740px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  position: relative;

  ${Content} {
    h1,
    p {
      display: block;
      text-align: center;
    }

    a {
      ${ButtonStyles.toString} {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }

      &:hover {
        text-decoration: none;
      }
    }

    ${ButtonStyles} {
      margin: auto;
      cursor: pointer;
    }

    ${Deck} {
      width: 450vw;
      position: absolute;
      left: 0;
      right: 0;
      top: 260px;
      bottom: 0;
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: ${RightToLeft('0vw', '-450vw')};

      &:focus {
        outline: none;
        border-color: ${Colors.black};
        box-shadow: 0px 0px 0px 2px ${Colors.black};
      }

      &:nth-child(5) {
        left: 450vw;
      }

      ${CardStyles} {
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
        display: none;
        transition: all 250ms ease-in-out 0s;

        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5) {
          display: block;
        }
      }
    }

    @media (min-width: ${Screens.medium}) {
      h1 {
        font-size: 30px;
      }
    }
  }

  @media (min-width: ${Screens.extraSmallest}) {
    ${Content} {
      ${Deck} {
        width: 400vw;

        &:nth-child(4) {
          animation-name: ${RightToLeft('0vw', '-400vw')};
        }

        &:nth-child(5) {
          animation-name: ${RightToLeft('0vw', '-400vw')};
          left: 400vw;
        }
      }
    }
  }

  @media (min-width: ${Screens.extraSmall}) {
    ${Content} {
      ${Deck} {
        width: 300vw;

        &:nth-child(4) {
          animation-name: ${RightToLeft('0vw', '-300vw')};
        }

        &:nth-child(5) {
          animation-name: ${RightToLeft('0vw', '-300vw')};
          left: 300vw;
        }
      }
    }
  }

  @media (min-width: ${Screens.small}) {
    ${Content} {
      h1,
      p {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      ${ButtonStyles} {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
      }

      ${Deck} {
        width: 250vw;

        &:nth-child(4) {
          animation-name: ${RightToLeft('0vw', '-250vw')};
        }

        &:nth-child(5) {
          animation-name: ${RightToLeft('0vw', '-250vw')};
          left: 250vw;
        }
      }
    }

    @media (min-width: ${Screens.medium}) {
      ${Content} {
        ${Deck} {
          top: 280px;
          width: 200vw;

          &:nth-child(4) {
            animation-name: ${RightToLeft('0vw', '-200vw')};
          }

          &:nth-child(5) {
            animation-name: ${RightToLeft('0vw', '-200vw')};
            left: 200vw;
          }
        }
      }
    }

    @media (min-width: ${Screens.large}) {
      ${Content} {
        ${Deck} {
          width: 150vw;

          &:nth-child(4) {
            animation-name: ${RightToLeft('0vw', '-150vw')};
          }

          &:nth-child(5) {
            animation-name: ${RightToLeft('0vw', '-150vw')};
            left: 150vw;
          }

          ${CardStyles} {
            &:hover {
              transform: scale(1.05);
            }
          }
        }
      }
    }

    @media (min-width: ${Screens.extraLarge}) {
      ${Content} {
        ${Deck} {
          width: 140vw;
          animation-duration: 25s;

          &:nth-child(4) {
            animation-name: ${RightToLeft('0vw', '-140vw')};
          }

          &:nth-child(5) {
            animation-name: ${RightToLeft('0vw', '-140vw')};
            left: 140vw;
          }

          ${CardStyles} {
            &:nth-child(6) {
              display: block;
            }
          }
        }
      }
    }

    @media (min-width: ${Screens.extraLargest}) {
      ${Content} {
        ${Deck} {
          width: 100vw;
          animation-duration: 25s;

          &:nth-child(4) {
            animation-name: ${RightToLeft('0vw', '-100vw')};
          }

          &:nth-child(5) {
            animation-name: ${RightToLeft('0vw', '-100vw')};
            left: 100vw;
          }
        }
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    ${Content} {
      padding-top: 60px;
      h1 {
        font-size: 30px;
      }
    }
  }
`;

const Banner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 4px 20px 30px;
  color: white;
  background: ${Colors.purple72}

  a {
    text-decoration: underline;
    color: ${Colors.white};
    border-radius: 18px;
    
    &:hover {
      color: ${Colors.white};
    }

    &:focus {
      outline: none;
      border-color: ${Colors.black};
      box-shadow: 0px 0px 0px 2px
      ${Color(Colors.black)
        .rgb()
        .toString()};
    }
  }

  button {
    display: block;
    margin-left: auto;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
  
    > div {
      background: ${Colors.white};
      display: block;
      width: 20px;
      height: 3px;
      transform-origin: center;
  
      &:nth-child(1) {
        transform: translateY(1.5px) rotate(45deg);
      }
  
      &:nth-child(2) {
        transform: translateY(-1.5px) rotate(-45deg);
      }
    }
  }
`

// EXPORTS
export default LandingStyles;

export { Content, Deck, Banner };
