import i18n from 'i18n-js';
import english from './en.json';
import french from './fr.json';

import { unflatten } from 'flat';

const { localStorage } = window;
const SUPPORTED_LANGUAGES = ['en', 'fr'];

export const initializeI18n = () => {
  let locale = localStorage.getItem('language');

  const en = unflatten(english);
  const fr = unflatten(french);
  i18n.translations = { en, fr };

  if (!locale) {
    const { language, browserLanguage } = navigator;
    locale = SUPPORTED_LANGUAGES.find(lang => (language || browserLanguage).indexOf(lang) === 0) || 'en';
  }

  i18n.locale = locale;
  return locale;
};
