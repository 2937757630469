// IMPORTS
import styled from 'styled-components';
import Color from 'color';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';
import MockButton from '../../styles/MockButton';

// TYPES
interface NavLinkProp {
  active: boolean;
}

interface NavigationStylesProp {
  isDashboard: boolean;
  open: boolean;
  show: boolean;
}

// MAIN COMPONENTS
const Nav = styled.ul`
  margin: 0;
  width: 100%;
  padding: 0;
  white-space: nowrap;
  list-style: none;
  @media screen and (min-width: ${Screens.medium}) {
    width: 300px;
  }
`;

const NavLink = styled.li<NavLinkProp>`
  a {
    display: block;
    width: 100%;
    transition: all 250ms ease-in-out 0s;
    margin-bottom: 20px;

    ${MockButton.toString} {
      width: 100%;
      
      > span {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
  
        svg {
          margin-right: 10px;
        }
      }
    }

  }
`;

const NavLinkIcon = styled.span``;

const NavLinkText = styled.span``;

const NavigationStyles = styled.div<NavigationStylesProp>`
  background: ${props =>
    props.isDashboard
      ? Color(Colors.purple72)
          .fade(0.1)
          .rgb()
          .toString()
      : Color(Colors.white)
          .alpha(0.9)
          .rgb()
          .toString()};
  display: block;
  position: fixed;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${Colors.greyDD};
  transform: ${props => (props.open ? 'translateX(0%)' : 'translateX(-100%)')};
  transition: all 250ms ease-in-out 0s;
  z-index: 90;
  width: 100%;
  padding: 20px 15px;

  @media screen and (min-width: ${Screens.medium}) {
    display: ${props => (props.show ? 'flex' : 'none')};
    overflow-x: hidden;
    width: ${props => (props.open ? '300px' : '0px')};
    position: initial;
  }

  @media screen and (max-width: ${Screens.medium}) {
    top: 70px;
    overflow-y: auto;
  }

  @media screen and (max-width: 380px) {
    max-width: 380px;
  }
`;

// EXPORTS
export default NavigationStyles;

export { Nav, NavLink, NavLinkIcon, NavLinkText };
