import { Dispatch } from 'redux';
import { SSO_REQUEST } from './types';

function doSSO(classlinkCode: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SSO_REQUEST,
      payload: {
        classlinkCode
      }
    });
  };
}

export { doSSO };
