// IMPORTS
import { Dispatch } from 'redux';
import { FORGOT_REQUEST, FORGOT_RESET_ERRORS } from './types';

// MAIN ACTIONS
/**
 *
 * @param email
 */
const doForgot = (email: string) => (dispatch: Dispatch) =>
  dispatch({
    type: FORGOT_REQUEST,
    payload: {
      email
    }
  });

/**
 *
 */
const doForgotResetErrors = () => (dispatch: Dispatch) =>
  dispatch({
    type: FORGOT_RESET_ERRORS
  });

// EXPORTS
export { doForgot, doForgotResetErrors };
