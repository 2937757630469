// IMPORTS
import React from 'react';

// CONTAINER COMPONENTS
import NotFoundContainer from '../../containers/NotFoundContainer';

// MAIN COMPONENT
const NotFoundPage = () => <NotFoundContainer />;

// EXPORTS
export default NotFoundPage;
