// TYPES
const USER_SEARCHKEY_REQUEST = 'Students/USER_SEARCHKEY_REQUEST';
const USER_SEARCHKEY_FAIL = 'Students/USER_SEARCHKEY_FAIL';
const USER_SEARCHKEY_SUCCESS = 'Students/USER_SEARCHKEY_SUCCESS';
const STUDENT_PDF_DOWNLOAD_REQUEST = 'Students/STUDENT_PDF_DOWNLOAD_REQUEST';
const STUDENT_PDF_DOWNLOAD_SUCCESS = 'Students/STUDENT_PDF_DOWNLOAD_SUCCESS';
const STUDENT_PDF_DOWNLOAD_FAIL = 'Students/STUDENT_PDF_DOWNLOAD_FAIL';
const STUDENT_COUNT_REQUEST = 'Students/STUDENT_COUNT_REQUEST';
const STUDENT_COUNT_SUCCESS = 'Students/STUDENT_COUNT_SUCCESS';
const STUDENT_COUNT_FAIL = 'Students/STUDENT_COUNT_FAIL';
const STUDENT_RESULTS_REQUEST = 'Students/STUDENT_RESULTS_REQUEST';
const STUDENT_RESULTS_SUCCESS = 'Students/STUDENT_RESULTS_SUCCESS';
const STUDENT_RESULTS_FAIL = 'Students/STUDENT_RESULTS_FAIL';
const STUDENT_DATA_REQUEST = 'Students/STUDENT_DATA_REQUEST';
const STUDENT_DATA_SUCCESS = 'Students/STUDENT_DATA_SUCCESS';
const STUDENT_DATA_FAIL = 'Students/STUDENT_DATA_FAIL';
const STUDENT_CSV_EXPORT_REQUEST = 'Students/STUDENT_CSV_EXPORT_REQUEST';
const STUDENT_CSV_EXPORT_SUCCESS = 'Students/STUDENT_CSV_EXPORT_SUCCESS';
const STUDENT_CSV_EXPORT_FAIL = 'Students/STUDENT_CSV_EXPORT_FAIL';
const SCHOOL_COUNT_REQUEST = 'Students/SCHOOL_COUNT_REQUEST';
const SCHOOL_COUNT_SUCCESS = 'Students/SCHOOL_COUNT_SUCCESS';
const SCHOOL_COUNT_FAIL = 'Students/SCHOOL_COUNT_FAIL';
const SCHOOL_REQUEST = 'Students/SCHOOL_REQUEST';
const SCHOOL_SUCCESS = 'Students/SCHOOL_SUCCESS';
const SCHOOL_FAIL = 'Students/SCHOOL_FAIL';
const SCHOOL_CSV_EXPORT_REQUEST = 'Students/SCHOOL_CSV_EXPORT_REQUEST';
const SCHOOL_CSV_EXPORT_SUCCESS = 'Students/SCHOOL_CSV_EXPORT_SUCCESS';
const SCHOOL_CSV_EXPORT_FAIL = 'Students/SCHOOL_CSV_EXPORT_FAIL';
const SCHOOL_DELETE_REQUEST = 'Students/SCHOOL_DELETE_REQUEST';
const SCHOOL_DELETE_SUCCESS = 'Students/SCHOOL_DELETE_SUCCESS';
const SCHOOL_DELETE_FAIL = 'Students/SCHOOL_DELETE_FAIL';

// EXPORTS
export {
  USER_SEARCHKEY_REQUEST,
  USER_SEARCHKEY_FAIL,
  USER_SEARCHKEY_SUCCESS,
  STUDENT_PDF_DOWNLOAD_REQUEST,
  STUDENT_PDF_DOWNLOAD_SUCCESS,
  STUDENT_PDF_DOWNLOAD_FAIL,
  STUDENT_COUNT_REQUEST,
  STUDENT_COUNT_SUCCESS,
  STUDENT_COUNT_FAIL,
  STUDENT_RESULTS_REQUEST,
  STUDENT_RESULTS_SUCCESS,
  STUDENT_RESULTS_FAIL,
  STUDENT_CSV_EXPORT_REQUEST,
  STUDENT_CSV_EXPORT_SUCCESS,
  STUDENT_CSV_EXPORT_FAIL,
  SCHOOL_COUNT_REQUEST,
  SCHOOL_COUNT_SUCCESS,
  SCHOOL_COUNT_FAIL,
  SCHOOL_REQUEST,
  SCHOOL_SUCCESS,
  SCHOOL_FAIL,
  SCHOOL_CSV_EXPORT_REQUEST,
  SCHOOL_CSV_EXPORT_SUCCESS,
  SCHOOL_CSV_EXPORT_FAIL,
  SCHOOL_DELETE_REQUEST,
  SCHOOL_DELETE_SUCCESS,
  SCHOOL_DELETE_FAIL,
  STUDENT_DATA_REQUEST,
  STUDENT_DATA_SUCCESS,
  STUDENT_DATA_FAIL
};
