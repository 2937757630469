// IMPORTS
import styled from 'styled-components';
import Colors from '../../../../styles/Colors'

const DeleteSchoolModalBody = styled.div`
  padding: 0 20px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  h2 {
    margin-top: 20px;
  }
`

const DeleteSchoolModalButtons = styled.div`
  margin-top: auto;
  margin-right: -20px;
  padding: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 100%;

  p {
    flex-grow: 1;
    flex-basis: 100%;
    text-align: right;
    margin-bottom: 0;
    color: ${Colors.error}
  }

  button {
    margin: 10px;

    span {
      width: 100%;
      display: inline;
      height: auto;
      line-height: initial;
    }
  }
`

const DeleteSchoolName = styled.span`
  font-weight: bold;
  color: ${Colors.grey33}
`

// EXPORTS
export {
  DeleteSchoolModalBody,
  DeleteSchoolModalButtons,
  DeleteSchoolName
};
