// IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { AppState } from '../../redux/reducer';

// PRESENTATION
import App from '../../components/App';

// CONTAINER
import HeaderContainer from '../HeaderContainer';
import NavigationContainer from '../NavigationContainer';
import { withRouter, RouteComponentProps } from 'react-router';

// ACTIONS

// REDUCERS

// SAGAS

// TYPES
interface Props extends RouteComponentProps {
  authUser: any;
}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const AppContainer: React.FC<Props> = props => {
  // Return
  return (
    <App isDashboard={!!props.authUser}>
      <HeaderContainer />
      <NavigationContainer />
      {props.children}
    </App>
  );
};

// PROPTYPES
AppContainer.propTypes = {
  authUser: PropTypes.any
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  authUser: state.authReducer.get('user')
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // actions here
    },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppContainer));
