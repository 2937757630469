import styled from 'styled-components';
import Colors from '../../styles/Colors';

interface IClasslinkStylesProps {
  isLoading: boolean;
}

export const ClasslinkStyles = styled.div<IClasslinkStylesProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: ${props => (props.isLoading ? 'center' : 'flex-start')};
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
`;

export const ErrorStyles = styled.div`
  text-align: center;
  color: ${Colors.error};
`;
