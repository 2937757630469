// IMPORTS
import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard,
  faUserCircle,
  faLifeRing,
  faClone,
  faSignInAlt,
  faPlusCircle,
  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';
import { FaShoppingCart } from 'react-icons/fa';

// PRESENTATION COMPONENT
import Navigation from '../../components/Navigation';

// REDUCER
import { AppState } from '../../redux/reducer';

// ACTIONS
import { doLogout } from '../LoginContainer/actions';

// TYPES
interface Props {
  state: AppState;
  doLogout: Function;
}

// MAIN COMPONENT
const NavigationContainer: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const helpLink = props.state.accountReducer.get('role') === 'student' ? '/dashboard/support' : '/dashboard/help'
  const baseLinks = [
    {
      icon: <FontAwesomeIcon icon={faLifeRing} />,
      name: translate('NavigationContainer.LinkName.Help'),
      link: helpLink,
      active: window.location.pathname.endsWith(helpLink),
      outline: true
    },
    ...(props.state.accountReducer.get('role') !== 'admin' ? [{
      icon: <FontAwesomeIcon icon={faUserCircle} />,
      name: translate('NavigationContainer.LinkName.Account'),
      link: '/dashboard/account',
      active: window.location.pathname.endsWith('/dashboard/account'),
      outline: true
    }] : [])
  ];

  let links = [
    {
      icon: <FontAwesomeIcon icon={faSignInAlt} />,
      name: translate('NavigationContainer.LinkName.Login'),
      link: '/login',
      active: false,
      outline: true
    },
    {
      icon: <FontAwesomeIcon icon={faPlusCircle} />,
      name: translate('NavigationContainer.LinkName.SignUp'),
      link: '/signup',
      active: false,
      style: 'primary',
      outline: false
    }
  ];
  if (props.state.authReducer.get('user')) {
    if (props.state.accountReducer.get('role') === 'school' || props.state.accountReducer.get('role') === 'admin') {
      links = [
        {
          icon: <FontAwesomeIcon icon={faClipboard} />,
          name: translate('NavigationContainer.LinkName.StudentResults'),
          link: '/dashboard/students',
          active: window.location.pathname.endsWith('/dashboard/students'),
          outline: true
        },
        ...baseLinks,
        {
          icon: <FaShoppingCart />,
          name: translate('NavigationContainer.LinkName.GetCards'),
          link: '/dashboard/getCards',
          active: window.location.pathname.startsWith('/dashboard/getCards'),
          outline: true
        }
      ];
    } else {
      links = [
        {
          icon: <FontAwesomeIcon icon={faClone} />,
          name: translate('NavigationContainer.LinkName.StudentResults'),
          link: '/dashboard/cards',
          active: window.location.pathname.startsWith('/dashboard/cards'),
          outline: true
        },
        ...baseLinks
      ];
    }
    links = [
      ...links,
      {
        icon: <FontAwesomeIcon icon={faSignOutAlt} />,
        name: translate('NavigationContainer.LinkName.LogOut'),
        link: '/logout',
        active: window.location.pathname.startsWith('/logout'),
        outline: true
      }
    ];
  }
  return (
    <Navigation
      router={props.state.router}
      links={links}
      open={props.state.navReducer.get('open')}
      show={false}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doLogout
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);
