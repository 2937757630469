import DashboardStyles from '../Dashboard/styles';
import Colors from '../../styles/Colors';
import styled from 'styled-components';
import ButtonStyles from '../../styles/Button';

const GetCardsStyle = styled(DashboardStyles)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  h2,
  p {
    color: ${Colors.white};
  }

  & * {
    text-align: center;
  }

  & img {
    height: 400px;
    margin-bottom: 20px;
  }
`;

export const BuyLink = ButtonStyles.withComponent('a');

export default GetCardsStyle;
