// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';

// MAIN COMPONENT
const PageLoaderStyles = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${Colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
`;

// EXPORTS
export default PageLoaderStyles;
