import ReactGA from 'react-ga';
import {validateRole} from './middleware'

export function resetAllDimensions() {
  ReactGA.set({
    // Role
    dimension1: null,
    // Code
    dimension2: null
  })
}

export function setDimensions({
  role, 
  code
}: any) {
  ReactGA.set({
    // Role
    ...(role && {dimension1: role}),
    // Code
    ...(code && {dimension2: code}),
  })
}

export default {
  resetAllDimensions,
  setDimensions: validateRole(setDimensions)
}