// IMPORTS
import {
  USER_SEARCHKEY_REQUEST,
  USER_SEARCHKEY_FAIL,
  USER_SEARCHKEY_SUCCESS,
  STUDENT_PDF_DOWNLOAD_REQUEST,
  STUDENT_PDF_DOWNLOAD_SUCCESS,
  STUDENT_PDF_DOWNLOAD_FAIL,
  STUDENT_COUNT_REQUEST,
  STUDENT_COUNT_SUCCESS,
  STUDENT_COUNT_FAIL,
  STUDENT_RESULTS_REQUEST,
  STUDENT_RESULTS_SUCCESS,
  STUDENT_RESULTS_FAIL,
  STUDENT_DATA_REQUEST,
  STUDENT_DATA_SUCCESS,
  STUDENT_DATA_FAIL,
  STUDENT_CSV_EXPORT_REQUEST,
  STUDENT_CSV_EXPORT_SUCCESS,
  STUDENT_CSV_EXPORT_FAIL,
  SCHOOL_COUNT_REQUEST,
  SCHOOL_COUNT_SUCCESS,
  SCHOOL_COUNT_FAIL,
  SCHOOL_FAIL,
  SCHOOL_REQUEST,
  SCHOOL_SUCCESS,
  SCHOOL_CSV_EXPORT_REQUEST,
  SCHOOL_CSV_EXPORT_SUCCESS,
  SCHOOL_CSV_EXPORT_FAIL,
  SCHOOL_DELETE_REQUEST,
  SCHOOL_DELETE_SUCCESS,
  SCHOOL_DELETE_FAIL
} from './types';
import { fromJS } from 'immutable';
import { USER_LOGGED_OUT } from '../LoginContainer/types';

// TYPES
const BASE_STATE: {
  loadingPDF: boolean;
  loading: boolean;
  key: string;
  error: null | { [field: string]: string };
  studentCountLoading: boolean;
  studentCountError: null | { [field: string]: string };
  studentCount: Object | null;
  studentResultsLoading: boolean;
  studentResultsError: null | { [field: string]: string };
  studentResults: Object | null;
  studentDataLoading: boolean;
  studentDataError: null | { [field: string]: string };
  studentData: Object | null;
  studentExportLoading: boolean;
  studentExportError: null | { [field: string]: string };
  schoolCountLoading: boolean;
  schoolCountError: null | { [field: string]: string };
  schoolCount: Object | null;
  schoolLoading: boolean;
  schoolError: null | { [field: string]: string };
  schools: null | Object;
  schoolExportLoading: boolean;
  schoolExportError: null | { [field: string]: string };
  schoolDeleteLoading: boolean;
  schoolDeleteSuccess: boolean;
  schoolDeleteError: null | { [field: string]: string };
} = {
  loadingPDF: false,
  loading: true,
  key: '',
  error: null,
  studentCountLoading: true,
  studentCountError: null,
  studentCount: null,
  studentResultsLoading: true,
  studentResultsError: null,
  studentResults: null,
  studentDataLoading: true,
  studentDataError: null,
  studentData: null,
  studentExportLoading: false,
  studentExportError: null,
  schoolCountLoading: true,
  schoolCountError: null,
  schoolCount: null,
  schoolLoading: true,
  schoolError: null,
  schools: null,
  schoolExportLoading: false,
  schoolExportError: null,
  schoolDeleteLoading: false,
  schoolDeleteSuccess: false,
  schoolDeleteError: null
};

type STUDENT_STATE = typeof BASE_STATE;

export type STUDENT_REDUCER = ImmutableMap<STUDENT_STATE>;

const INITIAL_STATE = fromJS(BASE_STATE) as STUDENT_REDUCER;

// REDUCER
const studentsReducer = (state = INITIAL_STATE, { payload, type }: ReduxAction) => {
  switch (type) {
    case USER_LOGGED_OUT:
      return INITIAL_STATE;
    case STUDENT_CSV_EXPORT_REQUEST:
      return state.set('studentExportLoading', true);
    case STUDENT_CSV_EXPORT_SUCCESS:
      return state.set('studentExportLoading', false).set('studentExportError', null);
    case STUDENT_CSV_EXPORT_FAIL:
      return state.set('studentExportError', fromJS(payload)).set('studentExportLoading', false);
    case SCHOOL_CSV_EXPORT_REQUEST:
      return state.set('schoolExportLoading', true);
    case SCHOOL_CSV_EXPORT_SUCCESS:
      return state.set('schoolExportLoading', false).set('schoolExportError', null);
    case SCHOOL_CSV_EXPORT_FAIL:
      return state.set('schoolExportError', fromJS(payload)).set('schoolExportLoading', false);
    case STUDENT_RESULTS_REQUEST:
      return state.set('studentResultsLoading', true);
    case STUDENT_RESULTS_SUCCESS:
      return state.set('studentResultsLoading', false).set('studentResults', payload);
    case STUDENT_RESULTS_FAIL:
      return state.set('studentResultsError', fromJS(payload)).set('studentResultsLoading', false);
    case STUDENT_DATA_REQUEST:
      return state.set('studentDataLoading', true);
    case STUDENT_DATA_SUCCESS:
      return state.set('studentDataLoading', false).set('studentData', payload);
    case STUDENT_DATA_FAIL:
      return state.set('studentDataError', fromJS(payload)).set('studentDataLoading', false);
    case STUDENT_COUNT_REQUEST:
      return state.set('studentCountLoading', true);
    case STUDENT_COUNT_SUCCESS:
      return state.set('studentCountLoading', false).set('studentCount', payload);
    case STUDENT_COUNT_FAIL:
      return state.set('studentCountError', fromJS(payload)).set('studentCountLoading', false);
    case SCHOOL_COUNT_REQUEST:
      return state.set('schoolCountLoading', true);
    case SCHOOL_COUNT_SUCCESS:
      return state.set('schoolCountLoading', false).set('schoolCount', payload);
    case SCHOOL_COUNT_FAIL:
      return state.set('schoolCountError', fromJS(payload)).set('schoolCountLoading', false);
    case SCHOOL_REQUEST:
      return state.set('schoolLoading', true);
    case SCHOOL_SUCCESS:
      return state.set('schoolLoading', false).set('schools', payload);
    case SCHOOL_FAIL:
      return state.set('schoolError', fromJS(payload)).set('schoolLoading', false);
    case USER_SEARCHKEY_REQUEST:
      return state.set('loading', true);
    case USER_SEARCHKEY_SUCCESS:
      return state.set('loading', false).set('key', payload.key);
    case USER_SEARCHKEY_FAIL:
      return state.set('error', fromJS(payload)).set('loading', false);
    case STUDENT_PDF_DOWNLOAD_REQUEST:
      return state.set('loadingPDF', true);
    case STUDENT_PDF_DOWNLOAD_FAIL:
    case STUDENT_PDF_DOWNLOAD_SUCCESS:
      return state.set('loadingPDF', false);
    case SCHOOL_DELETE_REQUEST:
      return state
        .set('schoolDeleteLoading', true)
        .set('schoolDeleteError', null)
        .set('schoolDeleteSuccess', false);
    case SCHOOL_DELETE_SUCCESS:
      return state.set('schoolDeleteLoading', false).set('schoolDeleteSuccess', true);
    case SCHOOL_DELETE_FAIL:
      return state
        .set('schoolDeleteError', fromJS(payload))
        .set('schoolDeleteLoading', false)
        .set('schoolDeleteSuccess', false);
    default:
      return state;
  }
};

// EXPORTS
export { studentsReducer };
