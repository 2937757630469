// IMPORTS
import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';

// STYLE COMPONENTS
import LanguageButtonStyle from './styles';

// TYPES
interface IProps {
  isDashboard: boolean;
}

// MAIN COMPONENT
const LanguageButton = (props: IProps) => {
  const { toggleLanguage, language, translate } = useContext(LanguageContext);

  return (
    <LanguageButtonStyle isDashboard={props.isDashboard} onClick={toggleLanguage} aria-label={translate('AriaLabel.ChangeLocale')}>
      {language.toUpperCase()}
    </LanguageButtonStyle>
  );
};

// EXPORTS
export default LanguageButton;
