// IMPORTS
import React from 'react';

// CONTAINAERS
import CardResultContainer from '../../../containers/CardResultContainer';

// COMPONENT
const CardResult = () => <CardResultContainer />;

// EXPORTS
export default CardResult;
