import styled from 'styled-components';
type ToggleProps = {
  height: number;
  open: boolean;
  shouldOverflow: boolean;
};
const ToggleStyle = styled.div<ToggleProps>`
  transition: ${props => (props.open ? 'height 0.3s ease-in-out, visibility 0s ease' : 'height 0.3s ease-in-out, visibility 0s ease 0.3s')};
  visibility: ${props => (props.open ? 'visibile' : 'hidden')};
  height: ${props => (props.open ? props.height : 0)}px;
  overflow-y: ${props => (props.shouldOverflow ? 'auto' : 'hidden')};
  width: 100%;
`;
export default ToggleStyle;
