import { useState } from 'react';
import { CustomCard } from '../../../types/customCard';

export const MAX_CUSTOM_CARDS_ALLOWED = 3;

export function useCustomCards() {
  const [tempCustomCards, setTempCustomCards] = useState<CustomCard[]>([]);
  return {
    tempCustomCards,
    setTempCustomCards
  };
}
