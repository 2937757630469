import BackOfCardReminder from '../../components/BackOfCardReminder';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ push }, dispatch);
const BackOfCardReminderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfCardReminder);
export default BackOfCardReminderContainer;
