// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';

// TYPES
interface IProps {
  color: string;
}

interface LikeButtonToolTipProps {
  active?: boolean;
}

// MAIN COMPONENTS
const LikeButtonToolTip = styled('span')<LikeButtonToolTipProps>`
  opacity: 0;
  width: 160px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
  color: ${Colors.grey33};
  font-size: 14px;
  position: absolute;
  bottom: -50px;
  border: 1px solid ${Colors.greyE6};
  border-radius: 20px;
  line-height: 18px;
  padding: 6px 10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  transition: all 250ms ease-in-out 250ms;

  &:hover {
    opacity: 1;
  }
  
  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: ${Colors.white};
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-bottom-color: ${Colors.greyE6};
    border-width: 7px;
    margin-left: -7px;
  }
`;

const LikeButtonStyle = styled('button')<IProps>`
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.color};
  border: none;
  transition: transform 0.1s ease-in-out;
  outline: none;
  position: relative;

  &:active {
    transform: scale(0.9);
  }

  & + & {
    margin-left: 8px;
  }

  span {
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 30px;
      path {
        fill: ${Colors.white};
      }

      @media (max-width: 320px) {
        width: 24px;
      }
    }
  }
  @media (min-width: ${Screens.medium}) {
    &:hover {
      transform: scale(1.05);

      ${LikeButtonToolTip} {
        display: flex;
        opacity: 1;
      }
    }
  }

  @media (max-width: 320px) {
    width: 40px;
    height: 40px;
    padding: 20px;
  }
`;

// EXPORTS
export default LikeButtonStyle;

export { LikeButtonToolTip };
