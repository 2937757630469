// IMPORTS
import React from 'react';

// CONTAINERS
import LoginContainer from '../../containers/LoginContainer';

// COMPONENT
const Login = () => <LoginContainer />;

// EXPORTS
export default Login;
