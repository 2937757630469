// IMPORTS
import React, { useContext, Ref, useRef, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import LanguageContext from '../../locale/LanguageContext';

// CONTAINER COMPONENTS
import Modal from '../Modal';

import Input from '../../components/Input';

// STYLE COMPONENT
import { SignupLeftStyles, SignupRightStyles, SignupStyles } from './styles';
import { InputLabelStyles } from '../../components/Input/styles';
import Loader from '../../styles/Loader';
import Button from '../../styles/Button';
import Error from '../../styles/Error';
import Link from '../../styles/Link';
import RadioInput from '../../styles/RadioInput';
import Success from '../../styles/Success';

// ACTIONS
import { doSignup } from '../../containers/SignupContainer/actions';
import { SelectInput } from '../../styles/Input/styles';

// TYPES
interface StateProps {
  type: string;
  subscription: string;
}

interface IProps {
  hideHeader?: boolean;
  loading: boolean;
  contactSubmissionSuccess?: boolean;
  error?: string;
  title?: string;
  show: boolean;
  onModalCancel: Function;
  onFormSubmit: Function;
  inputState: StateProps;
  onInputChange?: Function;
  updateState?: Function;
  firstnameRef: Ref<any>;
  lastnameRef: Ref<any>;
  emailRef: Ref<any>;
  passwordRef: Ref<any>;
  confirmPasswordRef: Ref<any>;
  codeRef: Ref<any>;
  addressRef: Ref<any>;
  cityRef: Ref<any>;
  stateRef: Ref<any>;
  countryRef: Ref<any>;
  postalzipRef: Ref<any>;
  phoneRef: Ref<any>;
  schoolnameRef: Ref<any>;
}

interface dispatchProps {
  doSignup: typeof doSignup;
}

interface stateProps {}

type FinalProps = IProps & dispatchProps & stateProps;

// MAIN COMPONENT
const Signup: React.FC<FinalProps> = props => {
  const { translate } = useContext(LanguageContext);
  const roleSelectRef = useRef<HTMLSelectElement>(null)
  const onRoleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (props.updateState) {
      props.updateState({
        type: event.target.value,
        ...(event.target.value === 'Student' && {subscription: ''})
      });
    }
  };

  function handleSubscriptionChange (event: React.ChangeEvent<HTMLInputElement>) {
    if (props.updateState) {
      props.updateState({
        subscription: event.target.value
      });
    }
  }

  const onModalShow = useCallback(() => {
    if (roleSelectRef && roleSelectRef.current) {
      roleSelectRef.current.focus()
    }
  }, [roleSelectRef])
  
  // Render
  return (
    <DocumentTitle title={`${translate('Header.SignUp')} | ${translate('document.title')}`}>
      <Modal
        maxHeight={700}
        maxWidth={900}
        width={null}
        minHeight={null}
        hideHeader={props.hideHeader}
        loading={props.loading}
        show={props.show}
        onCancel={props.onModalCancel}
        onShow={onModalShow}
      >
        <SignupStyles>
          <SignupLeftStyles></SignupLeftStyles>
          <SignupRightStyles>
            <h2>{translate('Signup.Heading')}</h2>
            <p>
              {translate('Signup.Paragraph.PartOne')}
              <Link doInherit={true} to={'/login'}>{translate('Signup.Link.PartOne')}</Link>
            </p>
            <hr />
            <form onSubmit={event => props.onFormSubmit(event)} noValidate>
              <InputLabelStyles htmlFor="role" required>{translate('Signup.SelectRole.Label')}</InputLabelStyles>
              <SelectInput
                id="role"
                disabled={props.loading}
                required
                name="role"
                ref={roleSelectRef}
                value={props.inputState.type}
                onChange={onRoleChange}
              >
                <option value='Student'>{translate('Signup.SelectRole.Student')}</option>
                <option value='School'>{translate('Signup.SelectRole.School')}</option>
              </SelectInput>
              <hr />
              {props.inputState.type === "School" && (
                <>
                  <InputLabelStyles required>{translate('Signup.SubscriptionChoice.Title')}</InputLabelStyles>
                  <RadioInput
                    name="subscription"
                    id="subscription-1"
                    value="purchased"
                    checked={props.inputState.subscription === 'purchased'}
                    onChange={handleSubscriptionChange}
                    disabled={props.loading}
                    label={translate('Signup.SubscriptionChoice.PurchasedOptionLabel')}
                  />
                  <RadioInput
                    name="subscription"
                    id="subscription-2"
                    value="contact"
                    checked={props.inputState.subscription === 'contact'}
                    onChange={handleSubscriptionChange}
                    containerStyles={{marginBottom: '20px'}}
                    disabled={props.loading}
                    label={translate('Signup.SubscriptionChoice.ContactOptionLabel')}
                  />
                  <hr />
                </>
              )}
              {((props.inputState.type === "School" && props.inputState.subscription) || props.inputState.type === "Student") && (
                <>
                  <Input
                    disabled={props.loading}
                    label={translate('Signup.InputFirstName.Label')}
                    placeholder={translate('Signup.InputFirstName.Placeholder')}
                    type="text"
                    required
                    name="firstname"
                    forwardRef={props.firstnameRef}
                    onChange={props.onInputChange && props.onInputChange('firstname')}
                    autoComplete="given-name"
                  />
                  <Input
                    disabled={props.loading}
                    label={translate('Signup.InputLastName.Label')}
                    placeholder={translate('Signup.InputLastName.Placeholder')}
                    type="text"
                    required
                    name="lastname"
                    forwardRef={props.lastnameRef}
                    onChange={props.onInputChange && props.onInputChange('lastname')}
                    autoComplete="family-name"
                  />
                  <Input
                    disabled={props.loading}
                    label={translate('Signup.InputEmail.Label')}
                    placeholder={translate('Signup.InputEmail.Placeholder')}
                    type="text"
                    required
                    name="email"
                    forwardRef={props.emailRef}
                    onChange={props.onInputChange && props.onInputChange('email')}
                    autoComplete="email"
                  />
                  <hr />
                  {props.inputState.type === 'Student' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputCode.Label')}
                      placeholder={translate('Signup.InputCode.Placeholder')}
                      type="text"
                      required
                      name="code"
                      forwardRef={props.codeRef}
                      onChange={props.onInputChange && props.onInputChange('code')}
                    />
                  )}
                  {props.inputState.type === 'School' && props.inputState.subscription !== 'contact' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputSchool.Label')}
                      placeholder={translate('Signup.InputSchool.Placeholder')}
                      type="text"
                      required
                      name="address"
                      forwardRef={props.addressRef}
                      onChange={props.onInputChange && props.onInputChange('address')}
                    />
                  )}
                  {props.inputState.type === 'School' && props.inputState.subscription !== 'contact' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputCity.Label')}
                      placeholder={translate('Signup.InputCity.Placeholder')}
                      type="text"
                      required
                      name="city"
                      forwardRef={props.cityRef}
                      onChange={props.onInputChange && props.onInputChange('city')}
                    />
                  )}
                  {props.inputState.type === 'School' && props.inputState.subscription !== 'contact' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputState.Label')}
                      placeholder={translate('Signup.InputState.Placeholder')}
                      type="text"
                      required
                      name="state"
                      forwardRef={props.stateRef}
                      onChange={props.onInputChange && props.onInputChange('state')}
                    />
                  )}
                  {props.inputState.type === 'School' && props.inputState.subscription !== 'contact' && (
                    <>
                      <InputLabelStyles required>{translate('Signup.Select.Label')}</InputLabelStyles>
                      <SelectInput
                        disabled={props.loading}
                        placeholder={translate('Signup.Select.Placeholder')}
                        type="text"
                        required
                        name="country"
                        ref={props.countryRef}
                        onChange={props.onInputChange && props.onInputChange('country')}
                      >
                        <option value={'usa'}>{translate('country.USA')}</option>
                        <option value={'can'}>{translate('country.Canada')}</option>
                        <option value={'other'}>{translate('country.Other')}</option>
                      </SelectInput>
                    </>
                  )}
                  {props.inputState.type === 'School' && props.inputState.subscription !== 'contact' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputPostalCode.Label')}
                      placeholder={translate('Signup.InputPostalCode.Placeholder')}
                      type="text"
                      required
                      name="postalzip"
                      forwardRef={props.postalzipRef}
                      onChange={props.onInputChange && props.onInputChange('postalzip')}
                    />
                  )}
                  {props.inputState.type === 'School' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputSchoolName.Label')}
                      placeholder={translate('Signup.InputSchoolName.Placeholder')}
                      type="text"
                      required
                      name="schoolname"
                      forwardRef={props.schoolnameRef}
                      onChange={props.onInputChange && props.onInputChange('schoolname')}
                    />
                  )}
                  {props.inputState.type === 'School' && (
                    <Input
                      disabled={props.loading}
                      label={translate('Signup.InputPhoneNumber.Label')}
                      placeholder={translate('Signup.InputPhoneNumber.Placeholder')}
                      type="text"
                      required
                      name="phone"
                      forwardRef={props.phoneRef}
                      onChange={props.onInputChange && props.onInputChange('phone')}
                    />
                  )}
                  {props.inputState.subscription !== 'contact' && (
                    <>
                      <Input
                        disabled={props.loading}
                        label={translate('Signup.InputPassword.Label')}
                        placeholder={'••••••••'}
                        type="password"
                        required
                        name="password"
                        forwardRef={props.passwordRef}
                        onChange={props.onInputChange && props.onInputChange('password')}
                      />
                      <Input
                        disabled={props.loading}
                        label={translate('Signup.InputConfirmPassword.Label')}
                        placeholder={'••••••••'}
                        type="password"
                        required
                        name="confirmPassword"
                        forwardRef={props.confirmPasswordRef}
                        onChange={props.onInputChange && props.onInputChange('confirmPassword')}
                      />
                    </>
                  )}
                  {props.contactSubmissionSuccess && (
                    <Success>
                      <span>
                        {translate('Signup.Contact.SuccessMessage')}
                      </span>
                    </Success>
                  )}
                  {props.error && (
                    <Error>
                      <span role="alert">
                        {props.error.split('\n').map(e => translate(e, {defaultValue: e})).join(' ')}
                      </span>
                    </Error>
                  )}
                  <p>
                    <Button type="submit" disabled={props.loading} styleType="primary">
                      {props.inputState.subscription === 'contact' ? translate('Button.Submit') : translate('Signup.Button.SignUp')}
                    </Button>
                  </p>
                </>
              )}
              <p>
                <small>
                  {translate('Signup.AlreadyMember.Label')}{' '}
                  <Link doInherit={true} to={'/login'}>{translate('Signup.AlreadyMember.Link')}</Link>
                </small>
              </p>
            </form>
            {props.loading && <Loader />}
          </SignupRightStyles>
        </SignupStyles>
      </Modal>
    </DocumentTitle>
  );
};

Signup.defaultProps = {
  hideHeader: true
};

// EXPORTS
export default Signup;
