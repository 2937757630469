// IMPORTS
import React from 'react';

// CONTAINERS
import ForgotContainer from '../../containers/ForgotContainer';

// MAIN COMPONENT
const Forgot = () => <ForgotContainer />;

// EXPORTS
export default Forgot;
