import React, { useRef, useEffect, useState, useLayoutEffect } from 'react';
import ToggleStyles from './styles';

interface IProps {
  open: boolean;
  style?: React.StyleHTMLAttributes<any>;
  className?: string;
  children: React.ReactChildren | React.ReactChild;
}

interface StateProps {}

interface DispatchProps {}

type Props = IProps & StateProps & DispatchProps;
const Toggle: React.FC<Props> = props => {
  const [height, setHeight] = useState(0);
  const [overflowAuto, setOverflowAuto] = useState(false);
  const countChildren = React.Children.count(props.children);

  const child = useRef<HTMLElement>(null);

  useEffect(() => {
    if (child && child.current) {
      setHeight(child.current.scrollHeight);
    }
  }, [child, height, props.open]);

  useLayoutEffect(() => {
    if (child && child.current) {
      setHeight(child.current.scrollHeight);
    }
  }, [height]);

  useEffect(() => {
    // If opening, wait on animation
    if (props.open) {
      setTimeout(() => {
        setOverflowAuto(true);
      }, 350);
      // If closing, change immediately
    } else {
      setOverflowAuto(false);
    }
  }, [props.open]);

  if (countChildren === 0) return null;
  let childElement;
  if (countChildren === 1) {
    if (React.isValidElement(props.children)) {
      childElement = React.cloneElement(props.children, { ref: child });
    }
  } else {
    const Wrapper = <div />;
    childElement = React.cloneElement(Wrapper, { ref: child, children: props.children });
  }

  return (
    <ToggleStyles
      height={height}
      open={props.open}
      className={props.className}
      style={props.style}
      shouldOverflow={overflowAuto}
    >
      {childElement}
    </ToggleStyles>
  );
};
export default Toggle;
