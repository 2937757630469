// IMPORTS
import { put, takeLeading } from 'redux-saga/effects';
import firebase from '../../firebase';
import { isEmailValid } from '../../util/validation';

// TYPES
import { FORGOT_REQUEST, FORGOT_REQUEST_SUCCESS, FORGOT_REQUEST_FAILURE } from './types';

// SAGAS
/**
 *
 * @param param0
 */
function* workerForgotPassword({ payload }: ReduxAction) {
  try {
    // Get payload
    const { email } = payload;

    if (!email) { throw new Error('error.emailRequired') }
    if (!isEmailValid(email)) { throw new Error('auth/invalid-email') }

    // Request
    yield firebase.auth().sendPasswordResetEmail(email, {
      url: `${window.location.origin}/login`
    });

    // Success
    yield put({
      type: FORGOT_REQUEST_SUCCESS
    });
  } catch (e) {
    let message = e.message
    if (e.code === 'auth/invalid-email' || e.code === 'auth/user-not-found') {
      message = e.code
    }
    
    // Failture
    yield put({
      type: FORGOT_REQUEST_FAILURE,
      payload: { errors: message }
    });
  }
}

/**
 *
 */
function* watcherForgotPassword() {
  yield takeLeading(FORGOT_REQUEST, workerForgotPassword);
}

// EXPORTS
export { watcherForgotPassword };
