import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function downloadResults() {
  ReactGA.event({
    category: 'Results',
    action: 'Get results',
    label: 'Download'
  })
}

export function emailResults() {
  ReactGA.event({
    category: 'Results',
    action: 'Get results',
    label: 'Email'
  })
}

export default {
  downloadResults: validateRole(downloadResults),
  emailResults: validateRole(emailResults)
}