import React, { FC, useEffect, useContext, useState } from "react";
import ModalBackground from '../../../ModalBackground'
import Modal from '../../../Modal'
import Button from '../../../../styles/Button';
import Loader from "../../../../styles/Loader";
import LanguageContext from '../../../../locale/LanguageContext';

import {
  DeleteSchoolModalBody,
  DeleteSchoolModalButtons,
  DeleteSchoolName
} from './styles'

interface IDeleteSchoolModalProps {
  school: Object | null;
  onCancel: Function;
  deleteSchool: Function;
  show: boolean;
  isLoading: boolean;
  error: any;
}

const DeleteSchoolModal: FC<IDeleteSchoolModalProps> = (props) => {
  const {show, onCancel, school, deleteSchool, isLoading, error: propError} = props
  const {schoolname: schoolName, id} = (school as any) || {}
  const { translate } = useContext(LanguageContext);
  const [error, setError] = useState(propError)

  useEffect(() => {
    if (!show) {
      setError(null)
    }
  }, [show])

  useEffect(() => {
    setError(propError)
  }, [propError])

  useEffect(() => {
    if (show) {
      const escapeHandler: any = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onCancel();
        }
      }
  
      document.body.addEventListener('keyup', escapeHandler);
  
      return () => {
        document.body.removeEventListener('keyup', escapeHandler)
      }
    }
  }, [onCancel, show]);

  return (
    <>
      <ModalBackground isDashboard={true} show={show}/>
      <Modal
        show={show}
        hideHeader={true}
        loading={false}
        minHeight={null}
        maxHeight={250}
        onCancel={onCancel}>
          <DeleteSchoolModalBody>
            <h2>{translate('Students.DeleteSchool.ModalTitle')}</h2>
            <p>
              {translate('Students.DeleteSchool.ModalDescription.PartOne')} <DeleteSchoolName>{schoolName}</DeleteSchoolName> {translate('Students.DeleteSchool.ModalDescription.PartTwo')}
            </p>
            <DeleteSchoolModalButtons>
              {error && (
                <p>{translate(error, {defaultValue: error})}</p>
              )}
              <Button 
                styleType="secondary" 
                outline 
                disabled={isLoading}
                onClick={() => onCancel()}>
                {translate('Students.DeleteSchool.ModalCancel')}
              </Button>
              <Button 
                styleType="danger" 
                disabled={isLoading}
                onClick={() => deleteSchool(id)}
                isLoading={isLoading}>
                  <Loader white/>
                  <span>{translate('Students.DeleteSchool.ModalConfirm')}</span>
              </Button>
            </DeleteSchoolModalButtons>
          </DeleteSchoolModalBody>
      </Modal>
    </>
  )
}

export default DeleteSchoolModal