import React, { useContext } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import GetCardsStyles, { BuyLink } from './styles';
import { CardStack } from '../../styles/assets';

interface IProps {}
interface StateProps {}
interface DispatchProps {}

type Props = IProps & StateProps & DispatchProps;
const GetCards: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  return (
    <GetCardsStyles>
      <h2>{translate('GetCards.Heading')}</h2>
      <p>{translate('GetCards.Paragraph')}</p>
      <img src={CardStack} alt={translate('GetCards.ImgAlt')} />
      <BuyLink
        styleType="primary-white"
        href="https://mysparkpath.com/collections/all"
        target="_blank"
      >
        {translate('GetCards.BuyLink')}
      </BuyLink>
    </GetCardsStyles>
  );
};
export default GetCards;
