// IMPORTS
import React, { useContext, useRef, useCallback, useState } from 'react';
import DocumentTitle from 'react-document-title';
import LanguageContext from '../../locale/LanguageContext';

// CONTAINER COMPONENTS
import Modal from '../Modal';
import Link from '../../styles/Link';
import { Container, ForgotStyles, Success } from './styles';
import Input from '../../components/Input';
import Button from '../../styles/Button';
import Error from '../../styles/Error';
import { AppState } from '../../redux/reducer';

// TYPES
interface StateProps {
  email: string;
}

interface IProps {
  loading: boolean;
  error?: string;
  title?: string;
  subTitle?: string;
  show: boolean;
  success?: boolean;
  onModalCancel: Function;
  onFormSubmit: Function;
  inputState: StateProps;
  onInputChange?: Function;
}

interface dispatchProps {
  doForgot: Function;
  doReset: Function;
}

interface stateProps {
  state: AppState;
}

type FinalProps = IProps & dispatchProps & stateProps;

// MAIN COMPONENT
const Forgot: React.FC<FinalProps> = props => {
  const { translate } = useContext(LanguageContext);
  const inputEmailRef = useRef<HTMLInputElement | null>(null)
  const [submittedEmail, setSubmittedEmail] = useState('')

  const onModalShow = useCallback(() => {
    if (inputEmailRef && inputEmailRef.current) {
      inputEmailRef?.current?.focus()
    }
  }, [inputEmailRef])

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    props.onFormSubmit(event)
    setSubmittedEmail(props.inputState.email)
  }
  
  return (
    <DocumentTitle title={`${translate('Header.ForgotPassword')} | ${translate('document.title')}`}>
      <Modal
        hideHeader={true}
        loading={props.loading}
        show={props.show}
        onCancel={props.onModalCancel}
        minHeight={null}
        width={440}
        onShow={onModalShow}
      >
        <ForgotStyles>
          <Container>
            <h2>{(props.title && props.title) || ''}</h2>
            <p>{(props.subTitle && props.subTitle) || ''}</p>
            <hr />
            <form onSubmit={onSubmit} noValidate>
              <Input
                disabled={props.loading}
                label={translate('Forgot.InputEmail.Label')}
                placeholder={translate('Forgot.InputEmail.Placeholder')}
                name={'email'}
                type={'email'}
                required
                forwardRef={inputEmailRef}
                value={props.inputState.email}
                onChange={props.onInputChange && props.onInputChange('email')}
                autoComplete="email"
              />
              {props.error && (
                <Error>
                  <span role="alert">{translate(props.error, {defaultValue: props.error})}</span>
                </Error>
              )}
              {props.success && (
                <Success>
                  <div>
                    {translate('Forgot.SuccessMessage.PartOne')}
                    <strong> {submittedEmail}</strong>
                  </div>
                  <div>{translate('Forgot.SuccessMessage.PartTwo')}</div>
                </Success>
              )}
              <p>
                <Button type={'submit'} disabled={props.loading}>
                  {translate('Forgot.Button.Submit')}
                </Button>
                <small>
                  <Link doInherit={true} to={'/login'}>{translate('Forgot.Link.Back')}</Link>
                </small>
              </p>
              <p>
                <small id="centre">
                  {translate('Forgot.Link.NotAMember')}{' '}
                  <Link doInherit={true} to={'/signup'}>{translate('Forgot.Link.SignUpNow')}</Link>
                </small>
              </p>
            </form>
          </Container>
        </ForgotStyles>
      </Modal>
    </DocumentTitle>
  );
};

// EXPORTS
export default Forgot;
