// REDUX TYPES
const SIGNUP_REQUEST = 'Auth/SIGNUP_REQUEST';
const SIGNUP_SUCCESS = 'Auth/SIGNUP_SUCCESS';
const SIGNUP_FAIL = 'Auth/SIGNUP_FAIL';
const SIGNUP_RESET_ERRORS = 'Auth/SIGNUP_RESET_ERRORS';

const DO_SIGNUP = 'Auth/DO_SIGNUP';
const SIGNUP_STARTED = 'Auth/SIGNUP_STARTED';
const USER_SIGNED_UP = 'Auth/USER_SIGNED_UP';
const USER_SIGNUP_ERROR = 'Auth/USER_SIGNUP_ERROR';

const CONTACT_SUBMISSION_REQUEST = 'Auth/CONTACT_SUBMISSION_REQUEST';
const CONTACT_SUBMISSION_SUCCESS = 'Auth/CONTACT_SUBMISSION_SUCCESS';
const CONTACT_SUBMISSION_ERROR = 'Auth/CONTACT_SUBMISSION_ERROR';
const CONTACT_SUBMISSION_RESET = 'Auth/CONTACT_SUBMISSION_RESET';

// EXPORTS
export {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  DO_SIGNUP,
  SIGNUP_STARTED,
  USER_SIGNED_UP,
  USER_SIGNUP_ERROR,
  SIGNUP_RESET_ERRORS,
  CONTACT_SUBMISSION_ERROR,
  CONTACT_SUBMISSION_REQUEST,
  CONTACT_SUBMISSION_SUCCESS,
  CONTACT_SUBMISSION_RESET
};
