import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function login() {
  ReactGA.event({
    category: 'Authentication',
    action: 'Login'
  })
}

export function logout() {
  ReactGA.event({
    category: 'Authentication',
    action: 'Log out'
  })
}

export function timeout() {
  ReactGA.event({
    category: 'Authentication',
    action: 'Timeout',
    nonInteraction: true
  })
}

export function signup() {
  ReactGA.event({
    category: 'Authentication',
    action: 'Signup'
  })
}

export default {
  login: validateRole(login),
  logout: validateRole(logout),
  timeout: validateRole(timeout),
  signup: validateRole(signup)
}