import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';
import Success from '../../styles/Success';
import Error from '../../styles/Error';

const CardStyles = styled.div`
  padding: 20px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  h1 {
    color: ${Colors.white};
    text-align: center;
  }

  & h3 {
    text-align: center;
    margin-top: 0;
    font-weight: 500;
    color: ${Colors.white};
  }

  & h4 {
    margin-top: 0;
    margin-bottom: 1rem
    font-weight: 500;
    text-align: center;
    color: ${Colors.white};
  }

  p {
    color: ${Colors.purpleDF};
  }

  .card-result__video-container {
    height: 24.75rem;
    width: 44rem;

    @media (max-width: 800px) {
      height: 18.5625rem;
      width: 33rem;
    }

    @media (max-width: 600px) {
      height: 12.375rem;
      width: 22rem;
    }
  }

  iframe {
    height: 100%;
    width: 100%;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: ${Screens.medium}) {
    flex-direction: row;
  }
  & > div {
    margin: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;

  ${Success}, ${Error} {
    margin-bottom: 16px;
  }

  button {
    margin-bottom: 16px;
    line-height: 1;

    &:nth-of-type(2) {
      margin-bottom: 8px;
    }
  }
`;

export const PlaceholderContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  @media screen and (min-width: 1100px) {
    flex-direction: column;
    width: 50%;
  }
  & > div {
    margin: 8px 8px 20px 8px;
    & > button {
      margin: 5px;
    }
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 14px;

  h1,
  h2 {
    margin: 12px;
    color: ${Colors.white};
    font-weight: 600;
  }

  img {
    margin-top: 32px;
    margin-bottom: 32px;
    height: 200px;
  }

  a,
  a:hover,
  a:visited {
    color: ${Colors.white};
    text-decoration: underline;
    margin-bottom: 64px;
  }

  ${Success} {
    margin: 0 0 16px 0;
    text-align: left;

    @media (min-width: 992px) {
      width: 910px;
    }

    @media (min-width: 1200px) {
      width: 1110px;
    }
  }
`;

export const CardWrapper = styled.div`
  margin: auto;
  width: 220px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  & span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 15%;
    background-color: ${Colors.white};
    color: ${Colors.black};
    right: 0;
    left: 0;
    min-height: 2.5rem;
    padding: 0.5rem;
  }
`;

export default CardStyles;
