// IMPORTS
import React from 'react';

// CONTAINERS
import InstructionsContainer from '../../../containers/InstructionsContainer';

// COMPONENT
const Instructions = () => <InstructionsContainer />;

// EXPORTS
export default Instructions;
