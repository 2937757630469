// IMPORTS
import styled from 'styled-components';
import Colors from '../Colors';

// MAIN COMPONENT
const Error = styled.div`
  background: ${Colors.error};
  border-radius: 15px;
  padding: 15px;

  font-size: 14px;
  color: ${Colors.errorFF};
`;

// EXPORTS
export default Error;
