import { AppState } from '../../redux/reducer';
import { connect } from 'react-redux';
import { doSSO } from './actions';
import Classlink from '../../components/Classlink';

const mapStateToProps = (state: AppState) => {
  return {
    classlinkReducer: state.classlinkReducer,
    authReducer: state.authReducer
  };
};

const mapDispatchToProps = {
  doSSO
};

const ClasslinkContainer = connect(mapStateToProps, mapDispatchToProps)(Classlink);

export default ClasslinkContainer;
