// IMPORTS
import { fromJS } from 'immutable';
import { SSO_REQUEST, SSO_FAIL } from './types';
import { USER_LOGGED_IN } from '../LoginContainer/types';
import { AUTH_STOP_LOADING } from '../Auth/types';

interface ErrorObject {
  [field: string]: string;
}

// TYPES
export type CLASSLINK_STATE = {
  isSSOLoading: boolean;
  error: string | null;
};

export type CLASSLINK_REDUCER = ImmutableMap<CLASSLINK_STATE>;

// INITIAL STATE
const STATE: CLASSLINK_STATE = {
  isSSOLoading: false,
  error: null
};

const INITAL_STATE = fromJS(STATE) as ImmutableMap<CLASSLINK_STATE>;

function parseErrorMessage(payload: any): string {
  const defaultMessage = 'error.defaultMessage';
  if (!payload) {
    return defaultMessage;
  }

  const errorCode = (payload.error && payload.error.code) || payload.code || '';
  if (
    errorCode &&
    (errorCode === 'classlink/invalid_grant' ||
      errorCode === 'classlink/student-only' ||
      errorCode === 'classlink/user-unauthorized' ||
      errorCode === 'auth/email-domain-blacklisted' ||
      errorCode === 'auth/email-domain-not-whitelisted' ||
      errorCode === 'auth/user-disabled' ||
      errorCode === 'classlink/school-not-found' ||
      errorCode === 'classlink/tenant-id-not-found' ||
      errorCode === 'classlink/email-required')
  ) {
    return errorCode;
  }

  if (payload.errors && payload.errors.length > 0) {
    return payload.errors.map((item: ErrorObject) => item.msg).join('\n');
  }

  return defaultMessage;
}

// MAIN REDUCER
export const classlinkReducer = (state = INITAL_STATE, { payload, type }: ReduxAction<any>) => {
  switch (type) {
    case SSO_REQUEST:
      return state.set('error', null).set('isSSOLoading', true);
    case USER_LOGGED_IN:
    case AUTH_STOP_LOADING:
      return state.set('isSSOLoading', false).set('error', null);
    case SSO_FAIL:
      return state.set('isSSOLoading', false).set('error', parseErrorMessage(payload));
    default:
      return state;
  }
};
