// IMPORTS
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
import LanguageContext from '../../locale/LanguageContext';

// PRESENTATION COMPONENT
import Footer from '../../components/Footer';

// TYPES
interface IProps {
  state?: any;
  light?: boolean;
}

// MAIN COMPONENT
const FooterContainer: React.FC<IProps> = props => {
  const { translate } = useContext(LanguageContext);
  // Var
  const links = [
    {
      icon: null,
      name: translate('termsAndConditions'),
      link: '',
      active: false,
      onClick: () => {
        if (window && window.open) {
          const newWindow = window.open(
            'https://mysparkpath.com/pages/sparkpath-terms-of-use',
            '_blank'
          );
          if (newWindow) {
            newWindow.focus();
          }
        }
      }
    },
    {
      icon: null,
      name: translate('privacyPolicy'),
      link: '',
      active: false,
      onClick: () => {
        if (window && window.open) {
          const newWindow = window.open(
            'https://mysparkpath.com/pages/sparkpath-privacy-policy',
            '_blank'
          );
          if (newWindow) {
            newWindow.focus();
          }
        }
      }
    }
  ];

  return <Footer light={props.light} links={links} />;
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  // statate
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterContainer);
