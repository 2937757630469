// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';
import DashboardStyles from '../Dashboard/styles';

// MAIN COMPONENTS
const CardIntroStyles = styled(DashboardStyles)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;

  & h1 {
    color: ${Colors.white};
  }
  
  & div {
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    
    & p {
      text-align: left;
      color: ${Colors.purpleDF};
      margin: 0 0 30px 0px;
      max-width: 460px;
    }
    
  }

  & button {
    margin-bottom: 2rem;
  }

  .card-intro__iframe {
    position: absolute;
    height: 100%;
    width: 60%;
    left: 50%;
    transform: translate(-50%, 0);

    @media (max-width: ${Screens.large}) {
      width: 80%;
    }
  }
`;

// EXPORTS
export default CardIntroStyles;
