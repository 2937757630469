// IMPORTS
import styled from 'styled-components';
import Colors from '../Colors';

// MAIN COMPONENT
const Success = styled.div`
  background: ${Colors.success};
  border: 1px solid ${Colors.successB8};
  border-radius: 3px;
  padding: 20px;

  font-size: 12px;
  color: ${Colors.success1E};
`;

// EXPORTS
export default Success;
