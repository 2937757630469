// IMPORTS
import React, { useState, FormEvent, useEffect, useContext } from 'react';
import { LiveMessage } from 'react-aria-live';
import DocumentTitle from 'react-document-title';
import LanguageContext from '../../locale/LanguageContext';
import { AppState } from '../../redux/reducer';
import { useAriaLiveMessage } from '../../hooks';

// STYLE COMPONENTS
import Button from '../../styles/Button';
import SupportStyles, { SupportTextArea, Field, SupportForm } from './styles';
import Error from '../../styles/Error';
import Success from '../../styles/Success';
import Loader from '../../styles/Loader';

// TYPES
interface Props extends AppState {
  loading: boolean;
  onSubmit: (message: string) => void;
}

// MAIN COMPONENT
const Support: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  // Variables
  const [message, setMessage] = useState('');
  const user = props.authReducer.get('user');
  let email = '';
  const success = props.supportReducer.get('success');
  const error = props.supportReducer.get('error');
  const successMessage = success ? translate('Support.Response.Success') : '';
  const errorMessage = props.supportReducer.get('error');

  if (user) {
    email = user.get('email') || '';
  }

  // Hooks
  const ariaLiveMessage = useAriaLiveMessage(successMessage, errorMessage);

  useEffect(() => {
    if (success) {
      setMessage('');
    }
  }, [success]);

  // Functions
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onSubmit(message);
  };

  // Render
  return (
    <DocumentTitle title={`${translate('Support.Heading')} | ${translate('document.title')}`}>
      <>
        <LiveMessage message={document.title} aria-live="polite" />
        <SupportStyles disabled={props.supportReducer.get('loading')}>
          <LiveMessage message={ariaLiveMessage} aria-live="polite" />
          <h1>{translate('Support.Heading')}</h1>
          <p>
            {translate('Support.Paragraph.PartOne')} {translate('Support.Paragraph.PartTwo')}
          </p>
          <div>
            <Field>
              <span>{translate('Support.Field.Name')} </span>
              {props.accountReducer.get('firstname') + ' ' + props.accountReducer.get('lastname')}
            </Field>
            <Field>
              <span>{translate('Support.Field.Email')}</span> {email}
            </Field>
            <SupportForm onSubmit={onSubmit}>
              {props.supportReducer.get('loading') && <Loader />}
              <label htmlFor="question-area">{translate('Support.Field.QuestionAreaLabel')}</label>
              <SupportTextArea
                id={'question-area'}
                name={'question-area'}
                disabled={props.supportReducer.get('loading')}
                value={message}
                onChange={({ target: { value } }) => setMessage(value)}
                placeholder={translate('Support.Field.QuestionArea')}
                rows={5}
                cols={50}
              />
              {error &&
                <Error>
                  <span>{errorMessage}</span>
                </Error>
              }
              {success &&
                <Success>
                  <span>{successMessage}</span>
                </Success>
              }
              <Button styleType="primary-white" type="submit">
                {translate('Support.Button.Submit')}
              </Button>
            </SupportForm>
          </div>
        </SupportStyles>
      </>
    </DocumentTitle>
  );
};

// EXPORTS
export default Support;
