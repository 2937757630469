import CardResult from '../../components/CardResult';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { doDownloadPdf } from './actions';
import { doSaveDetails } from '../AccountContainer/actions';
const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      doDownloadPdf,
      doSaveDetails
    },
    dispatch
  );
const CardResultContainer = connect(mapStateToProps, mapDispatchToProps)(CardResult);
export default CardResultContainer;
