// IMPORTS
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

// Fix for window.FIELDNAME, add here
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }

  interface Document {
      documentMode?: any;
  }
}

// RENDER
ReactDOM.render(
  <>
    <App />
  </>,
  document.getElementById('root')
);
