// IMPORTS
import React, { useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { createPortal } from 'react-dom'
import LanguageContext from '../../locale/LanguageContext';
import NavigationStyles, { Nav, NavLink as CustomNavLink } from './styles';
import NavLink from '../../styles/NavLink';
import { AppState } from '../../redux/reducer';
import MockButton from '../../styles/MockButton';

// TYPES
type Links = {
  icon: JSX.Element;
  name: string;
  link: string;
  active: boolean;
  style?: string;
  outline?: boolean;
};

interface IProps {
  links: Links[];
  open: boolean;
  show: boolean;
  authUser: any;
}

interface StateProps {
  router: AppState['router'];
}

type Props = IProps & StateProps;

const navElement = document.getElementById("nav-root");
const rootElement = document.getElementById("root");
const el = document.createElement("div");
navElement?.appendChild(el);

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const Navigation: React.FC<Props> = props => {
  const isDashboard = !!props.authUser;
  const buttonStyles = isDashboard ? 'primary-white' : 'primary';
  const { open } = props
  const { translate } = useContext(LanguageContext);

  useEffect(() => {
    if (open) {
      rootElement?.setAttribute('inert', '')

      return () => {
        rootElement?.removeAttribute('inert')
      };
    } else {
      navElement?.setAttribute('inert', '')

      return () => {
        navElement?.removeAttribute('inert')
      };
    }
  }, [open]);

  const content = (
    <NavigationStyles
      open={props.open}
      show={props.show}
      isDashboard={isDashboard}
      role="dialog"
      aria-modal="true"
      aria-label={translate('AriaLabel.Navigation')}
    >
      {props.links && props.links.length > 0 && (
        <Nav>
          {props.links.map((item, key) => (
            <CustomNavLink key={`NavLink-${key}`} active={item.active}>
              <NavLink to={item.link} activeClassName="nav-link--active">
                <MockButton styleType={item.style ? item.style : buttonStyles} outline={item.outline} tabIndex={-1}>
                  <span>{item.name}</span>
                </MockButton>
              </NavLink>
            </CustomNavLink>
          ))}
        </Nav>
      )}
    </NavigationStyles>
  )

  return createPortal(content, el);
};

const mapStateToProps = (state: AppState) => ({
  authUser: state.authReducer.get('user')
});

export default connect(mapStateToProps)(Navigation);
