import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import LanguageContext from '../../../../locale/LanguageContext';
import Checkbox from '../../../../styles/Checkbox';
import Button from '../../../../styles/Button';
import { FaDownload } from 'react-icons/fa';
import Screens from '../../../../styles/Screens';
import { StudentTableContainer } from './styles';
import { millisecondsToMinutes } from '../../../../util/time';
import { getStudentTopChallenge } from '../../../../util/card';

const StudentTable = ({
  extraCards,
  students,
  formatTime,
  downloadPDF,
  sortStudent,
  toggleStudentTableSort,
  disableUserClick
}: {
  extraCards: any;
  students: Object[];
  formatTime: Function;
  downloadPDF: Function;
  sortStudent: string;
  toggleStudentTableSort: Function;
  disableUserClick: Function;
}) => {
  const { translate, language } = useContext(LanguageContext);
  const [isDisabledLoading, setIsDisabledLoading] = useState(false);
  const onClickDisableUser = async (userId: string, disabled: boolean) => {
    setIsDisabledLoading(true);
    await disableUserClick(userId, disabled);
    setIsDisabledLoading(false);
  };

  return (
    <StudentTableContainer>
      <div className="table-overflow">
        <table>
          <thead>
            <tr>
              <th>{translate('Students.Results.FirstName')}</th>
              <th>{translate('Students.Results.LastName')}</th>
              <th>{translate('Students.Results.Email')}</th>
              <th>{translate('Students.Results.TotalTimeSpent')}</th>
              <th>
                <button
                  data-sort-direction={sortStudent}
                  className="student-table-sort-button"
                  onClick={() => toggleStudentTableSort()}
                >
                  {translate('Students.Results.LastSortDate')}
                </button>
              </th>
              <th>{translate('Students.TopChallenges.ChallengeNumber') + ' 1'}</th>
              <th>{translate('Students.TopChallenges.ChallengeNumber') + ' 2'}</th>
              <th>{translate('Students.TopChallenges.ChallengeNumber') + ' 3'}</th>
              <th>{translate('Students.Results.AccountEnabled')}</th>
              <th>{translate('Students.Results.Download')}</th>
            </tr>
          </thead>
          <tbody>
            {students?.length > 0 &&
              students?.map((student: any) => {
                const {
                  _id,
                  firstname,
                  lastname,
                  email,
                  totalTimeSpent,
                  results,
                  disabled,
                  sortAttempts,
                  firebaseUserId
                } = student;

                const selectedCards = results?.selected || [];
                const selectedCardsChallengeText: string[] = [
                  getStudentTopChallenge(translate, language, selectedCards[0], extraCards),
                  getStudentTopChallenge(translate, language, selectedCards[1], extraCards),
                  getStudentTopChallenge(translate, language, selectedCards[2], extraCards)
                ];

                return (
                  <tr key={`student-table-row-${email}-${firstname}-${lastname}`}>
                    <td>{firstname}</td>
                    <td>{lastname}</td>
                    <td>
                      <div className="student-account-email">{email}</div>
                    </td>
                    <td>
                      {totalTimeSpent ? formatTime(millisecondsToMinutes(totalTimeSpent)) : null}
                    </td>
                    <td>
                      <div className="student-account-lastsortdate">
                        {results?.updated
                          ? new Date(results?.updated).toLocaleDateString(language)
                          : null}
                      </div>
                    </td>
                    <td>
                      <div
                        className="student-account-topchallenges"
                        data-tip
                        data-for={`student-${_id}-1`}
                      >
                        {selectedCardsChallengeText[0]}
                        {selectedCardsChallengeText[0].length >= 11 ? (
                          <ReactTooltip id={`student-${_id}-1`} effect="solid" multiline>
                            {selectedCardsChallengeText[0]}
                          </ReactTooltip>
                        ) : null}
                      </div>
                    </td>
                    <td>
                      <div
                        className="student-account-topchallenges"
                        data-tip
                        data-for={`student-${_id}-2`}
                      >
                        {selectedCardsChallengeText[1]}
                        {selectedCardsChallengeText[1].length >= 11 ? (
                          <ReactTooltip id={`student-${_id}-2`} effect="solid" multiline>
                            {selectedCardsChallengeText[1]}
                          </ReactTooltip>
                        ) : null}
                      </div>
                    </td>
                    <td>
                      <div
                        className="student-account-topchallenges"
                        data-tip
                        data-for={`student-${_id}-3`}
                      >
                        {selectedCardsChallengeText[2]}
                        {selectedCardsChallengeText[2].length >= 11 ? (
                          <ReactTooltip id={`student-${_id}-3`} effect="solid" multiline>
                            {selectedCardsChallengeText[2]}
                          </ReactTooltip>
                        ) : null}
                      </div>
                    </td>
                    <td>
                      <div className="student-account-enabled">
                        <Checkbox
                          checked={!disabled}
                          onChange={() => onClickDisableUser(firebaseUserId, disabled)}
                          loading={isDisabledLoading}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="student-download">
                        <Button
                          disabled={!results?.selected?.length || !sortAttempts}
                          onClick={() => downloadPDF(firebaseUserId, language)}
                        >
                          {document.body.offsetWidth >= Screens.mediumAbsolute ? (
                            translate('Students.Button.Download')
                          ) : (
                            <FaDownload />
                          )}
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </StudentTableContainer>
  );
};

export default StudentTable;
