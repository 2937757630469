// IMPORTS
import { Dispatch } from 'redux';
import { LOGIN_REQUEST, DO_USER_LOGOUT, LOGIN_RESET_ERRORS } from './types';

// MAIN ACTIONS
/**
 *
 * @param email
 * @param password
 */
const doLogin = (email: string, password: string) => (dispatch: Dispatch) => {
  dispatch({
    type: LOGIN_REQUEST,
    payload: {
      email,
      password
    }
  });
};

/**
 *
 */
const doLogout = () => (dispatch: Dispatch) => {
  dispatch({
    type: DO_USER_LOGOUT
  });
};

/**
 *
 */
const doResetErrors = () => (dispatch: Dispatch) => {
  dispatch({
    type: LOGIN_RESET_ERRORS
  });
};

// EXPORTS
export { doLogin, doLogout, doResetErrors };
