import React, { FC } from "react";
import {
  List,
} from './styles';

interface ICustomPaginationProps {
  nbPages?: number,
  currentRefinement?: number,
  refine?: Function,
  onClick?: Function,
  isDisabled?: boolean
}

const CustomPagination: FC<ICustomPaginationProps> = (props) => {
  const { nbPages, currentRefinement, refine, isDisabled, onClick } = props

  return (
    <List>
      <li>
        <button 
          aria-label='Go to first page'
          onClick={() => {
            refine && refine(1)
            onClick && onClick()
          }} 
          disabled={currentRefinement === 1 || isDisabled}>
        «
        </button>
      </li>
      <li>
        <button 
          aria-label='Go to previous page'
          onClick={() => {
            refine && currentRefinement && refine(currentRefinement - 1)
            onClick && onClick()
          }}
          disabled={currentRefinement === 1 || isDisabled}>
        ‹
        </button>
      </li>
      <li aria-label='Current page'>{currentRefinement}</li>
      <li>
        <button 
          aria-label='Go to next page'
          onClick={() => {
            refine && currentRefinement && refine(currentRefinement + 1)
            onClick && onClick()
          }}
          disabled={currentRefinement === nbPages || isDisabled}>
        ›
        </button>
      </li>
      <li>
        <button 
          aria-label='Go to last page'
          onClick={() => {
            refine && nbPages && refine(nbPages)
            onClick && onClick()
          }}
          disabled={currentRefinement === nbPages || isDisabled}>
        »
        </button>
      </li>
    </List>
  )
}

export default CustomPagination