// IIMPORTS
import React, { useState, ComponentClass, FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { StaticContext } from 'react-router';

// PRESENTATION COMPONENT
import PageLoader from '../../components/PageLoader';

// TYPES
interface IProps {
  path: string;
  component?:
    | ComponentClass<any, any>
    | FunctionComponent<any>
    | ComponentClass<RouteComponentProps<any, StaticContext, any>, any>
    | FunctionComponent<RouteComponentProps<any, StaticContext, any>>
    | undefined;
  render?: Function;
  exact?: boolean;
  roles?: Array<string>;
}

type dispatchState = {
  auth: AppState['authReducer'];
  accountReducer: AppState['accountReducer'];
  resultReducer: AppState['resultReducer'];
};

// MAIN COMPONENT
const ProcRoute: React.FC<IProps & dispatchState> = props => {
  // State
  const [redirect, setRedirect] = useState(false);
  const userRole = props.accountReducer.get('role');
  const isLoading = props.auth.get('loading') || !userRole || (userRole === 'student' && props.resultReducer.get('resultsLoading'));
  const isLoggedIn = props.auth.get('user')?.get('email');

  // Render
  if (isLoading) {
    return <PageLoader />;
  }

  // if user is not logged in redirect to landing
  if (!isLoggedIn) {
    return <Redirect to={'/'} />;
  }

  // if role is specified on route and account is not that then redirect
  if (isLoggedIn && props.roles && props.roles.indexOf(userRole) === -1) {
    return <Redirect to={'/dashboard'} />;
  }

  if (isLoggedIn && props.render) {
    return (
      <Route exact={props.exact} path={props.path} render={(renderProps) => props.render && props.render(renderProps)} />
    );
  }

  if (isLoggedIn && !props.render) {
    return <Route exact={props.exact} path={props.path} component={props.component} />;
  }

  if (!isLoading && !isLoggedIn) {
    setTimeout(() => {
      setRedirect(true);
    }, 250);
  }

  return redirect ? <Redirect to={'/login'} /> : <PageLoader />;
};

// REDUX
const mapStateToProps = (state: AppState) => ({
  auth: state.authReducer,
  accountReducer: state.accountReducer,
  resultReducer: state.resultReducer
});

const ProtectedRoute = connect(mapStateToProps)(ProcRoute);

// EXPORTS
export default ProtectedRoute;
