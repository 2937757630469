// IMPORTS
import { takeEvery, put, select, takeLatest, delay } from 'redux-saga/effects';
import fetch from '../../fetch';
import env from '../../config/env.json';
import { provideCSVDownload } from '../../util/csv';
import gaUtil from '../../util/ga';

// REDUX TYPES
import {
  USER_SEARCHKEY_REQUEST,
  USER_SEARCHKEY_SUCCESS,
  USER_SEARCHKEY_FAIL,
  STUDENT_PDF_DOWNLOAD_REQUEST,
  STUDENT_PDF_DOWNLOAD_SUCCESS,
  STUDENT_PDF_DOWNLOAD_FAIL,
  STUDENT_COUNT_REQUEST,
  STUDENT_COUNT_SUCCESS,
  STUDENT_COUNT_FAIL,
  STUDENT_RESULTS_REQUEST,
  STUDENT_RESULTS_SUCCESS,
  STUDENT_RESULTS_FAIL,
  STUDENT_CSV_EXPORT_REQUEST,
  STUDENT_CSV_EXPORT_SUCCESS,
  STUDENT_CSV_EXPORT_FAIL,
  SCHOOL_COUNT_REQUEST,
  SCHOOL_COUNT_SUCCESS,
  SCHOOL_COUNT_FAIL,
  SCHOOL_REQUEST,
  SCHOOL_FAIL,
  SCHOOL_SUCCESS,
  SCHOOL_CSV_EXPORT_REQUEST,
  SCHOOL_CSV_EXPORT_SUCCESS,
  SCHOOL_CSV_EXPORT_FAIL,
  SCHOOL_DELETE_REQUEST,
  SCHOOL_DELETE_SUCCESS,
  SCHOOL_DELETE_FAIL,
  STUDENT_DATA_FAIL,
  STUDENT_DATA_REQUEST,
  STUDENT_DATA_SUCCESS
} from './types';

interface ISelectedCardCount {
  [key: string]: number;
}

// WORKERS
/**
 *
 */
function* workerGetSearchKey({ payload }: any) {
  try {
    const { schoolId } = payload || {};
    const data = yield fetch.get('/users/search/key', {
      params: {
        schoolid: schoolId
      }
    });
    if (!data || !data.data || !data.data.key) {
      throw new Error('No key returned.');
    }
    yield put({ type: USER_SEARCHKEY_SUCCESS, payload: { key: data.data.key } });
  } catch (e) {
    yield put({
      type: USER_SEARCHKEY_FAIL,
      payload: e
    });
  }
}

/**
 *
 */
function* workerDownloadPDF({ payload }: any) {
  try {
    if (!payload || !payload.id || payload.id.length <= 0) {
      throw new Error('No UID defined.');
    }
    const language = payload.language || 'en';
    const url = `${env.API_URL}/results/pdf?uid=${payload.id}&language=${language}&auth=${fetch.defaults.headers.common.Authorization}`;
    window.open(url, '_blank');
    const role = yield select(state => state.accountReducer.get('role'));
    gaUtil.events.studentTable.downloadStudentResults(role);
    yield put({ type: STUDENT_PDF_DOWNLOAD_SUCCESS });
  } catch (e) {
    yield put({
      type: STUDENT_PDF_DOWNLOAD_FAIL,
      payload: e
    });
  }
}

function* workerGetStudentCount({ payload }: any) {
  try {
    const { schoolId } = payload || {};
    const summary = yield fetch.get('/users/summary', {
      params: {
        role: 'student',
        schoolid: schoolId
      }
    });
    const { data: { data = {} } = {} } = summary || {};
    yield put({ type: STUDENT_COUNT_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: STUDENT_COUNT_FAIL,
      payload: e
    });
  }
}

function* workerGetSchoolCount() {
  try {
    const summary = yield fetch.get('/users/summary', { params: { role: 'school' } });
    const { data: { data = {} } = {} } = summary || {};
    yield put({ type: SCHOOL_COUNT_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: SCHOOL_COUNT_FAIL,
      payload: e
    });
  }
}

function* workerGetSchool({ payload }: any = {}) {
  try {
    const { page, sort, schoolId } = payload;
    const response = yield fetch.get('/users/school', {
      params: {
        ...(page && { page }),
        ...(sort && { sort }),
        schoolid: schoolId
      }
    });
    const { data = {} } = response || {};
    yield put({ type: SCHOOL_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: SCHOOL_FAIL,
      payload: e
    });
  }
}

function* workerGetStudentResults({ payload }: any) {
  try {
    const { schoolId } = payload || {};

    const sortedSelectedCardCount: ISelectedCardCount = {};

    const topChallenges = yield getSchoolTopChallenges(schoolId);
    const sortedKeys = Object.keys(topChallenges).sort((a, b) => {
      return topChallenges[b] - topChallenges[a];
    });

    for (let i = 0; i < sortedKeys.length; i++) {
      sortedSelectedCardCount[sortedKeys[i]] = topChallenges[sortedKeys[i]];
    }

    const studentSummary = yield fetch.get('/users/summary/student');

    const {
      totalTimeSpent,
      totalSortAttempts,
      totalSortAttemptsWithTrackedTime,
      totalStudentsWithTrackedTime,
      averageTimeSpentPerStudentWithTrackedTime,
      averageTimeSpentPerSortWithTrackedTime,
      totalStudents
    } = studentSummary?.data?.data;

    const data = {
      data: sortedSelectedCardCount,
      totalStudents: totalStudents,
      totalSortAttempts: totalSortAttempts ?? 0,
      totalTimeSpent: totalTimeSpent ?? 0,
      totalStudentsWithTrackedTime: totalStudentsWithTrackedTime ?? 0,
      totalSortAttemptsWithTrackedTime: totalSortAttemptsWithTrackedTime ?? 0,
      averageTimeSpentPerStudentWithTrackedTime: averageTimeSpentPerStudentWithTrackedTime ?? 0,
      averageTimeSpentPerSortWithTrackedTime: averageTimeSpentPerSortWithTrackedTime ?? 0
    };

    yield put({ type: STUDENT_RESULTS_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: STUDENT_RESULTS_FAIL,
      payload: e
    });
  }
}

async function getSchoolTopChallenges(schoolId: string) {
  const response = await fetch.get('/users/student/topchallenges', {
    params: {
      schoolid: schoolId
    }
  });
  const { data: { topChallenges = {} } = {} } = response || {};

  return topChallenges;
}

function* workerGetStudentsData({ payload }: any = {}) {
  yield delay(500);
  try {
    const { schoolId, page, limit = 20, lastSorted, search, showDisabled } = payload;
    const response = yield fetch.get('/users/v1/student', {
      params: {
        page,
        limit,
        schoolid: schoolId,
        lastSorted,
        search,
        showDisabled
      }
    });

    const { data } = response ?? {};

    yield put({ type: STUDENT_DATA_SUCCESS, payload: data });
  } catch (e) {
    yield put({
      type: STUDENT_DATA_FAIL,
      payload: e
    });
  }
}

function* workerDownloadStudentCSV({ payload }: any = {}) {
  try {
    const { query, locale, showDisabled = false, schoolId } = payload;
    const response = yield fetch.get('/users/download', {
      params: {
        query,
        locale,
        showDisabled,
        schoolid: schoolId
      }
    });
    const { data } = response || {};
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    provideCSVDownload(data, `students${formattedDate}.csv`);

    const role = yield select(state => state.accountReducer.get('role'));
    gaUtil.events.studentTable.exportCSV(role);

    yield put({ type: STUDENT_CSV_EXPORT_SUCCESS });
  } catch (e) {
    yield put({
      type: STUDENT_CSV_EXPORT_FAIL,
      payload: e
    });
  }
}

function* workerDownloadSchoolCSV({ payload }: any = {}) {
  try {
    const { locale, sort } = payload;
    const response = yield fetch.get('/users/school/csv', {
      params: {
        locale,
        ...(sort && { sort })
      }
    });
    const { data } = response || {};
    const date = new Date();
    const formattedDate = date
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    provideCSVDownload(data, `schools${formattedDate}.csv`);
    yield put({ type: SCHOOL_CSV_EXPORT_SUCCESS });
  } catch (e) {
    yield put({
      type: SCHOOL_CSV_EXPORT_FAIL,
      payload: e
    });
  }
}

function* workerDeleteSchool({ payload }: any = {}) {
  try {
    const { schoolId } = payload;
    yield fetch.delete('/users/school', {
      params: {
        schoolid: schoolId
      }
    });
    yield put({ type: SCHOOL_DELETE_SUCCESS });
  } catch (e) {
    const message =
      (e && e.response && e.response.data && (e.response.data.code || e.response.data.message)) ||
      'Students.DeleteSchool.Error';
    yield put({
      type: SCHOOL_DELETE_FAIL,
      payload: message
    });
  }
}

// WATCHERS
export function* watcherGetSearchKey() {
  yield takeEvery(USER_SEARCHKEY_REQUEST, workerGetSearchKey);
}

export function* watcherDownloadStudentPDF() {
  yield takeEvery(STUDENT_PDF_DOWNLOAD_REQUEST, workerDownloadPDF);
}

export function* watcherGetStudentCount() {
  yield takeEvery(STUDENT_COUNT_REQUEST, workerGetStudentCount);
}

export function* watcherGetSchoolCount() {
  yield takeEvery(SCHOOL_COUNT_REQUEST, workerGetSchoolCount);
}

export function* watcherGetSchool() {
  yield takeEvery(SCHOOL_REQUEST, workerGetSchool);
}

export function* watcherGetStudentResults() {
  yield takeEvery(STUDENT_RESULTS_REQUEST, workerGetStudentResults);
}

export function* watcherGetStudentsData() {
  yield takeLatest(STUDENT_DATA_REQUEST, workerGetStudentsData);
}

export function* watcherDownloadStudentCSV() {
  yield takeEvery(STUDENT_CSV_EXPORT_REQUEST, workerDownloadStudentCSV);
}

export function* watcherDownloadSchoolCSV() {
  yield takeEvery(SCHOOL_CSV_EXPORT_REQUEST, workerDownloadSchoolCSV);
}

export function* watcherDeleteSchool() {
  yield takeEvery(SCHOOL_DELETE_REQUEST, workerDeleteSchool);
}
