import { fromJS } from 'immutable';
import {
  SUPPORT_FORM_RESET,
  SUPPORT_FORM_REQUEST,
  SUPPORT_FORM_SUCCESS,
  SUPPORT_FORM_FAIL
} from './types';
type BASE_TYPE = {
  loading: boolean;
  success: boolean;
  error: any;
};
const BASE_STATE: BASE_TYPE = {
  loading: false,
  success: false,
  error: null
};

export type SUPPORT_REDUCER = ImmutableMap<BASE_TYPE>;

const INITAL_STATE = fromJS(BASE_STATE) as SUPPORT_REDUCER;

export const supportReducer = (state = INITAL_STATE, { type, payload }: ReduxAction) => {
  switch (type) {
    case SUPPORT_FORM_RESET:
      return INITAL_STATE;
    case SUPPORT_FORM_REQUEST:
      return state
        .set('loading', true)
        .set('error', null)
        .set('success', false);
    case SUPPORT_FORM_FAIL:
      return state.set('error', fromJS(payload)).set('loading', false);
    case SUPPORT_FORM_SUCCESS:
      return state.set('loading', false).set('success', true);
    default:
      return state;
  }
};
