import React, { useContext } from 'react';
import CardModalStyles, { ModalImageContainer } from './styles';
import { cardTypes } from '../Card';
import LanguageContext from '../../locale/LanguageContext';

export type CardType = ReturnType<typeof cardTypes> | null;

interface IProps {
  type: CardType;
}

interface StateProps {}

interface DispatchProps {}

type Props = IProps & StateProps & DispatchProps;
const CardModal: React.FC<Props> = props => {
  const { language } = useContext(LanguageContext);
  const { type } = props;
  if (!type) return null;
  return (
    <CardModalStyles>
      <h2 id="modal-title" className={language}>{type.name}</h2>
      <ModalImageContainer bgColor={type.color}>
        {type.src ? <img src={type.src} alt="" /> : null}
      </ModalImageContainer>
      {type.content}
    </CardModalStyles>
  );
};
export default CardModal;
