import {
  RESULT_END_LOADING,
  RESULT_START_LOADING,
  RESULT_GET_ERROR,
  RESULT_START_GET,
  RESULT_END_GET,
  RESULT_ERROR_GET,
  RESULT_LOAD_ALL_CARDS_END,
  RESULT_SET_START_TIME,
  RESULT_RESET_EMAIL,
  RESULT_RESET,
  RESULT_SAVE_PROGRESS_END,
  RESULT_SAVE_PROGRESS_ERROR,
  RESULT_SAVE_PROGRESS_START
} from './types';
import { fromJS } from 'immutable';
// import { CardEnum } from '../../components/Card';
// import { CustomCard } from '../../types/customCard';
import { CardAttributesResult } from '../../util/card';

const BASE_STATE: {
  email: boolean;
  loading: boolean;
  resultsLoading: boolean;
  error: null | { [field: string]: string };
  saveProgressLoading: boolean;
  cards: any[]; // string[] | CustomCard[];
  sortStartTime: number | null;
  extraCards: CardAttributesResult[];
  savedResultsCards: any[]; // Array<{ name: string; result: string | null }>;
  id: string | null;
} = {
  email: false,
  loading: false,
  resultsLoading: true,
  saveProgressLoading: false,
  error: null,
  cards: [],
  sortStartTime: null,
  extraCards: [],
  savedResultsCards: [],
  id: null
};

type RESULT_STATE = typeof BASE_STATE;

export type RESULT_REDUCER = ImmutableMap<RESULT_STATE>;

const INITIAL_STATE = fromJS(BASE_STATE) as RESULT_REDUCER;

export const resultReducer = (state = INITIAL_STATE, { payload, type }: ReduxAction) => {
  switch (type) {
    case RESULT_RESET:
      return INITIAL_STATE
    case RESULT_RESET_EMAIL:
      return state
        .set('error', null)
        .set('email', false);
    case RESULT_END_LOADING:
      return state
        .set('loading', false)
        .set('error', null)
        .set('email', payload.email);
    case RESULT_START_LOADING:
      return state.set('loading', true);
    case RESULT_START_GET:
      return state.set('resultsLoading', true);
    case RESULT_ERROR_GET:
      return state
        .set('resultsLoading', false)
    case RESULT_GET_ERROR:
      return state
        .set('error', fromJS(payload))
        .set('loading', false)
    case RESULT_END_GET:
      return state
        .set('resultsLoading', false)
        .set('cards', fromJS(payload.selected)) // liked cards
        .set('extraCards', payload.extraCards || state.get('extraCards'))
        .set('savedResultsCards', payload.cards || state.get('savedResultsCards'))
        .set('id', payload.id);
    case RESULT_LOAD_ALL_CARDS_END:
      return state
        .set('resultsLoading', false)
        .set('extraCards', payload.extraCards || state.get('extraCards'));
    case RESULT_SET_START_TIME:
      return state.set('sortStartTime', payload);
    case RESULT_SAVE_PROGRESS_START:
      return state.set('saveProgressLoading', true);
    case RESULT_SAVE_PROGRESS_END:
    case RESULT_SAVE_PROGRESS_ERROR:
      return state.set('saveProgressLoading', false);
    default:
      return state;
  }
};
