import styled from 'styled-components';
import Screens from '../../../../styles/Screens';

const StudentTableContainer = styled.div`
  .student-account-enabled {
    display: flex;
    justify-content: center;
  }
  .student-account-email {
    text-overflow: ellipsis;
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }
  .student-account-lastsortdate {
    min-width: 90px;
  }
  .student-account-topchallenges {
    position: relative;
    text-overflow: ellipsis;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
  }

  .student-download {
    width: 150px;
  }
  .student-table-sort-button {
    white-space: normal;
  }

  @media screen and (max-width: ${Screens.extraLarger}) {
    .student-account-topchallenges {
      width: 80px;
    }
  }
`;

export { StudentTableContainer };
