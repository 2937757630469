const unTrackedRoles = [
  'admin'
]

export function validateRole(next: Function) {
  return function(role: string, ...nextArgs: any[]) {
    if (unTrackedRoles.indexOf(role) === -1) {
      next(...nextArgs)
    }
  }
}
