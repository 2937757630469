// IMPORTS
import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import firebase from '../../firebase';
import fetch from '../../fetch';

// REDUX TYPES
import { SSO_FAIL, SSO_REQUEST } from './types';

// SAGAS
function* workerSSO({ payload }: ReduxAction) {
  try {
    const { classlinkCode } = payload;
    const classlinkResponse: AxiosResponse = yield fetch.post('/auth/classlink', {
      classlinkCode
    });
    const authToken: any = classlinkResponse.data && classlinkResponse.data.authToken;

    // todo: login user with authToken
    yield firebase.auth().signInWithCustomToken(authToken);
    // Next steps are handled by onAuthStateChange on store.ts
  } catch (e) {
    const getError = (e: any) => {
      if (e.response && e.response.data) {
        return e.response.data;
      }
      if (e.code) {
        return e;
      }
      return null;
    };

    const errorData: any = getError(e);
    yield put({
      type: SSO_FAIL,
      payload: errorData
    });
  }
}

/**
 *
 */
function* watcherSSO() {
  yield takeLatest(SSO_REQUEST, workerSSO);
}

export { watcherSSO };
