// IMPORTS
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import { AppState } from '../../redux/reducer';
import ModalBackground from '../../components/ModalBackground';

// TYPES
interface IProps {
  state: any;
  isDashboard: boolean;
  backgroundColor?: String;
}

// MAIN COMPONENT
const ModalBackgroundContainer: React.FC<IProps> = props => {
  return (
    <ModalBackground
      isDashboard={props.isDashboard}
      show={props.state.modalReducer.get('show')}
      backgroundColor={props.backgroundColor || ''}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      // actions here
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(ModalBackgroundContainer);
