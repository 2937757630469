// IMPORTS
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
import { history } from '../../redux/store';
import PageLoader from '../../components/PageLoader';

// PRESENTATION COMPONENT
import Landing from '../../components/Landing';
import { push, Push } from 'connected-react-router';

// ACTIONS
import { doModalHide } from '../ModalBackgroundContainer/actions';

// TYPES
interface IProps {
  doModalHide: Function;
  push: Push;
  authReducer: AppState['authReducer'];
  accountReducer: AppState['accountReducer'];
  isBlank: boolean;
}

// MAIN COMPONENT
const LandingContainer: React.FC<IProps> = props => {
  const isBlank = props.isBlank || false;
  // Functions
  const onClickGetStarted = () => {
    history.push('/signup');
  };

  useEffect(() => {
    if (props.authReducer.get('user') && props.accountReducer.get('email')) {
      props.doModalHide();
      props.push(
        props.accountReducer.get('role') === 'school' ||
          props.accountReducer.get('role') === 'admin'
          ? '/dashboard/students'
          : '/dashboard/cards'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ props.push, props.doModalHide, props.authReducer.get('user'), props.accountReducer.get('email'), props.accountReducer.get('role')]);

  if (
    !props.accountReducer.get('error') && (
      props.authReducer.get('loading') ||
      (props.authReducer.get('user') && !props.accountReducer.get('email'))
    )
  ) {
    return <PageLoader />;
  }

  return isBlank ? null : <Landing onClickGetStarted={onClickGetStarted} push={props.push} />;
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  authReducer: state.authReducer,
  accountReducer: state.accountReducer
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      doModalHide
    },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingContainer);
