// IMPORTS
import React from 'react';
import Color from 'color';

// STYLE COMPONENTS
import LikeButtonStyles, { LikeButtonToolTip } from './styles';
import Colors from '../../styles/Colors';

import { Like, Dislike, Superlike } from '../../styles/Icons';

// TYPES
type LikeTypes = 'dislike' | 'like' | 'superlike';
interface IProps {
  variant: LikeTypes;
  onClick: () => any;
  label?: string;
  hidden?: boolean;
  onMouseEnter?: () => any;
}
interface StateProps {}
interface DispatchProps {}

type Props = IProps & StateProps & DispatchProps;

// FUNCTIONS
const getColor = (variant: LikeTypes) => {
  const color = Color(Colors.purple62);
  switch (variant) {
    case 'dislike':
      return color.lighten(0.5).hex();
    case 'like':
      return color.lighten(0.7).hex();
      case 'superlike':
      return color.lighten(0.7).hex();
  }
};

const getIcon = (variant: LikeTypes) => {
  switch (variant) {
    case 'dislike':
      return (
        <span>
          <Dislike />
        </span>
      );
    case 'like':
      return (
        <span>
          <Like />
        </span>
      );
    case 'superlike':
      return (
        <span>
          <Superlike />
        </span>
      );
  }
};

// MAIN COMPONENT
const LikeButton: React.FC<Props> = props => {
  return (
    <LikeButtonStyles
      aria-label={props.label}
      color={getColor(props.variant)}
      onClick={props.onClick}
      onMouseEnter={() => props.onMouseEnter && props.onMouseEnter()}
    >
      {getIcon(props.variant)}
      {props.label && props.label.length > 0 && !props.hidden && (
        <LikeButtonToolTip
          id={props.variant}
        >
          {props.label}
        </LikeButtonToolTip>
      )}
    </LikeButtonStyles>
  );
};

// EXPORTS
export default LikeButton;
