import { takeEvery, put, takeLeading, select } from 'redux-saga/effects';
import { LOCATION_CHANGE, LocationChangePayload, push } from 'connected-react-router';
import {
  RESULT_START_LOADING,
  RESULT_END_LOADING,
  RESULT_GET_ERROR,
  RESULT_DOWNLOAD_PDF,
  RESULT_DO_LOAD,
  RESULT_DO_LOAD_ALL_CARDS,
  RESULT_LOAD_ALL_CARDS_END,
  RESULT_ERROR_GET,
  RESULT_START_GET,
  RESULT_END_GET,
  RESULT_SAVE_PROGRESS,
  RESULT_SAVE_PROGRESS_END,
  RESULT_SAVE_PROGRESS_ERROR,
  RESULT_SAVE_PROGRESS_START,
  RESULT_SET_START_TIME,
  RESULT_CREATE,
  RESULT_DELETE
} from './types';
import { ACCOUNT_UPDATE_RESULTS, DO_GET_USER_DATA } from '../AccountContainer/types';
import fetch from '../../fetch';
import {
  findFirstUnsortedCardIndex,
  getAllCardsWithContent,
  getCardsResultsWithExclusions
} from '../../util/card';

import { getAllCards } from '../../webApi/card';
import { shouldStudentRedirectToEndSurvey } from '../../util/account';
import * as env from '../../config/env.json';

function* goToResultsWorker({ payload }: ReduxAction<LocationChangePayload>) {
  try {
    const location = payload.location as any;
    if (location.pathname === '/dashboard/cards/results') {
      const id: string = yield select(state => state.resultReducer.get('id'));
      const role: string = yield select(state => state.accountReducer.get('role'));
      const hasCompletedConsumerEndSurvey: boolean = yield select(state =>
        state.accountReducer.get('hasCompletedConsumerEndSurvey')
      );
      const sortResults: string[] = yield select(state => state.resultReducer.get('cards').toJS());
      const isConsumerGroup: boolean = yield select(state =>
        state.accountReducer.get('isConsumerGroup')
      );
      const shouldUpdateResult =
        (location.state &&
        typeof location.state.shouldUpdateResult === 'boolean' &&
        location.state.shouldUpdateResult === false
          ? location.state.shouldUpdateResult
          : true) &&
        !shouldStudentRedirectToEndSurvey(
          role,
          hasCompletedConsumerEndSurvey,
          sortResults,
          isConsumerGroup
        );

      if (shouldUpdateResult) {
        const results = yield fetch.put(`/results/${id}`, location.state);
        yield put({
          type: RESULT_END_LOADING,
          payload: {
            email: false
          }
        });
        const { selected = [] } = results && results.data;
        yield put({
          type: RESULT_END_GET,
          payload: {
            selected,
            id
          }
        });
        yield put({
          type: ACCOUNT_UPDATE_RESULTS,
          payload: {
            results: true
          }
        });
      }
    }
    yield null;
  } catch (e) {
    yield put({
      type: RESULT_GET_ERROR,
      payload: e
    });
    yield put(push('/dashboard'));
  }
}

export function* goToResultsListener() {
  yield takeEvery(LOCATION_CHANGE, goToResultsWorker);
}

function* locationChangeWorker({ payload }: ReduxAction<LocationChangePayload>) {
  try {
    const location = payload.location as any;
    if (
      /\/cards\/(select|[0-9]+)$/.test(location.pathname) &&
      location.state &&
      location.state.cards &&
      !payload.isFirstRendering
    ) {
      yield put({
        type: RESULT_SAVE_PROGRESS,
        payload: location.state
      });
    }
  } catch (e) {}
}

export function* locatinChangeListener() {
  yield takeEvery(LOCATION_CHANGE, locationChangeWorker);
}

function* downloadPDFWorker(action?: ReduxAction) {
  if (action) {
    yield put({
      type: RESULT_START_LOADING
    });
  }
  try {
    const language = action ? action.payload.language : 'en';
    const redirect = action && action.payload && action.payload.redirect;
    const email = !!(action && action.payload.email);
    const url = `${env.API_URL}/results/pdf?language=${language}&email=${email}&auth=${fetch.defaults.headers.common.Authorization}`;
    yield put({
      type: DO_GET_USER_DATA
    });
    if (!email) {
      window.open(url, '_blank');
    } else {
      yield fetch.get(url);
    }
    if (action) {
      yield put({
        type: RESULT_END_LOADING,
        payload: {
          email
        }
      });
    }

    if (redirect) {
      yield put(push(redirect));
    }
  } catch (e) {
    yield put({
      type: RESULT_GET_ERROR,
      payload: e
    });
  }
}

export function* downloadPDFListener() {
  yield takeLeading(RESULT_DOWNLOAD_PDF, downloadPDFWorker);
}

function* onResultDoLoadWorker() {
  try {
    yield put({
      type: RESULT_START_GET
    });
    const results = yield fetch.get('/results');
    const { selected = [], created, cards: resultsCards = [], id } = results && results.data;
    const {
      data: { data: extraCards }
    } = yield getAllCards();
    const excludedCards: string[] = yield select(
      state => state.accountReducer.get('excludedCards').toJS() || []
    );
    const resultCardsWithExclusions = getCardsResultsWithExclusions(
      extraCards,
      excludedCards,
      resultsCards
    );
    const savedResultsCardsWithContent =
      resultCardsWithExclusions.length > 0
        ? getAllCardsWithContent(resultCardsWithExclusions, extraCards)
        : resultCardsWithExclusions;

    yield put({
      type: RESULT_END_GET,
      payload: {
        selected,
        id,
        extraCards,
        cards: savedResultsCardsWithContent
      }
    });

    if (created && resultsCards.length > 0 && (!selected || selected.length === 0)) {
      yield put({
        type: RESULT_SET_START_TIME,
        payload: created
      });
      if (resultsCards.length <= savedResultsCardsWithContent.length) {
        const index = findFirstUnsortedCardIndex(resultsCards);
        if (index === -1) {
          yield put(
            push('/dashboard/cards/select', {
              cards: savedResultsCardsWithContent,
              extraCards
            })
          );
        } else {
          yield put(
            push(`/dashboard/cards/${index + 1}`, {
              cards: savedResultsCardsWithContent,
              extraCards
            })
          );
        }
      } else {
        yield put(
          push('/dashboard/cards/1', {
            cards: savedResultsCardsWithContent,
            extraCards
          })
        );
      }
    }
  } catch (e) {
    yield put({
      type: RESULT_ERROR_GET,
      payload: e.message
    });
  }
}

function* loadAllCards() {
  // TODO: transition hardcoded cards to the backend, as of now these are extra cards
  try {
    yield put({ type: RESULT_START_GET });
    const {
      data: { data: extraCards }
    } = yield getAllCards();
    yield put({
      type: RESULT_LOAD_ALL_CARDS_END,
      payload: { extraCards }
    });
  } catch (error) {
    yield put({
      type: RESULT_ERROR_GET,
      payload: error.message
    });
  }
}

export function* onResultDoLoadListener() {
  yield takeLeading(RESULT_DO_LOAD, onResultDoLoadWorker);
}

export function* onResultDoLoadAllCardsListener() {
  // TODO: transition hardcoded cards to the backend, as of now these are extra cards
  yield takeLeading(RESULT_DO_LOAD_ALL_CARDS, loadAllCards);
}

/**
 * This save progress function works in the
 * following way: if there is nothing in the queue
 * that needs to be saved, it sends a PUT request
 * to the server to save the progress. But if there
 * is a second save progress request triggered, while
 * the first request to save progress is still being processed
 * it puts the second data into the queue. If there is a third request
 * to save progress, while the first one is still processing
 * the second data gets replaced with the third data.
 * This way we don't need to send the second data, because
 * the third data contains the second data progress.
 * Once the first request completes, it triggers this function
 * again, which checks to see if there is anything in the queue - if
 * there is then it start processing that.
 */

const saveProgressQueue: any[] = [];

function* onResultSaveProgress({ payload }: any) {
  try {
    const saveProgressLoading = yield select(state =>
      state.resultReducer.get('saveProgressLoading')
    );
    const id = yield select(state => state.resultReducer.get('id'));

    if (payload) {
      yield saveProgressQueue.splice(0, 1, payload);
    }

    const currentProgress = saveProgressQueue[0];

    if (currentProgress && !saveProgressLoading) {
      yield saveProgressQueue.shift();
      yield put({
        type: RESULT_SAVE_PROGRESS_START
      });
      yield fetch.put(`/results/${id}`, currentProgress);
      yield put({
        type: RESULT_SAVE_PROGRESS_END
      });
    }
  } catch (e) {
    yield saveProgressQueue.shift();
    yield put({
      type: RESULT_SAVE_PROGRESS_ERROR
    });
  }
}

export function* onResultSaveProgressListener() {
  yield takeEvery(RESULT_SAVE_PROGRESS, onResultSaveProgress);
}

export function* onResultSaveProgressEndListener() {
  yield takeEvery(RESULT_SAVE_PROGRESS_END, onResultSaveProgress);
}

export function* onResultSaveProgressErrorListener() {
  yield takeEvery(RESULT_SAVE_PROGRESS_ERROR, onResultSaveProgress);
}

function* createResultWorker({ payload }: any) {
  try {
    const selectedCards = yield select(state => state.resultReducer.get('cards'));
    const id = yield select(state => state.resultReducer.get('id'));

    if ((selectedCards && selectedCards.toJS().length > 0) || !id) {
      const response = yield fetch.post('/results', payload);
      const { created, id, cards = [], result } = (response && response.data) || {};
      const savedResultsCards = (result && result.cards) || cards || [];
      yield put({
        type: RESULT_SET_START_TIME,
        payload: created
      });
      yield put({
        type: RESULT_END_GET,
        payload: {
          selected: [],
          id,
          cards: savedResultsCards
        }
      });
    }
  } catch (e) {}
}

export function* createResultListener() {
  yield takeLeading(RESULT_CREATE, createResultWorker);
}

function* deleteResultWorker() {
  try {
    const id = yield select(state => state.resultReducer.get('id'));

    if (id) {
      yield put({
        type: RESULT_START_GET
      });
      yield fetch.delete(`/results/${id}`);

      yield put({
        type: RESULT_DO_LOAD
      });
    }
  } catch (e) {}
}

export function* deleteResultListener() {
  yield takeLeading(RESULT_DELETE, deleteResultWorker);
}
