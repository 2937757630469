// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import InputStyles from '../../styles/Input/styles';
import ButtonStyles from '../../styles/Button/styles';
import Loader from '../../styles/Loader/styles';
import Success from '../../styles/Success';
import Error from '../../styles/Error';

// MAIN COMPONENT
export const AccountSubscription = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.greyE6};
  max-width: 600px;
  ${Loader} {
    height: 100% !important;
  }

  & span {
    display: block;
    margin-top: -15px;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 20px;
    color: #fefefe;
  }
`;

export const AccountDetails = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.greyE6};
  max-width: 600px;
  ${Loader} {
    height: 100% !important;
  }
`;

export const AccountAddress = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.greyE6};
  max-width: 600px;
  ${Loader} {
    height: 100% !important;
  }
`;

export const AccountPassword = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.greyE6};
  max-width: 600px;
  ${Loader} {
    height: 100% !important;
  }
`;

export const AccountStyles = styled.div`
  position: relative;
  padding: 20px 15px;
  margin: 0 auto;

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  h1,
  h2,
  h3 {
    color: ${Colors.white};
  }

  form {
    position: relative;
  }

  label {
    display: block;
    font-size: 14px;
    margin-bottom: 10px;
    color: ${Colors.white};
  }

  ${InputStyles} {
    width: 100%;
    margin-bottom: 20px;
  }

  > ${Loader} {
    margin: 0;
  }

  ${Success}, ${Error} {
    margin-bottom: 20px;
  }

  ${ButtonStyles} {
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  ${Loader} {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
