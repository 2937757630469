import React from "react";
import { Container } from './styles';

interface IRadioInputProps {
  label: string;
  containerStyles?: Object;
}

const RadioInput = (props: React.HTMLProps<HTMLInputElement> & IRadioInputProps) => {
  const {id, label, containerStyles = {}, ...inputProps} = props
  
  return (
    <Container style={containerStyles}>
      <input 
        {...inputProps}
        type="radio"
        id={id}/>
      <label htmlFor={id}>{label}</label>
    </Container>
  )
}

export default RadioInput