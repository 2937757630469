import DashboardStyles from '../Dashboard/styles';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const ConfirmWrapper = styled.div`
  position: sticky;
  bottom: 0;
  padding: 6px;
  margin: -12px;
  background: white;
  margin-top: 6px;
  text-align: center;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  background: ${Colors.purple72};
  z-index: 3;
`;

const PickThreeStyle = styled(DashboardStyles)`
  h1 {
    color: ${Colors.white}
    display: block;
    text-align: center;
    margin-top: 20px;
  }
  padding: 12px;
  & button {
    margin: auto;
  }
  @media screen and (max-width: 338px) {
    margin: 10px;
  }
`;

export const TransitionStyles = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.purple62};
  color: white;
  z-index: 99;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  & h1 {
    color: white;
    font-weight: 600;
    margin: 0;
  }
  & p {
    margin-top: 20px;
    line-height: 1.5;
    padding: 0 16px;
    text-align: center;
    max-width: 450px;
  }
`;

export default PickThreeStyle;
