import React from 'react';
import { SearchBarContainer } from './styles';

const SearchBar = ({
  search,
  handleSearchChange
}: {
  search: string;
  handleSearchChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <SearchBarContainer>
      <input
        type="search"
        placeholder="Search here..."
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck={false}
        maxLength={512}
        value={search}
        onChange={handleSearchChange}
      />
    </SearchBarContainer>
  );
};

export default SearchBar;
