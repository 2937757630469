// IMPORTS
import styled from 'styled-components';

// MAIN COMPONENT
const LoaderStyles = styled.div`
  display: block;
  height: 50px;
  width: 50px;

  svg {
    display: block;
    height: 50px;
    width: 50px;
  }
`;

// EXPORTS
export default LoaderStyles;
