// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { Pagination } from 'react-instantsearch-dom';
import DashboardStyles from '../Dashboard/styles';
import Loader from '../../styles/Loader/styles';
import { ChartContainer as SignUpsChart } from './components/SignUpsChart/styles';
import { CustomCheckbox, InputStyles as CheckboxInput } from '../../styles/Checkbox/styles';
import { CardLogoImage } from '../Card/styles';

interface IAdditionalInfoProps {
  isDesktop: boolean;
}

// MAIN COMPONENTS
const StudentResultsHeader = styled.div`
  .ais-SearchBox-input {
    height: 40px;
  }

  > ul {
    display: flex;
    width: min-content;
    min-width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid ${Colors.greyE6};

    li {
      background: ${Colors.greyE6};
      color: ${Colors.grey33};
      flex: 1;
      width: 150px;
      line-height: 1.5;
      padding: 10px;
      border-right: 1px solid ${Colors.greyE6};

      &:last-child {
        border-right: none;
        min-width: 175px;
      }
    }
  }
`;

const SearchBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  label {
    user-select: none;
    display: flex;
    align-items: center;
    color: ${Colors.white};

    ${CustomCheckbox} {
      margin: 8px;
    }

    ${CheckboxInput}:checked {
      & ~ ${CustomCheckbox}::before {
        border: 1px solid ${Colors.greyE6} !important;
      }
    }
  }

  .ais-SearchBox {
    flex-grow: 1;
    flex-basis: 300px;
  }

  .ais-SearchBox-input {
    height: 40px;
  }
`;

const SearchPlaceholderStyles = styled.div`
  padding: 20px;

  h2 {
    text-align: center;
  }

  a {
    color: ${Colors.white};
    text-decoration: underline;

    &:visited {
      color: ${Colors.white};
    }
  }

  & p {
    text-align: center;
    color: ${Colors.purpleDF};
    margin: 0 auto 30px auto;
    max-width: 450px;
  }
`;

const StudentResultsContainer = styled.div`
  overflow: auto;
  border-bottom-right-radius: 18px;
  border-bottom-left-radius: 18px;
`;

const StudentResults = styled.div`
  > ul {
    display: block;
    padding: 0;
    width: min-content;
    min-width: 100%;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;

    li {
      border-top: 1px solid ${Colors.greyE6};
      border-right: 1px solid ${Colors.greyE6};
      border-left: 1px solid ${Colors.greyE6};
      display: flex;

      &:last-child {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
      }

      & > div {
        flex: 1;
        background: ${Colors.white};
        width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        padding: 5px 10px;
        line-height: 24px;
        border-right: 1px solid ${Colors.greyE6};

        &:last-child {
          border-right: none;
          text-align: right;
          min-width: 175px;
        }

        button {
          display: inline-block;
          width: 100%;
          line-height: 1;
          height: 24px;
          min-width: unset;
          padding: 0;
        }
      }

      .hit-download {
        display: flex;
        align-items: center;
      }

      .hit-time {
        line-height: 1.2;
        display: flex;
        align-items: center;
      }

      .hit-account-enabled {
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
`;

const StudentPagination = styled(Pagination)`
  ul {
    padding: 0;
    list-style: none;
    display: flex;

    li {
      display: block;
      flex: 1;
      width: calc(100% / 4);

      &:first-child {
        span,
        a {
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }

      &:last-child {
        span,
        a {
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }

      span,
      a {
        color: ${Colors.white};
        display: block;
        width: 100%;
        line-height: 42px;
        text-align: center;
      }

      a {
        color: ${Colors.white};
        transition: all 250ms ease-in-out 0s;

        &:hover {
          background: ${Colors.purpleDF};
          color: ${Colors.purple72};
        }
      }
    }
  }
`;

const StudentsStyles = styled(DashboardStyles)`
  height: 100%;
  margin: 20px 0;

  > h3 {
    color: ${Colors.white};
    margin-bottom: 40px;
  }
  h2 {
    color: ${Colors.white};
  }

  & .IntroAccount__Results {
    color: ${Colors.white};
    margin: 40px 0 0;
    font-size: 16px;

    > a {
      color: ${Colors.white};
      text-decoration: underline;
    }
  }

  form {
    input {
      background: ${Colors.white};
      display: block;
      line-height: 40px;
      padding: 0 12px;
      outline: none;
      border-radius: 3px;
      border: 1px solid ${Colors.greyE6};
      font-size: 14px;
      transition: all 250ms ease-in-out 0s;
      width: 100%;

      &:focus {
        border: 1px solid rgba(70, 142, 250, 0.5);
        box-shadow: 0px 0px 2px 0px rgba(70, 142, 250, 1);
      }
    }

    > button {
      display: none;
    }
  }
`;

const Container = styled.div`
  padding: 20px;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;

  ${CardLogoImage} {
    bottom: 8px;
  }

  ${StudentsStyles} > ${Loader} {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StudentTopCardsStyles = styled.div`
  display: flex;
  justify-content: center;
  margin-left: -8px;
  margin-right: -8px;
  flex-wrap: wrap;

  @media (max-width: 992px) {
    justify-content: center;
  }

  & > div {
    margin: 8px;
    width: 150px;
    height: 250px;
    position: relative;

    span {
      position: absolute;
      bottom: 15%;
      background-color: white;
      color: black;
      font-size: 0.8em;
      text-transform: uppercase;
      padding: 6px;
      width: 100%;
      display: block;
      text-align: center;
    }
  }
`;

const StudentTopChallengesSection = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 992px) {
    max-width: 940px;
  }
`;

const StudentTopChallengesContainerStyles = styled.div`
  display: flex;
  margin-bottom: 40px;

  ${Loader} {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 992px) {
    flex-direction: column;
  }
`;

const StudentTopChallengesStyles = styled.div`
  flex-grow: 1;
  margin: 8px 0 8px 16px;
  overflow: hidden;
  // Fix iOS Safari border-radius
  position: relative;
  border-radius: 18px;
  padding: 18px 0;
  background: ${Colors.white};

  @media (max-width: 992px) {
    margin-left: 0;
  }

  ol {
    margin: 0;
    padding: 8px 0;
    list-style: none;
    height: 100%;
    max-height: 214px;
    overflow-y: auto;

    li {
      padding: 8px 16px;
      display: flex;
      justify-content: space-between;

      :not(:last-child) {
        border-bottom: 1px solid ${Colors.greyE6};
      }

      span:last-child {
        margin-left: 8px;
      }
    }
  }
`;

const StudentResultsErrorMessageStyles = styled.p`
  color: ${Colors.white};
`;

const AlgoliaSearchStyles = styled.div`
  .ais-SearchBox-input,
  .ais-Pagination-item > a {
    border-radius: 18px;
  }
`;

const ExportCSVStyles = styled.div`
  background: ${Colors.greyE6};
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  padding: 10px 10px 0;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap-reverse;

  button {
    width: 155.5px;
    padding: 0 10px;

    svg {
      margin-right: 10px;
    }

    span {
      width: 100%;
      display: inline;
      height: auto;
      line-height: initial;
    }
  }
`;

const StudentExportErrorStyles = styled.span`
  margin-right: 10px;
  padding: 10px 0;
`;

const ChartContainer = styled.div`
  display: flex;
  margin-bottom: 16px;

  :not(:first-child) {
    margin-top: 16px;
  }

  ${SignUpsChart} {
    min-width: 0;
    flex: 1;
  }
`;

const AdditionalInfo = styled.div<IAdditionalInfoProps>`
  border-radius: 18px;
  background: ${Colors.white};
  padding: 8px 0;
  margin-left: 16px;
  overflow-y: auto;
  width: 250px;
  display: ${props => (props.isDesktop ? 'block' : 'none')};

  @media screen and (max-width: 767px) {
    display: ${props => (props.isDesktop ? 'none' : 'block')}
      ${props =>
        !props.isDesktop
          ? `
      margin-left: 0;
      flex-basis: 250px;
      flex-grow: 1;
      width: auto;
    `
          : ''};
  }

  ${Loader} {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      padding: 8px 16px;

      :not(:last-child) {
        border-bottom: 1px solid ${Colors.greyE6};
      }

      div:nth-child(1) {
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      div:nth-child(2) {
        font-weight: bold;
      }
    }
  }
`;

const AdditionalInfoContainer = styled.div`
  display: none;

  @media screen and (max-width: 767px) {
    display: block;
    margin: -24px -8px 0;

    & > ${AdditionalInfo} {
      margin: 16px 8px;
    }

    & > :last-child {
      margin-bottom: 40px;
    }
  }
`;

const SignUpsAndInfoContainer = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  & > :last-child {
    margin-bottom: 40px;
  }

  @media (max-width: 992px) {
    max-width: 940px;
  }
`;

const SchoolTableContainer = styled.div`
  .table-overflow-container {
    border-radius: 18px;
    overflow: hidden;

    // Fix for Safari iOS border-radius;
    position: relative;
  }

  .schools-table-overflow {
    table {
      width: 1140px;
    }
  }

  .table-overflow {
    overflow: auto;

    table {
      border-spacing: 0;
      border-collapse: collapse;

      tr {
        border: 1px solid ${Colors.greyE6};
      }

      th {
        width: calc(95% / 5);
        background: ${Colors.greyE6};
        color: ${Colors.grey33};
        line-height: 1.5;
        font-weight: normal;
        text-align: left;
        padding: 10px;
        white-space: nowrap;

        &:last-child {
          width: 5%;
        }

        button {
          height: auto;
          width: 100%;
          line-height: inherit;
          font-weight: inherit;
          text-align: inherit;
          background: inherit;
          color: ${Colors.purple72};
          padding: 10px;
          margin: -10px;
          box-sizing: content-box;
          border-radius: inherit;
          text-decoration: underline;

          &:hover {
            transform: none;
          }

          &[data-sort-direction='asc'] {
            &::after {
              content: '\u25B2';
              position: relative;
              float: right;
              padding-left: 0.75rem;
            }
          }

          &[data-sort-direction='desc'] {
            &::after {
              content: '\u25BC';
              position: relative;
              float: right;
              padding-left: 0.75rem;
            }
          }
        }
      }

      td {
        background: ${Colors.white};
        padding: 5px 10px;
        width: calc(95% / 5);
        overflow-wrap: anywhere;
        box-sizing: border-box;
        border-right: 1px solid ${Colors.greyE6};

        &:last-child {
          border-right: none;
          width: 5%;
        }

        a {
          text-decoration: underline;

          &:hover {
            transform: none;
          }
        }

        button {
          white-space: nowrap;
          height: 29px;
          line-height: 1;
          width: 100%;
          padding: 0 10px;
          min-width: 24px;

          path {
            fill: currentColor;
          }
        }
      }
    }
  }

  .school-table-placeholder {
    background: ${Colors.white};
    width: 1140px;

    &--loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 598px;
    }
  }

  .school-table-error {
    padding: 10px;
    margin-bottom: 0;
  }

  .school-pagination {
    padding: 0;
    list-style: none;
    display: flex;

    li {
      display: block;
      flex: 1;
      width: calc(100% / 4);

      span,
      button {
        color: ${Colors.white};
        display: block;
        width: 100%;
        line-height: 42px;
        text-align: center;
        cursor: default;
      }

      button {
        color: ${Colors.white};
        transition: all 250ms ease-in-out 0s;

        &:not(:disabled):hover {
          background: ${Colors.purpleDF};
          color: ${Colors.purple72};
          transform: none;
          cursor: pointer;
        }
      }
    }
  }
`;

const SchoolsTableSection = styled.div`
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 992px) {
    max-width: 940px;
  }
`;

const SchoolDetailsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    color: ${Colors.white};
    margin-bottom: 20px;
  }

  @media (max-width: 992px) {
    max-width: 940px;
  }

  & > a[href] {
    margin-bottom: 20px;
    color: ${Colors.white};
    text-decoration: underline;
  }
`;

// EXPORTS
export {
  Container,
  SearchBoxContainer,
  StudentResults,
  StudentPagination,
  StudentResultsHeader,
  StudentsStyles,
  SearchPlaceholderStyles,
  StudentTopCardsStyles,
  StudentTopChallengesContainerStyles,
  StudentTopChallengesStyles,
  StudentResultsErrorMessageStyles,
  AlgoliaSearchStyles,
  StudentResultsContainer,
  ExportCSVStyles,
  StudentExportErrorStyles,
  ChartContainer,
  AdditionalInfo,
  AdditionalInfoContainer,
  SignUpsAndInfoContainer,
  StudentTopChallengesSection,
  SchoolTableContainer,
  SchoolsTableSection,
  SchoolDetailsHeader
};
