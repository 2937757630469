// IMPORTS
import React from 'react';

// CONTAINERS
import PickThreeContainer from '../../../containers/PickThreeContainer';

// COMPONENT
const PickThree = () => <PickThreeContainer />;

// EXPORTS
export default PickThree;
