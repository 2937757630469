// IMPORTS
import React from 'react';

// CONTAINERS
import CardsContainer from '../../containers/CardsContainer';

// COMPONENT
const Cards = (props: any) => <CardsContainer location={props.location} />;

// EXPORTS
export default Cards;
