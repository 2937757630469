// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import { InputStyles } from '../Input/styles';

// MAIN COMPONENTS

const Container = styled.div`
  display: block;
  padding: 30px 30px 0 30px;
  height: 100%;
  overflow-y: auto;

  h2,
  p,
  hr {
    margin-bottom: 20px;
  }

  ${InputStyles.toString} {
    input {
      width: 100%;
    }
  }

  form {
    p {
      text-align: center;
      margin-bottom: 0;
      small {
        line-height: 42px;
        margin-left: 20px;
      }
      small#centre {
        margin-left: 0;
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    width: 100%;
  }
`;

const ForgotStyles = styled.div`
  form {
    position: relative;
  }

  button {
    margin-top: 10px;
    line-height: 42px;
    width: 100%;
  }

  @media (min-width: ${Screens.medium}) {
    button {
      width: inherit;
    }
  }

  @media (max-width: ${Screens.medium}) {
    display: flex;
    flex-direction: column;
  }

  height: 100%;
`;

const Success = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  padding: 10px 5px;

  > div {
    width: 100%;
    text-align: center;
    line-height: 22px;
    margin-bottom: 5px;
  }
`;

// EXPORTS
export { Container, ForgotStyles, Success };
