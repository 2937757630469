// IMPORTS
import { createGlobalStyle } from 'styled-components';
import Color from 'color';
import Colors from '../Colors';
import Screens from '../Screens';

export const GlobalStyles = createGlobalStyle`
    /* Normalize */
    html{line-height:1.15;-webkit-text-size-adjust:100%}body{margin:0}main{display:block}h1{font-size:2em;margin:.67em 0}hr{box-sizing:content-box;height:0;overflow:visible}pre{font-family:monospace,monospace;font-size:1em}a{background-color:transparent}abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}b,strong{font-weight:bolder}code,kbd,samp{font-family:monospace,monospace;font-size:1em}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sub{bottom:-.25em}sup{top:-.5em}img{border-style:none}button,input,optgroup,select,textarea{font-family:inherit;font-size:100%;line-height:1.15;margin:0}button,input{overflow:visible}button,select{text-transform:none}[type=button],[type=reset],[type=submit],button{-webkit-appearance:button}[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner,button::-moz-focus-inner{border-style:none;padding:0}[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring,button:-moz-focusring{outline:1px dotted ButtonText}fieldset{padding:.35em .75em .625em}legend{box-sizing:border-box;color:inherit;display:table;max-width:100%;padding:0;white-space:normal}progress{vertical-align:baseline}textarea{overflow:auto}[type=checkbox],[type=radio]{box-sizing:border-box;padding:0}[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}[type=search]{-webkit-appearance:textfield;outline-offset:-2px}[type=search]::-webkit-search-decoration{-webkit-appearance:none}::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}details{display:block}summary{display:list-item}template{display:none}  

    *, *::after, *::before {
      box-sizing: border-box;
    }

    body, html {
      margin: 0;
      border: 0;
      padding: 0;
      height: 100%;
      font-size: 16px;
      font-family: europa, 'Open Sans', sans-serif;
      color: ${Colors.grey66};
    }

    body {
      display: flex;
      flex-direction: column;
    }

    #root {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    h1, h2, h3, h4, h5 {
      margin-top: 0;
      margin-bottom: 30px;
      font-family: europa, sans-serif;
      color: ${Colors.grey33};
    }

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    h2 {
      font-size: 24px;
      line-height: 30px;
    }

    h3 {
      font-size: 21px;
      line-height: 28px;
    }

    p {
      margin-top: 0;
      margin-bottom: 30px;

      small {
          font-size: 14px;
          line-height: 21px;
      }
    }

    a {
      color: ${Colors.grey66};
      transition: all 250ms ease-in-out 0s;
      text-decoration: none;

      &:hover {
          color: ${Colors.blue00};
      }
    }
    
    button {
      background: ${Colors.purple72};
      color: ${Colors.white};
      font-family: europa, sans-serif;
      font-weight: 600;
      padding: 0 30px;
      line-height: 40px;
      height: 42px;
      border: none;
      border-radius: 25px;
      cursor: pointer;

      span {
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          line-height: 50px;
          margin: 0 2px;
      }

      &:focus {
          outline: none;
          border-color: ${Colors.black};
          box-shadow: 0px 0px 0px 2px
          ${Color(Colors.black)
            .rgb()
            .toString()};
      }

      @media (min-width: ${Screens.medium}) {
          &:hover {
              transform: scale(1.05);
          }
      }
  }
`;
