// IMPORTS
import React from 'react';

// CONTAINERS
import CardIntoContainer from '../../../containers/CardIntroContainer';

// COMPONENT
const CardInto = () => <CardIntoContainer />;

// EXPORTS
export default CardInto;
