// IMPORTS
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import Screens from '../../styles/Screens';

// TYPES
interface IProps {
  isDashboard: boolean;
}

// MAIN COMPONENTS
const LanguageButtonStyle = styled('button')<IProps>`
  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 0;
  border-radius: 50%;
  color: ${props => (props.isDashboard ? Colors.white : Colors.purple62)};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.isDashboard ? Colors.purple72 : Colors.white)};
  border: 1px solid ${props => (props.isDashboard ? Colors.white : Colors.purple62)};
  transition: transform 0.1s ease-in-out;
  outline: none;
  position: relative;

  &:active {
    transform: scale(0.9);
  }

  & + & {
    margin-left: 8px;
  }

  @media (min-width: ${Screens.medium}) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

// EXPORTS
export default LanguageButtonStyle;
