// IMPORTS
import { Dispatch } from 'redux';
import { MODAL_SHOW, MODAL_HIDE } from './types';

// MAIN ACTIONS
/**
 *
 */
const doModalShow = () => (dispatch: Dispatch) => {
  dispatch({
    type: MODAL_SHOW
  });
};

/**
 *
 */
const doModalHide = () => (dispatch: Dispatch) => {
  dispatch({
    type: MODAL_HIDE
  });
};

// EXPORTS
export { doModalShow, doModalHide };
