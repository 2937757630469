// IMPORTS
import React, { useState, useEffect, useRef } from 'react';
import DocumentTitle from 'react-document-title';
import { LiveAnnouncer } from 'react-aria-live';
import Routes from './routes';
import { GlobalStyles } from './styles/GlobalStyles';
import { Provider } from 'react-redux';
import configureStore, { history } from './redux/store';
import { ConnectedRouter } from 'connected-react-router';
import i18n from 'i18n-js';
import { useIdleTimer } from 'react-idle-timer';
import { DO_USER_LOGOUT } from './containers/LoginContainer/types';
import { MODAL_HIDE } from './containers/ModalBackgroundContainer/types';
import { initializeI18n } from './locale/translations';
import LanguageContext from './locale/LanguageContext';
import * as Sentry from '@sentry/browser';
import * as env from './config/env.json'
import ReactGA from 'react-ga';
import gaUtil from './util/ga'
import "wicg-inert";

import ErrorBoundary from './ErrorBoundary';

// VARIABLES
const store = configureStore();

// Sentry
if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://5183bfa460b241609b23101a85df3fa6@sentry.io/5180436',
    ignoreErrors: [
      `Cannot read property 'contentWindow' of null`
    ]
  });
}

// MAIN COMPONENT
const App = () => {
  const [languageContext, setLanguageContext] = useState({
    language: initializeI18n(),
    translate: i18n.t
  });
  const {reset} = useIdleTimer({
    timeout: 1000 * 60 * 60,
    onIdle: handleOnIdle,
  })
  const roleRef = useRef('')

  useEffect(() => {
    ReactGA.initialize(env.GA_ID);
  }, [])

  useEffect(() => {
    return store.subscribe(() => {
      const {accountReducer} = store.getState()
      const role = accountReducer.get('role')
  
      roleRef.current = role
    })
  }, [])

  useEffect(() => {
    gaUtil.pageView.recordPageView(roleRef.current, {path: history.location.pathname})
    
    return history.listen((location) => {
      gaUtil.pageView.recordPageView(roleRef.current, {path: location.pathname})
    })
  }, [])

  function handleOnIdle() {
    const {authReducer} = store.getState()
    if (authReducer.get('user')) {
      store.dispatch({
        type: DO_USER_LOGOUT,
        payload: {
          timeout: true
        }
      })
      store.dispatch({
        type: MODAL_HIDE
      })
    } else {
      reset()
    }
  }
  
  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  const toggleLanguage = () => {
    const lang = languageContext.language === 'en' ? 'fr' : 'en';
    gaUtil.events.navigation.changeLanguage(roleRef.current, {language: lang})
    localStorage.setItem('language', lang);
    i18n.locale = lang;
    document.documentElement.setAttribute('lang', lang);
    setLanguageContext({
      ...languageContext,
      language: lang
    });
  };

  // Needed in order to prevent too deeply nested languageContext
  const mergedContext = { ...languageContext, toggleLanguage };
  const documentTitle = languageContext.language === 'en'
    ? 'Challenge Cards - Meaningful Career Exploration'
    : 'Cartes défis - Exploration de carrière significative';

  if (isIE) {
    return <p>{i18n.t("internetExplorerSupportMessage")}</p>
  }

  return (
    <LiveAnnouncer>
      <LanguageContext.Provider value={mergedContext}>
        <ErrorBoundary>
          <Provider store={store}>
            <DocumentTitle title={documentTitle}>
              <ConnectedRouter history={history}>
                <GlobalStyles />
                <Routes />
              </ConnectedRouter>
            </DocumentTitle>
          </Provider>
        </ErrorBoundary>
      </LanguageContext.Provider>
    </LiveAnnouncer>
  );
};

// EXPORTS
export default App;
