// IMPORTS
import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

const AnimatedDiv = (props: any) => {
  const { color, watermarkImageUrl, ...otherProps } = props;
  return <animated.div {...otherProps} />;
};

// MAIN COMPONENTS
const CardStyles = styled(AnimatedDiv)`
  display: block;
  background: ${props => props.color || ''};
  ${props =>
    props.watermarkImageUrl
      ? `background-image: url(${props.watermarkImageUrl}); background-size: contain; background-repeat: no-repeat;`
      : ''}
  width: 240px;
  height: 400px;
  border-radius: 18px;
  margin: 0 auto;
`;

export const CardImage = styled.img`
  width: 100%;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
`;

export const CardText = styled.p`
  white-space: break-spaces;
`;

export const CardLogoImage = styled.img`
  max-height: 1.5rem;
  max-width: 3rem;
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;

// TODO: find the style that overlap. it's hard to find what's causing display issue that's why we used !important
export const BulletDescriptionContainer = styled.span`
  display: inline !important;
  font-weight: normal;
`;

// EXPORTS
export default CardStyles;
