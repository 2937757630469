import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { TextArea } from '../Inputs';
import ErrorStyles from '../../styles/Error';
import SuccessStyles from '../../styles/Success';
import LoaderStyles from '../../styles/Loader/styles';

// MAIN COMPONENTS
export const SupportForm = styled.form`
  position: relative;

  ${LoaderStyles} {
    position: absolute;
    top: -64px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
`;

const SupportStyles = styled.div<{ disabled: boolean }>`
  margin: 20px;
  padding: 20px 15px;
  margin: 0 auto;
  color: ${Colors.white};

  label {
    display: block;
    font-weight: 600;
    margin-bottom: 10px;
    color: ${Colors.white};
  }

  @media (min-width: 992px) {
    width: 940px;
  }

  @media (min-width: 1200px) {
    width: 1140px;
  }

  h1 {
    color: ${Colors.white};
  }

  & a {
    font-weight: 600;
    color: ${Colors.white} !important;
    &:hover {
      color: ${Colors.white} !important;
      text-decoration: underline;
    }
  }

  & * {
    pointer-events: ${props => (props.disabled ? 'none' : 'unset')} !important;
    user-select: ${props => (props.disabled ? 'none' : 'unset')} !important;
  }
  filter: ${props => (props.disabled ? 'opacity(0.5)' : '')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'unset')} !important;

  ${ErrorStyles}, ${SuccessStyles} {
    margin-bottom: 20px;
  }
`;

export const SupportTextArea = styled(TextArea)`
  background: ${props => (props.disabled ? Colors.greyF8 : Colors.white)};
  display: block;
  line-height: 40px;
  padding: 0 12px;
  outline: none;
  border-radius: 3px;
  border: 1px solid ${Colors.greyE6};
  font-size: 14px;
  transition: all 250ms ease-in-out 0s;
  width: 100%;
  resize: none;
  margin-bottom: 20px;

  &:focus {
    border: 1px solid rgba(70, 142, 250, 0.5);
    box-shadow: 0px 0px 2px 0px rgba(70, 142, 250, 1);
  }

  &::placeholder {
    color: ${Colors.grey75};
  }
`;

export const Field = styled.p`
  color: ${Colors.white};
  & span {
    font-weight: 600;
  }
`;
export default SupportStyles;
