import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import PickThree from '../../components/PickThree';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter, Prompt, useHistory } from 'react-router';
import { push } from 'connected-react-router';
import { CardEnum } from '../../components/Card';
import ModalBackgroundContainer from '../ModalBackgroundContainer';
import Modal from '../../components/Modal';
import CardModal, { CardType } from '../../components/CardModal';
import { doModalShow, doModalHide } from '../ModalBackgroundContainer/actions';
import { doSetStartTime, doDeleteResult } from '../../containers/CardResultContainer/actions';
import LanguageContext from '../../locale/LanguageContext';
import { CardSelectionType } from '../../types/customCard';
import gaUtil from '../../util/ga'

const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      doModalShow,
      doModalHide,
      doSetStartTime,
      doDeleteResult
    },
    dispatch
  );

type Props = AppState & ReturnType<typeof mapDispatchToProps>;

const PickThreeContainer: any = (props: any) => {
  const { translate, language } = useContext(LanguageContext);
  const [showModal, setShowModal] = useState(false);
  const [cardType, setCardType] = useState<CardType>(null);
  const activeElement = useRef<HTMLElement | null>(null);
  const {doModalHide, doDeleteResult, doSetStartTime} = props
  const history = useHistory()
  const role = props.accountReducer.get('role')
  const hasResults = props.accountReducer.get('results');
  const isFirstSort = !hasResults

  const onConfirm = (cards: CardEnum[] | string[] | CardSelectionType[]) => {
    const sortStartTime = props.resultReducer.get('sortStartTime')
    gaUtil.events.cardSort.completeCardSort(role, { isFirstSort })
    props.push('/dashboard/cards/results', {
      selected: cards,
      cards: props.router.location.state.cards,
      language,
      totalTimeSpent: sortStartTime ? Date.now() - sortStartTime : 0
    });
    props.doSetStartTime(null)
  };
  const onModalClose = useCallback(() => {
    setShowModal(false);
    doModalHide();
    if (activeElement.current) {
      setTimeout(() => {
        activeElement.current?.focus()
      }, 0);
    }
  }, [doModalHide]);

  useEffect(() => {
    function reset(location: any) {
      const {pathname} = location
      if (!/\/cards\/(select|results|[0-9]+)$/.test(pathname)) {
        doSetStartTime(null)
        doDeleteResult()
        gaUtil.events.cardSort.abandonCardSort(role, {isFirstSort})
      }
    }

    if (history) {
      return history.listen(reset)
    }

  }, [history, doSetStartTime, doDeleteResult, role, isFirstSort])

  useEffect(() => {
    if (showModal) {
      activeElement.current = document.activeElement as HTMLElement

      const escapeHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onModalClose()
        }
      }

      document.addEventListener('keyup', escapeHandler)

      return () => {
        document.removeEventListener('keyup', escapeHandler)
      }
    }
  }, [showModal, onModalClose])
  
  const onClickInfo = (type: CardType) => (e: any) => {
    e.stopPropagation();
    setCardType(type);
    setShowModal(true);
    props.doModalShow();
    gaUtil.events.cardSort.viewBackOfCard(role, {card: type && type.name})
  };
  return (
    <>
      <Prompt
        message={({ pathname }) => {
          return !/\/cards\/(select|results|[0-9]+)$/.test(pathname)
            ? translate('CardSortContainer.Warning.PreventLeave')
            : true;
        }}
      />
      <PickThree {...props} onClickInfo={onClickInfo} onConfirm={onConfirm} />
      <ModalBackgroundContainer isDashboard={true} />
      {showModal && (
        <Modal
          hideHeader={true}
          minHeight={null}
          maxHeight={660}
          show={true}
          loading={false}
          onCancel={onModalClose}
          noPadding
        >
          <CardModal type={cardType} />
        </Modal>
      )}
    </>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickThreeContainer));
