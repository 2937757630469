import React from 'react';
import { List } from './styles';

const StudentTablePagination = ({
  page,
  hasNextPage,
  hasPrevPage,
  handlePageChange,
  isDisabled
}: {
  page: number;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  handlePageChange: Function;
  isDisabled: boolean;
}) => {
  return (
    <List>
      <li>
        <button
          aria-label="Go to first page"
          onClick={() => handlePageChange('first')}
          disabled={page === 1 || !hasPrevPage || isDisabled}
        >
          «
        </button>
      </li>
      <li>
        <button
          aria-label="Go to previous page"
          onClick={() => handlePageChange('prev')}
          disabled={page === 1 || !hasPrevPage || isDisabled}
        >
          ‹
        </button>
      </li>
      <li aria-label="Current page">{page}</li>
      <li>
        <button
          aria-label="Go to next page"
          onClick={() => handlePageChange('next')}
          disabled={!hasNextPage || isDisabled}
        >
          ›
        </button>
      </li>
      <li>
        <button
          aria-label="Go to last page"
          onClick={() => handlePageChange('last')}
          disabled={!hasNextPage || isDisabled}
        >
          »
        </button>
      </li>
    </List>
  );
};

export default StudentTablePagination;
