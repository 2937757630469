// IMPORTS
import { put, takeLeading, select } from 'redux-saga/effects';
import gaUtil from '../../util/ga';
import fetch from '../../fetch';
import firebase from '../../firebase';
import { isEmailValid } from '../../util/validation';

// DISPATCH TYPES
import {
  ACCOUNT_DETAILS_UPDATE_REQUEST,
  ACCOUNT_DETAILS_UPDATE_SUCCESS,
  ACCOUNT_DETAILS_UPDATE_FAIL,
  ACCOUNT_GET_USER_REQUEST,
  ACCOUNT_GET_USER_FAIL,
  ACCOUNT_GET_USER_SUCCESS,
  ACCOUNT_PASSWORD_UPDATE_REQUEST,
  ACCOUNT_PASSWORD_UPDATE_FAIL,
  ACCOUNT_PASSWORD_UPDATE_SUCCESS,
  ACCOUNT_ADDRESS_UPDATE_REQUEST,
  ACCOUNT_ADDRESS_UPDATE_FAIL,
  ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST,
  ACCOUNT_SCHOOL_CODE_UPDATE_FAIL,
  ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS
} from './types';

import {
  USER_LOGIN_ERROR
} from '../LoginContainer/types'

import {
  RESULT_DO_LOAD,
  RESULT_DO_LOAD_ALL_CARDS
} from '../CardResultContainer/types'

// TYPES
interface ErrorObject {
  [field: string]: string;
}

// GENERATORS
/**
 *
 */
export function* workerGetUserData() {
  try {
    const results = yield fetch.get('/auth/me');

    if (!results || !results.data) {
      throw new Error('error.defaultMessage');
    }

    const {role, code} = results.data

    gaUtil.dimensions.setDimensions(role, {role, code})
    gaUtil.events.authentication.login(role)

    if (role === 'student') {
      yield put({
        type: RESULT_DO_LOAD,
      });
    } else if (role === 'admin' || role === 'school') {
      yield put({
        type: RESULT_DO_LOAD_ALL_CARDS
      });
    }

    yield put({
      type: ACCOUNT_GET_USER_SUCCESS,
      payload: results.data
    });
  } catch (e) {
    let message = e.message || 'error.defaultMessage';

    if (e.response && e.response.status === 404) {
      yield put({
        type: USER_LOGIN_ERROR,
        payload: 'We did not find a user with this e-mail address'
      });
    } else {
      if (e.response && e.response.status === 404) {
        message = 'We did not find a user with this e-mail address';
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors.length > 0
      ) {
        message = e.response.data.errors.map((item: ErrorObject) => `${item.msg}\n`);
      }
    }
    
    yield put({
      type: ACCOUNT_GET_USER_FAIL,
      payload: { message }
    });

    try {
      yield firebase.auth().signOut();
      gaUtil.dimensions.resetAllDimensions()
    } catch (e) {
      console.log(e);
    }
  }
}

/**
 *
 */
function* watcherGetUserData() {
  yield takeLeading(ACCOUNT_GET_USER_REQUEST, workerGetUserData);
}

/**
 *
 * @param param0
 */
function* workerUpdateAccountDetails({ payload }: ReduxAction) {
  try {
    // 1. Get payload
    const { firstname, lastname, email, hasCompletedConsumerEndSurvey } = payload;
    const shouldUpdateConsumerEndSurvey = typeof hasCompletedConsumerEndSurvey === 'boolean';
    if (!shouldUpdateConsumerEndSurvey) {
      if (!firstname) { throw new Error('error.firstNameRequired') }
      if (!lastname) { throw new Error('error.lastNameRequired') }
      if (!email) { throw new Error('error.emailRequired') }
      if (!isEmailValid(email)) { throw new Error('auth/invalid-email') }
    }

    const results = yield fetch.put('/users/me', {
      ...(firstname && { firstname }),
      ...(lastname && { lastname }),
      ...(email && { email }),
      ...(typeof hasCompletedConsumerEndSurvey === 'boolean' && { hasCompletedConsumerEndSurvey })
    });

    if (!results || !results.data || !results.data.data) {
      throw new Error('error.defaultMessage');
    }

    const role = yield select(state => state.accountReducer.get('role'))
    gaUtil.events.account.changeDetails(role)

    yield put({
      type: ACCOUNT_DETAILS_UPDATE_SUCCESS,
      payload: {
        email: results.data.data.email,
        firstname: results.data.data.firstname,
        lastname: results.data.data.lastname,
        hasCompletedConsumerEndSurvey: results.data.data.hasCompletedConsumerEndSurvey
      }
    });
  } catch (e) {
    let message = e.message || 'error.defaultMessage';
    if (
      e.response && 
      e.response.data && 
      e.response.data.message &&
      e.response.data.message.code === 'auth/invalid-email') {
      message = e.response.data.message.code
    }
    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      message = e.response.data.errors.map((item: ErrorObject) => `${item.msg}\n`);
    }
    yield put({
      type: ACCOUNT_DETAILS_UPDATE_FAIL,
      payload: message
    });
  }
}

/**
 *
 */
function* watcherUpdateAccountDetails() {
  yield takeLeading(ACCOUNT_DETAILS_UPDATE_REQUEST, workerUpdateAccountDetails);
}

/**
 *
 * @param param0
 */
function* workerUpdateAccountPassword({ payload }: ReduxAction) {
  try {
    // 1. Get payload
    const { password, confirm } = payload;

    if (!password) { throw new Error('error.passwordRequired') }
    if (password.length < 8) { throw new Error('error.passwordMinLength') }
    if (password !== confirm) { throw new Error('error.passwordsDoNotMatch') }

    const results = yield fetch.put('/users/me', {
      password,
      confirm
    });

    if (!results || !results.data || !results.data.data) {
      throw new Error('error.defaultMessage');
    }

    const role = yield select(state => state.accountReducer.get('role'))
    gaUtil.events.account.changePassword(role)
    
    yield put({
      type: ACCOUNT_PASSWORD_UPDATE_SUCCESS
    });
  } catch (e) {
    let message = e.message || 'error.defaultMessage';
    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      message = e.response.data.errors.map((item: ErrorObject) => `${item.msg}\n`);
    }

    if (
      e.response &&
      e.response.data &&
      e.response.data.message &&
      e.response.data.message.length > 0
    ) {
      message = e.response.data.message;
    }

    yield put({
      type: ACCOUNT_PASSWORD_UPDATE_FAIL,
      payload: message
    });
  }
}

/**
 *
 */
function* watcherUpdateSchoolCode() {
  yield takeLeading(ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST, workerUpdateSchoolCode);
}

function* workerUpdateSchoolCode({ payload }: ReduxAction) {
  try {
    // 1. Get payload
    const { code, emailDomainWhitelist, emailDomainBlacklist } = payload;

    if (!code) { throw new Error('error.codeRequired') }

    const { status, data } = yield fetch.put('/users/code', {
      code,
      emailDomainWhitelist,
      emailDomainBlacklist
    }, {
        validateStatus: status => status === 204 || status === 400
    });

    if (data.error && data.error.code === 'reservation-taken') {
      throw new Error('error.codeTaken');
    }

    if (status !== 204) {
      throw new Error('error.defaultMessage');
    }

    const role = yield select(state => state.accountReducer.get('role'))
    gaUtil.events.account.changeCode(role, {code})
    gaUtil.dimensions.setDimensions(role, {role, code})

    yield put({
      type: ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS,
      payload: {
        code,
        emailDomainWhitelist,
        emailDomainBlacklist
      }
    });
  } catch (error) {
    let message = error.message || 'error.defaultMessage';

    yield put({
      type: ACCOUNT_SCHOOL_CODE_UPDATE_FAIL,
      payload: message
    });
  }
}

/**
 *
 */
function* watcherUpdateAccountPassword() {
  yield takeLeading(ACCOUNT_PASSWORD_UPDATE_REQUEST, workerUpdateAccountPassword);
}

/**
 *
 * @param param0
 */
function* workerUpdateAccountAddress({ payload }: ReduxAction) {
  try {
    // 1. Get payload
    const { address, city, state, country, postalzip, phone, schoolname } = payload;

    if (!schoolname) { throw new Error('error.schoolNameRequired') }
    if (!address) { throw new Error('error.addressRequired') }
    if (!city) { throw new Error('error.cityRequired') }
    if (!state) { throw new Error('error.stateRequired') }
    if (!country) { throw new Error('error.countryRequired') }
    if (!postalzip) { throw new Error('error.validPostalCodeRequired') }
    if (!phone) { throw new Error('error.phoneNumberRequired') }

    const results = yield fetch.put('/users/me', {
      address,
      city,
      state,
      country,
      postalzip,
      phone,
      schoolname
    });

    if (!results || !results.data || !results.data.data) {
      throw new Error('error.defaultMessage');
    }

    yield put({
      type: ACCOUNT_ADDRESS_UPDATE_SUCCESS,
      payload: {
        address: results.data.data.address,
        city: results.data.data.city,
        state: results.data.data.state,
        country: results.data.data.country,
        postalzip: results.data.data.postalzip,
        phone: results.data.data.phone,
        schoolname: results.data.data.schoolname
      }
    });
  } catch (e) {
    let message = e.message || 'error.defaultMessage';
    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      message = e.response.data.errors.map((item: ErrorObject) => `${item.msg}\n`);
    }

    if (
      e.response &&
      e.response.data &&
      e.response.data.message &&
      e.response.data.message.length > 0
    ) {
      message = e.response.data.message;
    }

    yield put({
      type: ACCOUNT_ADDRESS_UPDATE_FAIL,
      payload: message
    });
  }
}

/**
 *
 */
function* watcherUpdateAccountAddress() {
  yield takeLeading(ACCOUNT_ADDRESS_UPDATE_REQUEST, workerUpdateAccountAddress);
}

// EXPORTS
export {
  watcherGetUserData,
  watcherUpdateAccountDetails,
  watcherUpdateAccountPassword,
  watcherUpdateAccountAddress,
  watcherUpdateSchoolCode
};

// import {
//   DO_GET_USER_DATA,
//   GET_USER_DATA_SUCCESS,
//   START_GET_USER_DATA,
//   GET_USER_DATA_ERROR,
//   DO_SAVE_USER_DATA
// } from './types';
// import { EditPayload } from './actions';
// import fetch from '../../fetch';
// import { USER_LOGGED_IN } from '../LoginContainer/types';

// function* onGetUserData({ payload }: ReduxAction<string>) {
//   try {
//     yield put({
//       type: START_GET_USER_DATA
//     });
//     yield fetch.get('/auth/claims');
//     const user = firebase.auth().currentUser;
//     if (user) {
//       const token = yield user.getIdToken(true);
//       yield put({
//         type: USER_LOGGED_IN,
//         payload: { token }
//       });
//       fetch.defaults.headers.common['Authorization'] = (yield user.getIdToken(true)) as string;
//     } else {
//       yield put({
//         type: GET_USER_DATA_ERROR,
//         payload: null
//       });
//     }
//   } catch (e) {
//     yield put({
//       type: GET_USER_DATA_ERROR,
//       payload: e.message
//     });
//   }
// }

// export function* onGetUserDataListener() {
//   yield takeLeading(DO_GET_USER_DATA, onGetUserData);
// }

// const BASE_PASS = '9834YHILDONF0H2849WGHEBF29834TBGWE403';
// function* doSaveUserData({ payload }: ReduxAction<EditPayload>) {
//   try {
//     yield put({
//       type: START_GET_USER_DATA
//     });
//     for (let key in payload) {
//       if (key === 'password') {
//         if (payload.password === BASE_PASS) delete payload.password;
//       } else if (payload[key] === '') {
//         delete payload[key];
//       }
//     }
//     if (payload.password === BASE_PASS) {
//       delete payload.password;
//     }
//     const data = yield fetch.put('/users/me', payload);
//     yield put({ type: GET_USER_DATA_SUCCESS, payload: data.data.user });
//   } catch (e) {
//     yield put({ type: GET_USER_DATA_ERROR, payload: e.message });
//   }
// }

// export function* saveUserDataListener() {
//   yield takeLeading(DO_SAVE_USER_DATA, doSaveUserData);
// }
