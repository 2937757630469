// IMPORTS
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './reducer';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
// import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import firebase from '../firebase';
import { USER_LOGGED_IN } from '../containers/LoginContainer/types';
import { AUTH_STOP_LOADING } from '../containers/Auth/types';
import fetch from '../fetch';
import { ACCOUNT_GET_USER_REQUEST } from '../containers/AccountContainer/types';
// import { fromJS } from 'immutable';
// const logger = createLogger({
//   stateTransformer: state => fromJS(state).toJS()
// });
// CONFIGS
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
  compose;

// HISTORY
const history = createBrowserHistory();

// STORE
const configureStore = (preloadedState: any = {}) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    composeEnhancers(applyMiddleware(thunk, routerMiddleware(history), sagaMiddleware))
  );
  firebase.auth().onAuthStateChanged(async state => {
    if (state) {
      try {
        fetch.defaults.headers.common['Authorization'] = await state.getIdToken(true);
        await fetch.get('/auth/claims');
        let token = await state.getIdToken(true)
        fetch.defaults.headers.common['Authorization'] = token;
        store.dispatch({
          type: USER_LOGGED_IN,
          payload: { ...state, token }
        });
        store.dispatch({
          type: ACCOUNT_GET_USER_REQUEST
        });
      } catch(e) {
        store.dispatch({
          type: AUTH_STOP_LOADING
        });
       }
    } else {
      store.dispatch({
        type: AUTH_STOP_LOADING
      });
    }
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

// EXPORTS
export default configureStore;

export { history };
