// IMPORTS
import React from 'react';

// CONTAINER COMPONENTS
import LogoutContainer from '../../containers/LogoutContainer';

// MAIN COMPONENT
const LogoutPage = () => <LogoutContainer />;

// EXPORTS
export default LogoutPage;
