// IMPORTS
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';

// PRESENTATION
import PageLoader from '../../components/PageLoader';

// ACTIONS
import { doLogout } from '../LoginContainer/actions';

// TYPES
interface Props {
  doLogout: Function;
}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const LogoutContainer = (props: Props) => {
  // Vars
  const { doLogout: logout } = props;

  useEffect(() => {
    logout();
  }, [logout]);

  // Render
  return <PageLoader />;
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doLogout
    },
    dispatch
  );

// EXPORTS
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutContainer);
