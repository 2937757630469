// IMPORTS
import React from 'react';

// CONTAINERS
import BackOfCardReminderContainer from '../../../containers/BackOfCardReminderContainer';

// COMPONENT
const BackOfCardReminder = () => <BackOfCardReminderContainer />;

// EXPORTS
export default BackOfCardReminder;
