// IMPORTS
import React from 'react';

// CONTAINERS
import SignupContainer from '../../containers/SignupContainer';

// COMPONENT
const Signup = () => <SignupContainer />;

// EXPORTS
export default Signup;
