// IMPORTS
import React, { useEffect, useState, useCallback, useContext, KeyboardEvent } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
import { history } from '../../redux/store';

// PRESENTATION
import Forgot from '../../components/Forgot';

// ACTIONS
import { doForgot, doForgotResetErrors } from './actions';
import { doModalShow, doModalHide } from '../ModalBackgroundContainer/actions';

// TYPES
interface IProps {}

interface dispatchProps {
  doForgot: Function;
  doForgotResetErrors: Function;
  doModalShow: Function;
  doModalHide: Function;
}

interface stateProps {
  state: any;
}

type FinalProps = IProps & dispatchProps & stateProps;

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const ForgotContainer: React.FC<FinalProps> = props => {
  // Vars
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [inputState, setInputState] = useState({
    email: ''
  });
  const {
    // doResetError: resetError,
    doForgotResetErrors: resetErrors,
    doModalShow: modalShow,
    doModalHide: modalHide,
    state: { authReducer, forgotReducer }
  } = props;

  // Functions
  const { translate } = useContext(LanguageContext);
  const onModalCancel = useCallback(() => {
    if (!authReducer.get('loading')) {
      setShow(false);
      modalHide();
      resetErrors();
      setTimeout(() => {
        history.push('/');
      }, 250);
    }
  }, [authReducer, modalHide, resetErrors]);

  useEffect(() => {
    if (!show) {
      resetErrors();
    }
  }, [show, resetErrors])

  /**
   *
   * @param event
   */
  const onFormSubmit = (event: Event) => {
    props.doForgot(inputState.email);
    event.preventDefault();
  };

  /**
   *
   * @param key
   */
  const onInputChange = (key: string) => (event: { target: HTMLInputElement }) => {
    const newState: any = { ...inputState };
    newState[key] = event.target.value || '';
    setInputState(newState);
  };

  // Hooks
  useEffect(() => {
    if (!loaded) {
      //   resetError();
      setTimeout(() => {
        setShow(true);
        modalShow();
        setLoaded(true);
      }, 100);
    }
  }, [show, loaded, modalShow]);

  useEffect(() => {
    const escapeHandler: any = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onModalCancel();
      }
    }

    document.body.addEventListener('keyup', escapeHandler);

    return () => {
      document.body.removeEventListener('keyup', escapeHandler)
    }
  }, [onModalCancel]);

  // Render
  return (
    <Forgot
      {...props}
      loading={forgotReducer.get('fetching')}
      error={forgotReducer.get('errors')}
      success={forgotReducer.get('success')}
      doForgot={doForgot}
      doReset={doForgotResetErrors}
      show={show}
      title={translate('ForgotContainer.Title')}
      subTitle={translate('ForgotContainer.Subtitle')}
      onModalCancel={onModalCancel}
      onFormSubmit={onFormSubmit}
      inputState={inputState}
      onInputChange={onInputChange}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doForgot,
      doForgotResetErrors,
      doModalHide,
      doModalShow
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(ForgotContainer);
