// IMPORTS
import styled from 'styled-components';

// MAIN COMPONENTS
const DashboardStyles = styled.div`
  flex: 1 0 auto;
  /* width: 100%; */
  margin: 20px;
  border-radius: 4px;
`;

// EXPORTS
export default DashboardStyles;
