// IMPORTS
import React from 'react';

// CONTAINERS
import CardSortContainer from '../../../containers/CardSortContainer';

// COMPONENT
const CardSort = () => <CardSortContainer />;

// EXPORTS
export default CardSort;
