export function arrayToCommaSeparatedString(arr: any[]) {
  if (!arr.length) return '';
  return arr.join(', ');
}

export function stringToEmailDomainArr(text: string) {
  if (!text) return [];
  return text.split(',').reduce((allItems: string[], item: string) => {
    const trimmed = item.trim();
    if (!trimmed) return allItems;
    return [...allItems, trimmed.toLowerCase()];
  }, []);
}

export function isEmailDomainValid(text: string) {
  const dotInBetweenLetters = /[A-z][.][A-z]/.test(text);
  const hasSpace = /\s+/.test(text);
  return dotInBetweenLetters && !hasSpace;
}

export function shouldStudentRedirectToEndSurvey(
  role: string,
  hasCompletedConsumerEndSurvey: boolean,
  sortResults: string[],
  isConsumerGroup: boolean
) {
  return (
    role === 'student' &&
    !hasCompletedConsumerEndSurvey &&
    sortResults.length >= 3 &&
    isConsumerGroup
  );
}
