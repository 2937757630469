// IMPORTS
import styled from 'styled-components';
import Color from 'color';
import Colors from '../Colors';
import Screens from '../Screens';
import Loader from '../Loader/styles';

// TYPES
export interface IMockButtonProps {
  outline?: boolean;
  styleType?: 'primary' | 'primary-white' | 'secondary' | 'danger' | null | undefined | string;
  isLoading?: boolean;
  disabled?: boolean;
}

// MAIN COMPONENTS
const MockButtonStyles = styled.div<IMockButtonProps>`
  background: ${Colors.purple72};
  color: ${Colors.white};
  font-family: europa, sans-serif;
  font-size: 16px;
  font-weight: 600;
  padding: 0 30px;
  line-height: 40px;
  height: 42px;
  border: none;
  border-radius: 25px;
  min-width: 100px;
  transition: all 250ms ease-in-out 0s;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  outline: none;
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  
  ${props => props.isLoading ? `
    & > :not(${Loader}) {
      height: 0;
      visibility: hidden
    };

    ${Loader} {
      width: 100%;
      height: 100%;
      padding: 5px 0;
      
      svg {
        height: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }

  ` : `
    ${Loader} {
      display: none;
    }
  `};

  ${props =>
    props.styleType === 'primary' &&
    !props.outline &&
    `
      background: ${Colors.purple72};
      border: 1px solid transparent;
      color: ${Colors.white};
      ${!props.disabled &&
        `&:hover {
          background: ${Color(Colors.purple72).darken(0.25)};
      }`}
    `}
  
  ${props =>
    props.styleType === 'primary-white' &&
    !props.outline &&
    `
      background: ${Color(Colors.white)
        .fade(0.1)
        .rgb()
        .toString()};
      border: 1px solid transparent;
      color: ${Colors.purple72};

      ${!props.disabled &&
        `&:hover {
        background: ${Colors.white};
      }`}
    `}
  
  ${props =>
    props.styleType === 'primary-white' &&
    props.outline &&
    `
      background: ${Color(Colors.purple72)
        .fade(0.1)
        .rgb()
        .toString()};
      border: 1px solid ${Colors.white};
      color: ${Colors.white};
      ${!props.disabled &&
        `&:hover {
        background: ${Colors.white};
        color: ${Colors.purple72};
      }`}
    `}
  
    ${props =>
      props.styleType === 'primary' &&
      props.outline &&
      `
      background: ${Colors.white};
      border: 1px solid ${Colors.purple72};
      color: ${Colors.purple72};
      ${!props.disabled &&
        `&:hover {
        background: ${Colors.purple72};
        color: ${Colors.white};
      }`}
    `}
  
    ${props =>
      props.styleType === 'secondary' &&
      !props.outline &&
      `
      background: ${Colors.greyDD};
      border: 1px solid transparent;
      color: ${Colors.grey66};
      ${!props.disabled &&
        `&:hover {
        background: ${Colors.grey66};
        color: ${Colors.white};
      }`}
    `}
  
  ${props =>
    props.styleType === 'secondary' &&
    props.outline &&
    `
      background: ${Colors.white};
      border: 1px solid ${Colors.greyDD};
      color: ${Colors.grey66};
      ${!props.disabled &&
        `&:hover {
        background: ${Colors.greyDD};
        color: ${Colors.grey33};
        border: 1px solid ${Colors.greyDD};
      }`}
    `}

  ${props =>
    props.styleType === 'danger' &&
    `
      background: ${Colors.error};
      color: ${Colors.white};
    `}

  &:focus {
    outline: none;
    border-color: ${Colors.black};
    box-shadow: 0px 0px 0px 2px
    ${Color(Colors.black)
      .rgb()
      .toString()};
  }

  span {
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 6px;
    line-height: 50px;
    margin: 0 2px;
  }

  @media (min-width: ${Screens.medium}) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;

// EXPORTS
export default MockButtonStyles;
