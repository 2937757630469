import CardIntro from '../../components/CardIntro';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
    },
    dispatch
  );
const CardIntroContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CardIntro);
export default CardIntroContainer;
