import React, { useContext, useRef, useEffect, Fragment, useState } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import closeButtonIconWhite from '../../styles/assets/icons/close/close-white.svg';
import useCustomCardForm, { limitAndTrimText } from './hooks/useCustomCardForm';
import { CustomCard } from '../../types/customCard';
import Loader from '../../styles/Loader';
import { InfoWrapper, InfoWrapperContainer } from '../CardSort/styles';
import { faFileImage } from '@fortawesome/free-solid-svg-icons';
import Modal from '../Modal';
import { FaInfo } from 'react-icons/fa';
import {
  EditCustomCardContainer,
  CloseButtonContainer,
  CloseButton,
  MainContentContainerForm,
  Card,
  SubmitButton,
  CardUploadContainer,
  BoxedLabel,
  UploadText,
  ImageFile,
  TextArea,
  HiddenInput,
  PhotoIcon,
  ErrorContainer,
  ModalContainerForm,
  TitleInput,
  ModalBoxedLabel,
  ModalImageFile,
  DescriptionTextArea,
  ModalButtonSubmitContainer,
  Reminder
} from './styles';
import ModalBackgroundContainer from '../../containers/ModalBackgroundContainer';

interface IProps {
  onCancel?: () => void;
  onUpdateCustomCard?: (value: CustomCard) => void;
  customCardLength: number;
  accountId: string;
  doModalShow: Function;
  doModalHide: Function;
}

type Props = IProps;

const EditCustomCard: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showCardInfoModal, setShowCardInfoModal] = useState(false);
  const [showHoverPopUp, setShowHoverPopUp] = useState(false);
  const { onCancel = () => {}, onUpdateCustomCard = () => {}, customCardLength, accountId } = props;
  const {
    title,
    setTitle,
    description,
    setDescription,
    updateCustomCard,
    isLoading,
    imageFile,
    setImageFile,
    error,
    hasFileValidationError,
    setHasFileValidationError,
    maxFileSizeInBytes
  } = useCustomCardForm({
    onUpdateCustomCard,
    customCardLength,
    accountId,
    showCardInfoModal,
    doModalHide: props.doModalHide
  });
  useEffect(() => {
    closeButtonRef.current && closeButtonRef.current.focus();
  }, []);
  return (
    <EditCustomCardContainer>
      <CloseButtonContainer>
        <CloseButton
          type={'button'}
          onClick={() => {
            if (!isLoading) onCancel();
          }}
          aria-label={translate('AriaLabel.Close')}
          ref={closeButtonRef}
        >
          <img src={closeButtonIconWhite} alt="close icon" />
        </CloseButton>
      </CloseButtonContainer>
      <MainContentContainerForm
        onSubmit={event => {
          event.preventDefault();
          if (!isLoading) updateCustomCard();
        }}
      >
        <Card isEdit visible cardObj={null}>
          {showHoverPopUp && (
            <Reminder>
              <span></span>
              <span></span>
              <span></span>
              <p id="read-more" aria-hidden={true}>
                {translate('customCard.popUpNote')}
              </p>
            </Reminder>
          )}
          <InfoWrapperContainer>
            <InfoWrapper
              onMouseEnter={() => {
                setShowHoverPopUp(true);
              }}
              onMouseLeave={() => {
                setShowHoverPopUp(false);
              }}
              onClick={e => {
                e.preventDefault();
                setShowCardInfoModal(true);
                props.doModalShow();
              }}
              aria-label={translate('customCard.infoIconNote')}
            >
              <FaInfo />
            </InfoWrapper>
          </InfoWrapperContainer>
          <CardUploadContainer>
            <BoxedLabel
              showBorder={!imageFile}
              onClick={() => {
                if (hasFileValidationError) {
                  setHasFileValidationError(false);
                }
              }}
            >
              {imageFile ? (
                <ImageFile
                  src={URL.createObjectURL(imageFile)}
                  alt={translate('customCard.imageAlt')}
                />
              ) : (
                <Fragment>
                  <PhotoIcon icon={faFileImage} />
                  <UploadText hasError={hasFileValidationError}>
                    {translate(
                      hasFileValidationError
                        ? 'customCard.fileValidationError'
                        : 'customCard.uploadDescription'
                    )}
                  </UploadText>
                </Fragment>
              )}
              <HiddenInput
                type="file"
                name="custom-card-upload"
                accept="image/png, image/jpeg"
                onChange={e => {
                  if (e.target.files && e.target.files[0]) {
                    const fileObj: File = e.target.files[0];
                    if (fileObj.size <= maxFileSizeInBytes) {
                      setImageFile(e.target.files[0]);
                    } else {
                      setHasFileValidationError(true);
                      setImageFile(null);
                    }
                  }
                }}
              />
            </BoxedLabel>
          </CardUploadContainer>
          <div>
            <TextArea
              cols={2}
              required
              onChange={event => limitAndTrimText(event, setTitle)}
              value={title}
            />
          </div>
        </Card>
        {error ? <ErrorContainer>{translate('error.defaultMessage')}</ErrorContainer> : null}
        {isLoading ? (
          <Loader white />
        ) : (
          <SubmitButton styleType="primary-white" aria-disabled={isLoading} type="submit">
            {translate('ok')}
          </SubmitButton>
        )}
      </MainContentContainerForm>
      <ModalBackgroundContainer isDashboard />
      {showCardInfoModal && (
        <Modal
          maxHeight={null}
          minHeight={null}
          hideHeader={true}
          show={true}
          loading={false}
          onCancel={() => {
            if (!isLoading) {
              setShowCardInfoModal(false);
              props.doModalHide();
            }
          }}
          noPadding
        >
          <ModalContainerForm
            onSubmit={event => {
              event.preventDefault();
              if (!isLoading) updateCustomCard();
            }}
          >
            <TitleInput
              required
              value={title}
              onChange={event => limitAndTrimText(event, setTitle)}
            />
            <CardUploadContainer>
              <ModalBoxedLabel
                showBorder={!imageFile}
                onClick={() => {
                  if (hasFileValidationError) {
                    setHasFileValidationError(false);
                  }
                }}
              >
                {imageFile ? (
                  <ModalImageFile
                    src={URL.createObjectURL(imageFile)}
                    alt={translate('customCard.imageAlt')}
                  />
                ) : (
                  <Fragment>
                    <PhotoIcon icon={faFileImage} />
                    <UploadText hasError={hasFileValidationError}>
                      {translate(
                        hasFileValidationError
                          ? 'customCard.fileValidationError'
                          : 'customCard.uploadDescription'
                      )}
                    </UploadText>
                  </Fragment>
                )}
                <HiddenInput
                  type="file"
                  name="custom-card-upload"
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    if (e.target.files && e.target.files[0]) {
                      const fileObj: File = e.target.files[0];
                      if (fileObj.size <= maxFileSizeInBytes) {
                        setImageFile(e.target.files[0]);
                      } else {
                        setHasFileValidationError(true);
                        setImageFile(null);
                      }
                    }
                  }}
                />
              </ModalBoxedLabel>
            </CardUploadContainer>
            <DescriptionTextArea
              cols={4}
              onChange={event => setDescription(event.target.value)}
              value={description}
              maxLength={500}
            />
            {error ? <ErrorContainer>{translate('error.defaultMessage')}</ErrorContainer> : null}
            <ModalButtonSubmitContainer>
              {isLoading ? (
                <Loader />
              ) : (
                <SubmitButton styleType="primary" aria-disabled={isLoading} type="submit">
                  {translate('ok')}
                </SubmitButton>
              )}
            </ModalButtonSubmitContainer>
          </ModalContainerForm>
        </Modal>
      )}
    </EditCustomCardContainer>
  );
};

export default EditCustomCard;
