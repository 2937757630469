// IMPORTS
import styled from 'styled-components';
import { NavLink, NavLinkProps } from 'react-router-dom';
import Colors from '../Colors';
import Screens from '../Screens';
import Color from 'color';
import MockButtonStyles from '../../styles/MockButton/styles';

// TYPES
interface IProps {
  doInherit?: true;
  alt?: string;
}

// MAIN COMPONENT
export default styled(NavLink)<IProps & NavLinkProps>`
  color: ${props => (props.doInherit ? 'inherit' : Colors.purple78)};
  text-decoration: none;
  border-radius: 25px;
  border: 1px solid transparent;
  display: inline-block;
  transition: all 250ms ease-in-out 0s;

  &.nav-link--active {
    text-decoration: underline;
    button,
    ${MockButtonStyles.toString} {
      color: ${props => (props.doInherit ? 'inherit' : Colors.purple62)};
      background: ${Colors.white}
    }
  }

  &:focus {
    outline: none;
    border-color: ${Colors.black};
    box-shadow: 0px 0px 0px 2px
    ${Color(Colors.black)
      .rgb()
      .toString()};

    button,
    ${MockButtonStyles.toString} {
      border-color: transparent;
    }
  }

  &:visited {
    color: ${props => (props.doInherit ? 'inherit' : Colors.purple78)};
    text-decoration: none;
  }

  &:hover {
    color: ${props => (props.doInherit ? 'inherit' : Colors.purple62)};
  }

  @media (min-width: ${Screens.medium}) {
    &:hover {
        transform: scale(1.05);
    }

    button,
    ${MockButtonStyles.toString} {
      &:hover {
        transform: none
      }
    }
}
`;
