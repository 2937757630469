// IMPORTS
import { put, takeLeading, select } from 'redux-saga/effects';
import gaUtil from '../../util/ga';
import firebase from '../../firebase';
import { push } from 'connected-react-router';
// REDUX TYPES
import {
  USER_LOGOUT_STARTED,
  USER_LOGGED_OUT,
  USER_LOGOUT_ERROR,
  DO_USER_LOGOUT,
  LOGIN_REQUEST,
  LOGIN_FAIL
} from './types';
import { RESULT_SET_START_TIME, RESULT_RESET } from '../CardResultContainer/types';

// TYPES
interface ErrorObject {
  [field: string]: string;
}

// SAGAS
/**
 *
 * @param param0
 */
function* workerUserLogin({ payload }: ReduxAction) {
  try {
    // 1. Get credentials
    const { email, password } = payload;
    if (!email) {
      throw new Error('error.emailRequired');
    }
    if (!password) {
      throw new Error('error.passwordRequired')
    }

    // 2. Sign in with firebase
    yield firebase.auth().signInWithEmailAndPassword(email, password);
    // Next steps are handled by onAuthStateChange on store.ts
  } catch (e) {
    let message = e.message || 'error.defaultMessage';

    if (e.code === 'auth/user-not-found' ||
        e.code === 'auth/wrong-password' ||
        e.code === 'auth/invalid-email' ||
        e.code === 'auth/user-disabled') {
      message = e.code
    }

    if (
      e.response &&
      e.response.data &&
      e.response.data.errors &&
      e.response.data.errors.length > 0
    ) {
      message = e.response.data.errors.map((item: ErrorObject) => item.msg).join('\n');
    }
    yield put({
      type: LOGIN_FAIL,
      payload: message
    });
  }
}

/**
 *
 */
function* watcherUserLogin() {
  yield takeLeading(LOGIN_REQUEST, workerUserLogin);
}

/**
 *
 */
function* onDoUserLogout({payload}: any) {
  try {
    const {timeout} = payload || {}
    const role = yield select(state => state.accountReducer.get('role'))
    yield put({
      type: USER_LOGOUT_STARTED
    });
    yield firebase.auth().signOut();
    yield put({
      type: USER_LOGGED_OUT
    });
    if (timeout) {
      gaUtil.events.authentication.timeout(role)
    } else {
      gaUtil.events.authentication.logout(role)
    }
    gaUtil.dimensions.resetAllDimensions()
    yield put({
      type: RESULT_SET_START_TIME,
      payload: null
    });
    yield put({
      type: RESULT_RESET
    });
    yield put(push(`/${timeout ? '?timeout=1' : ''}`));
  } catch (e) {
    yield put({
      type: USER_LOGOUT_ERROR
    });
  }
}

/**
 *
 */
function* doUserLogoutListener() {
  yield takeLeading(DO_USER_LOGOUT, onDoUserLogout);
}

// EXPORTS
export { watcherUserLogin, doUserLogoutListener };
