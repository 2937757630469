import styled from 'styled-components';
import Color from 'color';
import Colors from '../Colors';

interface IProps {
  href: String;
  rel: String;
  target?: String;
  styleType?: 'button' | null | undefined | String;
  download?: boolean | String;
}

const NativeLink = styled.a<IProps>`
  color: ${Colors.white};
  transition: all 250ms ease-in-out 0s;
  text-decoration: none;
  font-weight: 600;
  ${props =>
    props.styleType === 'button'
      ? `
    display: flex;
    align-items: center;
    height: 42px;
    padding: 0 30px;
    border-radius: 25px;
  ` : ''}

  &:hover {
    color: ${Colors.white};
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
    border-color: ${Colors.black};
    box-shadow: 0px 0px 0px 2px
    ${Color(Colors.black)
      .rgb()
      .toString()};
`;

export default NativeLink;
