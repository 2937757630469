// IMPORTS
import React, { useEffect, useRef, useContext } from 'react';
import { LiveMessage } from 'react-aria-live';
import { createPortal } from 'react-dom'
import ModalStyles, {
  ModalContainer,
  ModalHeader,
  ModalHeadless,
  ModalFooter,
  ModalHeaderButton,
  BackToTop
} from './styles';
import LanguageContext from '../../locale/LanguageContext';

// TYPES
interface Props {
  loading: boolean;
  show: boolean;
  hideHeader: boolean;
  maxHeight?: number | null;
  minHeight?: number | null;
  maxWidth?: number | null;
  width?: number | null;
  title?: string;
  children?: any;
  footer?: any;
  onCancel: Function;
  noPadding?: true;
  onShow?: Function;
}

// MAIN COMPONENT
const Modal = (props: Props) => {
  const el = useRef(document.createElement("div"));
  const { show, onShow } = props
  const { translate } = useContext(LanguageContext);
  const closeButtonRef = useRef<HTMLButtonElement | null>(null);
  const backToTopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (show) {
      const modalElement = document.getElementById("modal-root");
      const headerElement = document.getElementById("header-root");
      const rootElement = document.getElementById("content-container");
      const currentEl = el.current

      rootElement?.setAttribute('inert', '')
      headerElement?.setAttribute('inert', '')
      modalElement?.appendChild(currentEl);

      onShow && onShow()
      closeButtonRef.current && closeButtonRef.current.focus();

      const tabHandler = (e: KeyboardEvent) => {
        if (!(e && e.key === 'Tab')) {
          return;
        }
        if (e.shiftKey) {
          if (document.activeElement === closeButtonRef.current) {
            backToTopRef.current && backToTopRef.current.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === backToTopRef.current) {
            closeButtonRef.current && closeButtonRef.current.focus();
            e.preventDefault();
          }
        }
      }

      document.addEventListener('keydown', tabHandler);

      return () => {
        rootElement?.removeAttribute('inert')
        headerElement?.removeAttribute('inert')
        modalElement?.removeChild(currentEl)
        document.removeEventListener('keydown', tabHandler);
      };
    }
  }, [el, show, onShow]);

  const handleBackToTopClick = () => {
    closeButtonRef.current && closeButtonRef.current.focus();
  };

  const handleBackToTopKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Enter') {
      closeButtonRef.current && closeButtonRef.current.focus();
    }
  };

  const content = (
    <ModalStyles show={props.show}>
      <ModalContainer 
        maxHeight={props.maxHeight} 
        width={props.width} 
        minHeight={props.minHeight}
        maxWidth={props.maxWidth}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <LiveMessage message={document.title} aria-live="polite" />
        {!props.hideHeader && (
          <ModalHeader>
            <ModalHeaderButton
              disabled={props.loading}
              type={'button'}
              onClick={() => props.onCancel()}
              aria-label={translate('AriaLabel.Close')}
              ref={closeButtonRef}
            >
              <span></span>
              <span></span>
            </ModalHeaderButton>
            <h2 id="modal-title">{props.title && props.title}</h2>
          </ModalHeader>
        )}

        {props.hideHeader && (
          <ModalHeadless>
            <ModalHeaderButton
              disabled={props.loading}
              type={'button'}       
              onClick={() => props.onCancel()}
              aria-label={translate('AriaLabel.Close' )}
              ref={closeButtonRef}
            >
              <span></span>
              <span></span>
            </ModalHeaderButton>
            <h2 id="modal-title">{props.title && props.title}</h2>
          </ModalHeadless>
        )}

        {props.children && props.children}
        {props.footer && <ModalFooter></ModalFooter>}
        <BackToTop
          ref={backToTopRef}
          onClick={handleBackToTopClick}
          onKeyPress={handleBackToTopKeyPress}
          tabIndex={0}
          role="button"
        >
          {translate('Modal.BackToTop')}
        </BackToTop>
      </ModalContainer>
    </ModalStyles>
  )

  return createPortal(content, el.current)
};

Modal.defaultProps = {
  hideHeader: false,
  maxHeight: 440,
  minHeight: 360,
  width: 720
};

// EXPORTS
export default Modal;
