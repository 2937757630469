// IMPORTS
import { all } from 'redux-saga/effects';

// ALL CONTAINERS
const ctx = require.context('../containers', true, /sagas.ts$/);

// ALL SAGAS
const allSagas = ctx.keys().reduce(
  (prev, curr) => {
    const mod = ctx(curr);
    return prev.concat(
      Object.values<GeneratorFunction>(mod).filter(i => {
        return i.name.indexOf('worker') === -1;
      })
    );
  },
  [] as GeneratorFunction[]
);

// DEFAULT EXPORT
export default function* rootSaga() {
  yield all(allSagas.map(saga => saga()));
}
