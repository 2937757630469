// IMPORTS
import Colors from '../../styles/Colors';
import styled from 'styled-components';

interface AppStylesProps {
  id?: string;
  isDashboard?: boolean;
  role: string;
}

// MAIN COMPONENTS
const AppStyles: React.SFC<AppStylesProps> = styled.div<AppStylesProps>`
  background: ${props => (props.isDashboard ? Colors.purple72 : 'none')};
  padding-top: ${props => (props.isDashboard ? '100px' : '100px')};
  transition: filter 0.1s ease-in-out;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding-top: 80px;
  }
`;

// EXPORTS
export default AppStyles;
