// IMPORTS
import React, { RefObject, useContext, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import LanguageContext from '../../locale/LanguageContext';

// CONTAINER COMPONENTS
import Modal from '../Modal';
import Link from '../../styles/Link';

// ACTIONS
import { doLogin } from '../../containers/LoginContainer/actions';

import Input from '../../components/Input';

// STYLES
import { LoginLeftStyles, LoginRightStyles, LoginRightStylesForgot, LoginStyles } from './styles';
import Loader from '../../styles/Loader';
import Button from '../../styles/Button';
import Error from '../../styles/Error';

// TYPES
interface IProps {
  loading: boolean;
  error: string | null;
  hideHeader?: boolean;
  title?: string;
  show: boolean;
  inputEmailRef: RefObject<HTMLInputElement>;
  inputPasswordRef: RefObject<HTMLInputElement>;
  onModalCancel: Function;
  onFormSubmit: Function;
  onInputChange?: Function;
}

interface dispatchProps {
  doLogin: typeof doLogin;
}

type FinalProps = IProps & dispatchProps;

// MAIN COMPONENT
const Login: React.FC<FinalProps> = props => {
  const { translate } = useContext(LanguageContext);

  const onModalShow = useCallback(() => {
    if (props.inputEmailRef && props.inputEmailRef.current) {
      props.inputEmailRef?.current?.focus()
    }
  }, [props.inputEmailRef])
  
  return (
    <DocumentTitle title={`${translate('Header.Login')} | ${translate('document.title')}`}>
      <Modal
        maxHeight={440}
        minHeight={null}
        hideHeader={props.hideHeader}
        loading={props.loading}
        show={props.show}
        onCancel={props.onModalCancel}
        onShow={onModalShow}
      >
        <LoginStyles>
          <LoginLeftStyles></LoginLeftStyles>
          <LoginRightStyles>
            <h2>{(props.title && props.title) || ''}</h2>
            <hr />
            <form onSubmit={event => props.onFormSubmit(event)} noValidate>
              <Input
                aria-disabled={props.loading}
                type={'email'}
                label={translate('Login.InputEmail.Label')}
                placeholder={translate('Login.InputEmail.Placeholder')}
                required
                forwardRef={props.inputEmailRef}
                name="email"
                onChange={props.onInputChange && props.onInputChange('email')}
                autoComplete="email"
              />
              <LoginRightStylesForgot>
                <Input
                  aria-disabled={props.loading}
                  type={'password'}
                  label={translate('Login.InputPassword.Label')}
                  placeholder={translate('Login.InputPassword.Placeholder')}
                  required
                  forwardRef={props.inputPasswordRef}
                  name="password"
                  onChange={props.onInputChange && props.onInputChange('password')}
                  autoComplete="current-password"
                />
                {!props.loading && <Link doInherit={true} to={'/forgot'}>{translate('Login.Link.Forgot')}</Link>}
              </LoginRightStylesForgot>
              {props.error && (
                <Error>
                  <span role="alert">{props.error.split('\n').map(e => translate(e, {defaultValue: e})).join(' ')}</span>
                </Error>
              )}
              <p>
              <Button type={'submit'} aria-disabled={props.loading} styleType="primary">
                {translate('Login.Button.Login')}
              </Button>
              {!props.loading && (
                <small>
                  <Link doInherit={true} to={'/signup'}>{translate('Login.Link.NoAccount')}</Link>
                </small>
              )}
              </p>
              {props.loading && <Loader />}
            </form>
          </LoginRightStyles>
        </LoginStyles>
      </Modal>
    </DocumentTitle>
  );
};

Login.defaultProps = {
  hideHeader: true
};

// EXPORTS
export default Login;
