// IMPORTS
import React, { useState, useEffect, useContext } from 'react';
import { LiveMessage } from 'react-aria-live';
import DocumentTitle from 'react-document-title';
import LanguageContext from '../../locale/LanguageContext';
import { Seq } from 'immutable';
import {
  arrayToCommaSeparatedString,
  stringToEmailDomainArr,
  isEmailDomainValid
} from '../../util/account';
import { useAriaLiveMessage } from '../../hooks';

// STYLES
import {
  AccountStyles,
  AccountDetails,
  AccountAddress,
  AccountPassword,
  AccountSubscription,
  Container
} from './styles';
import Input from '../../styles/Input';
import { SelectInput, InputLabel } from '../../styles/Input/styles';
import Button from '../../styles/Button';
import Error from '../../styles/Error';
import Success from '../../styles/Success';
import Loader from '../../styles/Loader';

// REDUX  TYPES
import { AppState } from '../../redux/reducer';

// TYPES
interface IProps {}

interface StateProps {
  authReducer: AppState['authReducer'];
  accountReducer: AppState['accountReducer'];
}

interface DispatchProps {
  doSaveDetails: Function;
  doSaveAddress: Function;
  doSavePassword: Function;
  doUpdateReset: Function;
  doUpdateSchoolCode: Function;
}

type Props = IProps & StateProps & DispatchProps;

// COMPONENT
const Account: React.FC<Props> = props => {
  // Vars
  const role = props.accountReducer.get('role');
  const emailDomainWhitelist = Seq(props.accountReducer.get('emailDomainWhitelist')).toArray();
  const emailDomainWhitelistInput: string = arrayToCommaSeparatedString(emailDomainWhitelist);
  const emailDomainBlacklist = Seq(props.accountReducer.get('emailDomainBlacklist')).toArray();
  const emailDomainBlacklistInput: string = arrayToCommaSeparatedString(emailDomainBlacklist);
  const { doUpdateReset: updateReset } = props;
  const [user, setUser] = useState({
    lastname: props.accountReducer.get('lastname'),
    schoolname: props.accountReducer.get('schoolname'),
    city: props.accountReducer.get('city'),
    phone: props.accountReducer.get('phone'),
    state: props.accountReducer.get('state'),
    address: props.accountReducer.get('address'),
    postalzip: props.accountReducer.get('postalzip'),
    country: props.accountReducer.get('country'),
    firstname: props.accountReducer.get('firstname'),
    email: '',
    emailDomainWhitelistInput,
    emailDomainBlacklistInput,
    code: props.accountReducer.get('code'),
    expiry: props.accountReducer.get('expiry')
  });

  const [passwordInputs, setPasswordInputs] = useState({
    password: '',
    confirm: ''
  });

  const lastname = props.accountReducer.get('lastname');
  const schoolname = props.accountReducer.get('schoolname');
  const city = props.accountReducer.get('city');
  const phone = props.accountReducer.get('phone');
  const state = props.accountReducer.get('state');
  const address = props.accountReducer.get('address');
  const postalzip = props.accountReducer.get('postalzip');
  const country = props.accountReducer.get('country');
  const firstname = props.accountReducer.get('firstname');
  const email = props.accountReducer.get('email');
  const code = props.accountReducer.get('code');
  const expiry = props.accountReducer.get('expiry');
  const isEmailDomainWhitelistEnabled = props.accountReducer.get('isEmailDomainWhitelistEnabled');
  const isEmailDomainBlacklistEnabled = props.accountReducer.get('isEmailDomainBlacklistEnabled');

  const loading = props.accountReducer.get('loading');
  const loadingSchoolCode = props.accountReducer.get('loadingSchoolCode');
  const loadingDetails = props.accountReducer.get('loadingDetails');
  const loadingAddress = props.accountReducer.get('loadingAddress');
  const loadingPassword = props.accountReducer.get('loadingPassword');

  const successSchoolCode = props.accountReducer.get('successSchoolCode');
  const successDetails = props.accountReducer.get('successDetails');
  const successAddress = props.accountReducer.get('successAddress');
  const successPassword = props.accountReducer.get('successPassword');

  const errorSchoolCode = props.accountReducer.get('errorSchoolCode');
  const errorDetails = props.accountReducer.get('errorDetails');
  const errorAddress = props.accountReducer.get('errorAddress');
  const errorPassword = props.accountReducer.get('errorPassword');
  
  // Hooks
  const { translate } = useContext(LanguageContext);

  const successMessageSchoolCode = successSchoolCode ? translate('Account.SchoolCode.Updated') : '';
  const successMessageDetails = successDetails ? translate('Account.AccountDetails.Updated') : '';
  const successMessageAddress = successAddress ? translate('Account.AddressDetails.Updated') : '';
  const successMessagePassword = successPassword ? translate('Account.PasswordDetails.Updated') : '';

  const errorMessageSchoolCode = errorSchoolCode ? translate(errorSchoolCode, { defaultValue: errorSchoolCode }) : '';
  const errorMessageDetails = errorDetails ? translate(errorDetails, { defaultValue: errorDetails }) : '';
  const errorMessageAddress = errorAddress ? translate(errorAddress, { defaultValue: errorAddress }) : '';
  const errorMessagePassword = errorPassword ? translate(errorPassword, {defaultValue: errorPassword}) : '';

  const ariaLiveMessageSchoolCode = useAriaLiveMessage(successMessageSchoolCode, errorMessageSchoolCode);
  const ariaLiveMessageDetails = useAriaLiveMessage(successMessageDetails, errorMessageDetails);
  const ariaLiveMessageAddress = useAriaLiveMessage(successMessageAddress, errorMessageAddress);
  const ariaLiveMessagePassword = useAriaLiveMessage(successMessagePassword, errorMessagePassword);

  useEffect(() => {
    if (!loading) {
      setUser({
        lastname: lastname,
        city: city,
        phone: phone,
        state: state,
        address: address,
        schoolname: schoolname,
        postalzip: postalzip,
        country: country,
        firstname: firstname,
        email: email,
        code,
        emailDomainWhitelistInput,
        emailDomainBlacklistInput,
        expiry
      });
    }
  }, [
    loading,
    lastname,
    city,
    phone,
    state,
    address,
    postalzip,
    country,
    firstname,
    email,
    code,
    emailDomainWhitelistInput,
    emailDomainBlacklistInput,
    expiry,
    schoolname
  ]);

  useEffect(() => {
    if (!loading) {
      updateReset();
    }
  }, [updateReset, loading]);

  useEffect(() => {
    if (!loadingPassword && successPassword) {
      setPasswordInputs({
        password: '',
        confirm: ''        
      })
    }
  }, [loadingPassword, successPassword]);

  // Functions
  /**
   *
   * @param key
   */
  const onChangeInput = (key: string) => (event: { target: HTMLInputElement }) => {
    const newState: any = { ...user };
    newState[key] = event.target.value || '';
    setUser(newState);
  };

  /**
   *
   * @param key
   */
  const onChangePasswordInput = (key: string) => (event: { target: HTMLInputElement }) => {
    const newState: any = { ...passwordInputs };
    newState[key] = event.target.value || '';
    setPasswordInputs(newState);
  };

  /**
   *
   * @param key
   */
  const onChangeSelectnput = (key: string) => (event: { target: HTMLSelectElement }) => {
    const newState: any = { ...user };
    newState[key] = event.target.value || '';
    setUser(newState);
  };

  /**
   *
   * @param event
   */
  const onSubmitAccountDetails = (event: any): void => {
    if (!loadingDetails) {
      props.doSaveDetails({
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email
      });
    }
    event.preventDefault();
  };

  /**
   *
   * @param event
   */
  const onSubmitAccountAddress = (event: any): void => {
    if (!loadingAddress) {
      props.doSaveAddress({
        address: user.address,
        city: user.city,
        state: user.state,
        country: user.country,
        postalzip: user.postalzip,
        phone: user.phone,
        schoolname: user.schoolname
      });
    }
    event.preventDefault();
  };

  /**
   *
   * @param event
   */
  const onSubmitAccountPassword = (event: any): void => {
    if (!loadingPassword) {
      props.doSavePassword({
        password: passwordInputs.password,
        confirm: passwordInputs.confirm
      });
    }
    event.preventDefault();
  };

  const onSubmitSchoolCode = (event: any): void => {
    if (!(loadingSchoolCode || !isEmailDomainsValid(user.emailDomainWhitelistInput) || !isEmailDomainsValid(user.emailDomainBlacklistInput))) {
      const trimmedUserCode = user.code.trim();
      const trimmedEmailDomainWhitelistInput = user.emailDomainWhitelistInput.trim();
      const emailDomainWhitelist = stringToEmailDomainArr(trimmedEmailDomainWhitelistInput);
      const trimmedEmailDomainBlacklistInput = user.emailDomainBlacklistInput.trim();
      const emailDomainBlacklist = stringToEmailDomainArr(trimmedEmailDomainBlacklistInput);
      setUser({
        ...user,
        code: trimmedUserCode,
        emailDomainWhitelistInput: emailDomainWhitelist.join(', '),
        emailDomainBlacklistInput: emailDomainBlacklist.join(', ')
      });
      props.doUpdateSchoolCode({
        code: trimmedUserCode,
        emailDomainWhitelist,
        emailDomainBlacklist
      });
    }
    event.preventDefault();
  };

  const isEmailDomainsValid = (emailDomainInput: string) => {
    if (!emailDomainInput) return true;
    const trimmedEmailDomainInput = emailDomainInput.trim();
    const emailDomainArr = stringToEmailDomainArr(trimmedEmailDomainInput);
    return emailDomainArr.reduce((isValid, emailDomain) => {
      return isValid && isEmailDomainValid(emailDomain);
    }, true);
  };

  // Render
  return (
    <DocumentTitle title={`${translate('Account.Title')} | ${translate('document.title')}`}>
      <>
        <LiveMessage message={document.title} aria-live="polite" />
        <Container>
          {loading ? (
            <Loader white/>
          ) : (
            <AccountStyles>
              <h1>{translate('Account.Title')}</h1>
              <>
                {role === 'school' && (
                  <AccountSubscription>
                    
                    <LiveMessage message={ariaLiveMessageSchoolCode} aria-live="polite" />
                    <h2>{translate('Account.SubscriptionDetails')}</h2>
                    <form onSubmit={onSubmitSchoolCode} noValidate>
                      {loadingSchoolCode && <Loader white/>}

                      <InputLabel htmlFor="code" required>{translate('Account.UniqueCode')}</InputLabel>
                      <Input
                        disabled={loadingSchoolCode}
                        id={'code'}
                        name={'code'}
                        type={'text'}
                        placeholder={translate('Account.Subscription.PlaceholderCode')}
                        value={(user && user.code && user.code) || ''}
                        required
                        onChange={onChangeInput('code')}
                      />
                      <span>{translate('Account.Subscription.UniqueCodeDescription')}</span>
                      {isEmailDomainWhitelistEnabled ? (
                        <>
                          <InputLabel htmlFor={'email-domain-whitelist'}>
                            {translate('Account.EmailDomainWhitelist')}
                          </InputLabel>
                          <Input
                            disabled={loadingSchoolCode}
                            id={'email-domain-whitelist'}
                            name={'email-domain-whitelist'}
                            type={'text'}
                            placeholder={'abc.edu, careers.xyz.edu'}
                            value={user.emailDomainWhitelistInput}
                            onChange={onChangeInput('emailDomainWhitelistInput')}
                          />
                          <span>{translate('Account.EmailDomainWhitelistDescription')}</span>
                        </>
                        ) : null}
                      {isEmailDomainBlacklistEnabled ? (
                        <>
                          <InputLabel htmlFor={'email-domain-blacklist'}>
                            {translate('Account.EmailDomainBlacklist')}
                          </InputLabel>
                          <Input
                            disabled={loadingSchoolCode}
                            id={'email-domain-blacklist'}
                            name={'email-domain-blacklist'}
                            type={'text'}
                            placeholder={'fakeschool.edu, scam.xyz.edu'}
                            value={user.emailDomainBlacklistInput}
                            onChange={onChangeInput('emailDomainBlacklistInput')}
                          />
                          <span>{translate('Account.EmailDomainBlacklistDescription')}</span>
                        </>
                        ) : null}
                      {errorSchoolCode &&
                        <Error>{errorMessageSchoolCode}</Error>
                      }
                      {successSchoolCode &&
                        <Success>{successMessageSchoolCode}</Success>
                      }
                      <Button
                        styleType="primary-white"
                        aria-disabled={loadingSchoolCode || !isEmailDomainsValid(user.emailDomainWhitelistInput) || !isEmailDomainsValid(user.emailDomainBlacklistInput)}
                        type={'submit'}
                      >
                        {translate('Account.AccountDetails.Save')}
                      </Button>
                    </form>
                  </AccountSubscription>
                )}

                <AccountDetails>
                  <LiveMessage message={ariaLiveMessageDetails} aria-live="polite" />
                  <h2>{translate('Account.AccountDetails.Title')}</h2>
                  <form onSubmit={onSubmitAccountDetails} noValidate>
                    {loadingDetails && <Loader white/>}
                    <InputLabel htmlFor="firstname" required>{translate('Account.AccountDetails.PlaceholderFirstName')}</InputLabel>
                    <Input
                      disabled={loadingDetails}
                      id={'firstname'}
                      name={'firstname'}
                      type={'text'}
                      placeholder={translate('Account.AccountDetails.PlaceholderFirstName')}
                      value={user.firstname}
                      onChange={onChangeInput('firstname')}
                      autoComplete="given-name"
                      required
                    />

                    <InputLabel htmlFor="lastname" required>{translate('Account.AccountDetails.PlaceholderLastName')}</InputLabel>
                    <Input
                      disabled={loadingDetails}
                      id={'lastname'}
                      name={'lastname'}
                      placeholder={translate('Account.AccountDetails.PlaceholderLastName')}
                      value={user.lastname}
                      onChange={onChangeInput('lastname')}
                      autoComplete="family-name"
                      required
                    />

                    <InputLabel htmlFor="email" required>{translate('Account.AccountDetails.PlaceholderEmailAddress')}</InputLabel>
                    <Input
                      disabled={loadingDetails || role !== 'school'}
                      id={'email'}
                      name={'email'}
                      type={'email'}
                      placeholder={translate('Account.AccountDetails.PlaceholderEmailAddress')}
                      value={user.email}
                      onChange={onChangeInput('email')}
                      autoComplete="email"
                      required
                    />

                    {errorDetails &&
                      <Error>{errorMessageDetails}</Error>
                    }
                    {successDetails &&
                      <Success>{successMessageDetails}</Success>
                    }
                    <Button
                      styleType="primary-white"
                      aria-disabled={loadingDetails}
                      type={'submit'}
                    >
                      {translate('Account.AccountDetails.Save')}
                    </Button>
                  </form>
                </AccountDetails>

                {role === 'school' && (
                  <AccountAddress>
                    <LiveMessage message={ariaLiveMessageAddress} aria-live="polite" />
                    <h2>{translate('Account.AddressDetails.Title')}</h2>
                    <form onSubmit={onSubmitAccountAddress} noValidate>
                      {loadingAddress && <Loader white/>}
                      <InputLabel htmlFor="schoolname" required>{translate('Account.AddressDetails.PlaceholderSchoolname')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.schoolname}
                        id={'schoolname'}
                        name={'schoolname'}
                        placeholder={translate('Account.AddressDetails.PlaceholderSchoolname')}
                        onChange={onChangeInput('schoolname')}
                        required
                      />

                      <InputLabel htmlFor="address" required>{translate('Account.AddressDetails.PlaceholderAddress')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.address}
                        id={'address'}
                        name={'address'}
                        placeholder={translate('Account.AddressDetails.PlaceholderAddress')}
                        onChange={onChangeInput('address')}
                        required
                      />

                      <InputLabel htmlFor="city" required>{translate('Account.AddressDetails.PlaceholderCity')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.city}
                        id={'city'}
                        name={'city'}
                        placeholder={translate('Account.AddressDetails.PlaceholderCity')}
                        onChange={onChangeInput('city')}
                        required
                      />

                      <InputLabel htmlFor="state" required>{translate('Account.AddressDetails.PlaceholderState')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.state}
                        id={'state'}
                        name={'state'}
                        placeholder={translate('Account.AddressDetails.PlaceholderState')}
                        onChange={onChangeInput('state')}
                        required
                      />

                      <InputLabel htmlFor="country" required>{translate('Account.AddressDetails.PlaceholderCountry')}</InputLabel>
                      <SelectInput
                        disabled={loadingAddress}
                        placeholder={translate('Account.AddressDetails.PlaceholderCountry')}
                        type={'text'}
                        id={'country'}
                        name={'country'}
                        value={user.country}
                        onChange={onChangeSelectnput('country')}
                      >
                        <option value={'usa'}>{translate('country.USA')}</option>
                        <option value={'can'}>{translate('country.Canada')}</option>
                        <option value={'other'}>{translate('country.Other')}</option>
                      </SelectInput>

                      <InputLabel htmlFor="postalzip" required>{translate('Account.AddressDetails.PlaceholderPostalZip')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.postalzip}
                        id={'postalzip'}
                        name={'postalzip'}
                        placeholder={translate('Account.AddressDetails.PlaceholderPostalZip')}
                        onChange={onChangeInput('postalzip')}
                      />

                      <InputLabel htmlFor="phone" required>{translate('Account.AddressDetails.PlaceholderPhone')}</InputLabel>
                      <Input
                        disabled={loadingAddress}
                        type={'text'}
                        value={user.phone}
                        id={'phone'}
                        name={'phone'}
                        placeholder={translate('Account.AddressDetails.PlaceholderPhone')}
                        onChange={onChangeInput('phone')}
                      />

                      {errorAddress && (
                        <Error>{errorMessageAddress}</Error>
                      )}
                      {successAddress && (
                        <Success>{successMessageAddress}</Success>
                      )}
                      <Button
                        styleType="primary-white"
                        aria-disabled={loadingAddress}
                        type={'submit'}
                      >
                        {translate('Account.AddressDetails.Save')}
                      </Button>
                    </form>
                  </AccountAddress>
                )}

                <AccountPassword>
                  <LiveMessage message={ariaLiveMessagePassword} aria-live="polite" />
                  <h2>{translate('Account.PasswordDetails.Title')}</h2>
                  <form onSubmit={onSubmitAccountPassword} noValidate>
                    {loadingPassword && <Loader white/>}

                    <InputLabel htmlFor="password" required>{translate('Account.PasswordDetails.PlaceholderNewPassword')}</InputLabel>
                    <Input
                      disabled={loadingPassword}
                      id={'password'}
                      name={'password'}
                      type={'password'}
                      autoComplete="new-password"
                      placeholder={translate('Account.PasswordDetails.PlaceholderNewPassword')}
                      value={passwordInputs.password}
                      onChange={onChangePasswordInput('password')}
                      required
                    />

                    <InputLabel htmlFor="confirm" required>{translate('Account.PasswordDetails.PlaceholderConfirmPassword')}</InputLabel>
                    <Input
                      disabled={loadingPassword}
                      id={'confirm'}
                      name={'confirm'}
                      type={'password'}
                      autoComplete="new-password"
                      placeholder={translate('Account.PasswordDetails.PlaceholderConfirmPassword')}
                      value={passwordInputs.confirm}
                      onChange={onChangePasswordInput('confirm')}
                      required
                    />

                    {errorPassword && (
                      <Error>{errorMessagePassword}</Error>
                    )}
                    {successPassword && (
                      <Success>{successMessagePassword}</Success>
                    )}
                    <Button
                      styleType="primary-white"
                      aria-disabled={loadingPassword}
                      type={'submit'}
                    >
                      {translate('Account.PasswordDetails.Save')}
                    </Button>
                  </form>
                </AccountPassword>
              </>
            </AccountStyles>
          )}
        </Container>
      </>
    </DocumentTitle>
  );
};

// EXPORTS
export default Account;
