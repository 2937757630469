import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function exportCSV() {
  ReactGA.event({
    category: 'Student table',
    action: 'Export CSV'
  })
}

export function downloadStudentResults() {
  ReactGA.event({
    category: 'Student table',
    action: 'Download student results'
  })
}

export function disableStudentAccount() {
  ReactGA.event({
    category: 'Student table',
    action: 'Disable student account'
  })
}

export function enableStudentAccount() {
  ReactGA.event({
    category: 'Student table',
    action: 'Enable student account'
  })
}

export function showDisabledStudentAccounts() {
  ReactGA.event({
    category: 'Student table',
    action: 'Show disabled student accounts'
  })
}

export function hideDisabledStudentAccounts() {
  ReactGA.event({
    category: 'Student table',
    action: 'Hide disabled student accounts'
  })
}

export default {
  exportCSV: validateRole(exportCSV),
  downloadStudentResults: validateRole(downloadStudentResults),
  disableStudentAccount: validateRole(disableStudentAccount),
  enableStudentAccount: validateRole(enableStudentAccount),
  showDisabledStudentAccounts: validateRole(showDisabledStudentAccounts),
  hideDisabledStudentAccounts: validateRole(hideDisabledStudentAccounts)
}