// IMPORTS
import React, { useEffect, useState, useCallback, useContext, useRef, KeyboardEvent } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
import { history } from '../../redux/store';

// PRESENTATION
import Signup from '../../components/Signup';

// ACTIONS
import { doSignup, doResetErrors, doSubmitContactRequest, doResetContactState } from './actions';
import { doModalShow, doModalHide } from '../ModalBackgroundContainer/actions';

// TYPES
interface Props {
  state: any;
  doSignup: typeof doSignup;
  doModalShow: Function;
  doModalHide: Function;
  doResetErrors: Function;
  doSubmitContactRequest: Function;
  doResetContactState: Function;
}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const SignupContainer = (props: Props) => {
  // Vars
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [inputState, setInputState] = useState({
    type: 'Student',
    subscription: '',
    country: 'usa'
  });

  const {
    doModalShow: modalShow,
    doModalHide: modalHide,
    doResetErrors: resetErrors,
    doSubmitContactRequest: submitContactRequest,
    doResetContactState: resetContactState,
    state: { authReducer }
  } = props;

  useEffect(() => {
    return () => resetContactState()
  }, [resetContactState])

  // Refs
  const firstNameInput = useRef<any>('');
  const lastNameInput = useRef<any>('');
  const emailInput = useRef<any>('');
  const passwordInput = useRef<any>('');
  const confirmPasswordInput = useRef<any>('');
  const codeInput = useRef<any>('');
  const addressInput = useRef<any>('');
  const cityInput = useRef<any>('');
  const stateInput = useRef<any>('');
  const countryInput = useRef<any>('');
  const postalzipInput = useRef<any>('');
  const phoneInput = useRef<any>('');
  const schoolnameInput = useRef<any>('');

  const inputRefs = {
    firstnameRef: firstNameInput,
    lastnameRef: lastNameInput,
    emailRef: emailInput,
    passwordRef: passwordInput,
    confirmPasswordRef: confirmPasswordInput,
    codeRef: codeInput,
    addressRef: addressInput,
    cityRef: cityInput,
    stateRef: stateInput,
    countryRef: countryInput,
    postalzipRef: postalzipInput,
    phoneRef: phoneInput,
    schoolnameRef: schoolnameInput
  };

  // Functions
  const { translate } = useContext(LanguageContext);
  const onModalCancel = useCallback(() => {
    if (!authReducer.get('isSignUpLoading')) {
      setShow(false);
      modalHide();
      setTimeout(() => {
        history.push('/');
      }, 250);
    }
  }, [authReducer, modalHide]);

  /**
   *
   * @param event
   */
  const onFormSubmit = (event: Event) => {
    event.preventDefault();

    if (inputState.subscription === 'contact') {
      const fields = {
        firstname: firstNameInput.current?.value,
        lastname: lastNameInput.current?.value,
        email: emailInput.current?.value,
        phone: phoneInput.current?.value,
        schoolname: schoolnameInput.current?.value
      }
      submitContactRequest(fields)
    } else {
      const params = {
        firstname: firstNameInput.current?.value,
        lastname: lastNameInput.current?.value,
        email: emailInput.current?.value,
        password: passwordInput.current?.value,
        confirmPassword: confirmPasswordInput.current?.value,
        type: inputState.type.toLowerCase(),
        code: codeInput.current?.value?.trim(),
        address: addressInput.current?.value,
        city: cityInput.current?.value,
        state: stateInput.current?.value,
        country: countryInput.current?.value,
        postalzip: postalzipInput.current?.value,
        phone: phoneInput.current?.value,
        schoolname: schoolnameInput.current?.value
      };
      props.doSignup({ ...params });
    }
  };

  /**
   *
   * @param key
   * @param value
   */
  const updateState = (newState: Object) => {
    setInputState({
      ...inputState,
      ...newState,
    });
  };

  // Hooks
  useEffect(() => {
    if (!loaded && !show) {
      setTimeout(() => {
        resetErrors();
        modalShow();
        setShow(true);
        setLoaded(true);
      }, 100);
    }
  }, [show, loaded, modalShow, resetErrors]);

  useEffect(() => {
    const escapeHandler: any = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onModalCancel();
      }
      return () => doModalHide();
    }

    document.body.addEventListener('keyup', escapeHandler);

    return () => {
      document.body.removeEventListener('keyup', escapeHandler)
    }
  }, [onModalCancel]);

  // Render
  return (
    <Signup
      {...props}
      {...inputRefs}
      loading={props.state.authReducer.get('isSignUpLoading') || props.state.authReducer.get('isContactLoading')}
      contactSubmissionSuccess={props.state.authReducer.get('isContactSuccess')}
      error={props.state.authReducer.get('error')}
      doSignup={doSignup}
      show={show}
      title={translate('SignupContainer.Title')}
      onModalCancel={onModalCancel}
      onFormSubmit={onFormSubmit}
      inputState={inputState}
      updateState={updateState}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doSignup,
      doModalShow,
      doModalHide,
      doResetErrors,
      doSubmitContactRequest,
      doResetContactState
      // doResetError
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);
