import ReactGA from 'react-ga';
import {validateRole} from '../../middleware'

export function viewFAQ({
  label
} : any) {
  ReactGA.event({
    category: 'Help',
    action: 'View frequently asked question',
    label
  })
}

export default {
  viewFAQ: validateRole(viewFAQ)
}