// IMPORTS
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// PRESENTATION
import GetCards from '../../components/GetCards';

// TYPES
import { AppState } from '../../redux/reducer';
interface IProps extends AppState, ReturnType<typeof mapDispatchToProps> {}

// REDUX
const mapStateToProps = (state: AppState) => ({ ...state });
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const GetCardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(GetCards);

// EXPORTS
export default GetCardsContainer;
