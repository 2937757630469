// IMPORTS
import React from 'react';
import ModalBackgroundStyles from './styles';

// TYPES
interface IProps {
  show: boolean;
  isDashboard: boolean;
  backgroundColor?: String;
}

// MAIN COMPONENT
const Modal: React.FC<IProps> = props => (
  <ModalBackgroundStyles isDashboard={props.isDashboard} show={props.show} backgroundColor={props.backgroundColor || ''} />
);

// EXPORTS
export default Modal;
