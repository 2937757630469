// SCREENS
const Screens = {
  extraSmallest: '375px',
  extraSmall: '460px',
  small: '576px',
  smallAbsolute: 576,
  medium: '768px',
  mediumAbsolute: 768,
  large: '992px',
  extraLarge: '1200px',
  extraLarger: '1440px',
  extraLargest: '1920px'
};

// EXPORTS
export default Screens;
