// IMPORTS
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppContainer from './containers/AppContainer';

// PAGE COMPONENTS
import Login from './pages/Login';
import LandingPage from './pages/LandingPage';
import BlankLandingPage from './pages/LandingPage/BlankLandingPage';
import Signup from './pages/Signup';
import Forgot from './pages/Forgot';
import Account from './pages/Account';
import Cards from './pages/Cards';
import CardIntro from './pages/Cards/Intro';
import Instructions from './pages/Cards/Instructions';
import BackOfCardReminder from './pages/Cards/BackOfCardReminder';
import CardSort from './pages/Cards/Sort';
import NotFoundPage from './pages/NotFoundPage';
import FaqPage from './pages/FaqPage';
import SupportPage from './pages/SupportPage';
import Logout from './pages/Logout';
import StudentsPage from './pages/StudentsPage';
import Classlink from './pages/Classlink';

// CONTAINER COMPONENTS
import ProtectedRoute from './containers/ProtectedRoute';
import ModalBackgroundContainer from './containers/ModalBackgroundContainer';
import PickThree from './pages/Cards/PickThree';
import CardResult from './pages/Cards/Result';
import CardSortConclusion from './pages/Cards/Conclusion';
import FooterContainer from './containers/FooterContainer';
import GetCards from './pages/GetCards';

// MAIN COMPONENT
const Routes = () => (
  <>
    <AppContainer>
      <Route path={'/'} component={ModalBackgroundContainer} />
      <Switch>
        {/* PROCTECTED ROUTES */}
        <ProtectedRoute
          path={'/dashboard'}
          exact
          render={() => <Redirect to={'/dashboard/cards'} />}
        />
        <ProtectedRoute path={'/dashboard/getCards'} exact component={GetCards} />
        <ProtectedRoute path={'/dashboard/account'} exact component={Account} />
        <ProtectedRoute path={'/dashboard/help'} exact component={FaqPage} />
        <ProtectedRoute path={'/dashboard/support'} exact component={SupportPage} />
        <ProtectedRoute path={'/dashboard/cards'} exact component={Cards} />
        <ProtectedRoute path={'/dashboard/cards/intro'} exact component={CardIntro} />
        <ProtectedRoute path={'/dashboard/cards/instructions'} exact component={Instructions} />
        <ProtectedRoute path={'/dashboard/cards/reminder'} exact component={BackOfCardReminder} />
        <ProtectedRoute path={'/dashboard/cards/select'} exact component={PickThree} />
        <ProtectedRoute path={'/dashboard/cards/results'} exact component={CardResult} />
        <ProtectedRoute path={'/dashboard/cards/conclusion'} exact component={CardSortConclusion} />
        <ProtectedRoute path={'/dashboard/cards/:index'} exact component={CardSort} />
        <ProtectedRoute path={'/dashboard/students'} exact component={StudentsPage} />
        <ProtectedRoute path={'/dashboard/students/:schoolId'} exact component={StudentsPage} />

        {/* PUBLIC ROUTES */}
        <Route path={'/(login|signup|forgot|classlink|)/'}>
          <Route path={'/(login|signup|forgot|)/'} exact component={LandingPage} />
          <Route path={'/classlink'} component={BlankLandingPage} />

          <Switch>
            <Route path={'/login'} exact component={Login} />
            <Route path={'/signup'} exact component={Signup} />
            <Route path={'/forgot'} exact component={Forgot} />
            <Route path={'/classlink'} exact component={Classlink} />
          </Switch>
        </Route>
        <Route path={'/logout'} exact component={Logout} />
        <Route component={NotFoundPage} />
      </Switch>
      <Route render={(props: any) => {
        const { location: { pathname = ''} = {}} = props || {}
        const isLight = /^\/dashboard/.test(pathname)
        return <FooterContainer light={isLight}/>
      }}/>
    </AppContainer>
  </>
);

// EXPORTS
export default Routes;
