// IMPORTS
import React from 'react';

// CONTAINERS
import AccountContainer from '../../containers/AccountContainer';

// COMPONENT
const Account = () => <AccountContainer />;

// EXPORTS
export default Account;
