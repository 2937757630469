// IMPORTS
import React, { useEffect, useState, useCallback, useContext, useRef, KeyboardEvent } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { AppState } from '../../redux/reducer';
import { history } from '../../redux/store';

// PRESENTATION
import Login from '../../components/Login';

// ACTIONS
import { doLogin, doResetErrors } from './actions';
import { doModalShow, doModalHide } from '../ModalBackgroundContainer/actions';

// TYPES
interface Props {
  state: AppState;
  doLogin: Function;
  doResetErrors: Function;
  doModalShow: Function;
  doModalHide: Function;
}

// MAIN COMPONENT
/**
 * Main component
 * @param {*} props state and dispatch
 * @returns {Component} Presentation compoennt
 */
const LoginContainer = (props: Props) => {
  // Vars
  const [show, setShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const {
    doResetErrors: resetErrors,
    doModalShow: modalShow,
    doModalHide: modalHide,
    state: { authReducer }
  } = props;

  // Refs
  const inputEmail = useRef<any>('');
  const inputPassword = useRef<any>('');
  // Functions
  const { translate } = useContext(LanguageContext);
  const onModalCancel = useCallback(() => {
    if (!authReducer.get('isLoginLoading')) {
      setShow(false);
      modalHide();
      setTimeout(() => {
        history.push('/');
      }, 250);
    }
  }, [authReducer, modalHide]);

  /**
   *
   * @param event
   */
  const onFormSubmit = (event: Event) => {
    props.doLogin(inputEmail.current?.value, inputPassword.current?.value);
    event.preventDefault();
  };

  // Hooks
  useEffect(() => {
    if (!loaded && !show) {
      resetErrors();
      setTimeout(() => {
        setShow(true);
        modalShow();
        setLoaded(true);
      }, 100);
    }
  }, [show, loaded, resetErrors, modalShow]);

  useEffect(() => {
    const escapeHandler: any = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onModalCancel();
      }
    }

    document.body.addEventListener('keyup', escapeHandler);

    return () => {
      document.body.removeEventListener('keyup', escapeHandler)
    }
  }, [onModalCancel]);

  // Render
  return (
    <Login
      {...props}
      loading={
        props.state.authReducer.get('isLoginLoading') || props.state.accountReducer.get('loading')
      }
      error={props.state.authReducer.get('error') ?? props.state.accountReducer.get('error') as string}
      doLogin={doLogin}
      show={show}
      title={translate('LoginContainer.Title')}
      onModalCancel={onModalCancel}
      onFormSubmit={onFormSubmit}
      inputEmailRef={inputEmail}
      inputPasswordRef={inputPassword}
    />
  );
};

// REDUX
/**
 * Binds redux state to component
 * @param {*} state from redux state
 * @return {Object} state object
 */
const mapStateToProps = (state: AppState) => ({
  state
  // state props here
});

/**
 * Binds redux dispatch to component
 * @param {*} dispatch used for reducer
 * @return {bindActionCreators} combines all actionCreators to dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      doLogin,
      doResetErrors,
      doModalHide,
      doModalShow
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
