import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';
import Color from 'color';

export const ModalImageContainer = styled.div<{
  bgColor: string;
}>`
  height: 250px;
  width: 100%;
  margin: 12px 0;
  padding: 0;
  border-radius: 3px;
  background-color: ${props => props.bgColor};
  & img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const CardModalStyle = styled.div`
  padding: 20px;
  max-height: 600px;
  overflow: auto;
  h2 {
    font-weight: 600;
    margin: 0;
    text-transform: capitalize;
  }
  h2.fr {
    text-transform: none;
  }

  span {
    display: flex;
    align-items: center;
    position: relative;

    h3 {
      margin-bottom: 0;
    }

    span {
      cursor: pointer;
      height: 28px;
      padding-top: 5px;
      margin-left: 5px;
    }
  }

  ul {
    list-style: none;
    color: ${Colors.grey66};
    padding: 4px 8px;
    font-size: 16px;
    padding: 10px;
    margin: 0;
    word-wrap: break-word;
    line-height: 1.4em;

    li {
      font-weight: bold;
      margin-bottom: 5px;

      a {
        color: ${Colors.purple78};
        text-decoration: none;
        border-radius: 25px;
        border: 1px solid transparent;
        display: inline-block;
        transition: all 250ms ease-in-out 0s;

        &:focus {
          outline: none;
          border-color: ${Colors.black};
          box-shadow: 0px 0px 0px 2px
          ${Color(Colors.black)
            .rgb()
            .toString()};
        }

        &:visited {
          color: ${Colors.purple78};
          text-decoration: none;
        }

        &:hover {
          color: ${Colors.purple62};
        }

        @media (min-width: ${Screens.medium}) {
          &:hover {
              transform: scale(1.05);
          }
        }
      }
    }
  }

  #careers-expand-button {
    min-height: auto;
    height: auto;
    padding: 0;
    width: auto;
    min-width: auto;
    line-height: unset;
    border: none;
    background: none;
    margin-left: 6px;

    &:hover {
      color: currentColor;
    }
  }

  @media (max-width: ${Screens.small}) {
    ul {
      min-width: 95%;
      max-width: 95%;
      top: 15px;
      left: 2.5%;
      margin-bottom: 30px;
    }
  }

  p {
    margin: 15px 0;
  }
`;

export default CardModalStyle;
