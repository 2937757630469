// IMPORTS
import React from 'react';
import AppStyles from './styles';

// TYPES
interface Props {
  isDashboard: boolean;
}

// MAIN COMPONENT
const App: React.FC<Props> = props => {
  return (
    <AppStyles id="content-container" role="main" isDashboard={props.isDashboard}>
      {props.children}
    </AppStyles>
  );
};

// EXPORTS
export default App;
