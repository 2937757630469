// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import { InputStyles } from '../Input/styles';
import LoaderStyles from '../../styles/Loader/styles';
import { CardsDisplay } from '../../styles/assets';
import Colors from '../../styles/Colors';

// MAIN COMPONENTS
const LoginLeftStyles = styled.div`
  display: none;
  overflow: hidden;

  @media (min-width: ${Screens.medium}) {
    background: url(${CardsDisplay});
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const LoginRightStyles = styled.div`
  display: block;
  padding: 30px 30px 0 30px;
  height: 100%;
  overflow-y: auto;

  hr {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  ${InputStyles.toString} {
    input {
      width: 100%;
      color: ${Colors.grey33};
    }
  }

  form {
    p {
      margin-bottom: 0;
      small {
        line-height: 42px;
        margin-left: 20px;
      }
    }
  }

  @media (min-width: ${Screens.medium}) {
    width: 50%;
    margin-left: 50%;
  }
`;

const LoginRightStylesForgot = styled.div`
  position: relative;
  a {
    position: absolute;
    font-size: 12px;
    line-height: 42px;
    right: 0;
    top: 26px;
    padding: 0 14px;
  }
`;

const LoginStyles = styled.div`
  form {
    position: relative;
  }

  ${LoaderStyles} {
    position: absolute;
    top: -110px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  button {
    margin-top: 10px;
    line-height: 42px;
    width: 100%;
  }

  @media (min-width: ${Screens.medium}) {
    button {
      width: inherit;
    }
  }

  @media (max-width: ${Screens.medium}) {
    display: flex;
    flex-direction: column;
  }

  height: 100%;
`;

// EXPORTS
export { LoginLeftStyles, LoginRightStyles, LoginRightStylesForgot, LoginStyles };
