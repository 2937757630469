// IMPORTS
import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import LanguageContext from '../../locale/LanguageContext';
import CardSort, { StateItem } from '../../components/CardSort';
import { AppState } from '../../redux/reducer';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push, Push } from 'connected-react-router';
import { withRouter, RouteComponentProps, StaticContext, Prompt, useHistory } from 'react-router';
import Modal from '../../components/Modal';
import ModalBackgroundContainer from '../ModalBackgroundContainer';
import { doModalHide, doModalShow } from '../ModalBackgroundContainer/actions';
import {
  doSetStartTime,
  doDeleteResult,
  doCreateResult
} from '../../containers/CardResultContainer/actions';
import CardModal, { CardType } from '../../components/CardModal';
import { TransitionStyles } from '../../components/PickThree/styles';
import ButtonStyles from '../../styles/Button';
import {
  CardAttributesResult,
  prepareExtraCards,
  getAllCardsWithContent,
  getBaseCardsWithExclusion
} from '../../util/card';
import gaUtil from '../../util/ga';

// TYPES
interface IProps {}
interface StateProps {
  router: AppState['router'];
  resultReducer: AppState['resultReducer'];
  accountReducer: AppState['accountReducer'];
  location: {
    state: {
      cards: any[];
      extraCards: CardAttributesResult[];
      savedResultsCards: any[];
    };
  };
}
interface DispatchProps {
  push: Push;
  doModalShow: typeof doModalShow;
  doModalHide: typeof doModalHide;
  doSetStartTime: Function;
  doDeleteResult: Function;
  doCreateResult: Function;
}

type Props = IProps &
  StateProps &
  DispatchProps &
  RouteComponentProps<{ index: string }, StaticContext, any>;

export const cards = [
  'CardHealthcare',
  'CardHealthyLiving',
  'CardHealthInformatics',
  'CardMedicine',
  'CardMentalHealth',
  'CardSportsPerformance',
  'CardSchoolFuture',
  'CardProtectSociety',
  'CardReachPotential',
  'CardPoverty',
  'CardRespect',
  'CardMakeMatters',
  'CardSetThingsUp',
  'CardFixBrokenStuff',
  'CardMasterTool',
  'CardSpecialEquipment',
  'CardSpecialMaterials',
  'CardWealth',
  'CardSustainableDevelopment',
  'CardFairTrade',
  'CardEntrepreneurship',
  'CardFutureLeaders',
  'CardWorkOnHomes',
  'CardMakeBuildingsWork',
  'CardBigMachines',
  'CardGivePower',
  'CardControlWater',
  'CardWeatherElements',
  'CardIdeasEmotions',
  'CardMasterTools',
  'CardMeaningfulExperiences',
  'CardInspireArt',
  'CardPeopleBetter',
  'CardCareCars',
  'CardBuildBig',
  'CardGreatProducts',
  'CardUseComputers',
  'CardMakeItBeautiful',
  'CardHelpPeople',
  'CardPrepareFood',
  'CardGrowFood',
  'CardAI',
  'CardBioTech',
  'CardBigData',
  'CardVR',
  'CardBrain',
  'CardBlockChain',
  'CardHelpOthersSolve',
  'CardHelpTeamsAchieve',
  'CardYourOwnBusiness',
  'CardSustainableEnergy',
  'CardBiodiversity',
  'CardTransportation',
  'CardWaste',
  'CardFoodSystems',
  'CardLearnFromAnimal'
];

export const baseCards = cards.map(i => ({ name: i, result: null })) as StateItem[];
/**
 *
 * @param props
 */

const options = ['dislike', 'like', 'superlike'];

const CardSortContainer: React.FC<Props> = props => {
  const { translate } = useContext(LanguageContext);
  const extraCards =
    (props.location.state && props.location.state.extraCards) ||
    props.resultReducer.get('extraCards') ||
    [];
  const excludedCards = props.accountReducer.get('excludedCards').toJS() || [];
  const routerStateCards =
    (props.location && props.location.state && props.location.state.cards) || [];
  const savedResultCards =
    routerStateCards.length > 0
      ? routerStateCards
      : props.resultReducer.get('savedResultsCards') || [];
  const baseCardsWithExclusion = getBaseCardsWithExclusion(excludedCards);
  const baseCardsWithExtraCards =
    savedResultCards.length > 0
      ? savedResultCards
      : [...baseCardsWithExclusion, ...prepareExtraCards(extraCards)];
  const savedResultsCardsWithCopy = getAllCardsWithContent(baseCardsWithExtraCards, extraCards);
  const { index } = props.match.params;
  const liked = [...savedResultsCardsWithCopy];
  const [showModal, setShowModal] = useState(false);
  const [showBeSelective, setShowBeSelective] = useState(false);
  const [cardType, setCardType] = useState<CardType>(null);
  const activeElement = useRef<HTMLElement | null>(null);
  const { doModalHide, doSetStartTime, doDeleteResult, doCreateResult } = props;
  const next = +index + 1;
  const history = useHistory();
  const role = props.accountReducer.get('role');
  const hasResults = props.accountReducer.get('results');
  const isFirstSort = !hasResults;

  useEffect(() => {
    function reset(location: any) {
      const { pathname } = location;
      if (!/\/cards\/(select|results|[0-9]+)$/.test(pathname)) {
        doSetStartTime(null);
        doDeleteResult();
        gaUtil.events.cardSort.abandonCardSort(role, { isFirstSort });
      }
    }

    if (history) {
      return history.listen(reset);
    }
  }, [history, doSetStartTime, doDeleteResult, role, isFirstSort]);

  useEffect(() => {
    const cardArrayIndex = +index - 1;
    if (liked.length > 0 && cardArrayIndex > 0 && cardArrayIndex + 1 > liked.length) {
      props.push('/dashboard/cards/1');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, liked.length]);

  useEffect(() => {
    const baseCardsWithExclusion = getBaseCardsWithExclusion(excludedCards);
    doCreateResult({
      cards: [...baseCardsWithExclusion, ...prepareExtraCards(extraCards)]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doCreateResult]);

  const doConfirm = (nLiked: typeof liked) => {
    const numSuper = nLiked.filter((i: any) => i.result === options[2] || i.result === options[1])
      .length;
    if (numSuper < 3) {
      props.push('/dashboard/cards/1', {
        cards: liked
      });
      setShowBeSelective(true);
    } else {
      props.push('/dashboard/cards/select', {
        cards: nLiked
      });
    }
  };

  useEffect(() => {
    const listener = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = translate('CardSortContainer.Warning.Undo');
    };
    window.addEventListener('beforeunload', listener);
    return () => window.removeEventListener('beforeunload', listener);
  });

  const onClickButton = (option: number) => () => {
    const nLiked = [...liked];
    nLiked[+index - 1].result = options[option];
    if (next > liked.length) {
      doConfirm(nLiked);
    } else {
      props.push(`/dashboard/cards/${next}`, {
        cards: nLiked
      });
    }
  };

  const onUndo = () => {
    const last = +index - 1;
    if (last < 1) return;
    const nLiked = [...liked];
    nLiked[last - 1].result = null;
    props.push(`/dashboard/cards/${last}`, {
      cards: nLiked
    });
  };

  const onModalClose = useCallback(() => {
    doModalHide();
    setShowModal(false);
    if (activeElement.current) {
      setTimeout(() => {
        activeElement.current?.focus();
      }, 0);
    }
  }, [doModalHide]);

  useEffect(() => {
    if (showModal) {
      const escapeHandler = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          onModalClose();
        }
      };

      document.addEventListener('keyup', escapeHandler);

      return () => {
        document.removeEventListener('keyup', escapeHandler);
      };
    }
  }, [showModal, onModalClose]);

  const onClickCard = (type: CardType) => () => {
    activeElement.current = document.activeElement as HTMLElement;
    setCardType(type);
    setShowModal(true);
    props.doModalShow();
    gaUtil.events.cardSort.viewBackOfCard(role, { card: type && type.name });
  };

  if (!savedResultsCardsWithCopy[+index - 1]) {
    return null;
  }

  return (
    <>
      {showBeSelective ? (
        <TransitionStyles>
          <h2>{translate('CardSortContainer.Heading')}</h2>
          <p>{translate('CardSortContainer.Paragraph.PartOne')}</p>
          <p>{translate('CardSortContainer.Paragraph.PartTwo')}</p>
          <ButtonStyles outline onClick={() => setShowBeSelective(false)}>
            {translate('CardSortContainer.Button.GotIt')}
          </ButtonStyles>
        </TransitionStyles>
      ) : (
        <>
          <Prompt
            message={({ pathname }) => {
              return !/\/cards\/(select|[0-9]+)$/.test(pathname)
                ? translate('CardSortContainer.Warning.PreventLeave')
                : true;
            }}
          />
          <CardSort
            {...props}
            index={index}
            cards={liked}
            onUndo={onUndo}
            onDislike={onClickButton(0)}
            onLike={onClickButton(1)}
            onSuperLike={onClickButton(2)}
            onClickCard={onClickCard}
          />
          <ModalBackgroundContainer isDashboard={true} />
          {showModal && (
            <Modal
              maxHeight={600}
              minHeight={null}
              hideHeader={true}
              show={true}
              loading={false}
              onCancel={onModalClose}
              noPadding
            >
              <CardModal type={cardType} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

// REDUX
/**
 *
 * @param state
 */
const mapStateToProps = (state: AppState) => ({ ...state });

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      push,
      doModalShow,
      doModalHide,
      doSetStartTime,
      doDeleteResult,
      doCreateResult
    },
    dispatch
  );

// EXPORTS
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CardSortContainer));
