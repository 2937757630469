const DO_GET_USER_DATA = 'Account/DO_GET_USER_DATA';
const START_GET_USER_DATA = 'Account/START_GET_USER_DATA';
const GET_USER_DATA_SUCCESS = 'Account/GET_USER_DATA_SUCCESS';
const GET_USER_DATA_ERROR = 'Account/GET_USER_DATA_ERROR';
const DO_SAVE_USER_DATA = 'Account/DO_SAVE_USER_DATA';

const ACCOUNT_GET_USER_REQUEST = 'Account/ACCOUNT_GET_USER_REQUEST';
const ACCOUNT_GET_USER_FAIL = 'Account/ACCOUNT_GET_USER_FAIL';
const ACCOUNT_GET_USER_SUCCESS = 'Account/ACCOUNT_GET_USER_SUCCESS';

const ACCOUNT_UPDATE_RESET = 'Account/ACCOUNT_UPDATE_RESET';
const ACCOUNT_DETAILS_UPDATE_REQUEST = 'Account/ACCOUNT_DETAILS_UPDATE_REQUEST';
const ACCOUNT_DETAILS_UPDATE_FAIL = 'Account/ACCOUNT_DETAILS_UPDATE_FAIL';
const ACCOUNT_DETAILS_UPDATE_SUCCESS = 'Account/ACCOUNT_DETAILS_UPDATE_SUCCESS';

const ACCOUNT_ADDRESS_UPDATE_REQUEST = 'Account/ACCOUNT_ADDRESS_UPDATE_REQUEST';
const ACCOUNT_ADDRESS_UPDATE_FAIL = 'Account/ACCOUNT_ADDRESS_UPDATE_FAIL';
const ACCOUNT_ADDRESS_UPDATE_SUCCESS = 'Account/ACCOUNT_ADDRESS_UPDATE_SUCCESS';

const ACCOUNT_PASSWORD_UPDATE_REQUEST = 'Account/ACCOUNT_PASSWORD_UPDATE_REQUEST';
const ACCOUNT_PASSWORD_UPDATE_FAIL = 'Account/ACCOUNT_PASSWORD_UPDATE_FAIL';
const ACCOUNT_PASSWORD_UPDATE_SUCCESS = 'Account/ACCOUNT_PASSWORD_UPDATE_SUCCESS';

const ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST = 'Account/ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST';
const ACCOUNT_SCHOOL_CODE_UPDATE_FAIL = 'Account/ACCOUNT_SCHOOL_CODE_UPDATE_FAIL';
const ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS = 'Account/ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS';

const ACCOUNT_UPDATE_RESULTS = 'Account/ACCOUNT_UPDATE_RESULTS';

export {
  DO_GET_USER_DATA,
  GET_USER_DATA_ERROR,
  GET_USER_DATA_SUCCESS,
  START_GET_USER_DATA,
  DO_SAVE_USER_DATA,
  ACCOUNT_GET_USER_REQUEST,
  ACCOUNT_GET_USER_FAIL,
  ACCOUNT_GET_USER_SUCCESS,
  ACCOUNT_UPDATE_RESET,
  ACCOUNT_DETAILS_UPDATE_REQUEST,
  ACCOUNT_DETAILS_UPDATE_FAIL,
  ACCOUNT_DETAILS_UPDATE_SUCCESS,
  ACCOUNT_ADDRESS_UPDATE_REQUEST,
  ACCOUNT_ADDRESS_UPDATE_FAIL,
  ACCOUNT_ADDRESS_UPDATE_SUCCESS,
  ACCOUNT_PASSWORD_UPDATE_REQUEST,
  ACCOUNT_PASSWORD_UPDATE_FAIL,
  ACCOUNT_PASSWORD_UPDATE_SUCCESS,
  ACCOUNT_SCHOOL_CODE_UPDATE_REQUEST,
  ACCOUNT_SCHOOL_CODE_UPDATE_FAIL,
  ACCOUNT_SCHOOL_CODE_UPDATE_SUCCESS,
  ACCOUNT_UPDATE_RESULTS
};
