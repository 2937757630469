// IMPORTS
import styled from 'styled-components';
import Screens from '../../styles/Screens';
import Colors from '../../styles/Colors';

// TYPES
interface ModalStylesProps {
  show: boolean;
}
interface ModalContainerProps {
  maxHeight?: number | null;
  width?: number | null;
  minHeight?: number | null;
  maxWidth?: number | null;
}

interface ModalButtonProps extends React.HTMLProps<HTMLButtonElement> {}

// MAIN COMPONENTS
const ModalHeader = styled.div`
  display: block;
  border-bottom: 1px solid ${Colors.greyE6};
  padding: 20px;
  position: relative;

  h2 {
    margin: 0;
    font-weight: 200;
    font-size: 18px;
    color: ${Colors.grey99};
    font-style: normal;
  }
`;

const ModalHeadless = styled.div`
  position: absolute;
  right: 0;
`;

const ModalHeaderButton = styled.button<ModalButtonProps>`
  background: ${Colors.greyE6};
  opacity: ${props => (props.disabled ? '0.5' : '1')};
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto 0;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
  z-index: 99999;

  > span {
    background: ${Colors.purple62};
    display: block;
    width: 20px;
    height: 3px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;

    &:nth-child(1) {
      top: 50%;
      transform: rotate(45deg);
    }

    &:nth-child(2) {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
`;

const ModalBody = styled.div<{ noPadding?: true }>`
  display: block;
  border-bottom: 1px solid ${Colors.greyE6};
  padding: ${props => (props.noPadding ? 0 : 20)}px;
`;

const ModalFooter = styled.div`
  display: block;
  padding: 20px;
`;

const ModalContainer = styled.div<ModalContainerProps>`
  background: #ffffff;
  width: calc(100% - 30px);
  max-height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  margin: 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  top: 0;
  z-index: 9002;
  opacity: 1;
  -webkit-transition: all 500ms cubic-bezier(0.05, 2.32, 0.64, 0.92) 100ms;
  transition: all 500ms cubic-bezier(0.05, 2.32, 0.64, 0.92) 100ms;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);

  @media (min-width: ${Screens.medium}) {
    position: absolute;
    top: 15px;
    bottom: 15px;
    left: 0;
    right: 0;
    margin: auto;
    ${props => props.width && `
      width: ${props.width}px;
    `}
    ${props => props.minHeight && `
      min-height: ${props.minHeight}px;
    `}
    ${props => props.maxHeight && `
      max-height: ${props.maxHeight}px;
    `}
    ${props => props.maxWidth && `
      max-width: ${props.maxWidth}px;
    `}
  }
`;

const ModalStyles = styled.div<ModalStylesProps>`
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  margin: auto;
  transition: all 250ms ease-in-out 0s;
  opacity: ${props => (props.show ? '1' : '0')};
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${Screens.medium}) {
    padding: 20px;
  }
`;

const BackToTop = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: ${Colors.white};
  font-size: 14px;

  &:focus {
    color: ${Colors.grey66};
    outline: none;
    padding: 2px;
    margin-right: 1rem;
    border: 2px solid ${Colors.black};
    border-radius: 25px; 
  }
`;

// EXPORTS
export default ModalStyles;

export { ModalContainer, ModalHeader, ModalHeadless, ModalBody, ModalFooter, ModalHeaderButton, BackToTop };
