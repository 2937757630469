import React, { FC, useContext } from "react";
import Loader from '../../../../styles/Loader';
import { ChartData, ChartOptions } from 'chart.js';
import Colors from '../../../../styles/Colors';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LanguageContext from '../../../../locale/LanguageContext';
import {
  ChartContainer,
  Chart,
  Heading
} from './styles';

interface ISignUpsChartProps {
  error?: any;
  data: ChartData;
  heading: string;
  isLoading?: boolean;
}

const chartOptions = (language = 'en'): ChartOptions => ({
  layout: {
    padding: {
      top: 40,
      right: 40,
      left: 40
    }
  },
  maintainAspectRatio: false,
  animation: {
    duration: 0
  },
  plugins: {
    datalabels: {
      anchor: 'end',
      align: 'top',
      color: Colors.white,
      backgroundColor: Colors.blue1D,
      borderColor: Colors.white,
      borderWidth: 2,
      borderRadius: 4,
      font: {
        lineHeight: 1,
        weight: 'bold'
      }
    }
  },
  legend: {
    display: false
  },
  scales: {
    yAxes: [{
      display: false
    }],
    xAxes: [{
      gridLines: {
        zeroLineColor: "rgba(0,0,0,0.1)",
        drawTicks: false
      },
      ticks: {
        padding: 20,
        fontColor: "rgba(0,0,0,0.5)",
        fontStyle: "bold",
        maxRotation: 90,
        minRotation: 0,
        callback: function(value: string) {
          return new Date(value).toLocaleString(language, {month: 'long', timeZone: 'UTC'});
        }
      }
    }]
  }
})

const SignUpsChart: FC<ISignUpsChartProps> = (props) => {
  const { language, translate } = useContext(LanguageContext);
  const { error, data, heading, isLoading = false } = props

  return (
    <ChartContainer>
      <Heading>{heading}</Heading>
      {isLoading ? (
        <Loader/>
      ) : error ? (
        <p>{translate('Students.Chart.Error')}</p>
      ) : (
        <Chart>
          <Line 
            data={data}
            plugins={[ChartDataLabels]}
            options={chartOptions(language)}
          />
        </Chart>
      )}
    </ChartContainer>
  )
}

export default SignUpsChart