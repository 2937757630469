// IMPORTS
import React from 'react';
import Colors from '../../styles/Colors';

// TYPES
interface Props {}

interface ILogoProps {
  language: string;
}

// MAIN COMPONENTS
const Logo = (props: ILogoProps) =>
  props.language === 'en' ? (
    <svg
      role="img"
      viewBox="0 0 522 144"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>SparkPath logo</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-72.000000, -452.000000)" fillRule="nonzero">
          <g transform="translate(72.000000, 452.000000)">
            <circle style={{ fill: Colors.purple78 }} cx="72.09" cy="72" r="71.919"></circle>
            <path
              d="M108.948,68.477 L79.854,64.307 L76.253,35.216 L72.687,6.396 L68.568,35.142 L64.398,64.235 L35.307,67.836 L6.49,71.403 L35.234,75.523 L64.327,79.693 L67.928,108.783 L71.495,137.603 L75.614,108.857 L79.784,79.764 L108.875,76.161 L137.692,72.596 L108.948,68.477 Z M72.405,35.692 L76.337,67.463 C75.224,66.422 73.735,65.779 72.095,65.779 C70.454,65.779 68.964,66.423 67.852,67.465 L72.405,35.692 Z M74.438,72 C74.438,73.291 73.387,74.342 72.096,74.342 C70.805,74.342 69.755,73.291 69.755,72 C69.755,70.709 70.806,69.658 72.096,69.658 C73.387,69.658 74.438,70.709 74.438,72 Z M35.783,71.685 L67.568,67.75 C66.523,68.863 65.877,70.356 65.877,72 C65.877,73.639 66.519,75.126 67.558,76.238 L35.783,71.685 Z M71.775,108.307 L67.841,76.524 C68.955,77.572 70.449,78.219 72.095,78.219 C73.73,78.219 75.215,77.58 76.326,76.546 L71.775,108.307 Z M76.626,76.248 C77.671,75.135 78.316,73.644 78.316,72 C78.316,70.362 77.675,68.875 76.636,67.763 L108.398,72.316 L76.626,76.248 Z"
              style={{ fill: Colors.white }}
            ></path>
            <g transform="translate(171.000000, 57.000000)" fill="#FFFFFF">
              <path
                style={{ fill: Colors.grey33 }}
                d="M14.913,29.865 C8.791,29.865 4.762,28.116 0.502,23.745 L2.898,21.39 C6.624,25.076 9.589,26.483 14.875,26.483 C18.372,26.483 24.989,25.455 24.989,20.781 C24.989,17.359 21.452,15.989 18.562,15.952 L12.555,15.875 C8.98,15.838 7.231,15.42 5.521,14.316 C3.659,13.1 2.594,10.895 2.594,8.691 C2.594,2.417 9.665,0.135 14.836,0.135 C19.474,0.135 23.161,1.505 26.849,4.317 L24.757,6.939 C21.487,4.622 18.753,3.519 14.722,3.519 C11.682,3.519 5.978,4.735 5.978,8.691 C5.978,9.869 6.624,11.046 7.65,11.617 C8.944,12.302 9.817,12.493 13.43,12.528 L17.649,12.567 C19.627,12.567 21.64,12.758 23.467,13.517 C26.395,14.733 28.37,17.74 28.37,20.894 C28.371,27.776 20.462,29.865 14.913,29.865 Z"
              ></path>
              <path
                style={{ fill: Colors.grey33 }}
                d="M66.309,15.342 C64.561,16.75 62.356,17.053 60.189,17.053 L47.796,17.053 L47.796,29.144 L44.449,29.144 L44.449,0.744 L60.188,0.744 C65.511,0.744 69.162,3.214 69.162,8.842 C69.162,11.237 68.21,13.821 66.309,15.342 Z M63.8,4.697 C63.154,4.316 62.24,4.088 60.189,4.088 L47.796,4.088 L47.796,13.67 L60.189,13.67 C63.573,13.67 65.814,12.453 65.814,8.803 C65.813,7.207 65.208,5.535 63.8,4.697 Z"
              ></path>
              <path
                style={{ fill: Colors.grey33 }}
                d="M106.872,29.144 L102.232,19.373 L88.547,19.373 L84.059,29.144 L80.335,29.144 L93.868,0.744 L97.291,0.744 L110.595,29.144 L106.872,29.144 Z M95.541,4.811 L90.22,16.066 L100.75,16.066 L95.541,4.811 Z"
              ></path>
              <path
                style={{ fill: Colors.grey33 }}
                d="M148.234,15.646 C146.826,16.824 145.646,17.09 143.896,17.395 L150.513,29.143 L146.674,29.143 L140.095,17.395 L128.805,17.395 L128.805,29.143 L125.456,29.143 L125.456,0.743 L139.677,0.743 C144.276,0.743 145.57,0.894 147.737,2.304 C149.942,3.746 151.195,6.522 151.195,9.108 C151.195,11.578 150.134,14.049 148.234,15.646 Z M141.653,4.088 L128.805,4.088 L128.805,14.049 L141.045,14.049 C143.594,14.049 144.773,13.975 145.911,13.176 C147.167,12.301 147.850001,10.666 147.850001,9.147 C147.852,5.418 144.963,4.088 141.653,4.088 Z"
              ></path>
              <polygon
                style={{ fill: Colors.grey33 }}
                points="188.909 29.144 178.872 13.214 171 20.703 171 29.143 167.618 29.143 167.618 0.743 171 0.743 171 16.254 186.778 0.743 191.229 0.743 181.265 10.703 192.9 29.143 188.909 29.143"
              ></polygon>
              <path
                style={{ fill: Colors.grey33 }}
                d="M229.279,15.342 C227.531,16.75 225.326,17.053 223.157,17.053 L210.763,17.053 L210.763,29.144 L207.417,29.144 L207.417,0.744 L223.156,0.744 C228.481,0.744 232.132001,3.214 232.132001,8.842 C232.133,11.237 231.179,13.821 229.279,15.342 Z M226.771,4.697 C226.125,4.316 225.212,4.088 223.158,4.088 L210.764,4.088 L210.764,13.67 L223.158,13.67 C226.542,13.67 228.785,12.453 228.785,8.803 C228.784,7.207 228.177,5.535 226.771,4.697 Z"
              ></path>
              <path
                style={{ fill: Colors.grey33 }}
                d="M269.841,29.144 L265.203,19.373 L251.518,19.373 L247.03,29.144 L243.303,29.144 L256.839,0.744 L260.26,0.744 L273.566,29.144 L269.841,29.144 Z M258.512,4.811 L253.191,16.066 L263.721,16.066 L258.512,4.811 Z"
              ></path>
              <polygon
                style={{ fill: Colors.grey33 }}
                points="298.846 4.088 298.846 29.144 295.5 29.144 295.5 4.088 285.121 4.088 285.121 0.743 309.264 0.743 309.264 4.088"
              ></polygon>
              <polygon
                style={{ fill: Colors.grey33 }}
                points="347.316 29.144 347.316 15.989 329.066 15.989 329.066 29.144 325.682 29.144 325.682 0.744 329.066 0.744 329.066 12.644 347.316 12.644 347.316 0.744 350.7 0.744 350.7 29.144"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ) : (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 185.19 50" role="img">
      <title>SparkPath logo</title>
      <g>
        <g>
          <path d="M67.72,29.7H59.49V20h8v1.15H60.61v3H64.2v1.14H60.61v3.33h7.11ZM64.25,18.09H63.11l1-1.62H65.7Z" fill={Colors.grey33}/>
          <path d="M78.78,29.7H77.67V21.15H74.14V20h8.23v1.15H78.84V29.7Z" fill={Colors.grey33}/>
          <path d="M90.87,29.7H89.72V20h1.15Z" fill={Colors.grey33}/>
          <path d="M108,29.7h-1.15l-6.26-7.91h0V29.7H99.43V20h1.15l6.29,8h0V20h1.15v9.7Z" fill={Colors.grey33}/>
          <path d="M125.69,28a7.68,7.68,0,0,0-.56.53,5.25,5.25,0,0,1-.91.65,6.23,6.23,0,0,1-1.35.53,7.25,7.25,0,0,1-1.85.23,6.63,6.63,0,0,1-2-.32,5.7,5.7,0,0,1-1.73-.94,5,5,0,0,1-1.24-1.59,5.41,5.41,0,0,1,0-4.41A4.82,4.82,0,0,1,119,20.12a5.68,5.68,0,0,1,1.85-.33,6.46,6.46,0,0,1,1.79.24,6.63,6.63,0,0,1,1.36.56,5.26,5.26,0,0,1,1,.64l.56.5-.83.8c-.12-.12-.32-.27-.53-.44a5.54,5.54,0,0,0-.82-.53,4.93,4.93,0,0,0-1.12-.44,4.74,4.74,0,0,0-1.35-.18,4.49,4.49,0,0,0-1.68.32,4.11,4.11,0,0,0-2.12,2.12,3.6,3.6,0,0,0-.29,1.44,3.74,3.74,0,0,0,.29,1.47,4.14,4.14,0,0,0,.86,1.27,4.57,4.57,0,0,0,1.35.88,4.27,4.27,0,0,0,1.79.32,5,5,0,0,0,1.5-.2,4.29,4.29,0,0,0,1.09-.47,4.38,4.38,0,0,0,.77-.56l.47-.44Z" fill={Colors.grey33}/>
          <path d="M141.51,29.7h-8.23V20h8v1.15h-6.82v3H138v1.14h-3.58v3.33h7.11V29.7Z" fill={Colors.grey33}/>
          <path d="M156.25,29.7H149V20h1.15v8.56h6.09Z" fill={Colors.grey33}/>
          <path d="M170.25,29.7H163V20h1.15v8.56h6.09Z" fill={Colors.grey33}/>
          <path d="M185.16,29.7h-8.24V20h8v1.15h-6.82v3h3.59v1.14h-3.59v3.33h7.12V29.7Z" fill={Colors.grey33}/>
        </g>
        <circle cx="24.97" cy="25" r="24.97" fill={Colors.purple78}/>
        <path d="M37.77,23.78l-10.1-1.45-1.25-10.1-1.24-10-1.43,10L22.3,22.3,12.2,23.55l-10,1.24,10,1.43,10.11,1.45,1.25,10.1,1.24,10,1.43-10,1.44-10.1,10.1-1.26,10-1.23ZM25.08,12.39l1.37,11A2.18,2.18,0,0,0,25,22.84a2.11,2.11,0,0,0-1.47.59ZM25.79,25a.82.82,0,1,1-.82-.81A.82.82,0,0,1,25.79,25Zm-13.42-.11,11-1.37A2.18,2.18,0,0,0,22.81,25a2.14,2.14,0,0,0,.59,1.47ZM24.86,37.61l-1.36-11a2.14,2.14,0,0,0,2.94,0Zm1.69-11.14a2.15,2.15,0,0,0,0-2.94l11,1.58Z" fill={Colors.white}/>
      </g>
    </svg>
  );

const Like = (props: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
  >
    <path
      d="M44.95,18.94c-3.39,0-6.15-2.74-6.15-6.13c0-3.39,2.74-6.15,6.13-6.15s6.15,2.74,6.15,6.13
	v0.01C51.08,16.19,48.34,18.94,44.95,18.94L44.95,18.94z M14.24,18.94c-3.39,0-6.14-2.75-6.14-6.14s2.75-6.14,6.14-6.14
	s6.14,2.75,6.14,6.14l0,0C20.38,16.19,17.64,18.94,14.24,18.94C14.25,18.94,14.24,18.94,14.24,18.94z M13.47,32.87
	c7.4,8.9,20.62,10.12,29.53,2.72c0.99-0.82,1.9-1.73,2.72-2.72c1.72-1.87,4.64-2,6.51-0.28c1.75,1.6,1.99,4.27,0.56,6.17
	C42.1,51.58,23.05,53.3,10.23,42.61c-1.4-1.16-2.69-2.45-3.85-3.85c-1.53-2.03-1.13-4.92,0.9-6.45c1.89-1.43,4.56-1.19,6.17,0.56
	H13.47z"
    />
  </svg>
);

const Dislike = (props: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
  >
    <path
      d="M44.89,23.81c-3.39,0-6.15-2.74-6.15-6.13c0-3.39,2.74-6.15,6.13-6.15s6.15,2.74,6.15,6.13
	v0.01C51.03,21.06,48.28,23.81,44.89,23.81L44.89,23.81z M14.18,11.53c3.39,0,6.15,2.74,6.15,6.13c0,3.39-2.74,6.15-6.13,6.15
	s-6.15-2.74-6.15-6.13v-0.01C8.05,14.28,10.79,11.53,14.18,11.53L14.18,11.53z M12.64,39.16h33.78c2.54-0.06,4.66,1.95,4.72,4.49
	s-1.95,4.66-4.49,4.72c-0.07,0-0.15,0-0.22,0H12.64c-2.54,0.06-4.66-1.95-4.72-4.49s1.95-4.66,4.49-4.72
	C12.5,39.16,12.57,39.16,12.64,39.16z"
    />
  </svg>
);

const Superlike = (props: Props) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 60 60"
    xmlSpace="preserve"
  >
    <g>
      <path
        d="M57.04,16.16c-0.07-0.26-0.15-0.51-0.25-0.76c-1.41-3.37-5.09-5.17-8.62-4.22l-1.36,0.36l-0.38-1.36
		c-0.93-3.53-4.34-5.83-7.96-5.36c-3.71,0.57-6.26,4.03-5.7,7.75c0.04,0.27,0.1,0.53,0.17,0.79l3.72,13.42
		c0.24,0.87,1.14,1.39,2.02,1.15l13.47-3.49C55.79,23.5,57.98,19.8,57.04,16.16z"
      />
      <path
        d="M7.09,24.46l13.46,3.47c0.87,0.22,1.76-0.29,2.02-1.15l3.72-13.42c1-3.62-1.12-7.37-4.73-8.37
		c-0.26-0.07-0.53-0.13-0.79-0.17c-3.62-0.46-7.02,1.83-7.96,5.36l-0.38,1.36l-1.36-0.36c-3.53-0.96-7.22,0.85-8.62,4.22
		c-1.36,3.5,0.37,7.44,3.87,8.81C6.57,24.31,6.83,24.39,7.09,24.46z"
      />
      <path
        d="M51.07,37.23c-0.39-0.06-0.8-0.03-1.19,0.09c-6.6,1.81-13.43,2.65-20.27,2.51
		c-6.84,0.14-13.67-0.71-20.27-2.51c-1.39-0.44-2.87,0.33-3.31,1.72c-0.12,0.39-0.15,0.81-0.09,1.22
		c1.52,9.06,13.68,15.35,23.66,15.35s22.14-6.32,23.67-15.36C53.5,38.81,52.51,37.45,51.07,37.23z"
      />
    </g>
  </svg>
);

// EXPORTS
export { Logo, Like, Dislike, Superlike };
