// IMPORTS
import styled from 'styled-components';
import Color from 'color';
import Colors from '../Colors';

// MAIN COMPONENTS
export const Container = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  input {
    margin-right: 10px;

    :focus {
      outline: none;
      border-color: ${Colors.black};
      box-shadow: 0px 0px 0px 2px
      ${Color(Colors.black)
        .rgb()
        .toString()};
    }
  }
`
