import ReactGA from 'react-ga';
import {validateRole} from '../middleware'

export function changeCode({
  code
} : any) {
  ReactGA.event({
    category: 'Account',
    action: 'Change code',
    label: code
  })
}

export function changeDetails() {
  ReactGA.event({
    category: 'Account',
    action: 'Change details'
  })
}

export function changePassword() {
  ReactGA.event({
    category: 'Account',
    action: 'Change password'
  })
}

export default {
  changeCode: validateRole(changeCode),
  changeDetails: validateRole(changeDetails),
  changePassword: validateRole(changePassword)
}