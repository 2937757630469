// IMPORTS
import { combineReducers, Reducer } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { AUTH_REDUCER } from '../containers/Auth/reducer';
import { NAV_REDUCER } from '../containers/NavigationContainer/reducer';
import { ACCOUNT_REDUCER } from '../containers/AccountContainer/reducer';
import { RESULT_REDUCER } from '../containers/CardResultContainer/reducer';
import { STUDENT_REDUCER } from '../containers/StudentsContainer/reducer';
import { SUPPORT_REDUCER } from '../containers/SupportContainer/reducer';
import { CLASSLINK_REDUCER } from '../containers/ClasslinkContainer/reducer';

// TYPES
type KeyMap<T = any> = { [key: string]: T };

// REDUCERS
const ctx = require.context('../containers', true, /reducer.ts$/);
const allReducers = ctx.keys().reduce(
  (prev, i) => {
    for (const [reducerName, reducerCall] of Object.entries(ctx(i))) {
      prev[reducerName] = reducerCall;
    }
    return prev;
  },
  {} as KeyMap
);

// ROOT REDUCER
const rootReducer = (history: History) =>
  (combineReducers({
    router: connectRouter(history),
    ...allReducers
  }) as unknown) as Reducer<AppState>;

// EXPORTS
export type AppState = {
  authReducer: AUTH_REDUCER;
  navReducer: NAV_REDUCER;
  accountReducer: ACCOUNT_REDUCER;
  resultReducer: RESULT_REDUCER;
  studentsReducer: STUDENT_REDUCER;
  supportReducer: SUPPORT_REDUCER;
  router: RouterState;
  classlinkReducer: CLASSLINK_REDUCER;
};

export default rootReducer;
