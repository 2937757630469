import ReactGA from 'react-ga';
import {validateRole} from '../../middleware'

export function submitSupportRequest() {
  ReactGA.event({
    category: 'Help',
    action: 'Submit support request'
  })
}

export default {
  submitSupportRequest: validateRole(submitSupportRequest)
}